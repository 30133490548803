<template>
    <div class="gate-article-add">
      <PublishHeader :currentMenu="$t('editorMenus.articlePublish')"/>
      <div class="article-container">
        <div class="editor-card">
          <div class="editor-title el-input-title">
            <el-input v-model="articleTitle" :placeholder="$t('views.editorArticle.titlePh')" maxlength="100" show-word-limit></el-input>
          </div>
          <div class="editor-title el-input-title">
            <el-input
              type="textarea"
              :rows="2"
              maxlength="3000"
              :placeholder="$t('views.editorArticle.introPh')"
              v-model="introduction" />
          </div>
          <TinyEditor ref="articleEditor" @wordCountChange="wordCountChange"/>
        </div>
        <div class="option-card">
          <div class="option-title">{{ $t('views.editorArticle.setting') }}</div>
          <ArticleOptionFormVue ref="articleOption"/>
        </div>
      </div>
      <div class="style-spacer" />
      <ArticleFooter :total="wordCount" @onSave="onSave" @onPreview="onPreview" @onPost="onPost" @autoSave="onSave" :isDraft="isDraft"/>
    </div>
  </template>
  
  <script>
  import TinyEditor from '@/components/gateEditor/tinyEditor.vue';
  import ArticleOptionFormVue from './components/articleOptionForm.vue';
  import ArticleFooter from './components/articleFooter.vue';
  import PublishHeader  from '@/layout/publishHeader/index.vue';
  import { queryArticleDraft, saveArticle } from '@/api/article';
  import { ArticleAuditStatus } from '@/config/article';
  import { EditorTypes, PageModes } from '@/config/stat';
  import { throttle } from '@/utils/throttle';
  import { exportPV } from '@/utils/auth';
  export default {
    name: 'gate-article-add',
    components: { TinyEditor, ArticleOptionFormVue, PublishHeader, ArticleFooter },
    data() {
      return {
        articleTitle: '',
        introduction: '',
        articleId: '', // 知识文章
        userId: '', // 用户Id
        editor_type: EditorTypes.Editor_Type_1,
        wordCount: 0,
        isDraft: true
      }
    },
    mounted() {
      const articleId = this.$route.query.id;
      if (articleId){
        this.articleId = articleId;
        this.getArticleDraft();
      }
      // this.getArticleDraft();
      exportPV();
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    methods: {
      onSave: throttle(async function(){
        const articleOption = await this.$refs.articleOption.submitForm();
        console.log(articleOption);
        const params = this.checkArticle(articleOption, true);
        if (params) {
          console.log('params:', params);
          params.status = ArticleAuditStatus.Status_Draft;
          this.saveArticleDraft(params, this.$t('views.editorArticle.saveSuccess'));
        }
      }, 2000),
      onPreview: throttle(async function() {
        const articleOption = await this.$refs.articleOption.submitForm();
        console.log(articleOption);
        const params = this.checkArticle(articleOption);
        if (params) {
          params.status = ArticleAuditStatus.Status_Draft;
          const result = await this.saveArticleDraft(params, this.$t('views.editorArticle.saveSuccess'));
          if (!result) {
            this.$message.error(this.$t('views.editorArticle.saveError'))
            return;
          }
          window.open(`/knowledge/detail?id=${this.articleId}&type=${PageModes.Preview}`)
        }
      }, 2000),
      onPost: throttle(async function() {
        const articleOption = await this.$refs.articleOption.submitForm();
        console.log(articleOption);
        const params = this.checkArticle(articleOption);
        if (params) {
          // params.status = ArticleAuditStatus.Status_Draft;
          params.status = ArticleAuditStatus.Status_Release_Ing;
          const result = this.saveArticleDraft(params, this.$t('views.editorArticle.postSuccess'));
          if (!result) {
            this.$message.error(this.$t('views.editorArticle.postError'))
            return;
          }
          setTimeout(() => {
            this.$router.push({path: '/personal/home'})
          }, 200)
        }
      }, 2000),
      checkArticle(option, isSave = false) {
        const content = this.getArticleContent();
        if (!isSave && this.articleTitle === '') {
          this.$message.warning(this.$t('views.editorArticle.titleEmpty'));
          return false;
        }
        if (!isSave && content === '') {
          this.$message.warning(this.$t('views.editorArticle.contentEmpty'));
          return false;
        }
        const baseData = {
          label: option.label
        };
        const otherData = {
          tools_id: option.tools_id,
          lore_essay_id: option.lore_essay_id,
          medias: {
            cover: option.cover
          }
        };

        const params = {
          lore_essay_id: this.articleId,
          lore_essay_type: option.lore_essay_type,
          title: this.articleTitle,
          profiles: this.introduction,
          content,
          editor_type: this.editor_type,
          base_data: baseData,
          other_data: otherData
        };

        return params;
      },
      getArticleContent() {
        return this.$refs.articleEditor.getContent();
      },
      recoveryArticleContent(content) {
        this.$refs.articleEditor.setContent(content);
      },
      async saveArticleDraft(draft, msg){
        const result = await saveArticle(draft);
        if (result.code === 0){
          if(msg) {
            this.$message.success(msg);
          }
          const { data: { audit_base_data: articleInfo } } = result;
          const { lore_essay_id } = articleInfo;
          if (lore_essay_id && this.articleId !== lore_essay_id) {
            this.$router.replace({
              path: '/editor/article',
              query: {
                id: lore_essay_id
              }
            })
          }
          this.articleId = lore_essay_id;
          return true;
        }
        return false;
      },
      async getArticleDraft() {
        if(!this.articleId) {
          return;
        }
        const result = await queryArticleDraft({ lore_essay_id: this.articleId });
        if (result.code === 0) {
          const { data: { base_data: draftData } } = result;
          this.restoreDraft(draftData);
          if (draftData.status !== ArticleAuditStatus.Status_Draft) {
            this.isDraft = false;
          }
        }
        console.log(result);
      },
      restoreDraft(data) {
        const { content, title, profiles, base_data, other_data, lore_essay_type, editor_type } = data;
        // 基础内容
        this.recoveryArticleContent(content);
        this.articleTitle = title;
        this.introduction = profiles;
        this.editor_type = editor_type;
        // setting内容
        const { label } = base_data;
        const { lore_essay_id, medias, tools_id } = other_data;
        this.$refs.articleOption.restoreForm({
          label,
          tools_id,
          lore_essay_id,
          cover: medias.cover,
          lore_essay_type
        });
      },
      wordCountChange(count) {
        this.wordCount = count;
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  body{
    background: #000;
  }
  .gate-article-add{
    display: flex;
    justify-content: center;
    background: #000;
    flex-direction: column;
    align-items: center;
    min-width: 1280px;
    .article-container{
      width: 1136px;
      margin-top: 40px;
      .editor-card{
        display: flex;
        flex-direction: column;
        background: #0F0F0F;
        padding: 32px;
        .el-input-title{
          border-bottom: 1px solid rgba(255, 255, 255, 0.10);
          :deep(.el-input__inner){
            padding-right: 70px !important;
          }
        }
        
        .editor-title{
          font-size: 32px;
          color: #333;
          width: 100%;
          padding: 10px 0px 15px 4px;
          :deep(.el-input__inner){
            font-size: 32px;
            color: #333;
            padding: 30px 0px 20px 4px;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: none;
          }
          :deep(.el-input__count-inner){
            color: #333;
            font-size: 14px;
            background: transparent;
          }
          :deep(.el-textarea__inner){
            font-size: 16px;
            color: #333;
            border: none;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-left: 5px;
            height: 89px;
            overflow: hidden;
            border: none;
            resize: none;
          }
        }
      }
      .divisionLine{
        margin: 40px 0;
        width: 100%;
        height: 1px;
        flex-shrink: 0;
        background: rgba(255, 255, 255, 0.10);
      }
      .option-card{
        margin-top: 40px;
        background-color: #0f0f0f;
        padding: 32px;
        .option-title{
          color: #DDD;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 30px */
        }
      }
    }
    .style-spacer{
      width: 100%;
      height: 96px;
    }
  }
  
  </style>
  