<template>
  <div class="guest-mixed">
    <div class="user">
      <div class="thumb">
        <div class="avatar">
          <UserAvatar :src="userInfo.user_url" :userName="userInfo.user_name"></UserAvatar>
        </div>
        <div class="name">{{userInfo.user_name}}</div>
        <div class="introduce">{{userInfo.user_profiles}}</div>
      </div>
       <div class="follow-btn-warp">
        <button class="follow-btn" v-if="userInfo.is_followee" @click="handleFollo(folloStatus.unfollow)">{{ $t('following.followedBtn') }}</button>
        <button class="follow-btn no-follow" v-else-if="userInfo.is_follower && !userInfo.is_followee" @click="handleFollo(folloStatus.follow)">{{ $t('following.followMutualBtn') }}</button>
        <button class="follow-btn no-follow" v-else @click="handleFollo(folloStatus.follow)">{{ $t('following.followBtn') }}</button>
      </div>
    </div>
    <!-- 个人信息下图片 暂时隐藏 -->
    <!-- <img src="@/assets/knowledge/user-center-pendant.png" alt class="pendant" /> -->
    <InfoEditDialog ref="infoEditDialog" :limit="50"></InfoEditDialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import UserAvatar from '@/components/userAvatar/index.vue'
import InfoEditDialog from './infoEditDialog.vue'
import { getLitUserData } from '@/api/gateUser'
import { followOption } from '@/api/following'
import { FolloStatus } from '@/config/personal'

@Component({
  name: 'PersonalCenter',
  components: { UserAvatar, InfoEditDialog },
})
class PersonalInfo extends Vue {
  @Prop({ default: '' }) public userId!: String
  public userInfo = {} as any;
  public folloStatus = {
    follow: FolloStatus.follow,
    unfollow: FolloStatus.unfollow,
  }

  mounted () {
    console.log('fetchUserData', this.userId)
    if(this.userId) {
      this.fetchUserData();
    }
  }

  public fetchUserData() {
    getLitUserData({
      user_id: this.userId
    }).then(res => {
      console.log('res=>', res);
      const { data } = res;
      if (data) {
        this.userInfo = data.data;
      }
    }).catch((e) => {
      console.log('e:', e);
    })
  }

  public async handleFollo(operate: number) {
    const res: any = await followOption({
      user_id: this.userId,
      operate
    })
    console.log('handleFollo res', res)

    if (res.code === 0) {
     this.fetchUserData()
    }
  }

  @Watch('userId')
  updateUserId() {
    this.fetchUserData();
  }
}
export default PersonalInfo
</script>

<style lang="scss" scoped>
  $buttonColor: #00FFF6;
  $textColor: #666666;

  .guest-mixed {
    position: absolute;
    top: -240px;
    right: 0;
    width: 416px;
    flex-shrink: 0;

    .user {
      width: 100%;
      background-color: rgba(20, 20, 20, 0.8);
      backdrop-filter: blur(15px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 72px;
      position: relative;

      .icon-edit {
        width: 40px;
        height: 40px;
        background: url(@/assets/knowledge/edit-icon.png) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }

      .thumb {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 72px 16px;

        .avatar {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #141414;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          color: #fff;
          font-size: 32px;
          margin: 13px 0 5px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }

        .introduce {
          color: rgba(255, 255, 255, 0.5);
          font-size: 20px;
          width: 100%;
          word-wrap: break-word;
          text-align: center;
        }

      }

      >.btn {
        margin-top: 40px;
        width: 100%;
        height: 40px;
        background-color: $buttonColor;
        font-size: 16px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .follow-btn-warp{
        .follow-btn{
          width: 376px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          color: var(--text-text-3666, $textColor);
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          border: 1px solid var(--text-text-3666, $textColor);
          &:hover{
            border: 1px solid var(--text-text-2999, #999);
          }
          // &:active{
          //   border: 1px solid var(--text-text-2999, $buttonColor);
          //   color: var(--text-text-2999, $buttonColor);
          // }
        }
        .no-follow{
          color: var(--text-text-2999, $buttonColor);
          border: 1px solid $buttonColor;
          &:hover{
           border: 1px solid #99FFFB;;
          }
          // &:active{
          //   color: var(--text-text-2999, $textColor);
          //   border: 1px solid var(--text-text-3666, $textColor);
          // }
        }
      }
    }

    .pendant {
      width: 100%;
      height: auto;
      margin-top: 24px;
    }
  }
</style>
<style>
</style>

