<template>
  <div class="reply-view-box" v-if="allReplyList.length > 0">
    <div v-for="(reply, j) in allReplyList" :key="j" class="reply-line">
      <ReplyItem :content="reply" :comment="comment" @onReply="onReply" @onLike="onReplyLike" :commentType="commentType" :gId="gId" @onDelete="onDelete" @onReport="onReport"/>
      <div v-show="replyType === ReplyModes.ForReply && commentTarget.comment_id === comment.comment_id && reply.reply_id === replyTarget.reply_id " class="reply-input-box">
        <Reply @onSend="onSend" :replyType="replyType" :replyTarget="replyTarget.user_data?.user_name" :isSimple="isSimple"/>
      </div>
    </div>
    <div class="more" v-if="hasMore" @click="onViewMore">
      <div class="tips">{{ $t('comment.viewMore') }}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6 5.6L14 7L8 13L2 7L3.4 5.6L8 10.175L12.6 5.6Z" fill="#666666"/>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import Reply from './components/replyReplyInput.vue';
import ReplyItem from './components/replyItem.vue';
import { selectReplyList } from '@/api/comment';
import { CommentTypes, LikeTypes, ReplyModes } from '@/config/stat';
import { userLike } from '@/api/userLikeCollect';
import { mapGetters } from 'vuex';
// 评论回复
export default {
    name:'ArticleComment',
    props: {
      content: {
        type: Array,
        default: () => []
      },
      comment: {
        type: Object,
        default: () => {}
      },
      commentTarget: {
        type: Object,
        default: () => {}
      },
      replyType: {
        type: Number,
        default: ReplyModes.ForComment
      },
      commentType: {
        type: Number,
        default: CommentTypes.Comment_Type_Lore_Essay
      },
      gId: {
        type: String,
        default: ''
      },
      auditReplyList: {
        type: Array,
        default: () => []
      },
      isSimple: {
        type: Boolean,
        default: false
      }
    },
    components: { Reply, ReplyItem },
    data(){
      return {
          ReplyModes,
          replyTarget: {},
          pages: {
            current: 0,
            size: 10,
            total: 8
          },
          isInit: true,
          replyList: [],
          hasMore: false
      }
    },
    mounted() {
      this.replyList = this.content
      this.hasMore = this.comment.reply_num > this.replyList.length;
      if (this.hasMore) {
        console.log('replyView:', this.comment, this.replyList, this.auditReplyList)
      }
    },
    methods: {
      onReport(type, reply) {
        this.$emit('onReport', type, {
          g_id: reply.reply_id,
          key_values:['' + this.commentType, this.gId, this.comment.comment_id]
        })
      },
      onDelete() {
        this.isInit = true;
        this.pages = {
          current: 0,
          size: 10,
          total: 8
        };
        this.getReplyList()
        this.$emit('onDelete')
      },
      onReply(replyTarget, commentTarget) {
        this.replyTarget = replyTarget
        this.$emit('onReply', replyTarget, commentTarget)
      },
      // 对回复进行回复
      onSend(content) {
        this.$emit('onSend', content)
      },
      onViewMore() {
        if (!this.isInit) {
          this.pages.current ++;
        }
        this.getReplyList()
      },
      async getReplyList() {
        console.log('comment:===>', this.comment)
        const params = {
          comment_type: this.commentType,
          g_id: this.gId,
          comment_id: this.comment.comment_id,
          comm_limit: this.pages.size,
          comm_offset: this.pages.current
        }
        console.log('params:', params);
        const result = await selectReplyList(params);
        if (result.code === 0 && result.data) {
          console.log('comment result:', result)
          if (result.data.info.length < this.pages.size) {
            this.hasMore = false
          }
          this.pages.total = Number(result.data.comm_total)
          if (this.isInit) {
            this.replyList = result.data.info || []
            this.isInit = false;
            return;
          }
          this.replyList = this.replyList.concat(result.data.info || [])
          console.log(this.replyList)
        }
      },
      async onReplyLike(isLike, reply) {
        const params = {
          like_type: this.commentType === CommentTypes.Comment_Type_Lore_Essay ? LikeTypes.Like_Type_Lore_Essay_Reply : LikeTypes.Like_Type_Works_Game_Deme_Reply,
          gid: reply.reply_id,
          is_like: isLike ? 1 : 0,
          key_values:['' + this.commentType, this.gId, this.comment.comment_id]
        }
        const result = await userLike(params)
        if (result.code === 0) {
          // 找到当前评论节点，直接修改节点数据
          const target = this.allReplyList.find(item => item.reply_id === reply.reply_id);
          target.is_like = isLike;
          if (isLike) {
            target.like_num += 1;
          } else {
            target.like_num = target.like_num > 0 ? target.like_num - 1 : 0
          }
        }
      },
      getAuditReplyByComment() {
        return this.auditReplyList.filter(item => item.comment_id === this.com);
      }
    },
    computed: {
      allReplyList() {
        const currentReplyList = this.auditReplyList.filter(item => item.comment_id === this.comment.comment_id);
        return currentReplyList.concat(this.replyList);
      },
      ...mapGetters(['userInfo'])
    },
    watch: {
      content() {
        this.isInit = true;
        this.hasMore = this.comment.reply_num > this.replyList.length;
        this.pages = {
          current: 0,
          size: 10,
          total: 8
        };
        this.replyList = this.content;
      }
    } 
}
</script>
<style lang="scss" scoped>
.reply-view-box{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  margin: 12px 0 0 50px;
  width: calc(100% - 50px);
  .reply-line{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    .reply-input-box{
      width: 100%;
    }
  }
  .more{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
    margin-left: 48px;
    .tips{
      font-size: 14px;
      line-height: 22px;
      color: #666;
    }
  }
}
</style>
