  <template>
    <div>
      <el-dialog
        :title="$t('views.incentiveAccount.dialog.incentiveDetailTitle')"
        :visible.sync="visible"
        width="725px"
        :show-close="false"
        custom-class="incentive-detail-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <div class="title-line" slot="title">
          <div class="title">{{ $t('views.incentiveAccount.dialog.incentiveDetailTitle') }}</div>
          <div class="close-btn" @click="visible = false"></div>
        </div>
        <LitTable :columns="columns" :tableData="gridData" :height="300" :headerCellStyle="showCellStyle" :cellStyle="{ border: '0.5px solid #333' }"/>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { copyToClipboard } from '@/utils/dom';
  import LitTable from '@/components/table/index.vue'
import { fetchIncentiveDetail } from '@/api/token';
import { Table_Column_Types } from '@/config/table';
  export default {
    name: 'incentive-detail-dialog',
    components: { LitTable },
    props: {
    },
    data() {
      return {
        visible: false,
        columns: [
          { label: 'views.incentiveAccount.tableTh.incentiveTime', prop: 'create_time', type: Table_Column_Types.Time, timeFormat: 'YYYY-mm-dd HH:MM:SS', align: 'left' },
          { label: 'views.incentiveAccount.tableTh.incentiveGift', prop: 'op_value', align: 'left', type: Table_Column_Types.Custom, showTxt: (row) => this.showGiftTxt(row) },
        ],
        gridData: [],
        info: null
      }
    },
    mounted() {
    },
    methods: {
      open(info) {
        this.key = info.keys;
        this.info = info;
        this.visible = true;
        this.fetchBillingDetail()
      },
      onClose() {
        this.visible = false;
        this.$emit('onClose');
      },
      async fetchBillingDetail() {
        const result = await fetchIncentiveDetail({
          comm_limit: 100,
          comm_offset: 0,
          project_id: this.info.project_id
        })
        console.log('result:', result)
        if (result.code === 0 && result.data) {
          const { list } = result.data;
          this.gridData = list;
        }
      },
      showGiftTxt(row) {
        return `$${Number(row).toFixed(4)}`
      },
      copyText() {
        copyToClipboard(this.key);
        const tips = this.$t('tips.succ.copy');
        this.$message.success(tips);
      },
      showCellStyle(arg) {
        const { columnIndex } = arg;
        if (columnIndex === 1) {
          return { borderTop: '1px solid #333', borderBottom: '1px solid #333', borderLeft: 'none', borderRight: '1px solid #333' }
        }
        return { borderTop: '1px solid #333', borderBottom: '1px solid #333', borderLeft: '1px solid #333',  borderRight: 'none' }
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .incentive-detail-dialog{
    .title-line {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      .title{
        color: #DDD;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        // align-self: stretch;
      }
      .close-btn{
        width: 24px;
        height: 24px;
        background: url('@/assets/postApplication/apiKey/icon_dialog_close.png');
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    .list-wrap{
      height: 380px;
      overflow-y: scroll;
    }
    :deep(.lit-table-wrap[data-v-6ed10cf1] .el-table th.el-table__cell){
      border-bottom:1px solid #333 ;
    }
  }
  
  </style>
  