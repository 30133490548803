<template>
  <div class="card-wrap" @click="onClick">
    <div class="card">
      <div :class="{'thumb': true, 'hand-status': value.status === ArticleAuditStatus.Status_Audit_OK}"
        v-lazy-container="{ selector: 'img', error: require('@/assets/knowledge/card-normal-default.png'), loading: require('@/assets/knowledge/card-normal-default.png') }" @click.stop="gotoSeeWorksDetail">
        <img :data-src="value.other_data.medias.cover" alt="">
        <!-- <div class="tag-wrap">
          <div class="tag" v-for="(tag, index) in value.base_data.label" :key="index">{{ tag }}</div>
        </div> -->
        <!-- 浏览量 -->
        <div class="views-warp" v-if="value.status == ArticleAuditStatus.Status_Audit_OK">
          <div class="count-wrap">
            <div class="views-icon"></div>
            <div class="count">{{ value.stat_data && value.stat_data.browse_num }} Views</div>
          </div>
          <div class="count-wrap">
            <div class="experience-icon"></div>
            <div class="count">{{ value.stat_data && value.stat_data.download_num }} Players</div>
          </div>
        </div>
      </div>
      <div class="mixed">
        <div class="info-wrap">
          <div class="title-line">
            <div :class="{'title': true, 'hand-status': value.status === ArticleAuditStatus.Status_Audit_OK}" @click.stop="gotoSeeWorksDetail">{{ value.works_title }}</div>
            <div class="creator-box">
              <el-popover
                placement="top"
                v-if="value.is_token_apply"
                trigger="hover">
                <div class="join-status" v-if="value.token_audit_status !== Audit_State.Status_Default"
                :style="{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', gap: '5px', background: '#000', padding: '13px 16px'}" >
                  <div class="normal-txt" :style="{ color: '#999' }">{{ $t('views.createApplication.joinCreator.joinStatus') }}: </div>
                  <div class="normal-txt" v-if="value.token_audit_status === Audit_State.Status_Draft" :style="{ color: '#999' }">{{ $t('auditState.wait') }}</div>
                  <div class="success-txt" v-if="value.token_audit_status === Audit_State.Status_Audit_OK" :style="{ color: '#00FFF6' }">{{ $t('auditState.success') }}</div>
                  <div class="fail-txt" v-if="value.token_audit_status === Audit_State.Status_Audit_Fail" :style="{ color: '#FF5C51' }">
                    {{ $t('auditState.fail') }}
                  </div>
                </div>
                <div class="creator-audit-fail" v-if="value.token_audit_status === Audit_State.Status_Audit_Fail"  slot="reference"></div>
                <div class="creator-audit-success" v-if="value.token_audit_status === Audit_State.Status_Audit_OK"  slot="reference"></div>
                <div class="creator-audit-applying" v-if="value.token_audit_status === Audit_State.Status_Draft"  slot="reference"></div>
              </el-popover>
            </div>
          </div>
          <div class="state-info">
            <div class="id-txt">Demo ID · {{ value.works_id }}</div>
            <div class="id-txt" v-if="value.status === ArticleAuditStatus.Status_Audit_OK">{{ stateInfoTxt }}</div>
          </div>
          <div class="profile">{{ value.profiles }}</div>
        </div>
        <div class="status-wrap">
          <div class="status" v-if="value.status === ArticleAuditStatus.Status_Audit_OK">
            <div class="icon publish-icon"></div>
            <div class="status-txt publish-txt">{{ $t('publishState.published') }}</div>
          </div>
          <div class="status" v-if="value.status === ArticleAuditStatus.Status_Release_Ing">
            <div class="icon audit-icon"></div>
            <div class="status-txt audit-txt">{{ $t('publishState.underReview') }}</div>
          </div>
          <div class="status" v-if="value.status === ArticleAuditStatus.Status_Draft">
            <div class="icon draft-icon"></div>
            <div class="status-txt draft-txt">{{ $t('publishState.draft') }}</div>
          </div>
          <div class="status" v-if="value.status === ArticleAuditStatus.Status_Audit_Fail">
            <div class="icon fail-icon"></div>
            <div class="status-txt fail-txt">{{ $t('publishState.fail') }}</div>
            <el-tooltip :content="value.audit_opinion" placement="top" style="color: #fff;" class="status-txt">
              <div class="audit-fail-icon"></div>
            </el-tooltip>
          </div>
          <div class="option">
              <!-- 点赞 -->
              <div :class="{'option-item': true, active: value.is_like == 1 }" @click.stop="onClickLike"
                v-if="value.status === ArticleAuditStatus.Status_Audit_OK">
                <div class="icon">
                  <img v-if="value.is_like" src="@/assets/knowledge/icon_like_active.png" alt="" />
                  <img v-else src="@/assets/knowledge/icon-likes.png" alt="" />
                </div>
                <div class="count">{{formatCount(value.stat_data && value.stat_data.like_num)}}</div>
              </div>
              <!-- 评论 -->
              <div :class="{'option-item': true, active: value.is_like == 1 }" @click.stop="onClickComment"
                v-if="value.status === ArticleAuditStatus.Status_Audit_OK">
                <div class="icon">
                  <img v-if="value.is_comment" src="@/assets/knowledge/comment-icon-active.png" alt="" />
                  <img v-else src="@/assets/knowledge/comment-icon.png" alt="" />
                </div>
                <div class="count">{{formatCount(value.stat_data && value.stat_data.comment_num)}}</div>
              </div>
              <!-- 收藏 -->
              <div class="option-item" @click.stop="onClickCollect"
                v-if="value.status === ArticleAuditStatus.Status_Audit_OK">
                <div class="icon">
                  <img v-if="value.is_collect" src="@/assets/knowledge/icon_favorite_active.png" alt="">
                  <img v-else src="@/assets/knowledge/icon_favorite.png" alt="">
                </div>
                <div class="count">{{value.stat_data && value.stat_data.collect_num}}</div>
              </div>
              <!-- 分享 -->
              <!-- <el-popover
                v-if="value.status === ArticleAuditStatus.Status_Audit_OK"
                placement="bottom"
                v-model="popoverVisible"
                title=""
                width="160"
                trigger="click">
                <ShareCard @share="copyFunc"/>
                <div class="btn shareBtn" slot="reference" >
                  <div class="icon share"></div>
                  <div class="info" >{{ $t('views.gameWorksDetail.share') }}</div>
                </div>
              </el-popover> -->
              <!-- 设置 -->
              <div class="option-item settings-item" @click.stop>
                <div class="icon">
                  <img src="@/assets/knowledge/settings_icon.png" alt="" />
                </div>
                <div class="settings-menu-warp">
                  <div class="settings-menu-box">
                    <div class="menu-item" @click.stop="onClickEdit">{{ $t('personCard.edit') }}</div>
                    <div class="menu-item" @click.stop="onClickDelete">{{ $t('personCard.delete') }}</div>
                  </div>
                </div>
               
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { normalizeDate, formatDate } from '@/utils/format'
import { abbreviateNumber } from '@/utils/statFormat'
import UserAvatar from '@/components/userAvatar/index.vue'
import { ArticleAuditStatus } from '@/config/article'
import ShareCard from '@/components/statStatus/shareCard.vue'
import { RouteActions } from '@/config/stat'
import { Audit_State } from '@/config/token'

@Component({
  name: 'demo-list-card',
  components: {
    UserAvatar,
    ShareCard
  },
})
class CardLong extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
  })
  public value

  public ArticleAuditStatus = ArticleAuditStatus;
  public Audit_State = Audit_State;
  public popoverVisible = false;

  mounted() {
    
  }

  public onClick () {
    this.$emit('click', this.value)
  }

  public onClickLike () {
    this.$emit('onLike', this.value)
  }

  public onClickCollect () {
    this.$emit('onCollect', this.value)
  }

  public onClickComment () {
    this.$router.push({ path: `/gameworks/detail`, query: { id: this.value.works_id, actionType: RouteActions.Comment as any }  })
    // this.$emit('onComment', this.value)
  }

  public gotoSeeWorksDetail() {
    if (this.value.status === ArticleAuditStatus.Status_Audit_OK){
      this.$router.push({ path: `/gameworks/detail`, query: { id: this.value.works_id } })
    }
  }

  public onClickEdit () {
    this.$router.push({ path: `/application/editworks?id=${this.value.works_id}` })
  }

  public onClickDelete () {
    this.$emit('onClickDelete', this.value)
  }

  public formatTime (time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime, 'YYYY/mm/dd')
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }

    //复制
  public copyFunc() {
    const currentLink = window.location.href;
    console.log('currentLink:', currentLink)
    return;
    navigator.clipboard.writeText(`${currentLink}?pictDetailsId=${this.value.works_id}`)
    const tips:any = this.$t('tips.succ.copy');
    this.$message.success(tips);
  }

  get stateInfoTxt() {
    let nTime = normalizeDate(this.value.release_time)
    const releaseTime = nTime ? formatDate(nTime, 'dd/mm/YYYY'): '';
    return `${this.$t('views.createApplication.recentPublishedDate')} · ${releaseTime}`
  }
}

export default CardLong
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.card-wrap {
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  .card {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    .thumb {
      width: 320px;
      height: 100%;
      flex-shrink: 0;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .tag-wrap{
        position: absolute;
        left: 16px;
        top: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 4px;
        width: calc(100% - 32px);
        flex-wrap: wrap;
        .tag{
          padding: 4px 8px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          overflow: hidden;
          color: rgba(255, 255, 255, 0.60);
          background: rgba(0, 0, 0, 0.50);
        }
      }

      .views-warp{
        position: absolute;
        left: 16px;
        bottom: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 12px;
        .count-wrap{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: row;
          gap: 3px;
          .views-icon{
            width: 24px;
            height: 24px;
            background: url('@/assets/statIcon/icon_views_grey.png') no-repeat;
            background-size: 100% 100%;
          }
          .experience-icon{
            width: 24px;
            height: 24px;
            background: url('@/assets/statIcon/icon_experience.png') no-repeat;
            background-size: 100% 100%;
          }
          .count{
            margin-left: 3px;
            font-size: 12px;
            font-weight: 400;
            text-wrap: nowrap;
            color: var(--text-text-6-fff-60, rgba(255, 255, 255, 0.60));
          }
        }
      }
    }

    .mixed {
      flex: 1;
      flex-shrink: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin-left: 24px;
      .info-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        .title-line{
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          width: 100%;
          .title{
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            color: #ddd;
            line-height: 150%;
            
          }
          .creator-box{
            .join-status{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              gap: 5px;
              .normal-txt {
                color: #666;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
              }
              .success-txt {
                color: #00FFF6;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
              }
              .fail-txt {
                color: #FF5C51;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
              }
            }
            .creator-audit-fail{
              width: 32px;
              height: 32px;
              background: url('@/assets/postApplication/icon_creator_fail.png');
              background-size: 100% 100%;
            }
            .creator-audit-success{
              width: 32px;
              height: 32px;
              background: url('@/assets/postApplication/icon_creator_success.png');
              background-size: 100% 100%;
            }
            .creator-audit-applying{
              width: 32px;
              height: 32px;
              background: url('@/assets/postApplication/icon_creator_applying.png');
              background-size: 100% 100%;
            }
          }
        }
        .state-info{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          .id-txt{
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: #666;
            line-height: 18px;
          }
        }
        .profile{
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: #666;
          line-height: 22px;
          display:-webkit-box;
          -webkit-box-orient:vertical;
          text-overflow:ellipsis;
          -webkit-line-clamp:2;//例如超过2行显示省略号
          overflow:hidden;
          word-break: break-all;
          white-space: pre-wrap;
        }
      }
      .status-wrap{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .status{
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          .icon{
            width: 24px;
            height: 24px;
            background-size: 100% 100%;
          }
          .status-txt{
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
          .publish-icon{
            background-image: url('@/assets/postApplication/icon_publish.png');
          }
          .publish-txt{
            color: #00FFF6;
          }
          .draft-icon{
            background-image: url('@/assets/postApplication/icon_draft.png');
          }
          .draft-txt{
            color: #666;
          }
          .audit-icon{
            background-image: url('@/assets/postApplication/icon_wait.png');
          }
          .audit-txt{
            color: #666;
          }
          .fail-icon{
            background-image: url('@/assets/postApplication/icon_fail.png');
          }
          .audit-fail-icon{
            width: 14px;
            height: 14px;
            background-size: 100% 100%;
            background-image: url('@/assets/postApplication/icon_warn.png');
            cursor: pointer;
          }

          .fail-txt{
            color: #FF5C51;
          }
        }
        .option {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
            gap: 24px;

            .option-item {
              display: flex;
              align-items: center;
              cursor: pointer;

              .icon {
                width: 24px;
                height: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .count {
                font-size: 12px;
                margin-left: 2px;
                color: $gray_6;
              }

              .edit-size {
                color: #666;
                font-size: 14px;
                font-weight: 500;
                margin-left: 2px;
              }
            }

            .browse-item {
              cursor: default;
            }

            .settings-item {
              position: relative;

              &:hover {
                .settings-menu-warp {
                  display: block;
                }
              }

              .settings-menu-warp {
                display: none;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 20px;
                width: 100px;
                height: 105px;
                z-index: 1000;

                &::before {
                  content: '';
                  width: 10px;
                  height: 6px;
                  position: absolute;
                  top: 14px;
                  left: 50%;
                  transform: translateX(-50%);
                  background: url('@/assets/triangle.png') no-repeat;
                  background-size: 100% 100%;
                }

                .settings-menu-box {
                  width: 100px;
                  height: 80px;
                  margin-top: 20px;
                  padding: 6px;
                  color: #666;
                  font-size: 14px;
                  font-weight: 500;
                  background: #141414;
                  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);

                  .menu-item {
                    width: 88px;
                    height: 34px;
                    padding: 6px 8px;
                    text-align: center;

                    &:hover {
                      color: #DDD;
                      background: rgba(255, 255, 255, 0.10);
                    }
                  }
                }
              }
            }
        }
        .btn{
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          .icon{
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .share{
            background: url('@/assets/postApplication/icon_interaction.png') no-repeat;
            background-size: 100%;
          }
          .info {
            color: #666;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; 
            margin-left: 3px;
          }
        }
        .shareBtn:hover{
          .share{
            background: url('@/assets/postApplication/icon_interaction.png') no-repeat;
            background-size: 100%;
          }
          .info{
            color: #00FFF6;
          }
        }
      }
    }
    .hand-status{
      cursor: pointer;
    }
  }
}
</style>