<template>
  <div class="inspect-chat-view" ref="chatView">
    <div class="msg-wrap" >
      <div class="msg-line" v-for="(item, index) in filterMsg" :key="index">
        <UserMsg v-if="item.type === 'User'" :msgInfo="item" />
        <AIMsg v-if="item.type === 'AI'" :msgInfo="item" />
      </div>
    </div>
  </div>
  </template>
  
<script>
import UserMsg from './userMsg.vue'
import AIMsg from './AiMsg.vue'

import { chatMsgShowModes, chatMsgShowModeTypes } from '@/config/aiInspect'
export default {
  name: 'inspect-chat-view',
  props: {
    chatMsgs: {
      type: Array,
      default: () => []
    },
  },
  components: {
    UserMsg,
    AIMsg,
  },
  data() {
    return {
      chatMsgShowModes,
      chatMsgShowModeTypes
    }
  },
  mounted() {
  },
  methods: {
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.chatView.scrollTop = this.$refs.chatView.scrollHeight;
      })
    },
  },
  computed: {
    filterMsg() {
      if (this.chatMsgShowMode === chatMsgShowModeTypes.ALL) {
        return this.chatMsgs;
      }
      return this.chatMsgs
    }
  }
}
</script>
  
<style lang="scss" scoped>
  .inspect-chat-view{
    width: 100%;
    border-radius: 8px;
    padding: 8px 12px;
    height: calc(100% - 70px);
    overflow-y: auto;
    .msg-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      .msg-line{
        width: 100%;
      }
    }
  }
</style>
  