
// 游戏作品类型
export enum GameWorksTypes {
  Works_Type_Game_0 = 0,        //其他
  Works_Type_Game_Demo = 1,        //游戏Dome
  Works_Type_Game_Res = 2,         //游戏资源
  Works_Type_Art_Creativity = 3   //美术创意
}


export enum GameWorksPlatform {  //标识什么平台的游戏
  windows = 0,
  macos = 1,
  pcWeb = 2,
  android = 3,
  ios = 4,
  h5 = 5,
  xbox = 6,
  ps = 7,
  wii = 8,  
}

// 可下载状态
export enum DownloadState {
  Download_Yes = 1,
  Download_No = 0
}

