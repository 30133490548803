<template>
  <div :class="{'case-swiper-card': true, 'case-normal': !isHover, 'case-hover': isHover}" :style="{ width: width + 'px', height: height + 'px' }" @mouseenter="mouseOverFunc" @mouseleave="mouseoutFunc">
    <div class="video-container" v-show="isHover">
      <video autoplay loop class="imgs"
                muted width="100%" :src="videoUrl"></video>
    </div>
    <div :class="{'game-img': true, 'game-img-hover': isHover}" :style="{ background: 'url(' + bgPath + ') no-repeat', backgroundSize: '100% 100%'}"></div>
    <div class="info">
      <div :class="{'title': true, 'title-hover': isHover}">
        {{ title }}
      </div>
      <div class="introduction" v-if="!isHover">
        {{ introduction }}
      </div>
      <div class="tagsBox" v-if="isHover">
        <div class="tag" v-for="(item, index) in tags" :key="index"> {{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'case-swiper-card',
  props: {
    width: {
      type: Number,
      default: 590
    },
    height: {
      type: Number,
      default: 460
    },
    title: {
      type: String,
      default: ''
    },
    bgPath: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: []
    },
    videoUrl: {
      type: String,
      default: 'https://oasis-1300799399.cos.ap-nanjing.myqcloud.com/video-pub/8f794370f3a078b7efca282784138a706848fa13-167140642-stream.mp4'
    }
  },
  data() {
    return {
      isHover: false,
    }
  },
  mounted() {
     ;
  },
  methods: {
    clickMore() {
      this.$emit('onMore');
    },
    mouseOverFunc(){
      if (!this.isHover) {
        this.isHover = true
      }
    },
    mouseoutFunc() {
      if (this.isHover) {
        this.isHover = false;
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.case-swiper-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 630px;
  // border: 1px solid rgba(255, 255, 255, 0.24);
  .game-img{
    position: absolute;
    width: 100%;
    height: 315px;
    left: 0;
    top: 0;
    transition: all ease-in-out 0.6s;
  }
  .game-img-hover{
    left: 15px;
    top: 220px;
    width: 200px;
    height: 120px;
    z-index: 1000;
    box-shadow: 0px 16px 48px 4px rgba(0, 0, 0, 0.30);
  }
  .video-container{
    width: 100%;
    height: 315px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .info{
    width: 100%;
    height: 165px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 30px 20px 10px;
    padding: 20px;
    .title{
      color: #F9FAFB;
      text-align: center;
      
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; 
      transition: all ease-in-out 0.5s;
      margin-top: 20px;
    }
    .title-hover{
      padding: 20px 0 0 0;
    }
    .introduction{
      color: #FFF;
      height: 76px;
      width: 100%;
      
      color: rgba(255, 255, 255, 0.65);
      display: -webkit-box;
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap:break-word;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      // opacity: 0.6;
    }
    .tagsBox{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      .tag{
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: 1px solid rgba(255, 255, 255, 0.20);
        color: rgba(255, 255, 255, 0.85);
        
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin-right: 20px;
      }
    }
  }
}
.case-normal{
  border: 4px solid #000;
  background: linear-gradient(0deg, #001E13 0%, #001E13 100%), linear-gradient(0deg, rgba(14, 159, 110, 0.10) 0%, rgba(14, 159, 110, 0.10) 100%), #191919;
}
.case-hover{
  border: 4px solid  #0E9F6E;
  background: #0E9F6E;
  /* GeekGreen */
  // box-shadow: 0px 22px 70px 4px rgba(58, 242, 154, 0.25);
  box-shadow: 0px 22px 40px 4px rgba(58, 242, 154, 0.25);
}
</style>
