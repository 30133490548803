<template>
  <div class="activity-incentives-main">
    <ActivityTitle :title="$t('views.tokenActivity.incentives.title')" :subTitle="$t('views.tokenActivity.incentives.subTitle')"/>
    <div class="content">
      <div class="step-bg"></div>
      <IncentivesStep :iconImg="beginImg" :title="$t('views.tokenActivity.incentives.beginStep.title')" titleIndex="01" :list="list.begin" :left="20" :top="40" />
      <IncentivesStep :iconImg="onlineImg" :title="$t('views.tokenActivity.incentives.onlineStep.title')" titleIndex="02" :list="list.online" :left="20" :top="720" />
      <IncentivesStep :iconImg="gloryImg" :title="$t('views.tokenActivity.incentives.gloryStep.title')" titleIndex="03" :list="list.glory" :left="794" :top="330"/>
    </div>
    <div class="incentives-tips">
      <div class="txt">
        {{ $t('views.tokenActivity.incentives.extraTips1') }}
        <span class="high-light">{{ $t('views.tokenActivity.incentives.extraTips2') }}</span>
      </div>
      <div class="txt">
        {{ $t('views.tokenActivity.incentives.extraTips3') }}
        <span class="high-light"> {{ $t('views.tokenActivity.incentives.extraTips4') }}</span>
      </div>
    </div>
  </div>
</template>
  
<script>
import ActivityTitle from '../components/activityTitle.vue'
import IncentivesStep from '../components/incentivesStep.vue'
import beginImg  from '@/assets/activity/token/incentives-begin.png'
import gloryImg  from '@/assets/activity/token/incentives-glory.png'
import onlineImg  from '@/assets/activity/token/incentives-online.png'
export default {
  components: { ActivityTitle, IncentivesStep },
  name: 'activity-incentives-main',
  data () {
    return {
      activityTime: '2024.06.01-09.01',
      beginImg,
      gloryImg,
      onlineImg,
      list: {
        begin: [
          { content: 'views.tokenActivity.incentives.beginStep.content1', tips: 'views.tokenActivity.incentives.beginStep.tips1' }
        ],
        online: [
          { content: 'views.tokenActivity.incentives.onlineStep.content1', tips: 'views.tokenActivity.incentives.onlineStep.tips1' }
        ],
        glory: [
          { content: 'views.tokenActivity.incentives.gloryStep.content1', tips: 'views.tokenActivity.incentives.gloryStep.tips1' },
          { content: 'views.tokenActivity.incentives.gloryStep.content2', tips: 'views.tokenActivity.incentives.gloryStep.tips2' }
        ]
      }
    }
  },
  mounted () {
  },

}
</script>
<style lang="scss" scoped>
$content-width: 1360px;
.activity-incentives-main{
  min-width: $content-width;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  .content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: $content-width;
    height: 1263px;
    gap: 24px;
    position: relative;
    .step-bg{
      width: 275px;
      height: 1126px;
      background: url('@/assets/activity/token/incentives-step.png');
      background-size: 100% 100%;
    }
  }
  .incentives-tips{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 138px;
    .txt{
      color: #CFDCDB;
      font-size: 26px;
      text-align: center;
      max-width: 1360px;
      .high-light{
        color: #00FFEE;
        font-weight: bold;
        font-size: 26px;
      }
      .hight-cursor{
        color: #00FFEE;
        cursor: pointer;
        font-size: 26px;
        text-decoration: underline;
      }
    }
    
  }

}
</style>