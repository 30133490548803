<template>
  <div class="video-carousel">
    <div class="preview-box">
      <div class="video" v-if="currentCover.type === 'video'">
        <video
          :src="currentCover.url"
          @ended="autoNext"
          ref="videoPlayer"
          class="video-player"
          autoplay
          preload="auto"
          controls
          controlslist="nodownload"
          webkit-playsinline
          x5-playsinline
          playsinline
          muted
        />
      </div>
      <div class="img" v-if="currentCover.type !== 'video'" :style="{ backgroundImage: 'url(' + currentCover.cover + ')' }"></div>
      <!-- <div class="left-btn arrow-btn"></div>
      <div class="right-btn arrow-btn"></div> -->
    </div>
    <div class="carousel-box">
      <div class="left-btn btn" @click="gotoPre"></div>
      <div class="cover-container-window">
        <div class="cover-container">
          <div :class="{'cover': true, 'cover-active': item.id === currentCover.id }" v-for="(item, index) in mediaList" :key="index" @click.stop="selectCover(index)">
            <img :src="item.cover" v-if="item.type === 'img'"/>
            <div class="videoBtn" v-if="item.type === 'video'">
              <video
                :src="currentCover.url"
                :ref="'videoBtn' + index"
                width="100%"
                height="100%"
                muted
                preload="auto"
                @loadeddata="setVideoBtnPoster(index)"
              />
            </div>
            <div class="mask" v-if="item.id !== currentCover.id"></div>
          </div>
        </div>
      </div>
      
      <div class="right-btn btn" @click="gotoNext"></div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { isImagePath } from '@/utils/statFormat';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'VideoCarousel'
})
class VideoCarousel extends Vue {
  @Prop({ default: () => [] }) public movie!: any[]
  @Prop({ default: () => [] }) public picture!: any[]

  public timer = null;
  public currentIndex = 0;
  public mediaList = [] as any[];

  mounted() {
    this.countMediaList();
  }

  public setVideoBtnPoster(index) {
    const target = this.$refs['videoBtn' + index];
    if (target) {
      target[0].currentTime = 0;
    }
  }

  public autoNext() {
    if (this.currentIndex < this.mediaList.length - 1) {
      this.gotoNext();
    } else {
      this.currentIndex = 0;
    }
  }

  public gotoNext() {
    if (this.currentIndex < this.mediaList.length - 1) {
      this.currentIndex ++;
    }
  }
  public gotoPre() {
    if (this.currentIndex > 0) {
      this.currentIndex --;
    }
  }

  public countMediaList() {
    const currentArr = [] as any[];
    const mediaSet = this.movie.concat(this.picture);
    mediaSet.forEach((item, index) => {
      if (item && isImagePath(item)) {
          currentArr.push({
            id: index,
            cover: item,
            type: 'img'
          });
        } else {
          currentArr.push({
            id: index,
            url: item,
            type: 'video'
          });
        }
    })
    console.log('current', this.movie, this.picture)
    console.log('currentArr', currentArr)
    this.mediaList = currentArr;
  }
  
  public selectCover(index) {
    this.currentIndex = index;
  }

  get currentCover() {
    return this.mediaList[this.currentIndex] || {};
  }

  @Watch('movie')
  movieChange(val) {
    this.countMediaList();
  }
  @Watch('picture')
  pictureChange(val) {
    this.countMediaList();
  }
  
}

export default VideoCarousel
</script>
  
<style lang="scss" scoped>
.video-carousel{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .preview-box {
    position: relative;
    width: 100%;
    @media screen and (min-width: 1920px) {
      .video, .img{
        height: 720px;
      }
    }
    @media screen and (max-width: 1919px) {
      .video, .img{
        height: 619px;
      }
    }
    @media screen and (max-width: 1679px) {
      .video, .img{
        height: 450px;
      }
    }
    .video, .img{
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      .video-player{
        width: 100%;
        height: 100%;
      }
    }
  }
  .carousel-box{
    display: flex;
    justify-content: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    overflow: visible;
    .btn{
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    .left-btn{
      background: url('../../../assets/gameWorks/detail/icon_carousel_left.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
    }
    .left-btn:hover{
      background: url('../../../assets/gameWorks/detail/icon_carousel_left_active.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
    }
    .right-btn{
      background: url('../../../assets/gameWorks/detail/icon_carousel_right.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 8px;
    }
    .right-btn:hover{
      background: url('../../../assets/gameWorks/detail/icon_carousel_right_active.png') no-repeat;
      background-size: 100% 100%;
      margin-left: 8px;
    }
    .cover-container-window{
      display: flex;
      flex: 1;
      overflow-x: scroll;
      overflow-y: hidden;
      transition: transform 0.3s ease;
    }
    .cover-container{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 64px;
      .cover {
        width: 110px;
        height: 62px;
        margin-left: 8px;
        margin-right: 8px;
        position: relative;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
          background-size: contain;
          // object-fit: contain;
          background-repeat: no-repeat;
        }
        
        .videoBtn{
          width: 100%;
          height: 100%;
        }
        .mask{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
        }
        .mask:hover{
          background: rgba(0, 0, 0, 0);
        }
      }
      .cover:first-child{
        margin-left: 0;
      }
      .cover:last-child{
        margin-right: 0;
      }
      .cover-active{
        border: 1px solid #00FFF6;
      }
    }
  }

}
</style>