<template>
  <div v-html="content" :class="'cherry cherry-markdown'" class="gate-cherry"/>
</template>

<script lang="ts">
// cherry markdown content显示
export default {
  name: 'cherry-view',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss" scoped>
.gate-cherry {
  padding: 20px 45px 20px 20px;
}
</style>
