<template>
  <div class="gate-guide">
    <guideBanner navType="gate"/>
    <guideEvents />
    <guideAbout />
    <guideCase />
    <guidePartners />
    <guideContact />
    <guideFooter />
  </div>
</template>

<script lang="ts">
import guideBanner from '../guide/guideBanner.vue';
import guideAbout from '../guide/guideAbout.vue';
import guideEvents from '../guide/guideEvents.vue';
import guideCase from '../guide/guideCase.vue';
import guidePartners from '../guide/guidePartners.vue';
import guideContact from '../guide/guideContact.vue';
import guideFooter from '../guide/guideFooter.vue';

export default {
  name: 'gateHome',
  components: { guideBanner, guideAbout, guideEvents, guideCase, guidePartners, guideContact, guideFooter },
  data() {
    return {
      cacheBgColor: '',
    }
  },
  mounted() {
    this.cacheBgColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#000';
    window.scrollTo({
      top: 0
    });
  },
  methods: {

  },
  beforeDestroy() {
    document.body.style.backgroundColor = this.cacheBgColor;
  }
}
</script>

<style lang="scss" scoped>
.gate-guide{
  min-width: 1200px;
  background-position: top;
  background-image: url("https://cdn.litgate.ai/gate/guide/guide_bg.png");
  background-size: 100% auto; /* 横向占满，竖向自适应 */
  background-repeat: repeat-y;
  :lang(zh-cn) {
    font-family: 'guideCNR', sans-serif;
  }
  
  :lang(en) {
    font-family: 'guideENR', sans-serif;
  }
}
</style>