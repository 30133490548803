import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c89d1494&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=c89d1494&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c89d1494",
  null
  
)

export default component.exports