import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { UserState } from '@/store/interface/index';

import store from '@/store';
import { GateReportEnvironments } from '@/config/report';

export type IUserState = UserState;

interface OpenOptions {
  gId: string,
  env: GateReportEnvironments,
  options?: Object
}

@Module({ dynamic: true, store, name: 'report' })
class GateReport extends VuexModule {
  private _reportOptions = {};
  private _visible = false;
  private _gId = '';
  private _reportEnvironments = GateReportEnvironments.Complaint_Lore_Essay

  @Action
  public async open(options: OpenOptions) {
    this.openReport(options)
  }
  @Action
  public close() {
    this.closeReport()
  }

  @Mutation
  private openReport(openOptions) {
   const { gId, env, options = {} } = openOptions
   this._reportOptions = options;
   this._visible = true;
   this._gId = gId;
   this._reportEnvironments = env;
  }
  @Mutation
  private closeReport() {
   this._visible = false;
  }

  get reportOptions() {
    return this._reportOptions
  }
  get visible() {
    return this._visible
  }
  get reportEnvironments() {
    return this._reportEnvironments;
  }
  get gId() {
    return this._gId;
  }
}

export const ReportModule = getModule(GateReport);
