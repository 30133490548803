<template>
  <div class="application-side-menu">
    <div class="menu-list">
      <div :class="{'menu-item': true, 'active': active === item.key}" v-for="(item, index) in menuList" :key="index" @click="changeActive(item)">{{ $t(item.label) }}</div>
    </div>
    <div class="activity-item" :style="{ backgroundImage: 'url(' + getBg() + ')' }" @click="gotoActivity"></div>
  </div>
</template>
  
  <script>
  import { applicationMenuKey } from '@/config/application'
  import activityImg from '@/assets/activity/token/token-banner.png'
import activityImgEn from '@/assets/activity/token/token-banner-en.png'
import { getLanguage } from '@/lang'
  export default {
    name: 'side-menu',
    props: {
      active: {
        type: Number,
        default: applicationMenuKey.demoManagement
      }
    },
    data() {
      return {
        timer: null,
        lastSaveTime: Date.now(),
        waitSaveTime: 0,
        menuList: [
          { label: 'applicationMenus.demoManagement', key: applicationMenuKey.demoManagement },
          // { label: 'applicationMenus.apiKey', key: applicationMenuKey.apiKey },
          { label: 'applicationMenus.incentiveAccount', key: applicationMenuKey.incentiveAccount },
          { label: 'applicationMenus.billingDetail', key: applicationMenuKey.billingDetail },
          // { label: 'applicationMenus.developmentDocuments', key: applicationMenuKey.developmentDocuments },
        ],
        activityImg,
        activityImgEn,
        lang: 'en'
      }
    },
    mounted() {
      this.lang = getLanguage();
    },
    methods: {
      changeActive(item) {
        console.log('item');
        this.$emit('onChange', item.key);
      },
      getBg() {
        return this.lang === 'en' ? this.activityImgEn : this.activityImg
      },
      gotoActivity() {
        this.$router.push({
          path: '/activity/token'
        })
      }
    },
    watch: {
      '$i18n.locale': function(newLocale) {
        this.lang = newLocale;
      }
    },
    destroyed() {
    }
  }
  </script>
  <style lang="scss">
  .application-side-menu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    align-items: flex-start;
    width: 232px;
    .menu-list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 4px;
      align-items: flex-start;
      width: 232px;
      .menu-item{
        color: #fff;
        padding: 8px 8px 8px 12px;
        width: 100%;
        color: #ddd;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        text-transform: capitalize;
        cursor: pointer;
      }
      .active{
        background: #141414;
      }
    }
    .activity-item{
      width: 232px;
      height: 120px;
      background-size: 100% 100%;
      cursor: pointer;
    }
    .activity-item:hover{
      border: 0.5px solid #141414;
    }
  }
  </style>