import { SessionKey } from '@/config';
import { Local } from '@/utils/storage';

const ReportUrlMap = {
  "widthSession": `/log/report`,
  "noSession": `/public/log/report`,
}
function sendReport(url, path, event, param, token) {

}

/**
 * 发送上报请求
 * @param path 路径
 * @param event 事件类型
 * @param content 内容 json
 * @param withSession 是否带登录态
 */
export function AReport(path, event, param = {}, withSession = true) {
  const sSession = Local.get(SessionKey);
  // 无session回退到不带登录态
  if (!sSession && withSession) withSession = false;
  sendReport(ReportUrlMap[withSession ? "widthSession" : "noSession"], path, event, param, withSession ? sSession.token : '');
}

