<template>
  <div class="case-carousel-card" :style="{ width: width + 'px', height: height + 'px' }">
    <div class="videoContainer" @click="onPlay">
      <video preload="auto" loop class="imgs" :controls="isPlaying"
                muted width="100%" :src="getVideoUrl()" ref="videoEle" controlslist="nodownload"></video>
    </div>
    <div class="info" v-show="!isPlaying">
      <div class="title">
        {{ title }}
      </div>
      <div class="introduction">
        {{ introduction }}
      </div>
    </div>
    <div class="play-btn" v-show="!isPlaying" ></div>
  </div>
</template>

<script>
import { getLanguage } from '@/lang';

export default {
  name: 'case-carousel-card',
  props: {
    width: {
      type: Number,
      default: 1200
    },
    height: {
      type: Number,
      default: 680
    },
    title: {
      type: String,
      default: ''
    },
    bgPath: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      enVideoUrl: 'https://cdn.litgate.ai/gate/guide/video/show_case_en.mp4',
      zhVideoUrl: 'https://cdn.litgate.ai/gate/guide/video/show_case_zh.mp4',
      isPlaying: false,
      isPaused: false
    }
  },
  mounted() {
    this.$refs.videoEle.addEventListener('play', this.onPlay);
    this.$refs.videoEle.addEventListener('pause', this.onPause);
    this.$refs.videoEle.addEventListener('ended', this.onEnded);
  },
  beforeDestroy() {
    this.$refs.videoEle.removeEventListener('play', this.onPlay);
    this.$refs.videoEle.removeEventListener('pause', this.onPause);
    this.$refs.videoEle.removeEventListener('ended', this.onEnded);
  },

  methods: {
    playVideo() {

    },
    onPlay() {
      this.isPlaying = true;
      this.isPaused = false;
    },
    onPause() {
      this.isPlaying = false;
      this.isPaused = true;
    },
    onEnded() {
      this.isPlaying = false;
      this.isPaused = false;
    },
    getVideoUrl() {
      if (getLanguage() === 'en') {
        return this.enVideoUrl;
      }
      return this.zhVideoUrl;
    }
  }
}

</script>

<style lang="scss" scoped>
.case-carousel-card{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  .videoContainer{
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
  }
  .info{
    width: 100%;
    height: 258px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 100px 24px 32px 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
    .title{
      color: #FFF;
      text-align: center;
      
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; 
    }
    .introduction{
      color: #FFF;
      
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      opacity: 0.6;
    }
  }
  .play-btn{
    width: 120px;
    height: 120px;
    background: url('@/assets/guide/case/play_btn.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: calc(50% - 60px);
    top: calc(50% - 120px);
    cursor: pointer;
    pointer-events: none;
  }
}
</style>
