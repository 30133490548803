<template>
  <div class="gate-views">
    <div v-html="content" :class="'mce-content-body'" ref="tinyViewContent" v-if="editorType === Editor_Types.Tiny" />
    <div v-html="content" :class="'cherry cherry-markdown'" :style="{padding: '20px 45px 20px 20px'}" ref="tinyViewContent" v-if="editorType === Editor_Types.CherryMarkdown"/>
  </div>
</template>

<script lang="ts">
import { previewImage } from '@/utils/previewImage';
import { Editor_Types } from '@/config/stat';

// tinymce富文本显示html
export default {
  name: 'cherry-view',
  props: {
    content: {
      type: String,
      default: ''
    },
    editorType: {
      type: Number,
      default: Editor_Types.Tiny
    },
    mainTitle: {
      type: String,
      default: 'h1'
    },
    subLevelTitle: {
      type: String,
      default: 'h2'
    },
  },
  data() {
    return {
      Editor_Types,
      headlineList: [],
      directoryActive: -1,
      scrolling: false,
      timer: null,
      contentHeight: 100,
      formatTimer: null,
      formatCount: 0
    }
  },
  mounted() {
    this.formatContent();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    addImagePreview() {
      if (!this.$refs.tinyViewContent) return;
      const imgElements = this.$refs.tinyViewContent.querySelectorAll('img');
      imgElements.forEach((ele) => {
        ele.addEventListener('click', this.previewImage);
        ele.style.cursor = 'zoom-in';
      });
    },
    previewImage(event) {
      const target = event.target;
      console.log(event, target);
      previewImage(target.src);
    },
    removeVideoDownload() {
      // controlslist="nodownload"
      if (!this.$refs.tinyViewContent) return;
      const videoElements = this.$refs.tinyViewContent.querySelectorAll('video');
      videoElements.forEach((ele) => {
        ele.setAttribute('controlslist', 'nodownload');
      });
    },
    getDirectoryList() {
      if (!this.$refs.tinyViewContent) {
        return;
      }
      const headlineNodes = this.$refs.tinyViewContent.querySelectorAll(`${this.mainTitle}, ${this.subLevelTitle}`);
      const headlineList = [] as any[];
      headlineNodes.forEach((item, index) => {
        const id = `knowledge-headline-${item.tagName}-${index}`;
        item.setAttribute('id', id);
        headlineList.push({
          title: item.textContent,
          tagName: item.tagName,
          node: item,
          offsetTop: item.offsetTop - 118,
          id
        })
      });
      this.headlineList= headlineList;
      this.onCountContentHeight();
    },
    onCountContentHeight() {
      if (!this.$refs.tinyViewContent) {
        return;
      }
      const contentHeight = this.$refs.tinyViewContent.offsetHeight;
      if (this.contentHeight !== contentHeight) {
        this.contentHeight = contentHeight;
        this.$emit('onContent', { height: contentHeight });
      }
    },
    handleScroll(e) {
      const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let target = -1;
      for(let i = 0; i < this.headlineList.length; i ++) {
        if (this.headlineList[i].title === '') {
          continue;
        }
        if (this.headlineList[i].offsetTop <= scrollTop) {
          target = i;
          continue;
        }
        break;
      }
      if (this.directoryActive !== target && !this.scrolling) {
        this.$emit('directoryActive', target);
      }
      this.directoryActive = target;
      this.onCountContentHeight();
    },
    setActive(info) {
      const  { tagName, index } = info;
      const node = this.$refs.tinyViewContent.querySelector(`#knowledge-headline-${tagName}-${index}`);
      if (node){
        const offsetTop = node.offsetTop - 118;
        this.scrolling = true;
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.scrolling = false
        }, 800);
      }
      this.onCountContentHeight();
    },
    // 鉴于长文章[几十万px高度]比较多。经常出现目录和格式化方法执行时(onload)，content并未真正渲染完毕。format格式处理将延迟3秒执行
    formatContent() {
      if (this.formatTimer) {
        this.removeFormatContent();
      }
      this.formatTimer = setInterval(() => {
        if (this.formatCount > 5) {
          this.removeFormatContent();
          return;
        }
        this.formatCount ++;
        this.addImagePreview();
        (window as any).Prism.highlightAll();
        this.removeVideoDownload();
        this.getDirectoryList();
      }, 3000)
    },
    removeFormatContent() {
      clearInterval(this.formatTimer);
      this.formatTimer = null;
      this.formatCount = 0;
    }
  },
  watch: {
    content() {
      this.formatContent();
    }
  },
  beforeDestroy() {
    this.removeFormatContent();
  },
}
</script>

<style lang="scss" scoped>
.gate-views{
  width: 100%;
  .gate-cherry {
    padding: 20px 45px 20px 20px;
    width: 100%;
  }
}
</style>
