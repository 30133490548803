import UploadClient, { InitUploadRequest, InitUploadResponse, setWorkerPath } from '@tencent/lscos-client';
import { initLsCosUpload } from '@/api/upload';

setWorkerPath('/lscos-client-worker/index.js');
export class LiteGateCosUpload {
    private static instance: LiteGateCosUpload;
    private static uploadQueue = [] as any[];
    private static currentUpload;
    private static hasInitLsCos = false;
    private static lsCosUploader = new UploadClient();
    private static uploading = false;
    private static timer = null as any;
  
    private constructor() {
      if (!LiteGateCosUpload.timer) {
        LiteGateCosUpload.timer = setInterval(() => LiteGateCosUpload.checkUpload(), 1000);
      }
    }
  
    public static getInstance(): LiteGateCosUpload {
      if (!LiteGateCosUpload.instance) {
        LiteGateCosUpload.instance = new LiteGateCosUpload();
      }
      if (!LiteGateCosUpload.hasInitLsCos) {
        LiteGateCosUpload.hasInitLsCos = true;
        LiteGateCosUpload.initUploadClient();
      }
      return LiteGateCosUpload.instance;
    }
  
    private static checkUpload(): void {
      if (LiteGateCosUpload.uploading) {
        return;
      }
      LiteGateCosUpload.uploadNext();
    }

    public upload(blobInfo, success, fail, type = 'blob', onProgress = (num) => {}, onFileInfo = (e) => {}): void {
      const uploadInfo = {
        blobInfo,
        success,
        fail,
        type,
        onProgress,
        onFileInfo
      };
      LiteGateCosUpload.uploadQueue.push(uploadInfo);
    }

    private static uploadNext(): void {
      if (LiteGateCosUpload.uploadQueue.length > 0) {
        LiteGateCosUpload.currentUpload = LiteGateCosUpload.uploadQueue.shift();
        LiteGateCosUpload.lsCosUpload();
      }
    }

    private static lsCosUpload(): void {
      if (!LiteGateCosUpload.hasInitLsCos) {
        LiteGateCosUpload.hasInitLsCos = true;
        LiteGateCosUpload.initUploadClient();
      }
      if (!LiteGateCosUpload.currentUpload) {
        return;
      }
      LiteGateCosUpload.uploading = true;
      if (LiteGateCosUpload.currentUpload.blobInfo.blob) {
        LiteGateCosUpload.lsCosUploader.startUpload(LiteGateCosUpload.currentUpload.blobInfo.blob(),  LiteGateCosUpload.uploadCallback as (e: InitUploadRequest) => Promise<InitUploadResponse>, 2);
      } else {
        LiteGateCosUpload.lsCosUploader.startUpload(LiteGateCosUpload.currentUpload.blobInfo,  LiteGateCosUpload.uploadCallback as (e: InitUploadRequest) => Promise<InitUploadResponse>, 2);
      }
    }

    private static initUploadClient(): void {
      LiteGateCosUpload.lsCosUploader.addEventListener('error', e => {
        LiteGateCosUpload.uploading = false;
        LiteGateCosUpload.currentUpload.fail(e);
      })

      LiteGateCosUpload.lsCosUploader.addEventListener('success', r => {
        LiteGateCosUpload.checkImageReady(r as string);
      });
      // LiteGateCosUpload.lsCosUploader.addEventListener('update', r => {
      //   const progress = (r * 100).toFixed(0);
      //   LiteGateCosUpload.currentUpload.onProgress(progress);
      // });
      LiteGateCosUpload.lsCosUploader.addEventListener('sha1', r => {
        LiteGateCosUpload.currentUpload.onProgress(Number(Number(r).toFixed(0)));
      });

    }

    private static uploadCallback(e: InitUploadRequest):  Promise<InitUploadResponse> {
      if (!e.file_name) {
        e.file_name = 'unknown_file';
      }
      LiteGateCosUpload.currentUpload.onFileInfo(e);
      return initLsCosUpload(e).then(res => {
        console.log('res', res)
        if (res.code === 0){
          return res.data;
        }
      })
    }

    private static checkImageReady(url: string): void {
      const timer = setTimeout(() => {
        LiteGateCosUpload.uploading = false;
        LiteGateCosUpload.currentUpload.success(url);
      }, 2000);
      if (LiteGateCosUpload.isImagePath(url)) {
        try{
          const img = new Image();
          img.onload = () => {
            LiteGateCosUpload.uploading = false;
            clearTimeout(timer);
            LiteGateCosUpload.currentUpload.success(url);
          }
          img.onerror = () => {};
          img.src = url;
        } catch(e) {}
      }
    }

    private static isImagePath(filePath: string): boolean {
      const imageExtensions = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
      return imageExtensions.test(filePath);
    }

  }

  
//   // 在其他地方使用单例类
//   const instance1 = Singleton.getInstance();
//   const instance2 = Singleton.getInstance();
  
//   console.log(instance1 === instance2); // 输出: true