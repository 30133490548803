<template>
  <div class="knowledge-index">
    <div class="container" id="focus-wrap">
      <Category id="category" class="category" :activeIndex="menuIndex" @change="onChangeMenu" :menu="menu"></Category>
      <div class="main">
        <!-- <div class="nav-wrap">
          <div class="nav">
            <div v-for="(item, index) in nav" :class="{ 'nav-item': true, 'active': navIndex == index }" :key="item.name"
              @click="onChangeNav(index)">{{item.name}}</div>
          </div>
          <div class="extra">
            <Dropdown class="filter" :options="options" v-model="status"></Dropdown>
          </div>
        </div> -->
        <div class="content" id="focus" v-if="!empty">
          <div class="list" :key="index" :ref="item.ref" v-for="(item, index) in menu">
            <div class="list-head">
              <div class="title">{{$t(item.name)}}</div>
              <div class="extra" v-if="item.value && item.list && item.list.length >= 6"
                @click="onClickCardMore(item.value)">
                {{$t('views.knowledge.more')}} >
              </div>
            </div>
            <div class="list-content">
              <template v-if="item.value">
                <CardNormal v-for="(_item, _index) in item.list" class="list-item" :key="_index + 'normal'"
                  :value="_item" @click="onClickCard" />
              </template>
              <template v-else>
                <CardRecommend v-for="(_item, _index) in item.list.slice(0,6)" class="list-item" :key="_index"
                  :value="_item" @click="onClickCard" />
              </template>
            </div>
          </div>
        </div>
        <pageEmpty v-if="empty"></pageEmpty>
      </div>
      <div class="mixed">
        <KnowledgeUserCard />
        
        <!-- <div class="list">
          <div class="list-head">
            <div class="title">Recommend</div>
            <div class="extra">
              <div class="icon-refresh"></div>
            </div>
          </div>
          <div class="list-content">
            <CardAuthor @click="onClickFollow" class="list-item" v-for="(item, index) in value" :key="index"
              :value="item"></CardAuthor>
          </div>
        </div> -->
        <div class="list">
          <div class="list-head">
            <div class="title">{{$t('views.knowledge.hot')}}</div>
          </div>
          <div class="list-content">
            <CardHot class="list-item" v-for="(item, index) in LoreEssayHotAll" :key="index" :value="item"
              :rank="index+1" @click="onClickCard"></CardHot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getLoreEssayHome, getLoreEssayHotAll, batchGetArticle } from '@/api/article'
import { getAdvertisePos, getLitUserData } from '@/api/gateUser'
import Category from './component/category.vue'
import CardNormal from '@/components/card/cardNormal.vue'
import CardRecommend from '@/components/card/cardRecommend.vue'
import CardHot from '@/components/card/cardHot.vue'
import CardAuthor from '@/components/card/cardAuthor.vue'
import Dropdown from '@/components/dropdown/index.vue'
import { normalizeDate, formatDate } from '@/utils/format'
import { AdvertiseTypes } from '@/config/stat'
import { ArticleTypes } from '@/config/article'
import { exportPV } from '@/utils/auth'
import { getGlobalStatData } from '@/api/gateUser'
import { GlobalStateType } from '@/config/stat'
import KnowledgeUserCard from './component/userCard.vue'
import pageEmpty from '@/components/exception/pageEmpty.vue'
import iconCategory0 from '@/assets/knowledge/icon-category-0.png'
import iconCategory0Active from '@/assets/knowledge/icon-category-0-active.png'
import iconCategory1 from '@/assets/knowledge/icon-category-1.png'
import iconCategory1Active from '@/assets/knowledge/icon-category-1-active.png'
import iconCategory2 from '@/assets/knowledge/icon-category-2.png'
import iconCategory2Active from '@/assets/knowledge/icon-category-2-active.png'
import iconCategory3 from '@/assets/knowledge/icon-category-3.png'
import iconCategory3Active from '@/assets/knowledge/icon-category-3-active.png'
import iconCategory4 from '@/assets/knowledge/icon-category-4.png'
import iconCategory4Active from '@/assets/knowledge/icon-category-4-active.png'
import iconCategory5 from '@/assets/knowledge/icon-category-5.png'
import iconCategory5Active from '@/assets/knowledge/icon-category-5-active.png'
import iconCategory6 from '@/assets/knowledge/icon-category-6.png'
import iconCategory6Active from '@/assets/knowledge/icon-category-6-active.png'
import iconCategory7 from '@/assets/knowledge/icon-category-7.png'
import iconCategory7Active from '@/assets/knowledge/icon-category-7-active.png'
import iconCategory8 from '@/assets/knowledge/icon-category-8.png'
import iconCategory8Active from '@/assets/knowledge/icon-category-8-active.png'
import iconCategory9 from '@/assets/knowledge/icon-category-9.png'
import iconCategory9Active from '@/assets/knowledge/icon-category-9-active.png'
import iconCategory10 from '@/assets/knowledge/icon-category-10.png'
import iconCategory10Active from '@/assets/knowledge/icon-category-10-active.png'
import iconCategory11 from '@/assets/knowledge/icon-category-11.png'
import iconCategory11Active from '@/assets/knowledge/icon-category-11-active.png'


@Component({
  name: 'Knowledge',
  components: {
    CardNormal,
    CardRecommend,
    Category,
    CardHot,
    CardAuthor,
    Dropdown,
    pageEmpty,
    KnowledgeUserCard
  }
})
class Knowledge extends Vue {
  public nav = [
    {
      name: 'All',
    },
    {
      name: 'Articles',
    },
    {
      name: 'Videos',
    },
  ]

  public menus: any = [
    {
      icon: iconCategory0,
      activeIcon: iconCategory0Active,
      name: 'views.knowledge.category0',
      value: ArticleTypes.Type_0,
      list: [],
      scrollTop: 0,
      ref: 'list1',
      countName: '',
      count: 0
    },
    {
      icon: iconCategory1,
      activeIcon: iconCategory1Active,
      name: 'views.knowledge.category1',
      value: ArticleTypes.Type_2D,
      list: [],
      scrollTop: 0,
      ref: 'list2',
      countName: 'd2_data',
      count: 0
    },
    {
      icon: iconCategory2,
      activeIcon: iconCategory2Active,
      name: 'views.knowledge.category2',
      value: ArticleTypes.Type_3D,
      list: [],
      scrollTop: 0,
      ref: 'list3',
      countName: 'd3_data',
      count: 0
    },
    {
      icon: iconCategory3,
      activeIcon: iconCategory3Active,
      name: 'views.knowledge.category3',
      value: ArticleTypes.Type_Video,
      list: [],
      scrollTop: 0,
      ref: 'list4',
      countName: 'video_data',
      count: 0
    },
    {
      icon: iconCategory4,
      activeIcon: iconCategory4Active,
      name: 'views.knowledge.category4',
      value: ArticleTypes.Type_Mp3,
      list: [],
      scrollTop: 0,
      ref: 'list5',
      countName: 'mp3_data',
      count: 0
    },
    {
      icon: iconCategory5,
      activeIcon: iconCategory5Active,
      name: 'views.knowledge.category5',
      value: ArticleTypes.Type_Test,
      list: [],
      scrollTop: 0,
      ref: 'list6',
      countName: 'test_data',
      count: 0
    },
    {
      icon: iconCategory6,
      activeIcon: iconCategory6Active,
      name: 'views.knowledge.category6',
      value: ArticleTypes.Type_Code,
      list: [],
      scrollTop: 0,
      ref: 'list7',
      countName: 'code_data',
      count: 0
    }, {
      icon: iconCategory8,
      activeIcon: iconCategory8Active,
      name: 'views.knowledge.category8',
      value: ArticleTypes.Type_8,
      list: [],
      scrollTop: 0,
      ref: 'list9',
      countName: 'a8_data',
      count: 0
    }, {
      icon: iconCategory7,
      activeIcon: iconCategory7Active,
      name: 'views.knowledge.category7',
      value: ArticleTypes.Type_7,
      list: [],
      scrollTop: 0,
      ref: 'list8',
      countName: 'a7_data',
      count: 0
    }, 
    {
      icon: iconCategory9,
      activeIcon: iconCategory9Active,
      name: 'views.knowledge.category9',
      value: ArticleTypes.Type_9,
      list: [],
      scrollTop: 0,
      ref: 'list10',
      countName: 'a9_data',
      count: 0
    },
    {
      icon: iconCategory10,
      activeIcon: iconCategory10Active,
      name: 'views.knowledge.category10',
      value: ArticleTypes.Type_10,
      list: [],
      scrollTop: 0,
      ref: 'list11',
      countName: 'a10_data',
      count: 0
    },
    {
      icon: iconCategory11,
      activeIcon: iconCategory11Active,
      name: 'views.knowledge.category11',
      value: ArticleTypes.Type_11,
      list: [],
      scrollTop: 0,
      ref: 'list12',
      countName: 'a11_data',
      count: 0
    },
  ]
  public menu: any = []
  public menuIndex: any = 0

  public timer: any = null
  public status: any = 'Default'
  public navIndex: Number = 0
  public page: Number = 0
  public pageSize: Number = 6
  public pageSizeHot: Number = 8
  public empty: boolean = false
  public clickLoading: boolean = false
  public localVerify: any = {}
  public LoreEssayHotAll: any[] = []
  public value = [ {}, {}, {}, {}, {} ]
  public options = [
    { label: 'Default', value: 'Default' },
    { label: 'Popular', value: 'Popular' },
    { label: 'Latest', value: 'Latest' },
  ]

  mounted () {
    const local = localStorage.getItem('oasis_verify_session')
    const _local = local ? JSON.parse(local) : {}

    this.fetchData()
    this.fetchHotData()

    this.onCalculateMenuLeft()

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.onCalculateMenuLeft)

    exportPV()
  }

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.onCalculateMenuLeft);
  }

  @Watch('$i18n.locale')
  async onI18nLocaleChange(val: string) {
    console.log('change locale')
    this.resetList();
    await this.fetchData()
    await this.fetchHotData()
  }

  public resetList() {
    this.menus.forEach(item => {
      item.list = []
    })
    this.menu.forEach(item => {
      item.list = []
    })
  }


  /* 内容滚动事件 */
  public handleScroll () {
    const child = document.querySelectorAll('#focus .list')

    for (let i = 0; i < child.length; i++) {
      const current = child[ i ].getBoundingClientRect()

      if (current.top >= 110 && current.top <= current.height - 110) {
        this.menuIndex = i
        break
      }
    }
  }

  /* 切换左导航 */
  public onChangeMenu (index?: any) {
    const currentMenu = this.menu[ index ]
    const currentRef: any = this.$refs[ currentMenu.ref ]

    if (index == this.menu.length - 1) {
      document.documentElement.scrollTop = currentRef[ 0 ].offsetTop
      return
    }

    document.documentElement.scrollTop = currentRef[ 0 ].offsetTop - 110
  }

  /* 动态计算左导航定位距离 */
  public onCalculateMenuLeft () {
    const focusWrapWidth = document.querySelectorAll('#focus-wrap')[ 0 ].clientWidth
    const categoryWidth = focusWrapWidth / 8

    document.querySelectorAll('#focus-wrap')[ 0 ].setAttribute("style", `padding-left:${ categoryWidth + 36 }px`)

    const focusLeft = document.querySelectorAll('#focus')[ 0 ].getBoundingClientRect().left

    document.querySelectorAll('#category')[ 0 ].setAttribute("style", `width:${ categoryWidth }px; left: ${ focusLeft - (categoryWidth + 36) }px`)
  }

  /* 切换Nav */
  public onChangeNav (index: Number) {
    this.navIndex = index
  }

  /* 点击Follow */
  public onClickFollow (e: any) {
    console.log('携带的参数', e)
  }

  /* 点击卡片 */
  public onClickCard (e: any) {
    const { lore_essay_id } = e

    window.open(`/knowledge/detail?id=${ lore_essay_id }`)
  }

  /* 点击卡片里的viewMore */
  public onClickCardMore (val) {
    this.$router.push({
      path: `/knowledge/more?id=${ val }`,
    })
  }

  /* 获取分类数据 */
  public async fetchData () {
    const { menus } = this
    const res: any = await getLoreEssayHome({})

    const { code, data = {}, message = '' } = res
    console.log('fetchData:', res)

    if (code !== 0) {
      this.$message.error('获取内容失败:' + message)
      return
    }

    const { base_data = [] } = data || {}

    if (!base_data.length) {
      this.empty = true
      this.menu = [];
    } else {
      this.empty = false;
      base_data.forEach((v: any) => {
        const target = menus.find(item =>item.value === Number(v.lore_essay_type) );
        if (target){
          target.list.push({ ...v })
        }
      })
  
      this.menu = menus.filter(v => v.list.length)
    }
    this.fetchGlobalData()
  }

  /* 获取全局统计数据 */
  public async fetchGlobalData () {
    const { menu } = this

    const res: any = await getGlobalStatData({
      stat_type: GlobalStateType.Global_Yes
    })

    const { code, message = '', data = {} } = res

    if (String(code) !== '0') {
      this.fetchRecommendData()
      this.$message.error('获取内容失败:' + message)
      return
    }

    const { lore_essay_data = {} } = data || {}

    const _menu = menu.map((v) => {
      return { ...v, count: lore_essay_data[ v[ 'countName' ] ] ? Number(lore_essay_data[ v[ 'countName' ] ].lore_essay_count) : 0 }
    })

    this.menu = _menu
    this.fetchRecommendData()
  }

  /* 获取广告位数据 */
  public async fetchRecommendData () {
    const res: any = await getAdvertisePos({
      advertise_pos_type: AdvertiseTypes.Pos_Knowledge,
    })

    const { code, message = '' } = res

    if (String(code) !== '0') {
      this.$message.error('获取内容失败:' + message)
      return
    }

    const { data = [] } = res.data || {}

    const ids = data.map((v: any) => v.g_id)

    this.fetchSelfData(ids)
  }

    /* 获取广告位详细数据 */
  public async fetchSelfData (g_id) {
    const { menu, menus } = this

    const res: any = await batchGetArticle({
      lore_essay_id: g_id
    })

    const { code, data = {}, message = '' } = res

    if (String(code) !== '0') {
      this.$message.error('获取内容失败:' + message)
      return
    }

    const { base_data = [] } = data || {}

    if (base_data.length) {
      const _list = new Array(base_data.length)

      for (let i = 0; i < base_data.length; i++){
        const current = base_data[i]
        const index: any = g_id.findIndex(_v => _v == current.lore_essay_id)

        if (index != undefined) {
          _list[ index ] = current
        }
      }

      menu.unshift({ ...menus[ 0 ], count: base_data.length, list: _list.filter(v => !!v) })

      this.menu = menu
    }
  }

  /* 获取热门推荐 */
  public async fetchHotData () {
    const res: any = await getLoreEssayHotAll({
      lore_essay_type: ArticleTypes.Type_0,
      lore_essay_limit: this.pageSizeHot,
      lore_essay_offset: this.page,
    })

    const { code, data = {}, message = '' } = res

    if (String(code) !== '0') {
      this.$message.error('获取内容失败:' + message)
      return
    }

    const { base_data = [] } = data || {}

    this.LoreEssayHotAll = base_data
  }

  /* 格式化时间 */
  public formatTime (time: string) {
    let nTime = normalizeDate(time)

    if (!nTime) return ''

    return formatDate(nTime)
  }
}
export default Knowledge
</script>

<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

/* 修改滚动条的颜色 */
::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条的宽度 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #000;
  /* 设置滚动条轨道的背景色 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: $black_light;
  /* 设置滚动条滑块的背景色 */
}

/* 鼠标悬停在滚动条上时的滑块样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: $black_light;
  /* 设置鼠标悬停时滚动条滑块的背景色 */
}

@media screen and (max-width: 1919px) {
  .knowledge-index {
    >.container {
      width: 1472px !important;
    }
  }
}

@media screen and (max-width: 1679px) {
  .knowledge-index {
    min-height: calc(100vh - 86px);
    >.container {
      width: 1072px !important;

      :deep(.category .content .category-item .text) {
        font-size: 12px;
      }

      .main .content .list {

        .list-item {
          width: calc(50% - 8px) !important;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .knowledge-index {
    >.container {
      width: 1712px !important;
    }
  }
}

.knowledge-index {
  background-color: #000;
  display: flex;
  justify-content: center;
  position: relative;
  .title {
    font-size: 24px;
    color: $gray_d;
  }

  .container {
    padding-top: 110px;
    padding-left: 308px;
    display: flex;
    position: relative;

    .category {
      position: fixed;
      left: 50%;
      top: 110px;
    }

    .main {
      flex: 2.4;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 180px);

      .nav-wrap {
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);

        .nav {
          height: 40px;
          display: flex;
          align-items: center;

          .nav-item+.nav-item {
            margin-left: 32px;
          }

          .nav-item {
            &.active {
              color: #ddd;
              background-color: $black_light;
            }

            color: $gray_9;
            font-size: 20px;
            padding: 5px 16px;
            cursor: pointer;
          }
        }

        .extra {
          
          .filter {
            width: 116px;
          }
        }
      }

      >.content {
        width: 100%;

        .list+.list {
          margin-top: 72px;
        }

        >.list {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          overflow: visible;

          .list-head {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 5px;
            .extra {
              color: $gray_9;
              font-size: 16px;
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }

          .list-content {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            overflow: hidden;
            margin-top: 24px;
            color: $gray_6;
            overflow: visible;

            .list-item {
              width: calc(33.3% - 12px);
              overflow: visible;
            }
          }
        }
      }
    }

    .mixed {
      flex: 1;
      flex-shrink: 0;
      margin-left: 36px;

      .user {
        width: 100%;

        .thumb {
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;

          .avatar {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
            background-color: $black_light;
            flex-shrink: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .info {
            flex: 1;
            width: 100%;
            margin-left: 16px;

            .name {
              color: $gray_d;
              font-size: 24px;
            }

            .introduce {
              color: $gray_6;
              font-size: 14px;
            }
          }
        }

        .count {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 25px;

          .count-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            .num {
              color: $gray_9;
              font-size: 32px;
            }

            .name {
              color: $gray_9;
              font-size: 12px;
            }
          }
        }

        >.btn {
          width: 100%;
          height: 40px;
          background-color: #00fff6;
          font-size: 16px;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 40px;
        }
      }

      >.list {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 16px;

        .list-head {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          .extra {
            color: $gray_6;
            font-size: 20px;
            display: flex;
            align-items: center;

            .icon-refresh {
              width: 22px;
              height: 16px;
              background: url(@/assets/knowledge/icon-refresh.png) no-repeat center;
              background-size: 100% 100%;

              &:hover {
                background-image: url(@/assets/knowledge/icon-refresh-active.png);
              }
            }
          }
        }

        .list-content {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          .list-item+.list-item {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
</style>