<template>
    <div class="game-works-card-wrap" @click="onClick">
        <div class="card">
            <div class="thumb">
              <div class="bg" :style="{ backgroundImage: 'url('+ cover || defaultCover +')' }"></div>
              <div class="tags" v-if="tags.length > 0">
                <div class="tag" v-for="(curTag, index) in showTags" :key="index">{{ curTag }}</div> 
              </div>
            </div>
            <div class="mixed">
                <div class="info">
                    <div class="avatar">
                        <img :src="value.user_data.user_url || defaultAvatar" />
                    </div>
                    <div class="name">{{ value.user_data.user_name }}</div>
                    <div class="time">{{formatTime(value.release_time)}}</div>
                </div>
                <div class="works-content" v-html="value.works_title"></div>
                <div class="option">
                    <div class="option-item">
                        <div class="icon">
                            <img src="@/assets/statIcon/icon_views.png" />
                        </div>
                        <div class="count">{{formatStat(value.stat_data && value.stat_data.browse_num)}}</div>
                    </div>
                   
                    <div class="option-item" >
                        <div class="icon">
                          <img src="@/assets/knowledge/icon-likes.png" />
                        </div>
                        <div class="count">{{formatStat(value.stat_data && value.stat_data.like_num)}}</div>
                    </div>
                    <div class="option-item">
                      <div class="icon">
                          <img src="@/assets/knowledge/icon-comments.png" />
                      </div>
                      <div class="count">{{formatStat(value.stat_data && value.stat_data.comment_num)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { normalizeDate, formatDate } from '@/utils/format'
import defaultAvatar from '@/assets/knowledge/avatar-default.png'
import defaultCover from '@/assets/knowledge/card-normal-default.png'
import { abbreviateNumber } from '@/utils/statFormat'

@Component({
    name: 'info-card',
    components: {},
})
class InfoCard extends Vue {
    @Prop({ required: true, default: {} }) public value
    @Prop({ required: true, default: '' }) public cover;
    @Prop({ required: false, default: () => [] }) public tags;


    public defaultAvatar = defaultAvatar
    public defaultCover = defaultCover

    mounted() {}

    public onClick() {
      this.$emit('click', this.value)
    }
    public formatTime(time: string) {
      let nTime = normalizeDate(time)
      if (!nTime) return ''
      return formatDate(nTime, 'YYYY/mm/dd')
    }

    public formatStat(num) {
      return abbreviateNumber(Math.max(Number(num), 0));
    }

    get showTags() {
      if (this.tags.length > 3) {
        return this.tags.slice(0, 3);
      }
      return this.tags;
    }
    
}

export default InfoCard
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;
@media screen and (min-width: 1920px) {
  .game-works-card-wrap{
    width: 416px;
    .card{
      .thumb{
        height: 234px;
      }
    }
  }
}
@media screen and (max-width: 1919px) {
  .game-works-card-wrap{
    width: 356px;
    .card{
      .thumb{
        height: 200px;
      }
    }
  }
}
@media screen and (max-width: 1679px) {
  .game-works-card-wrap{
    width: 256px;
    .card{
      .thumb{
        height: 144px;
      }
    }
  }
}
.game-works-card-wrap {
  .card {
    width: 100%;
    background-color: $black_light;
    cursor: pointer;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 3px solid #00fff6;
      box-shadow: 0px 12px 32px 0px rgba(0, 255, 246, 0.3);
      display: none;
      pointer-events: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    &:hover::after {
      display: block; 
    }
    &:hover{
      .thumb{
        .bg{
          transform: scale(1.1);
        }
      }
    }

    .thumb {
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      overflow: hidden;
      transition: transform 0.3s ease;
      .bg{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        overflow: hidden;
        transition: transform 0.3s ease;
        // &:hover {
        //   transform: scale(1.1);
        // }
      }
      .tags{
        position: absolute;
        top: 16px;
        left: 16px;
        display: flex;
        height: 30px;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        .tag{
          margin-right: 8px;
          background: rgba(0, 0, 0, 0.50);
          padding: 5px 8px;
          color: #ddd;
          font-size: 12px;
          margin-bottom: 8px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }

    .mixed {
        width: 100%;
        padding: 6px 16px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
            padding-left: 56px;
            width: 100%;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .avatar {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: -24px;
                border: 1px solid rgba(255, 255, 255, 0.30);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .name {
                color: $gray_9;
                font-size: 12px;
            }

            .time {
                color: $gray_6;
                font-size: 12px;
            }
        }

        .works-content {
            width: 100%;
            height: 48px;
            font-size: 16px;
            line-height: 24px;
            color: $gray_d;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin: 12px 0;
            word-break: break-all;
        }

        .option {
            width: 100%;
            display: flex;
            align-items: center;

            .option-item {
                margin-right: 16px;
            }

            .option-item {
                display: flex;
                align-items: center;

                .icon {
                    width: 24px;
                    height: 24px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .count {
                    font-size: 12px;
                    margin-left: 2px;
                    color: $gray_6;
                }
            }
        }
    }
  }
}
</style>