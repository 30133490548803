<template>
  <div class="player-chat-mg">
    <div class="user-avatar">
        <img :src="defaultAvatar" />
    </div>
    <div class="container-wrap">
      <div class="name">{{ msgInfo.roleInfo.nick }}</div>
      <div class="msg-container" v-if="msgInfo.behavior === 'chat'">
        <!-- <div class="title">{{ behaviorLabel[msgInfo.behavior] }}</div> -->
        <div class="msg">{{ msgInfo.msg }}</div>
        <div class="time">{{ msgInfo.timer }}</div>
      </div>
      <div class="msg-container" v-if="msgInfo.behavior === 'behavior'">
        <div class="title">{{ behaviorLabel[msgInfo.behavior] }}</div>
        <div class="msg">{{ showBehaviorMsg() }}</div>
        <div class="time">{{ msgInfo.timer }}</div>
      </div>
      <div class="msg-container" v-if="msgInfo.behavior === 'game'">
        <div class="title">{{ behaviorLabel[msgInfo.behavior] }}</div>
        <div class="msg">{{ showBehaviorMsg() }}</div>
        <div class="time">{{ msgInfo.timer }}</div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import defaultAvatar from '@/assets/knowledge/avatar-default.png'
  import { behaviorConfig, multiBehaviorConfig } from '@/config/aiInspect'
  export default {
    name: 'player-chat-mg',
    props: {
      msgInfo: {
        type: Object,
        default: () => { return { type: '玩家A', msg: { content: 'this is a test', score: 90 } }},
      }
    },
    data() {
      return {
        defaultAvatar,
        behaviorLabel: {
          chat: '聊天',
          behavior: '行为　',
          game: '小游戏'
        }
      }
    },
    mounted() {
      console.log(this.msgInfo);
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      showBehaviorMsg() {
        if (this.msgInfo.isMulti) {
          const targetAction = multiBehaviorConfig.find(item => item.action_id === this.msgInfo.actionInfo.action_id);
          if (targetAction.isGame) {
            return `${targetAction.action_label}: ${this.msgInfo.game_score}分`;
          }
          const targetAnswer = targetAction.option.find(answer => answer.answer_no === this.msgInfo.actionInfo.answer_no);
          return `${targetAction.action_label}: ${this.fillUser(targetAnswer.label)}`;
        }
        const targetAction = behaviorConfig.find(item => item.action_id === this.msgInfo.actionInfo.action_id);
        if (targetAction.isGame) {
          return `${targetAction.action_label}: ${this.msgInfo.actionInfo.game_score}分`;
        }
        const targetAnswer = targetAction.single.find(answer => answer.answer_no === this.msgInfo.actionInfo.answer_no);
        return `${targetAction.action_label}: ${targetAnswer.label}`;
      },
      fillUser(label){
        const result = label.replace(/\{0\}/g, this.msgInfo.roleList[0].nick).replace(/\{1\}/g, this.msgInfo.roleList[1].nick);
        return result
      },
      getMultiName(){
        let title = '玩家';
        this.msgInfo.roleList.forEach((item, index) => {
          if (index !== 0) {
            title += '+';
          }
          title += item.nick
        });
        return title;
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .player-chat-mg{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    .user-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #999;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .container-wrap{
      margin-left: 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      .name{
        color: #999;
        font-size: 14px;
        font-weight: bold;
      }
      .msg-container{
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        gap: 5px;
        max-width: 360px;
        .title{
          font-size: 14px;
          font-weight: bold;
          color: #000;
        }
        .msg{
          font-size: 14px;
          color: #333;
        }
        .time{
          font-size: 12px;
          font-weight: 400;
          color: #999;
        }
      }
    }
  }
</style>
  