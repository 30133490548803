<template>
  <div class="publish-entrance">
    <img src="@/assets/publish-icon.png" alt="">
    <div>{{ $t('addNav.publish') }}</div>
    <div class="add-menu-warp">
      <div class="add-menu">
        <div :class="{'add-menu-item': true, 'menu-item': !item.disabled, 'item-disabled': item.disabled }" v-for="(item, index) in addNav" :key="index" @click="gotoEdit(item)">
          <img :src="item.img" alt="">
          <div>{{ $t(item.key) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import iconKnowledge from '@/assets/gateNav/post_knowledge_icon.png';
  import iconPostProduct from '@/assets/gateNav/post_image_iocn.png';
  import iconPostGameDemo from '@/assets/gateNav/post_gameDemo_icon.png';
  import iconAnswer from '@/assets/answer_icon.png';
  export default {
    name: 'publish-entrance',
    components: { },
    props: {
      currentMenu: {
        type: String,
        default: 'article'
      }
    },
    data() {
      return {
        addNav: [
          { key: 'addNav.knowledge', img: iconKnowledge, path: '/editor/article' },
          { key: 'addNav.image', img: iconPostProduct, path: '/postproduct/image' },
          { key: 'addNav.demo', img: iconPostGameDemo, path: '/application/main'  },
          // { key: 'addNav.answer', img: iconAnswer, disabled: true,  },
        ]
      }
    },
    mounted() {
    },
    methods: {
      gotoEdit(item) {
        if (item.disabled) return;
        this.$router.push({
          path: item.path,
        })
      }
    }
  }
  </script>
  <style lang="scss">
    .publish-entrance{
        width: 104px;
        height: 36px;
        // background: #00FFF6;
        border-radius: 1px;
        border: 1px solid rgba(255, 255, 255, 0.30);
        color: #ADADAD;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &>img{
          width: 12px;
          height: 12px;
          margin-right: 6px;
        }
        &:hover{
          .add-menu-warp{
            display: block;
          }
        }
        .add-menu-warp{
          position: absolute;
          left: 50%;
          top: 35px;
          width: 172px;
          // height: 130px;
          transform: translateX(-50%);
          display: none;
          .add-menu{
            width: 172px;
            background: #141414;
            box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
            font-weight: 500;
            font-size: 14px;
            color: #999;
            padding: 8px;
            margin-top: 15px;
            &::before{
              content: '';
              width: 10px;
              height: 6px;
              position: absolute;
              left: 50%;
              top: 10px;
              transform: translateX(-50%);
              background: url('@/assets/triangle.png') no-repeat;
              background-size: 100% 100%;
            }
            .menu-item{
              &:hover{
                border-radius: 3px;
                background: rgba(255, 255, 255, 0.10);
                &>img{
                  filter: brightness(3);
                }
                &>div{
                  color: var(--text-text-1-ddd, #DDD);
                }
              }
            }
            .add-menu-item{
              display: flex;
              align-items: center;
              width: 156px;
              height: 32px;
              padding: 5px 8px;
              white-space: nowrap;
              &>img{
                width: 20px;
                height: 20px;
                margin-right: 8px;
              }
            }
            .item-disabled{
              cursor: not-allowed;
              color: #333;
            }
          }
        }
      }
  </style>