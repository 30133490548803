  <template>
    <el-dialog
      :visible.sync="visible"
      width="424px"
      :show-close="false"
      custom-class="lit-common-delete-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      >
      <div class="delete-pop-top" slot="title">
        <div class="delete-title">{{ title ? title : $t('deleteDraft.title') }}</div>
        <div class="delete-clone" @click="onCancel" v-if="showClose"></div>
      </div>
      <div class="delete-content" >{{ content ? content :  $t('deleteDraft.content') }}</div>
      <div class="delete-btn-warp">
        <div class="btn-cancel" @click="onCancel">{{ cancelBtn ? cancelBtn : $t('deleteDraft.cancel') }}</div>
        <div class="btn-confirm" @click="onConfirm">{{ confirmBtn ? confirmBtn : $t('deleteDraft.confirm') }}</div>
      </div>
    </el-dialog>
  </template>
  
  <script>
  export default {
    name: 'delete-dialog',
    props: {
      title: {
        type: String,
        default: ''
      },
      content: {
        type: String,
        default: ''
      },
      cancelBtn: {
        type: String,
        default: ''
      },
      confirmBtn: {
        type: String,
        default: ''
      },
      showClose: {
        type: Boolean,
        default: true
      },
      visible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        
      }
    },
    mounted() {
    },
    methods: {
      onCancel() {
        this.$emit('onCancel');
      },
      onConfirm() {
        this.$emit('onConfirm');
      }

    },
  }
  </script>
  
  <style lang="scss" scoped>
  .lit-common-delete-dialog{
    :deep(.el-dialog__body){
      padding-top: 0;
    }
      .delete-pop-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .delete-title {
          color: #DDD;
          font-size: 16px;
        }

        .delete-clone {
          width: 16px;
          height: 16px;
          background: url(@/assets/knowledge/close_info.png) no-repeat center;
          background-size: 100% 100%;
          cursor: pointer;
        }
      }

      .delete-content {
        margin-bottom: 24px;
      }

      .delete-btn-warp {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-cancel {
          width: 81px;
          height: 32px;
          color: #666;
          text-align: center;
          line-height: 32px;
          border: 1px solid #666;
          cursor: pointer;
        }

        .btn-confirm {
          width: 90px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          background: #00FFF6;
          color: #000;
          margin-left: 16px;
          cursor: pointer;
        }
      }
  }
  
  </style>
  