import { RouteConfig } from 'vue-router';

/**
 * 存放公共路由
 * 公用路由包括： 登录、报错、异常等
 */
export const staticRoutes: Array<RouteConfig> = [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/layout/account.vue'),
    redirect: '/account/login',
    children: [
      {
        path: 'login',
        name: 'accountSignIn',
        component: () => import(/* webpackChunkName: "acc-account" */ '@/views/account/signIn.vue'),
        meta: {
          title: 'route.account.signIn',
          noSession: true,
          isHide: false,
          fitH5: true,
        },
      },
      {
        path: 'signup',
        name: 'accountSignUp',
        component: () => import(/* webpackChunkName: "acc-account" */ '@/views/account/signUp.vue'),
        meta: {
          title: 'route.account.signUp',
          noSession: true,
          isHide: false,
          fitH5: true,
        },
      },
      {
        path: 'forget',
        name: 'accountForget',
        component: () => import(/* webpackChunkName: "acc-account" */ '@/views/account/forget.vue'),
        meta: {
          title: 'route.account.forget',
          noSession: true,
          isHide: false,
          fitH5: true,
        },
      },
      {
        path: 'passwordreset',
        name: 'accountPasswordReset',
        component: () => import(/* webpackChunkName: "acc-account" */ '@/views/account/passwordReset.vue'),
        meta: {
          title: 'route.account.passwordReset',
          noSession: true,
          isHide: false,
          fitH5: true,
        },
      }
    ],
  }
];
