<template>
  <div class="token-activity-way-main" ref="wayContainer">
    <ActivityTitle :title="$t('views.tokenActivity.way.title')" :subTitle="$t('views.tokenActivity.way.subTitle')"/>
    <div class="content-box">
      <div class="step"></div>
      <div class="content">
        <div class="step-content">
          <div class="step-icon icon-submit"></div>
          <div class="step-title" v-html="$t('views.tokenActivity.way.submitTitle')"></div>
          <div class="step-intro" v-html="$t('views.tokenActivity.way.submitIntro')"></div>
          <div class="step-tips" v-html="$t('views.tokenActivity.way.submitTips')"></div>
        </div>
        <div class="step-content">
          <div class="step-icon icon-audit"></div>
          <div class="step-title" v-html="$t('views.tokenActivity.way.auditTitle')"></div>
          <div class="step-intro" v-html="$t('views.tokenActivity.way.auditIntro')"></div>
        </div>
        <div class="step-content">
          <div class="step-icon icon-submit"></div>
          <div class="step-title" v-html="$t('views.tokenActivity.way.joinTitle')"></div>
          <div class="step-intro" v-html="$t('views.tokenActivity.way.joinIntro')"></div>
          <div class="step-tips" v-html="$t('views.tokenActivity.way.joinTips')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ActivityTitle from '../components/activityTitle.vue'
export default {
  components: { ActivityTitle },
  name: 'token-activity-way-main',
  data () {
    return {
      activityTime: '2024.06.01-09.01'
    }
  },
  mounted () {
  },
  methods: {
    gotoPath(path) {
      this.$router.push({
        path
      })
    }
  }

}
</script>
<style lang="scss">
.token-activity-way-main{
  min-width: 1360px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  .content-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    .step{
      width: 960px;
      height: 61px;
      background: url('@/assets/activity/token/step-bg.png');
      background-size: 100% 100%;
    }
    .content{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-start;
      width: 1238px;
      gap: 24px;
      .step-content{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 312px;
        .step-icon{
          width: 210px;
          height: 210px;
        }
        .icon-submit{
          background: url('@/assets/activity/token/step-icon-submit.png');
          background-size: 100% 100%;
        }
        .icon-audit{
          background: url('@/assets/activity/token/step-icon-audit.png');
          background-size: 100% 100%;
        }
        .icon-join{
          background: url('@/assets/activity/token/step-icon-join.png');
          background-size: 100% 100%;
        }
        .step-title{
          font-size: 36px;
          font-weight: bold;
          color: #00FFEE;
          margin-top: 8px;
          text-align: center;
        }
        .step-intro{
          color: #CFDCDB;
          font-size: 26px;
          text-align: center;
        }
        .step-tips{
          color: #CFDCDB;
          font-size: 20px;
          text-align: center;
          .high-light-txt{
            color: #00FFEE;
            cursor: pointer;
          }
        }
  
      }
      .content-title{
        width: 610px;
        background: url('@/assets/activity/token/intro-title-border.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #00FFEE;
        font-size: 36px;
        line-height: 150%;
        font-weight: bold;
      }
      .content-marks{
        width: 713px;
        background: url('@/assets/activity/token/intro-marks.png');
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 26px;
        line-height: 150%;
      }
      .content-line{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 20px;
        line-height: 150%;
        .high-light-txt{
          color: #00FFEE;
        }
        .pointer {
          text-decoration: underline;
        }
      }
    }
  }

}
</style>