<template>
  <div class="gate-guide-nav">
    <div class="gate-guide-contain">
      <div class="gate-guide-logo"></div>
      <div class="gate-lang">
        <div v-for="item in langTypes" :key="item.key" :class="{'lang-option': true, 'active': lang === item.key}" @click="setLang(item)">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n, { getLanguage, setLanguage } from '@/lang';
export default {
  name: 'guide-nav',
  data() {
    return {
      lang: 'en',
      langTypes: [
        { key: 'zh-cn', label: '中' },
        { key: 'en', label: 'EN' },
      ]
    }
  },
  mounted() {
    const cacheLang = getLanguage();
    if (cacheLang) {
      this.lang = cacheLang;
      setLanguage(cacheLang);
    } else {
      setLanguage(this.lang);
    }
    this.$emit('setLang', this.lang);
  },
  methods: {
    setLang(item) {
      this.lang = item.key;
      setLanguage(this.lang);
      this.$emit('setLang', this.lang);
    }
  }
}
</script>

<style lang="scss" scoped>
.gate-guide-nav {
  margin: 0;
  height: 80px;
  padding: 12px 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .gate-guide-contain{
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .gate-guide-logo{
      width: 240px;
      height: 60px;
      background: url("https://cdn.litgate.ai/gate/guide/gate_logo.png") no-repeat;
      background-size: 100% 100%;
      align-items: center;
    }
    .gate-lang{
      width: 120px;
      height: 30px;
      display: flex;
      justify-content: center;
      .lang-option {
        font-size: 16px;
        line-height: 1.5;
        color: #fff;
        padding: 0 3px;
        opacity: 0.45;
        cursor: pointer;
      }
      .lang-option + .lang-option::before {
        content: "/ ";
        opacity: 0.45;
        font-family: "guideCNR", serif !important;
        font-size: 16px !important;
      }
      .active {
        opacity: 1;
      }
    }
  }
}
</style>
