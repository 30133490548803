<template>
  <div class="article-container page-article">
    <Nav currentTab="creator"/>
    <router-view :key="key" />
  </div>
</template>

<script lang="ts">
import Nav from '@/layout/navMenu/gateNav.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'layoutActivity',
  components: { Nav},
})
class layoutActivity extends Vue {
  get key() {
    return this.$route.path;
  }
 }
export default layoutActivity
</script>
<style lang="scss">
  /* 修改滚动条的颜色 */
  ::-webkit-scrollbar {
    width: 8px;
    /* 设置滚动条的宽度 */
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: #000;
    /* 设置滚动条轨道的背景色 */
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #141414;
    /* 设置滚动条滑块的背景色 */
  }

  /* 鼠标悬停在滚动条上时的滑块样式 */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #141414;
    /* 设置鼠标悬停时滚动条滑块的背景色 */
  }
</style>