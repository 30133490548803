<template>
  <div class="info-container" v-if="isShowInfo">
    <div class="info-mask">
      <div class="info-box-warp">
        <div class="info-box">
          <div class="info-title">
            <div>{{ $t('personInfo.title') }}</div>
            <button class="info-close" @click="closePop"></button>
          </div>
          <div class="info-content">
            <div class="avatar-warp">
              <img v-if="userAvatar" class="avatar-img" :src="userAvatar" alt="" />
              <img v-else class="avatar-img" src="@/assets/knowledge/avatar-default.png" alt="" />
              <div class="avatar-mask"></div>
              <div class="edit-warp">
                <div :class="{'edit-btn-warp': true, 'loading': uploadLoading}">
                  <div class="upload-icon" v-if="uploadLoading"></div>
                  <button class="edit-avatar" v-else></button>
                  <LscosUpload @onSuccess="uploadCoverSuccess" @onChange="onUpload" @onError="onUploadError" />
                </div>
                <div v-if="uploadLoading">{{ $t('personInfo.upload') }}</div>
                <div v-else>{{ $t('personInfo.edit') }}</div>
              </div>
            </div>
            <div class="info-input-box">
              <div class="user-name-box">
                <div class="title">{{ $t('personInfo.userName') }}</div>
                <div>
                  <input v-model="userInfo.user_name" @input="inputUserName" type="text">
                </div>
                <div class="edit-icon-warp" v-if="!isUserName">
                  <div class="edit-icon" @click="setUserName">
                    <img src="@/assets/knowledge/edit-name.png" alt="">
                    <div>Edit</div>
                  </div>
                </div>
              </div>
              <div class="user-description-box">
                <div class="title">{{ $t('personInfo.description') }}</div>
                <div>
                  <input v-model="userInfo.user_profiles" @input="inputUserProfiles" ref="profilesInput" type="text">
                </div>
                <div class="edit-icon-profiles" v-if="!isUserProfiles">
                  <div class="edit-icon" @click="setProfiles">
                    <img src="@/assets/knowledge/edit-name.png" alt="">
                    <div>Edit</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-warp">
            <button class="button-cancel" @click="closePop">{{ $t('personInfo.cancel') }}</button>
            <button class="button-save" @click="setUserInfo">{{ $t('personInfo.save') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { setLitUserData } from '@/api/gateUser'
import LscosUpload from '@/components/lscosUpload/index.vue';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'PersonalCenter',
  components: { LscosUpload },
})
class PersonalInfo extends Vue {

  public isShowInfo = false;
  public uploadLoading = false;
  public userInfo:any = {};
  public isUserName = false;
  public isUserProfiles = false;
  public userAvatar = '';
  public userNameLimit = 30;
  public userProfilesLimit = 100;
  public regex = /[^\u4e00-\u9fa5a-zA-Z0-9]/g 
  public avatarVerify = {
    url: '',
    platform: 0,
    filesize: 0,
    sha1: '',
  }

  mounted () {
  }

  public openPop(e:any) {
    console.log('e', e)
    e = JSON.stringify(e);
    this.userInfo = JSON.parse(e);
    this.avatarVerify.url = this.userInfo.user_url;
    this.userAvatar = this.userInfo.user_url;
    this.isShowInfo = true;
  }

  public closePop() {
    this.isUserName = false;
    this.isUserProfiles = false;
    this.isShowInfo = false;
    this.uploadLoading = false;
  }

  public setUserName() {
    this.isUserName = true
  }

  public setProfiles() {
    this.isUserProfiles = true;
    this.$nextTick(() => {
      const input = this.$refs.profilesInput;
      input.scrollLeft = 1000;
    });
  }

  public inputUserName() {
    //删除表情 空格 特殊字符
    this.userInfo.user_name = this.userInfo.user_name.replace(this.regex, "");

    if (this.userInfo.user_name.length > this.userNameLimit) {
      this.userInfo.user_name = this.userInfo.user_name.slice(0, this.userNameLimit);
    }
    
  }

  public inputUserProfiles() {
    if (this.userInfo.user_profiles.length > this.userProfilesLimit) {
      this.userInfo.user_profiles = this.userInfo.user_profiles.slice(0, this.userProfilesLimit);
    }
  }

  public onUpload () {
    this.uploadLoading = true
  }

  public onUploadError () {
    this.uploadLoading = false
  }


  public async setUserInfo() {
    this.uploadLoading = false;
    let language = localStorage.getItem('language');
    if (!this.userInfo.user_name) {
      let errMsg = language === 'zh-cn' ? '用户昵称不能为空' : 'Display Name cannot be empty';
      this.$message.error(errMsg);
      return
    }

    const res: any = await setLitUserData({
      user_name: this.userInfo.user_name,
      user_profiles: this.userInfo.user_profiles,
      user_url: this.avatarVerify,
      gender: this.userInfo.gender,
      email: this.userInfo.email
    })
    const { code, data = {}, message = '' } = res
    if (String(code) !== '0') {
      this.$message.error('获取内容失败:' + message)
      return
    }
    
    let msg = language === 'zh-cn' ? '保存成功' : 'Save successfully';
    this.$message.success(msg);
    UserModule.updateUserInfo();
    setTimeout(() => {
      this.isUserName = false;
      this.isUserProfiles = false;
      this.isShowInfo = false;
      this.$emit('updateInfo')
    }, 200)
  }

  public uploadCoverSuccess (file: any) {
    this.uploadLoading = false
    console.log('upload success:', file)
    this.avatarVerify.url = file.url,
    this.avatarVerify.platform = file.platform,
    this.avatarVerify.filesize = file.filesize,
    this.avatarVerify.sha1 = file.sha1,
    this.userAvatar = file.url;
  }

}
export default PersonalInfo
</script>

<style lang="scss" scoped>
.info-container{
  .info-mask{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.90);
    z-index: 1000;
    .info-box-warp{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .info-box{
      width: 720px;
      height: 350px;
      padding: 40px 40px 48px 40px;
      background: radial-gradient(115.63% 103.73% at 126.07% -32.66%, rgba(41, 45, 45, 0.80) 0%, #121212 100%);
      .info-title{
        color: #DDD;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .info-close{
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url(@/assets/knowledge/close_info.png) no-repeat center;
        background-size: 100% 100%;
      }
    }
    .info-content{
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .avatar-warp{
        width: 120px;
        height: 120px;
        position: relative;
        .avatar-img{
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
        .avatar-mask{
          position: absolute;
          left: 0;
          top: 0;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
        }
        .edit-warp{
          font-size: 14px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.60);
          position: absolute;
          left: 50%;
          top: 31px;
          text-align: center;
          transform: translateX(-50%);
          .edit-avatar{
            width: 32px;
            height: 32px;
            background: url(@/assets/knowledge/edit-avatar.png) no-repeat center;
            background-size: 100% 100%;
            cursor: pointer;
          }
          .upload-icon{
            width: 32px;
            height: 32px;
            display: inline-block;
            background: url(@/assets/knowledge/loading-icon.webp) no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
      .info-input-box{
        width: 480px;
        color: #999;
        font-weight: 500;
        font-size: 14px;
        .user-name-box{
          display: flex;
          align-items: center;
          width: 480px;
          height: 48px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.06);
          position: relative;
          .edit-icon-warp{
            display: none;
          }
          // &:hover{
          //   .edit-icon-warp{
          //     display: block;
          //   }
          // }
        }
        .user-description-box{
          display: flex;
          align-items: center;
          width: 480px;
          height: 48px;
          margin-top: 24px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.06);
          position: relative;
          .edit-icon-profiles{
            display: none;
          }
          // &:hover{
          //   .edit-icon-profiles{
          //     display: block;
          //   }
          // }
          .user-profiles-text{
            width: 230px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .title{
          margin-right: 24px;
          color: #666;
        }

        input {
          color: #999;

          &:focus {
            color: #ddd;
          }
        }
      }
      .edit-icon{
        display: flex;
        align-items: center;
        margin-left: 8px;
        cursor: pointer;
        img{
          width: 16px;
          height: 16px;
        }
        &>div{
          color:#00FFF6;
          font-size: 14px;
          font-weight: 500;
          margin-left: 3px;
        }
      }
    }
    .button-warp{
      text-align: center;
      margin-top: 40px;
      color:  #666;
      font-size: 14px;
      font-weight: 500;
      &>button{
        width: 81px;
        height: 32px;
        cursor: pointer;
        // &:hover{
        //   background: #00FFF6;
        //   color: #000;
        //   border: 0;
        // }
      }
      .button-cancel{
        border: 1px solid #666;
      }
      .button-save{
        margin-left: 16px;
        // background: rgba(255, 255, 255, 0.10);
        background: #00FFF6;
        color: #000;
        border: 0;
      }
    }
  }
}
.edit-btn-warp{
  position: relative;
  .lscos-upload{
    position: absolute;
    left: -10px;
    top: -8px;
    width: 50px;
    height: 50px;
    opacity: 0;
  }
}
</style>
<style>
</style>

