<template>
    <div class="api-icon-box" @click="gotoApi">
      <div class="api-icon"></div>
      {{ $t('views.createApplication.apiDocument') }}
    </div>
</template>
  
  <script>
  export default {
    name: 'api-doc-btn',
    components: { },
    props: {
      currentMenu: {
        type: String,
        default: 'article'
      }
    },
    data() {
      return {
      }
    },
    mounted() {

    },
    methods: {
      gotoApi() {
        this.$router.push({ path: '/document/token' })
      }
    }
  }
  </script>
  <style lang="scss" scoped>
    .api-icon-box{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      color: #ADADAD;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      gap: 4px;
      cursor: pointer;
      .api-icon {
        width: 20px;
        height: 20px;
        background-image: url('@/assets/postApplication/icon_api.png');
        background-size: 100% 100%;
      }
      &:hover{
        color: #ddd;
      }
    }
  </style>