<template>
  <div :class="{ 'gate-logo': true, 'gate-logo-light': styleType === 'light', 'gate-logo-dark': styleType === 'dark' }" :style="{ width: width + 'px', height: height + 'px' }" ></div>
</template>

<script lang="ts">
export default {
  props: {
    styleType: {
      default: 'light',
      type: String
    },
    width: {
      default: 150,
      type: Number
    },
    height: {
      default: 30,
      type: Number
    }
  },
  name: 'gate-logo',
  data() {
    return {
      cacheBgColor: '',
      lang: 'en'
    }
  },
  mounted() {
  },
  methods: {

  },
  beforeDestroy() {
    
  },
}
</script>

<style lang="scss" scoped>
.gate-logo{
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
}

.gate-logo-light{
  background-image: url("../../assets/lit_gate_logo.png");
}
.gate-logo-dark{
  background-image: url("../../assets/lit_gate_logo_dark.png");
}
/* 中文语言环境下的字体 */
</style>
