<template>
    <div id="layout-article" class="article-container page-article">
      <Nav :hasLine="true" />
      <router-view />
      <Footer />
    </div>
  </template>
  
  <script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
 
  import Footer from '@/layout/navMenu/gateFooter.vue'
  import Nav from '@/layout/navMenu/gateNav.vue'
  
  @Component({
    name: 'layout-knowledge',
    components: { Nav, Footer },
  })
  class layoutKnowledge extends Vue {
    private bgColor;
    mounted() {
      this.bgColor = document.body.style.backgroundColor;
      document.body.style.backgroundColor = '#000';
    }
    destroy() {
      document.body.style.backgroundColor = this.bgColor;
    }
  }
  export default layoutKnowledge
  </script>
  <style lang="scss">
  .page-article{
    background: #000;
    width: 100%;
    min-width: 1280px;
  }
  </style>