<template>
  <div class="gate-forget">
    <div class="forget-box content-box" v-if="!isSend">
      <div class="title">{{ $t('views.forgetPage.title') }}</div>
      <el-form label-width="0px" ref="form" :model="form" :rules="rules" :style="{ width: '100%'}" @submit.native.prevent>
        <el-form-item label="" prop="email">
          <el-input v-model.trim="form.email" :placeholder="$t('views.signUp.emailPh')" @keyup.enter.native="send"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="send-btn" :loading="loading" @click="send">{{ $t('views.forgetPage.sendBtn') }}</el-button>
    </div>
    <div class="result-box content-box" v-if="isSend">
      <div class="result-img"></div>
      <div class="result-txt">{{ $t('views.forgetPage.sendResultTips') }}</div>
      <div class="result-tips-box">
        <div class="tips">{{ $t('views.forgetPage.noEmail') }}</div>
        <div class="resend-link" @click="reSend">{{ $t('views.forgetPage.reSend') }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { accountResetPwdCode } from '@/api/account';
import { validateSignInEmail } from '@/utils/validator';


export default {
  name: 'forgetPage',
  components: {  },
  data() {
    return {
      rules: {
        email: [
          { validator: validateSignInEmail, trigger: 'submit' }
        ],
      },
      form: {
        email: ''
      },
      isSend: false,
      loading: false,
      resultTxt: "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder."
    }
  },
  mounted() {
   this.isSend = false;
  },
  methods: {
    reSend() {
      this.isSend = false;
    },
    send() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.sendEmail();
        }
      })
    },
    async sendEmail() {
      this.loading = true;
      const result = await accountResetPwdCode({
        email: this.form.email
      });
      this.loading = false;
      if (result.code === 0) {
        this.isSend = true;
        return;
      }
      if (result.code){
        this.$message.error(this.$t(`accountCode.code${result.code}`))
      }
    }

  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
.gate-forget{
  background-image: url('@/assets/account/bg.png');
  background-size: 100% 100%;
  width: 100vw;
  min-width: 1280px;
  padding-top: 80px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .content-box{
    width: 560px;
    display: inline-flex;
    padding: 40px 64px 64px 64px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border: 2px solid;
    border-image: linear-gradient(215deg, rgba(56, 254, 238, 0.38), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)) 1;
    background: radial-gradient(115.63% 103.73% at 126.07% -32.66%, rgba(0, 69, 64, 0.99) 0%, rgba(12, 12, 12, 0.99) 100%);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(2.0190839767456055px);
  }
  .forget-box{
    .title{
      color: #DDD;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .send-btn{
      display: flex;
      width: 100%;
      padding: 14px 24px;
      background: #00FFF6;
      color:  #000;
      justify-content: center;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .result-box{
    .result-img{
      width: 120px;
      height: 120px;
      background-image: url('@/assets/account/forget_result.png');
      background-size: 100% 100%;
    }
    .result-txt{
      color: #DDD;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  .result-tips-box{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .tips{
      color: #666;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; 
    }/* 150% */
    .resend-link{
      margin-left: 10px;
      color: #00FFF6;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      cursor: pointer;
    }
  }
}
</style>
