<template>
  <div class="article-option">
    <el-form ref="optionForm" :model="form" :rules="rules" label-width="160px" >
      <el-form-item :label="$t('views.editorArticle.category')" prop="lore_essay_type" style="marginLeft: -10px;" label-width="170px">
        <el-select v-model="form.lore_essay_type" :placeholder="$t('views.editorArticle.categoryPh')" popper-class="article-select">
          <el-option v-for="(item, index) in ArticleCategoryOptions" :key="index" :label="$t(item.i18n)" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('views.editorArticle.articleTag')" prop="label">
        <el-tag
          :key="tag"
          v-for="tag in form.label"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          maxlength="30"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-if="showAddTagBtn" class="button-new-tag" size="small" @click="showInput" >+ {{ $t('views.editorArticle.tagAdd') }}</el-button>
      </el-form-item>
      <el-form-item :label="$t('views.editorArticle.addCover')" prop="cover">
        <LscosUpload @onSuccess="uploadCoverSuccess" :fileList="fileList" @handleRemove="handleRemoveCover"/>
      </el-form-item>
      <!-- <el-form-item label="Recommended AI Tool" prop="tools_id">
        <el-button icon="el-icon-plus" plain>Add AI Tool</el-button>
      </el-form-item> -->
      <el-form-item :label="$t('views.editorArticle.relevantContent')" prop="lore_essay_id">
        <el-button icon="el-icon-plus" plain @click="openRelevantDialog">{{ $t('views.editorArticle.relevantAdd') }}</el-button>
        <div class="relevant-content" v-if="relevantList.length > 0">
          <div class="relevant-item" v-for="(item, index) in relevantList" :key="index">
            <div class="content" @click="gotoSeeRelevant(item)">{{ item.title }}</div>
            <div class="del-btn" @click="removeRelevant(item)"></div>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <articleSelectDialog :title="$t('views.editorArticle.relatedPost')" ref="relevantDialog" @submit="setRelevant"/>
  </div>
  </template>
  
  <script>
  import { ArticleCategoryOptions } from '@/config/article';
  import LscosUpload from '@/components/lscosUpload/index.vue';
  import articleSelectDialog from '@/components/articleSelectDialog/index.vue';
  import { batchGetArticle } from '@/api/article';
  export default {
    name: 'article-option-form',
    components: { LscosUpload, articleSelectDialog },
    data() {
      return {
        ArticleCategoryOptions,
        fileList: [],
        relevantList: [],
        form: {
          lore_essay_type: '',
          label: [],
          cover: [],
          lore_essay_id: [],
          tools_id: []
        },
        rules: {
          lore_essay_type: [
            { required: true, message: 'please select article category', trigger: 'change' },
          ],
          // label: [
          //   { required: true, message: 'please add tag', trigger: 'change' },
          // ],
        },
        inputVisible: false,
        maxTagLimit: 5,
        inputValue: '',
        relatedVisible: false
      }
    },
    mounted() {
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      uploadCoverSuccess(file) {
        this.fileList.push(file);
      },
      submitForm() {
        return new Promise(async (resolve) => {
          this.$refs.optionForm.validate((valid, errs = {}) => {
            if (valid) {
              if (this.relevantList.length > 0) {
                this.form.lore_essay_id = this.relevantList.map(item => item.lore_essay_id);
              }
              let targetFile = {};
              if (this.fileList.length > 0){
                targetFile = this.fileList[0];
              }
              const { platform = 2, sha1 = '', filesize = 0, url='' } = targetFile;
              this.form.cover = {
                platform,
                sha1,
                filesize,
                url
              };
              return resolve(this.form);
            } else {
              console.log(errs);
              if (errs.lore_essay_type || errs.lore_essay_type.length > 0) {
                this.$message.error(this.$t('views.editorArticle.categoryPh'))
              }
              return false;
            }
          });
        });
      },
      restoreForm(formData) {
        const { lore_essay_type, label = [], cover, lore_essay_id, tools_id } = formData;
        if (lore_essay_type) {
          this.form.lore_essay_type = lore_essay_type;
        }
        if (label) {
          this.form.label = label;
        }
        if (cover) {
          this.form.cover = [
            { name: 'unknown', url: cover }
          ];
          this.fileList = [
            { name: 'unknown', url: cover }
          ];
        }
        if (lore_essay_id) {
          this.form.lore_essay_id = lore_essay_id;
          this.fetchRelevant();
        }
        if (tools_id) {
          this.form.tools_id = tools_id;
        }
      },
      resetForm() {
        this.$refs.optionForm.resetFields();
      },
      handleClose(tag) {
        this.form.label.splice(this.form.label.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.form.label.push(inputValue);
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
      handleRemoveCover(item) {
        this.fileList = [];
      },
      openRelevantDialog() {
        this.$refs.relevantDialog.openDialog(this.relevantList);
      },
      async fetchRelevant() {
        if (this.form.lore_essay_id.length === 0) {
          return;
        }
        const result = await batchGetArticle({
          lore_essay_id: this.form.lore_essay_id
        });
        console.log('result ===>', result);
        if (result.code === 0) {
          const { base_data = [] } = result.data;
          this.relevantList = base_data;
        }
      },
      setRelevant(arr) {
        this.relevantList = arr;
      },
      gotoSeeRelevant(item) {
        window.open(`/knowledge/detail?id=${ item.lore_essay_id }`)
      },
      removeRelevant(item) {
        let selectedArr = this.relevantList;
        const result = selectedArr.findIndex(ele => ele.lore_essay_id === item.lore_essay_id);
        if (result >= 0) {
          selectedArr.splice(result, 1);
          this.relevantList = [].concat(selectedArr);
        }
      }
    },
    computed: {
      showAddTagBtn() {
        return !this.inputVisible && this.form.label.length < this.maxTagLimit
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  $border_color: #666;
  .article-option{
    padding: 24px 0;
    :deep(.el-select){
      width: 320px;
    }

    :deep(.el-button){
      width: 241px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      font-size: 14px;
    }
    :deep(.el-form-item__label){
      text-align: left;
    }
    :deep(.el-input__inner){
      color: #666;
    }
    :deep(.el-upload--picture-card){
      width: 241px;
      height: 136px;
    }
    .relevant-content{
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      background: rgba(255, 255, 255, 0.10);
      margin-top: 16px;
      .relevant-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .content{
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          border-bottom: 1px solid transparent;
          cursor: pointer;
        }
        .content:hover{
          border-bottom: 1px solid #999;
        }
        .del-btn{
          margin-left: 5px;
          width: 16px;
          height: 16px;
          background-image: url('@/assets/article/icon/close_icon.png');
          background-size: contain;
          cursor: pointer;
        }
      }
    }
  }
  
  </style>
  