<template>
  <div class="general-setting">
    <el-form ref="optionForm" :model="form" :rules="rules" label-width="180px" @submit.native.prevent>
      <el-form-item :label="$t('views.createApplication.name')" prop="title"  label-width="170px">
        <el-input
          class="input-title"
          v-model.trim="form.title"
          :placeholder="$t('views.createApplication.titlePlaceHolder')"
          maxlength="100"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('views.createApplication.introduction')" prop="profiles" label-width="170px">
        <el-input
          class="input-title"
          type="textarea"
          :placeholder="$t('views.createApplication.introductionPlaceHolder')"
          v-model="form.profiles"
          :autosize="{ minRows: 6, maxRows: 6}"
          maxlength="1000"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
  </template>
  
  <script>
  import { ArticleCategoryOptions } from '@/config/article';
  export default {
    name: 'image-setting-form',
    components: { },
    data() {
      return {
        ArticleCategoryOptions,
        fileList: [],
        form: {
          title: '',
          profiles: '',
        },
        rules: {
          title: [
            { required: true, message: '', trigger: 'submit' },
          ],
          profiles: [
            { required: true, message: '', trigger: 'submit' },
          ],

        },
        inputVisible: false,
        maxTagLimit: 5,
        inputValue: '',
        relatedVisible: false
      }
    },
    mounted() {
    },
    methods: {
      submitForm(isValidate = true) {
        return new Promise(async (resolve, reject) => {
          if (!isValidate) {
            this.$refs.optionForm.validateField('title', (valid, errs = {}) => {
              if (!valid) {
                return resolve(this.form);
              } else {
                return false;
              }
            })
            return;
          }
          this.$refs.optionForm.validate((valid, errs = {}) => {
            console.log('valid:', valid)
            if (valid) {
              return resolve(this.form);
            } else {
              return false;
            }
          });
        });
      },
      restoreForm(formData) {
        console.log('formData:', formData)
        const { title='', profiles=''  } = formData;
        this.form.title = title;
        this.form.profiles = profiles;
      },
      resetForm() {
        this.$refs.optionForm.resetFields();
      },
    },
    computed: {
      showAddTagBtn() {
        return !this.inputVisible && this.form.label.length < this.maxTagLimit
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  $border_color: #666;
  .general-setting{
    padding: 32px 256px 32px 0;
    background: #0f0f0f;
    :deep(.el-select){
      width: 320px;
    }

    :deep(.el-button){
      width: 241px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      font-size: 14px;
    }
    :deep(.el-form-item__label){
      text-align: left;
    }
    :deep(.el-input__inner){
      border: 1px solid #333;
      color: #999;
    }
    :deep(.el-upload--picture-card){
      width: 241px;
      height: 136px;
    }
    :deep(.el-textarea__inner) {
      color: #999 !important;
    }
    :deep(textarea) {
      resize: none;
      border: 1px solid #333;
    }
    :deep(.el-input__count){
      background: transparent;
      color: #666;
    }
    :deep(.el-input__count-inner){
      background: transparent;
      color: #666;
    }
  }
  
  </style>
  