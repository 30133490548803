<template>
  <div class="inspect-send">
    <!-- 图片列表框 -->
    <div class="img-list-warp" v-if="imgList.length > 0">
      <div class="img-list">
        <div v-for="(item, index) in imgList" :key="index">
          <div class="img-item-warp">
            <img :src="item" alt="">
            <button class="delete-btn" @click="removeImg(index)"></button>
          </div>
        </div>
        <button class="delete-all-btn" @click="removeImgAll"></button>
      </div>
    </div>
    <!-- 语音转换弹框 -->
    <div>
      <Convert ref="AudioConvertRef" @onSendAudio="onSendAudio" />
    </div>
    <div class="send-box-warp">
      <div class="input-outer-box" @keydown.enter="onSendMsg">
        <el-input type="text" v-model="msg" :disabled="version === 'whisper' || version === 'tts-hd'" placeholder="请输入"></el-input>
      </div>
      <div class="send-option">
        <div class="audio-convert-btn" @click="openAudioPop" v-if="version === 'whisper' || version === 'tts-hd'"></div>
        <UploadImg @onUpload="onUpload" v-if="version === 'gpt-4o'" />
        <div class="send-btn" @click="onSendMsg" v-if="version !== 'whisper' && version !== 'tts-hd'"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadImg from './uploadImg.vue';
import Convert from './convert.vue'
import { MessageTypes } from '@/config/aiInspect';
import { MsgType } from '@/config/tokenTest';
import { LiteGateCosUpload } from '@/utils/lsCosUpload';

export default {
  name: 'send-box',
  components: {
    UploadImg,
    Convert,
  },
  props:{
    chatMsgs: {
      type: Array,
      default: () => []
    },
    version: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      msg: '',
      imgList: [],
      files:{},
      isShowAudioPop: false,
    }
  },
  mounted() {
    
  },
  methods:{
    onSendMsg() {
      console.log('onSendMsg this.files', this.files)
      if (this.msg || this.imgList.length > 0 || Object.keys(this.files).length > 0) {
        let len = this.chatMsgs.length - 1;
        //ai是否在回答中
        if (this.chatMsgs.length > 0 && this.chatMsgs[len].type === 'AI' && this.chatMsgs[len].messageType === MessageTypes.Wait) {
          this.$message({message: '请等待机器人回答完毕', type: 'warning'});
          return;
        }

        if (this.imgList.length > 0) {
          this.$emit('sendMsg', this.msg, MsgType.Image, this.imgList)
        } else if (Object.keys(this.files).length > 0) {
          if (this.files.type === MsgType.TextConvert) {//语音转文字
            this.$emit('sendMsg', this.msg, MsgType.TextConvert, this.files)
          } else {//文字转语言
            this.$emit('sendMsg', this.msg, MsgType.Audio, this.files)
          }
        } else {
          this.$emit('sendMsg', this.msg, MsgType.Text)
        }
        this.clearSendBox();
      }
    },
    onUpload(file, type) {
      if (type === MsgType.Audio) {
        this.files.raw = file.raw

        const reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = (event) => {
          this.files.audioUrl = event.target.result;
        }
        return;
      }
      const uid = file.uid;
      LiteGateCosUpload.getInstance().upload(file.raw, (url) => {
        this.onSuccess(uid, url);
      }, () => {
        console.log('upload error')
      }, 'file', (progress) => {

      });
    },
    onSuccess(uid, url) {
     this.imgList.push(url);
    },
    removeImg(index) {
      this.imgList.splice(index, 1)
    },
    removeImgAll() {
      this.imgList = [];
    },
    clearSendBox() {
      this.msg = '';
      this.imgList = [];
      this.files = {};
    },
    openAudioPop() {
      this.$refs.AudioConvertRef.openDialog(this.version)
    },
    onSendAudio(files) {
      this.files = files;
      this.onSendMsg()
    }
  }
}
</script>

<style lang="scss" scoped>
.inspect-send{
  width: 100%;
  position: relative;
}

.send-box-warp{
  width: 100%;
  display: flex;
  position: relative;
  background: #f8f9fa;
  padding: 10px 8px 10px;
  .input-outer-box{
    width: 100%;
    min-height: 50px;
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    :deep .el-input__inner{
      border: 0;
      margin-top: 5px;
    }
  }
  .send-option{
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-right: 10px;
    .send-btn{
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('@/assets/aiInspect/tokenTest/send-icon.png') no-repeat;
      background-size: 100% 100%;
    }
    .audio-convert-btn{
      width: 35px;
      height: 35px;
      cursor: pointer;
      margin-right: 15px;
      background: url('@/assets/aiInspect/tokenTest/audio-text-icon.png') no-repeat;
      background-size: 100% 100%;
    }
  }
  
}

.img-list-warp{
  position: absolute;
  top: -120px;
  width: 100%;
  height: 120px;
  padding: 10px 6px 6px 6px;
  background: #ffffff;
  .img-list{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    .delete-all-btn{
      position: absolute;
      top: 30px;
      right: 0;
      width: 20px;
      height: 20px;
      background: url('@/assets/aiInspect/tokenTest/delete-all-icon.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .img-item-warp{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
    .delete-btn{
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
      background: url('@/assets/aiInspect/tokenTest/delete-icon.png') no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
</style>