import Vue from 'vue';
import VueRouter from 'vue-router';
import { staticRoutes, defaultRoutes } from './route';
import routeDecorator from './routeDecorators';
import 'nprogress/nprogress.css';
import { resetRoute } from './commonRoute';

// 过滤重定向错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch(() => { });
};
Vue.use(VueRouter);

/**
 * 创建一个可以被 Vue 应用程序使用的路由实例
 * @method createRouter(options: RouterOptions): Router
 */
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: createRoutesTree(),
});

function createRoutesTree() {
  const routes = [...defaultRoutes, ...staticRoutes];
  navRouteHooks(routes);
  return routes;
}

// 生成相应的导航
function navRouteHooks(route) {
  resetRoute(route);
}

// 导出路由
export default routeDecorator(router, '/');
