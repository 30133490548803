<template>
  <div class="activity-fixed-btn" @click="onClick" :style="{ backgroundImage: 'url(' + getBg() + ')' }">
  </div>
</template>
  
<script>
import fixedBtnIcon from '@/assets/activity/token/fixed-btn.png'
import fixedBtnIconEn from '@/assets/activity/token/fixed-btn-en.png'
import { getLanguage } from '@/lang';
export default {
  name: 'activity-fixed-btn',
  props: {
    btnTxt: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fixedBtnIcon,
      fixedBtnIconEn,
      lang: 'en'
    }
  },
  mounted () {
    this.lang = getLanguage();
  },
  methods: {
    onClick() {
      this.$emit('onClick')
    },
    getBg() {
      return this.lang === 'en' ? this.fixedBtnIconEn : this.fixedBtnIcon
    }
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      this.lang = newLocale;
    }
  }

}
</script>
<style lang="scss" scoped>
  .activity-fixed-btn{
    width: 88px;
    height: 230px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0px;
    top: 48%;
    font-size: 46px;
    color: #022A27;
    font-weight: 800;
    padding: 24px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }
</style>