
export function abbreviateNumber(number) {
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}

export function addCommasToNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 将file size换算文件大小
export function formatFileSize(fileSize) {
  if (fileSize < 1024) {
    return fileSize + 'B';
  } else if (fileSize < 1024 * 1024) {
    return (fileSize / 1024).toFixed(2) + 'KB';
  } else if (fileSize < 1024 * 1024 * 1024) {
    return (fileSize / (1024 * 1024)).toFixed(2) + 'MB';
  } else {
    return (fileSize / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
  }
}

// 判断是否图片
export function isImagePath(filePath) {
  const imageExtensions = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
  return imageExtensions.test(filePath);
}

// 判断图片/视频的规格比例是否符合要求 proportionLimit：比例，deviation: 比例允许的偏差(0~100)
export async function  checkFileProportion(file, proportionLimit, _deviation = 0) {
  const deviation = Math.max(0, Math.min(100, _deviation));
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const img = new Image();
    const video = document.createElement('video');

    function checkRatio(width, height) {
      const ratio = width / height;
      const lowerBound = proportionLimit * (100 - deviation) / 100;
      const upperBound = proportionLimit * (100 + deviation) / 100;
      return ratio >= lowerBound && ratio <= upperBound;
    }

    img.onload = () => {
      URL.revokeObjectURL(url);
      resolve(checkRatio(img.width, img.height));
    };

    img.onerror = () => {
      video.src = url;
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(url);
        resolve(checkRatio(video.videoWidth, video.videoHeight));
      };
      video.onerror = () => {
        URL.revokeObjectURL(url);
        reject(new Error('Invalid file type'));
      };
    };

    img.src = url;
  });
}
