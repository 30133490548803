import { Local } from '@/utils/storage';

export interface CookieCache {
  expiresAt: number,
  cookie: Object,
}

export interface SessionCache {
  expiresAt: number,
  sessionToken: string,
}

const KEY = 'kratos';

const cache: { [key: string]: any } = {
  cookie: null,
  session: null,
};

export const getCache = (type: string) => {
  if (cache[type]) {
    if (cache[type].expiresAt < Date.now() / 1000) {
      return null;
    }
    return cache[type];
  }
  const key = `${KEY}_${type}`;
  const temp = Local.get(key);
  if (!temp) {
    return null;
  }
  const cc = temp;
  if (!cc) {
    return null;
  }
  if (cc.expiresAt < Date.now() / 1000) {
    return null;
  }
  cache[type] = cc;
  return cc;
};

export const saveCookie = (cookie: Object) => {
  const key = `${KEY}_cookie`;
  cache.cookie = { ...cache.cookie, ...cookie, expiresAt: Date.now() / 1000 + 3600 };
  // cache.cookie = { ...cache.cookie, ...cookie, expiresAt: undefined }
  Local.set(key, cache.cookie);
};

export const clearCookie = () => {
  const key = `${KEY}_cookie`;
  Local.remove(key);
};

export const saveSession = (sessionToken: string, expiresAt: number) => {
  const key = `${KEY}_session`;
  cache.session = { sessionToken, expiresAt };
  Local.set(key, cache.session);
};
