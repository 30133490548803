

//聊天token
// export const Token = '804305636274753536-7b59ffd5-a6f3-4b48-a6ec-42977da14b5c-bb703026-e371-468f-a0f9-43f029550c75';
// export const Token = '806938975020273664-e2e4e2e4-f349-4abf-94e0-0e32746ae931-19b713ad-2bd6-4360-b723-a8eda92ebdc0';
// export const Token = '810175255392833536-ec75ff2d-64bc-4f1f-860c-566a4376c1ad-fe099491-0626-4272-b664-dbc0ad1f241a';
export const Token = '815242262115012608-f02c1802-fb48-4b04-be30-f794eeb7cd53-e52d2bc5-104c-4551-838f-1f118f6a806c';

//模型版本
export const modelVersion = [
  {key:1, name:'gpt-35-turbo'}, 
  {key:2, name:'gpt-4'}, 
  {key:3, name:'gpt-4o'}, 
  {key:4, name:'claude-3-haiku'},
  {key:5, name:'claude-3-sonnet'},
  {key:6, name:'whisper'},
  {key:7, name:'tts-hd'},
]

//消息类型
export const MsgType = { 
  Text: 0,   //文本类型
  Image: 1,  //图片类型
  Audio: 2,  //语音类型 转换文字
  TextConvert: 3, //文字类型转语音
}

//音频格式
export const AudioFormatList = [
  {key: 1, name: 'mp3'},
  {key: 2, name: 'wav'},
]

//音频格式
export const VoiceFormatList = [
  {key: 1, name: 'Alloy'},
  {key: 2, name: 'echo'},
  {key: 3, name: 'onyx'},
  {key: 4, name: 'nova'},
  {key: 5, name: 'shimmer'},
]