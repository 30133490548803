import axios from 'axios';
import { AxiosRequestExtConfig, AxiosResponse } from 'axios';
import { Message } from 'element-ui';
import { cacheViewsPath, languageKey, litGateSessionKey, ResponseCode } from '@/config';
import { Local, clearUserSession } from '@/utils/storage';
import { getCache } from '@/utils/cache';

import router from '@/router';
import { Language_Types } from '@/config/stat';

declare module 'axios' {
  interface AxiosRequestExtConfig extends AxiosRequestConfig {
    noSessionToken: boolean   // 是否带X-Session-Token的header，并前置校验session
    noCookie: boolean   // 是否带x-cookie
  }
  // type AxiosInstance = (config: AxiosRequestExtConfig) => Promise<any>;
}

const initOptsRemote = {
  withCredentials: true,
  timeout: 0,
  headers: { 'Content-Type': 'application/json' },
  baseUrl: './'
};

function getCurrentLanguageType() {
  let cookieLanguage = Local.get(languageKey);
  if (!cookieLanguage) {
    const language = navigator.language.toLowerCase();
    cookieLanguage = language === 'zh-cn' ? language : 'en';
  }
  return cookieLanguage === 'zh-cn' ? Language_Types.Language_CN : Language_Types.Language_EN

}

export const clearAndLogout = () => {
  // Message.error('请登录');
  setTimeout(() => {
    clearUserSession();
    Local.set(cacheViewsPath, {
      path: window.location.href,
      query: {}
    });

    window.location.href = `${location.origin}/account/login`;
  }, 100);
};
const reqOpts = {
  success: (config: AxiosRequestExtConfig) => {
    if (!config.noSessionToken) {
      const oasisSession = Local.get(litGateSessionKey);
      if (oasisSession) {
        (config.headers as any)['user_id'] = oasisSession['user_id'] || '';
        (config.headers as any)['verify_session'] = oasisSession['verify_session'] || '';
      }
    }
    if (!config.noCookie) {
      const cookie = getCache('cookie');
      if (cookie) {
        (config.headers as any)['x-cookie'] = JSON.stringify(cookie);
      }
    }
    (config.headers as any)['language'] = getCurrentLanguageType();
    if (config.url?.indexOf('/openpf/') === 0) {
      config.baseURL = '';
    }
    return config;
  },
  error: (err: any) => Promise.reject(err),
};
const resOpts = {
  success: (response: AxiosResponse<any, any>) => {
    // trpc错误处理
    if (response.headers['trpc-func-ret']) {
      return Promise.reject(response.headers['trpc-error-msg']);
    }
    const res = response.data;
    if ((res.code && res.code !== 0) || (res.ret && res.ret !== 0)) {
      if (res.code === ResponseCode.SESSION_INVALID) {
        clearAndLogout();
        return;
      }
      // return Promise.reject(service.interceptors.response);
    }
    return response.data;
  },
  error: (error: any) => {
    // 对响应错误做点什么
    if (error.message.indexOf('timeout') !== -1) {
      Message.error('网络超时');
    } else if (error.message === 'Network Error') {
      Message.error('网络连接错误');
    } else {
      if (!error.response) {
        Message.error('网络连接错误');
        return Promise.reject(error);
      }
      const httpStatus = error.response.status;
      // 400为业务错误，reject
      if (httpStatus === 400) return Promise.reject(error.response.data);
      const errPageMap = {
        // 401: '/error/401',
        // 404: '/error/404',
      };
      if (httpStatus === 401 || httpStatus === 403) {
        clearAndLogout();
        return;
      } else if (errPageMap[httpStatus]) {
        router.push({ path: errPageMap[httpStatus] });
      } else if (error.response.data) Message.error(error.response.data);
      else Message.error('接口请求失败');
    }
    return Promise.reject();
  },
};

const service: any = axios.create(initOptsRemote);
service.interceptors.request.use(reqOpts.success, reqOpts.error);
service.interceptors.response.use(resOpts.success, resOpts.error);
export default service;
