<template>
  <div class="gate-comment-item">
    <UserAvatar :src="content.user_data.user_url" :userName="content.user_data.user_name" :size="32"></UserAvatar>
    <div class="comment-wrap">
      <div class="comment-title-container">
        <div class="comment-user-name">
          <div class="name">{{ content.user_data.user_name }}</div>
          <div class="reply" v-if="content.other_reply_id !== '0' && content.other_user_data">Reply</div>
          <div class="name" v-if="content.other_reply_id !== '0' && content.other_user_data">{{ content.other_user_data.user_name }}</div>
        </div>
        <div class="comment-time">{{ formatTime(content.create_time) }}</div>
      </div>
      <div class="comment-content" v-html="content.content"></div>
      <div class="comment-tool-wrap">
        <div class="btn-wrap" @click="onLike">
          <div :class="{ 'icon': true, 'like': !content.is_like, 'like-active': content.is_like }" ></div>
          <div class="value">{{ content.like_num }}</div>
        </div>
        <div class="comment btn" @click="onReply"></div>
        <el-popover
          placement="bottom"
          v-model="popoverVisible"
          title=""
          width="80"
          trigger="click">
          <MoreView @onDelete="deleteReply" @onHide="hidePopover" :isSelf="isSelf" :commentText="content.content" @onReport="onReport"/>
          <div class="more" slot="reference" ></div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import UserAvatar from '@/components/userAvatar/index.vue'
import { formatDate } from '@/utils/format';
import MoreView from './moreView.vue'
import { delReply } from '@/api/comment';
import { CommentTypes } from '@/config/stat';
import { mapGetters } from 'vuex';
export default {
  name: 'gate-comment-item',
  components: {
    UserAvatar,
    MoreView
  },
  props: {
    content: {
      type: Object,
      default: () => {}
    },
    comment: {
      type: Object,
      default: () => {}
    },
    commentType: {
      type: Number,
      default: CommentTypes.Comment_Type_Lore_Essay
    },
    gId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      popoverVisible: false
    }
  },
  methods: {
    onReport() {
      this.$emit('onReport', 'reply', this.content)
    },
    onLike() {
      this.$emit('onLike', !this.content.is_like, this.content);
    },
    onReply(){
      this.$emit('onReply', this.content, this.comment);
    },
    formatTime(seconds) {
      const date = new Date(Number(seconds) * 1000);
      return formatDate(date);
    },
    async deleteReply() {
      const result = await delReply({
        comment_type:  this.commentType,
        g_id: this.gId,
        comment_id: this.comment.comment_id,
        reply_id: this.content.reply_id
      })
      console.log('delete result', result)
      if (result.code === 0) {
        this.$message.success(this.$t('comment.deleteSuccess'))
        this.$emit('onDelete', this.comment.comment_id);
      }
    },
    hidePopover() {
      this.popoverVisible = false;
    }
  },
  computed: {
    isSelf() {
      return this.userInfo.user_id === this.content.user_id
    },
    ...mapGetters(['userInfo'])
  }
}
</script>

<style lang="scss" scoped>
$info_color: #666;
$content_color: #ADADAD;
.gate-comment-item{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  .comment-head{
    width: 40px;
    min-width: 40px;
  }
  .comment-wrap{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: calc(100% - 46px);
    .comment-title-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      padding: 6px 0;
      width: 100%;
      .comment-user-name{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        gap: 12px;
        .name{
          color: $info_color;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }
        .reply{
          color: #333;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
      .comment-time {
        color: $info_color;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .comment-content {
      width: 100%;
      color: $content_color;
      font-size: 14px;
      line-height: 22px;
    }
    .comment-tool-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      margin-top: 16px;
      gap: 16px;
      .btn{
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      .comment{
        background: url('@/assets/knowledge/icon_comment.png') no-repeat;
        background-size: 100%;
      }
      .more{
        width: 20px;
        height: 14px;
        background: url('@/assets/comment/icon_more.png') no-repeat;
        background-size: contain;
        cursor: pointer;
      }
      .btn-wrap{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        .icon{
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        .icon:first-of-type{
          margin-left: 0;
        }
        .like{
          background: url('@/assets/knowledge/icon_like.png') no-repeat;
          background-size: 100%;
        }
        .like-active{
          background: url('@/assets/knowledge/icon_like_active.png') no-repeat;
          background-size: 100%;
        }
        .value{
          color: #666;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}

</style>
