  <template>
    <div>
      <el-dialog
        :title="$t('views.apiKeyManager.dialog.resultTitle')"
        :visible.sync="visible"
        width="424px"
        :show-close="false"
        custom-class="lit-custom-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <div class="title-line" slot="title">
            <div class="title">{{ $t('views.apiKeyManager.dialog.resultTitle') }}</div>
            <div class="close-btn" @click="visible = false"></div>
          </div>
        <div class="dialog-content-wrap">
          <div class="tips">{{ $t('views.apiKeyManager.dialog.resultTips') }}</div>
          <div class="key-window">
            <div class="key-value"> {{ key }}</div>
            <div class="copy-btn" @click="copyText"></div>
          </div>
        </div>
        <div slot="footer" :class="{'submit-btn': true}" @click="onClose">
          {{ $t('views.apiKeyManager.dialog.sure') }}
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
import { copyToClipboard } from '@/utils/dom';

  export default {
    name: 'create-api-key-dialog',
    props: {
    },
    data() {
      return {
        visible: false,
        key: '',
        isCreate: false
      }
    },
    mounted() {
    },
    methods: {
      open(info, isCreate = false) {
        this.key = info.keys;
        this.visible = true;
        this.isCreate = isCreate;
      },
      onClose() {
        this.visible = false;
        this.$emit('onClose', this.isCreate);
      },
      copyText() {
        copyToClipboard(this.key);
        const tips = this.$t('tips.succ.copy');
        this.$message.success(tips);
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .lit-custom-dialog{
    .title-line {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        .title{
          color: #DDD;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          // align-self: stretch;
        }
        .close-btn{
          width: 24px;
          height: 24px;
          background: url('@/assets/postApplication/apiKey/icon_dialog_close.png');
          background-size: 100% 100%;
          cursor: pointer;
        }
      }
    .dialog-content-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 24px;
      .tips{
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          // align-self: stretch;
        }
      .key-window{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 8px;
        padding: 5px 12px;
        align-items: center;
        border: 1px solid #333;
        .key-value{
          flex: 1 0 0;
          overflow: hidden;
          color: #999;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        .copy-btn{
          width: 14px;
          height: 16px;
          cursor: pointer;
          background: url('@/assets/postApplication/apiKey/icon_copy.png');
          background-size: 100% 100%;
        }
      }
      .input-wrap{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 16px;
        .input-label{
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; 
        }
        .lit-custom-dialog-input{

        }
      }
    }
    .submit-btn{
      display: flex;
      height: 32px;
      padding: 5px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      background: #00FFF6;
      cursor: pointer;
    }
    .submit-btn:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #00FFF6;
    }
    .disable{
      opacity: 0.3;
      background: #00FFF6 !important;
    }
  }
  
  </style>
  