<template>
  <div class="stat-info-tool" ref="statTool">
    <div class="info" v-show="postTime !== 0">{{ $t('views.gameWorksDetail.posted') }}: {{ showPostTime }}</div>
    <div v-if="postTime === 0"></div>
    <div class="tool">
      <div class="btn" @click="likeFunc">
        <div :class="{ 'icon': true, 'like': !isLiked, 'like-active': isLiked }" ></div>
        <div class="info">{{ likeNum }}</div>
      </div>
      <div class="btn">
        <div class="icon comment"></div>
        <div class="info">{{ commentNum }}</div>
      </div>
      <div class="btn" @click="collectFunc">
        <div :class="{'icon': true, 'favorite': !isFavorite, 'favorite-active': isFavorite}" ></div>
        <div class="info">{{ isFavorite ? $t('common.favorited') : $t('common.favorite') }}</div>
      </div>
      <el-popover
        placement="bottom"
        v-model="popoverVisible"
        title=""
        width="160"
        trigger="click">
        <ShareCard @share="copyFunc"/>
        <div class="btn shareBtn" slot="reference" >
          <div class="icon share"></div>
          <div class="info" >{{ $t('views.gameWorksDetail.share') }}</div>
        </div>
      </el-popover>

      <el-popover
        placement="bottom"
        v-model="moreViewVisible"
        title=""
        width="80"
        trigger="click">
        <MoreView @onReport="onReport" />
        <div class="more" slot="reference" ></div>
      </el-popover>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ShareCard from './shareCard.vue'
import MoreView from './components/moreView.vue'
import GateReport from '@/components/report/index.vue';
@Component({
    name: 'statInfoTool',
    components: { ShareCard, MoreView, GateReport }
})
class statInfoTool extends Vue {
  @Prop({ default: Date.now() }) public postTime!: number
  @Prop({ default: 0 }) public likeCount!: number
  @Prop({ default: false }) public isLiked!: boolean
  @Prop({ default: 0 }) public commentCount!: number
  @Prop({ default: false }) public isFavorite!: boolean
  @Prop({ default: '' }) public articleId!: String

  public popoverVisible = false;
  public moreViewVisible = false;
  public observer;

  mounted() {
    this.stickAnchorElement();
  }

  public formatCount(num){
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`
    }
    return Math.max(num, 0);
  }

  public formatTime(milliseconds) {
    const date = new Date(Number(milliseconds));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  public likeFunc() {
    this.$emit('like', !this.isLiked ? 1 : 0)
  }

  public collectFunc() {
    this.$emit('collect', !this.isFavorite)
  }

  public copyFunc() {
    this.popoverVisible = false;
    this.$emit('share');
  }

  public onReport() {
    this.moreViewVisible = false;
    this.$emit('onReport')
  }

  public stickAnchorElement() {
    const self = this;
    if (!this.$refs.statTool) return;
    var stickyElement = this.$refs.statTool;
    this.observer = new IntersectionObserver(function(entries) {
      console.log('entries:', entries);
      entries.forEach(function(entry) {
        if (entry.intersectionRatio <= 0) {
          self.$emit('onShowAnchorBar', true);
        } else {
          self.$emit('onShowAnchorBar', false);
        }
      });
    });
    this.observer.observe(stickyElement);
  }
  

  get likeNum() {
    return this.formatCount(this.likeCount);
  }

  get commentNum() {
    return `${this.formatCount(this.commentCount)} ${this.$t('comment.title')}`;
  }

  get showPostTime() {
    return this.formatTime(this.postTime)
  }
  get isSelf() {
    return true
  }
  beforeDestroy() {
    var stickyElement = this.$refs.statTool;
    this.observer.unobserve(stickyElement);
    this.observer.disconnect();
  }
  
}

export default statInfoTool
</script>
  
<style lang="scss" scoped>
.stat-info-tool{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .info{
    color: #666;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  .tool {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    .btn{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      .icon{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .icon:first-of-type{
        margin-left: 0;
      }
      .like{
        background: url('../../assets/knowledge/icon_like.png') no-repeat;
        background-size: 100%;
      }
      .like-active{
        background: url('../../assets/knowledge/icon_like_active.png') no-repeat;
        background-size: 100%;
      }
      .favorite{
        background: url('../../assets/knowledge/icon_favorite.png') no-repeat;
        background-size: 100%;
      }
      .favorite-active{
        background: url('../../assets/knowledge/icon_favorite_active.png') no-repeat;
        background-size: 100%;
      }
      .comment{
        background: url('../../assets/knowledge/icon_comment.png') no-repeat;
        background-size: 100%;
      }
      .share{
        background: url('../../assets/knowledge/icon_share.png') no-repeat;
        background-size: 100%;
      }
      
      .info {
        color: #666;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
        margin-left: 3px;
      }
    }
    .shareBtn:hover{
      .share{
        background: url('../../assets/knowledge/icon_share_active.png') no-repeat;
        background-size: 100%;
      }
      .info{
        color: #00FFF6;
      }
    }
    .more-view-dropdownList{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      background:  #141414;
      padding: 8px;
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
      .link-options{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 4px 0;
        .icon{
          width: 20px;
          height: 20px;
        }
        .link-icon{
          background: url('@/assets/knowledge/detail/icon_link.png') no-repeat;
          background-size: 100%;
        }
        
        .text{
          overflow: hidden;
          color: #666;
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
        
      }
      .link-options:hover{
        .link-icon{
          background: url('@/assets/knowledge/detail/icon_link_active.png') no-repeat;
          background-size: 100%;
        }
        .text{
          color: #ddd;
        }
      }
    }

    .more{
      width: 20px;
      height: 14px;
      background: url('@/assets/comment/icon_more.png') no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    
  }
}
</style>