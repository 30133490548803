<template>
  <div class="detail-mask" v-if="isShow" @click.self="closePop">
    <div class="detail-warp">
      <div class="close-icon" @click="closePop"></div>
      <div class="detail-container">
        <!-- 左边图片区域 -->
        <div class="left-warp">
          <el-carousel trigger="click" height="1000px" :loop="false" :autoplay="false" :indicator-position="indicators" arrow="always" @change="handleChange">
            <div v-if="value.photo_data.length > 0">
              <el-carousel-item v-for="(item, index) in value.photo_data" :key="index">
                <div class="image-item">
                  <div class="images" :style="`background-image: url(${item.photo})`"></div>
                </div>
              </el-carousel-item>
            </div>
            <div v-else>
              <el-carousel-item v-for="(item, index) in value.other_data.medias.pictures" :key="index">
                <div class="image-item">
                  <div class="images" :style="`background-image: url(${item})`"></div>
                </div>
              </el-carousel-item>
            </div>
          </el-carousel>
        </div>
        <!-- 右边信息区域 -->
        <div class="right-warp">
          <!-- 作者信息 -->
          <div :class="{'author-info-warp': true, 'bottom-line': rightScroll}">
            <div class="avatar" @click="gotoSeeGuest">
              <UserAvatar :src="value.user_data.user_url" :userName="value.user_data.user_name"></UserAvatar>
            </div>
            <div class="name-warp">
              <div class="name" @click="gotoSeeGuest">{{ value.user_data.user_name }}</div>
              <div class="profile">{{ value.user_data.user_profiles }}</div>
            </div>
            <!-- 关注按钮 -->
            <div v-if="isShowFollow">
              <button class="follow-botton " v-if="userInfo.is_followee" @click="handleFollo(folloStatus.unfollow)">{{ $t('following.followedBtn') }}</button>
              <!-- <button class="follow-botton" v-else-if="userInfo.is_follower && !userInfo.is_followee" @click="handleFollo(folloStatus.follow)">{{ $t('following.mutualFollowing') }}</button> -->
              <button class="follow-botton no-follow" v-else @click="handleFollo(folloStatus.follow)">{{ $t('following.followBtn') }}</button>
            </div>
          </div>
          <div class="scroll-views">
            <div ref="scrollViewsAnchor"/>
            <!-- 标题 -->
            <div class="works-title">{{ value.works_title }}</div>
            <!-- 简介 -->
            <div class="works-profiles" ref="profiles">{{ value.profiles }}</div>
            <!-- 更多按钮 -->
            <div class="profiles-view-more" v-if="isMore" @click="handleClickMore">
              <div class="view-more-text">{{ $t('views.productImageDetail.viewMore') }}</div>
              <img src="@/assets/gameWorks/detail/more-icon.png" alt="">
            </div>
            <!-- 标签 -->
            <div class="works-lable">
              <div class="lable-item" v-for="(label, indexs) in value.base_data.label" :key="indexs">{{ label }}</div>
            </div>
            <!-- 时间 -->
            <div class="time">
              <div>{{formatTime(value.release_time)}}</div>
            </div>
            <!-- 功能 -->
            <div class="option">
              <!-- 点赞 -->
              <div class="option-item" @click="handleClickLike">
                <div class="icon">
                  <img v-if="value.is_like" src="@/assets/knowledge/icon_like_active.png" alt="" />
                  <img v-else src="@/assets/statIcon/details/icon_likes.png" />
                </div>
                <div class="count">{{formatStat(value.stat_data && value.stat_data.like_num)}}</div>
              </div>
              <!-- 评论 -->
              <div class="option-item">
                <div class="icon">
                  <img v-if="value.is_comment" src="@/assets/knowledge/comment-icon-active.png" alt="" />
                  <img v-else src="@/assets/statIcon/details/icon_comment.png" />
                </div>
                <div class="count">{{formatStat(value.stat_data && value.stat_data.comment_num)}}</div>
              </div>
              <!-- 收藏 -->
              <div class="option-item" @click="handleClickCollect">
                <div class="icon">
                  <img v-if="value.is_collect" src="@/assets/knowledge/icon_favorite_active.png" alt="">
                  <img v-else src="@/assets/statIcon/details/icon_collect.png" />
                </div>
                <div class="count">{{formatStat(value.stat_data && value.stat_data.collect_num)}}</div>
              </div>
              <!-- 分享 -->
              <div class="option-item" @click="copyFunc">
                <div class="icon">
                  <img src="@/assets/statIcon/details/icon_share.png" />
                </div>
                <div class="count">{{ $t('views.productImageDetail.share') }}</div>
              </div>
            </div>
            <!-- 评论 -->
            <div class="comment-wrap">
              <CommentView :gId="value.works_id" :commentType="CommentTypes.Comment_Type_Works_Game_Deme" :isSimple="true" :pageSize="5" ref="commentView" />
            </div>
            <div class="generation-wrap" v-if="false">
              <div class="generation-title">Generation Data</div>
              <div class="option-title">Prompt</div>
              <div class="option-content">{{ currentPhotoData.prompt || '' }}</div>
              <div class="option-title">Negative Prompt</div>
              <div class="option-content">{{ currentPhotoData.negative_prompt || '' }}</div>
              <div class="option-title">Sampler</div>
              <div class="option-content">{{ currentPhotoData.sampler || '' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import CommentView from '@/components/comment/index.vue'
import UserAvatar from '@/components/userAvatar/index.vue'
import { abbreviateNumber } from '@/utils/statFormat'
import { normalizeDate, formatDate } from '@/utils/format'
import { CollectTypes, LikeTypes, CommentTypes, GateReportTypes } from '@/config/stat'
import { deleteUserCollect, updateUserCollect, userLike, getUserCollect } from '@/api/userLikeCollect'
import { getGameWorks } from '@/api/gameWorks';
import { gateReport } from '@/api/gateReport'
import { exportPV } from '@/utils/auth'
import { getLitUserData } from '@/api/gateUser';
import { followOption } from '@/api/following'
import { FolloStatus } from '@/config/personal'
import { Local } from '@/utils/storage';
import { litGateSessionKey } from '@/config';

@Component({
  name: 'ArtsDetails',
  components: { CommentView, UserAvatar }
})
class ArtsDetails extends Vue {
  public openById = false;
  public worksId = '';
  public value = {} as any;
  public isShow = false;
  public CommentTypes = CommentTypes;
  public currentImageIndex = 0;
  public currentPhotoData = {} as any;
  public observer: any;
  public rightScroll = false;
  public indicators = '';
  public isMore = false;
  public userInfo = {} as any;
  public isShowFollow = true;
  public folloStatus = {
    follow: FolloStatus.follow,
    unfollow: FolloStatus.unfollow,
  };

  public closePop() {
    this.isShow = false;
    this.unObserver();
  }

  public openPopById(worksId: string) {
    this.isShow = true;
    this.openById = true;
    this.getWorksById(worksId);
    this.initState();
  }

  public openPop(value: Object) {
    this.isShow = true;
    this.value = value;
    console.log('this.value', this.value)
    this.worksId = value.works_id;
    this.initState();
    this.getUserInfo();
  }
  
  public initState() {
    this.handleChange(0);
    this.$nextTick(() => {
      this.stickAnchorElement();
      //一张图片不显示指示器
      this.indicators = this.value.photo_data.length === 1 ? 'none' : '';
      this.gateReportBrowse();
      this.checkTextHeight();
    })
  }

  public gotoSeeGuest() {
    this.$router.push({ path: '/personal/guest', query: { id: this.value.user_id } })
  }

  mounted() {
  }

  public formatTime(time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime, 'YYYY/mm/dd')
  }

  public formatStat(num:string) {
    return abbreviateNumber(Number(num));
  }

  //复制
  public copyFunc() {
    const currentLink = window.location.href;
    navigator.clipboard.writeText(`${currentLink}?pictDetailsId=${this.value.works_id}`)
    const tips:any = this.$t('tips.succ.copy');
    this.$message.success(tips);
  }

  //点赞
  public async handleClickLike() {
    const isLike = this.value.is_like ? 0 : 1;
    const params = {
      like_type: LikeTypes.Like_Type_Works_Game_Deme,
      gid: this.worksId,
      is_like: isLike
    }
    const res = await userLike(params);
    if (res.code === 0) {
      this.$emit('updateArtValue', this.value.works_id, 'change')
      if (this.openById) {
        this.getWorksById(this.worksId);
        return;
      }
      this.value.is_like = res.data.is_like === 1 ? true : false;
      if (res.data.is_like === 1) {
        this.value.stat_data.like_num = parseInt(this.value.stat_data.like_num) + 1;
      } else {
        this.value.stat_data.like_num = parseInt(this.value.stat_data.like_num) - 1;
      }
    }
  }

  //收藏
  public async handleClickCollect() {
    const params = {
      collect_type: CollectTypes.Collect_Type_Works_Game_Deme,
      gid: this.value.works_id,
    }

    let res: any;
    //收藏 取消收藏
    if (this.value.is_collect) {
      res = await deleteUserCollect(params);
    } else {
      res = await updateUserCollect(params);
      // collect_type: 1
    }
    console.log('res', res)

    if (res.code === 0) {
      this.$emit('updateArtValue', this.value.works_id, 'change')
      // this.getWorksById(this.value.works_id);
      // this.value.is_collect = res.data.collect_type === 1 ? true : false;
      // if (res.data.collect_type === 1) {
      //   this.value.stat_data.collect_num = parseInt(this.value.stat_data.collect_num) + 1;
      // } else {
      //   this.value.stat_data.collect_num = parseInt(this.value.stat_data.collect_num) - 1;
      // }
    }
  }

  public handleChange(index: number) {
    this.currentImageIndex = index;
    if (this.value.photo_data && this.value.photo_data.length >= index ) {
      this.currentPhotoData = this.value.photo_data[index] || {}
    }
  }

  public getWorksId() {
    return this.value.works_id
  }

  //id查询作品
  public async getWorksById(id: any) {
    this.worksId = id;
    this.value = {};
    const result = await getGameWorks({works_id: id});
    if (result.code === 0) {
      this.value = result.data.base_data;
    }
  }

  async gateReportBrowse() {
    gateReport({
      event_type: GateReportTypes.Report_Event_Type_WorksBrowse,
      param: [ Math.floor(Date.now() / 1000) ],
      param_str: [
        this.value.works_id,
        this.$route.path,
        this.$route.fullPath,
      ],
    }).catch(e=> {});
    exportPV([
      this.value.works_id,
      this.$route.path,
      this.$route.fullPath
    ]);
  }

  public stickAnchorElement() {
    const self = this;
    if (!this.$refs.scrollViewsAnchor) return;
    var stickyElement = this.$refs.scrollViewsAnchor;
    this.observer = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry) {
        if (entry.intersectionRatio <= 0) {
          self.rightScroll = true;
        } else {
          self.rightScroll = false;
        }
      });
    });
    this.observer.observe(stickyElement);
  }

  public unObserver() {
    var stickyElement = this.$refs.scrollViewsAnchor;
    this.observer.unobserve(stickyElement);
    this.observer.disconnect();
  }

  public checkTextHeight() {
    let el:any = this.$refs.profiles;    
    let height = el.clientHeight;
    let lineHeight = parseInt(window.getComputedStyle(el).getPropertyValue('line-height'));
    let lines = Math.floor(height / lineHeight)

    if (lines > 2) {
      el.style.webkitLineClamp = 2;
      this.isMore = true;
    }
  }

  public handleClickMore() {
    let el:any = this.$refs.profiles;
    el.style.webkitLineClamp = 999;
    this.isMore = false;
  }

  public getUserInfo() {
    this.isShowFollow = true;
    getLitUserData({
      user_id: this.value.user_id
    }).then(res => {
      console.log('res=>', res);
      if (res.code === 0) {
        const { data } = res;
        this.userInfo = data.data;
        const { user_id = '' } =  Local.get(litGateSessionKey) || {};
        //用户是当前作者
        if (user_id && user_id === this.userInfo.user_id) {
          this.isShowFollow = false;
        }
      }
    }).catch((e) => {
      console.log('err:', e);
    })
  }

   public async handleFollo(operate: number) {
    const res: any = await followOption({
      user_id: this.value.user_id,
      operate
    })
    console.log('handleFollo res', res)
    if (res.code === 0) {
     this.getUserInfo()
    }
  }
  
}

export default ArtsDetails
</script>

<style lang="scss" scoped>
@media screen and (max-height: 1000px) {
  .close-icon{
    top: 10px !important;
    right: 1% !important;
  }
  .right-warp{
    height: 700px !important;
  }
  ::v-deep .el-carousel__container{
    height: 700px !important;
  }
}

$optionTitle: #ddd;
$buttonColor: #00FFF6;
$textColor: #666666;
.detail-mask{
  font-family: 'Poppins'; 
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  backdrop-filter: blur(12px);
  z-index: 1000;
  .close-icon{
    width: 40px;
    height: 40px;
    background: url('@/assets/gameWorks/detail/close-detail-icon.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 45px;
    right: 5%;
    cursor: pointer;
  }
  .detail-warp{
    .detail-container{
      width: 1202px;
      min-height: calc(100vh - 100px);
      position: absolute;
      left: 50%;
      top: 50%;
      // margin-top: 40px;
      display: flex;
      align-items: center;
      transform: translate(-50%, -50%);
    }
    .left-warp{
      width: 762px;
      height: 100%;
      .image-item{
        width: 100%;
        height: 100%;
        background: #141414;
        .images{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: contain;
        }
        // img{
        //   width: 100%;
        //   height: 100%;
        //   // object-fit: cover;
        // }
      }
    }
    .right-warp{
      width: 440px;
      height: 1000px;
      padding: 24px 8px;
      background: #141414;
      position: relative;
      .author-info-warp{
        padding: 0 16px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        color: var(--text-text-1-ddd, #DDD);
        .avatar{
          width: 40px;
          height: 40px;
          cursor: pointer;
          .UserAvatar{
            width: 40px !important;
            height: 40px !important;
          }
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        .name-warp{
          margin-left: 12px;
          .name{
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
          }
          .profile{
            color: var(--text-text-3666, #666);
            font-size: 12px;
            font-weight: 400;
            width: 235px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .follow-botton{
          position: absolute;
          right: 24px;
          top: 24px;
          min-width: 77px;
          height: 32px;
          color: var(--text-text-3666, $textColor);
          font-size: 14px;
          font-weight: 500;
          display: flex;
          padding: 5px 16px;
          justify-content: center;
          align-items: center;
          transform: translateY(5px);
          cursor: pointer;
          border: 1px solid var(--text-text-3666, $textColor);
          &:hover{
            border: 1px solid var(--text-text-2999, #999);
          }
        }
        .no-follow{
          color: var(--text-text-2999, $buttonColor);
          border: 1px solid $buttonColor;
          &:hover{
            border: 1px solid #99FFFB;;
          }
        }
      }
      .bottom-line{
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
      }
      .scroll-views{
        height: calc(100% - 50px);
        overflow: auto;
        padding: 0 12px;
        .works-title{
          color: #DDD;
          font-size: 20px;
          font-weight: 500;
          margin-top: 24px;
        }
        .works-profiles{
          margin-top: 8px;
          color: var(--text-text-2999, #999);
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          overflow: hidden;
          display: -webkit-box;
          // -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .profiles-view-more{
          position: relative;
          color: var(--text-text-3666, #666);
          font-size: 14px;
          font-weight: 400;
          display: flex;
          align-items: center;
          cursor: pointer;
          // .view-more-text{
            
          // }
          // &::after{
          //   content: '';
          //   position: absolute;
          //   top: 50%;
          //   left: 80px;
          //   transform: translateY(-50%);
          //   width: 16px;
          //   height: 16px;
          //   background: url('@/assets/gameWorks/detail/more-icon.png') no-repeat;
          //   background-size: 100% 100%;
          // }
        }
        .works-lable{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 8px;
          .lable-item{
            min-width: 28px;
            height: 28px;
            text-align: center;
            padding: 0 8px;
            line-height: 28px;
            background: rgba(255, 255, 255, 0.06);
            color: var(--Brand, #00FFF6);
            font-size: 12px;
            font-weight: 500;
            margin-right: 12px;
            margin-bottom: 5px;
          }
        }
        .time{
          font-size: 12px;
          font-weight: 400;
          color: #666;
          margin-top: 8px;
        }
        .option{
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 16px;
          .option-item{
            display: flex;
            align-items: center;
            color: #666;
            font-size: 12px;
            font-weight: 400;
            margin-right: 16px;
            cursor: pointer;
            .icon{
              margin-right: 2px;
              img{
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .comment-wrap{
          margin-top: 12px;
        }
        .generation-wrap{
          margin-top: 8px;
          .generation-title{
            font-size: 16px;
            font-weight: 400;
            padding: 8px 0;
            color: $optionTitle;
          }
          .option-title{
            font-size: 14px;
            font-weight: 400;
            color: #999;
            line-height: 22px;
            margin-top: 8px;
          }
          .option-content{
            padding: 12px;
            background: rgba(255, 255, 255, 0.04);
            font-size: 14px;
            color: #999;
            line-height: 22px;
            font-weight: 500;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // overflow: hidden;
            // -webkit-line-clamp: 6;
            // -webkit-box-orient: vertical;
            // word-break: break-all;
          }
        }
      }
    }
  }
}

</style>

<style scoped>
/deep/ .el-carousel__button {
  width: 6px;
  height: 6px;
}

/deep/ .el-carousel__arrow--left{
  display: block !important;
}

/deep/ .el-carousel__arrow--right{
  display: block !important;
}

/deep/ .el-carousel__arrow{
  background-color: #00000066;
}

</style>