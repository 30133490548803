import request from '@/utils/request';

const BaseUrl = `/api/sns`;
const BaseUserUrl = `/api/sns/user`;

// 评论列表
export async function selectCommentList(data: any) {
  return request({
    url: `${BaseUrl}/selectcommentall`,
    method: 'post',
    data
  });
}

// 回复列表
export async function selectReplyList(data: any) {
  return request({
    url: `${BaseUrl}/selectreplyall`,
    method: 'post',
    data
  });
}

// 评论
export async function sendComment(data: any) {
  return request({
    url: `${BaseUserUrl}/addcomment`,
    method: 'post',
    data
  });
}

// 回复=>评论 || 回复=>回复
export async function sendReply(data: any) {
  return request({
    url: `${BaseUserUrl}/addreply`,
    method: 'post',
    data
  });
}

// 获取用户审核中的列表
export async function selectAuditCommentList(data: any) {
  return request({
    url: `${BaseUserUrl}/commentuserall`,
    method: 'post',
    data
  });
}

// 获取用户审核中的列表
export async function selectAuditReplyList(data: any) {
  return request({
    url: `${BaseUserUrl}/replyuserall`,
    method: 'post',
    data
  });
}

// 删除评论
export async function delComment(data: any) {
  return request({
    url: `${BaseUserUrl}/delcomment`,
    method: 'post',
    data
  });
}

// 删除评论回复
export async function delReply(data: any) {
  return request({
    url: `${BaseUserUrl}/delreply`,
    method: 'post',
    data
  });
}


