import request from '@/utils/request';

const BaseUrl = `/api/basesys`;

// 上报
export async function gateReport(data: any) {
  return request({
    url: `${BaseUrl}/commreportevent`,
    method: 'post',
    data
  });
}

// 获取全局类型数据
export async function getGlobalTypeData(data: any) {
  return request({
    url: `${BaseUrl}/getglobaltypedata`,
    method: 'post',
    data
  });
}



