<template>
  <div class="convert-container">
    <div class="convert-audio-warp">
      <el-dialog :visible.sync="AudioDialog" width="30%" center>
        <el-tabs v-model="activeName">
          <el-tab-pane label="语音转文字" name="audio">
            <div class="upload-audio-warp">
              <UploadAudio @onUpload="onUpload" />
              <div class="audio-file-warp" v-if="Object.keys(files).length > 0">
                <div class="audio-file">
                  <button class="close-btn" @click="delAudio"></button>
                  <div class="file-name">{{ files.name }}</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sendAudio">发 送</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="TextDialog" width="30%" center>
        <el-tabs v-model="activeName">
          <el-tab-pane label="文字转语音" name="text">
            <div class="text-file-warp">
              <el-input type="textarea" :rows="3" placeholder="请输入需要转换的文字" v-model="textFiles.input"></el-input>
              <div class="option-item">
                <div class="title">格式：</div>
                <el-select v-model="textFiles.response_format" placeholder="请选择音频格式">
                  <el-option
                    v-for="item in AudioFormatList"
                    :key="item.key"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="option-item">
                <div class="title">嗓音：</div>
                <el-select v-model="textFiles.voice" placeholder="请选择音频格式">
                  <el-option
                    v-for="item in VoiceFormatList"
                    :key="item.key"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <div class="option-item">
                <div class="title">语速：</div>
                <div class="slider-warp">
                  <div>慢</div>
                  <el-slider v-model="textFiles.speed" :min="0.1" :max="2" :step="0.1" style="width: 300px;margin: 0 15px;"></el-slider>
                  <div>快</div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sendAudio">发 送</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UploadAudio from './uploadAudio.vue'
import { MsgType, AudioFormatList, VoiceFormatList } from '@/config/tokenTest';

export default {
  name: 'chat-audio-convert',
  components: {
    UploadAudio,
  },
  data() {
    return{
      AudioDialog: false,
      TextDialog: false,
      activeName: 'audio',
      version: '',
      files: {},
      textFiles: {
        model: 'tts-hd',
        input: '',
        speed: 1,
        voice: 'alloy',
        response_format: 'wav',
      },
      AudioFormatList,
      VoiceFormatList,
    }
  },
  methods: {
    onUpload(file, type) {
      if (type === MsgType.Audio) {
        this.files = {};
        this.files.raw = file.raw;
        this.files.name = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = (event) => {
          this.files.audioUrl = event.target.result;
        }
      }
    },
    openDialog(type) {
      if (type === 'whisper') {
        this.AudioDialog = true;
        this.activeName = 'audio';
      } else if (type === 'tts-hd') {
        this.activeName = 'text';
        this.TextDialog = true;
      }
      this.initOption();
    },
    delAudio() {
      this.files = {};
    },
    sendAudio() {
      if (this.activeName === 'text') {
        this.files = JSON.stringify(this.textFiles);
        this.files = JSON.parse(this.files);
        this.files.type = MsgType.TextConvert;
      }
      if (Object.keys(this.files).length > 0) {
        this.$emit('onSendAudio', this.files);
        this.AudioDialog = false;
        this.TextDialog = false;
        this.initOption();
      } else {
        if (this.activeName === 'audio') {
          this.$message.error('请先上传音频文件');
        } else {
          if (!this.textFiles.input) {
            this.$message.error('请输入转换文字');
          }
        }
      }
    },
    initOption() {
      // this.activeName = 'audio',
      this.files = {};
      this.textFiles.input = '';
      this.textFiles.speed = 1;
      this.textFiles.voice = 'alloy';
      this.response_format = 'wav';
    },
  }
}
</script>

<style lang="scss" scoped>
.convert-container{
  .convert-audio-warp{
    :deep(.el-dialog__header) {
      padding: 0;
    }
    :deep(.el-tabs__content){
      height: auto
    }

    .upload-audio-warp{
      padding: 35px;
      .audio-file-warp{
        text-align: center;
        margin-top: 30px;
        .audio-file{
          display: inline-block;
          position: relative;
          width: 50px;
          height: 50px;
          background: url('@/assets/aiInspect/tokenTest/audio-icon.png') no-repeat;
          background-size: 100% 100%;
          .close-btn{
            position: absolute;
            top: -5px;
            right: -10px;
            width: 15px;
            height: 15px;
            background: url('@/assets/aiInspect/tokenTest/delete-icon.png') no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
          }
          .file-name{
            position: absolute;
            left: 50%;
            bottom: -15px;
            transform: translateX(-50%);
            text-wrap: nowrap;
          }
        }
      }
    }
    .text-file-warp{
      padding: 20px;
      .option-item{
        display: flex;
        align-items: center;
        margin-top: 20px;
        .title{
          margin-right: 10px;
        }
        .slider-warp{
          width: 80%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

</style>