<template>
  <div class="activity-title-container">
    <template v-if="lang !== 'en'">
      <div class="title">{{ title }}</div>
      <div class="sub-title">{{ subTitle }}</div>
    </template>
    <template v-if="lang === 'en'">
      <div class="title-en">{{ title }}</div>
    </template>
  </div>
</template>
  
<script>
import { getLanguage } from '@/lang';

export default {
  name: 'activity-title-container',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lang: 'en'
    }
  },
  mounted () {
    this.lang = getLanguage();
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      this.lang = newLocale;
    }
  }
}
</script>
<style lang="scss" scoped>
  .activity-title-container{
    width: 1306px;
    height: 123px;
    background: url('@/assets/activity/token/title-border.png');
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    gap: 12px;
    .title{
      font-size: 53px;
      font-weight: bold;
      line-height: 54px;
      background: linear-gradient(to right, #D15BF5 0%, #26CEFF 57%, #10F3C6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .title-en{
      font-size: 56px;
      font-weight: bold;
      line-height: 60px;
      background: linear-gradient(to right, #D15BF5 0%, #26CEFF 57%, #10F3C6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .sub-title{
      font-size: 16px;
      background: linear-gradient(to right, #D15BF5 0%, #26CEFF 57%, #10F3C6 100%);
      opacity: 0.5;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

  }
</style>