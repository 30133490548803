<template>
  <div class="stat-info-tag">
    <div class="stat-info-tag-item" v-for="(item, index) in tags" :key="index" >{{ item }}</div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
  name: 'statTag'
})
class statTag extends Vue {
  @Prop({ default: () => [] }) public tags!: any[]

}

export default statTag
</script>
  
<style lang="scss" scoped>
.stat-info-tag {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  .stat-info-tag-item{
    padding: 5px 16px;
    color: #3AC3B9;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; 
    background: #141414;
    margin-right: 16px;
  }
}
</style>