<template>
  <div :class="{'gate-comment-view': true, 'gate-comment-view-simple': isSimple}">
    <CommentInput @onSend="onSendComment"  @onShowAnchorComment="onShowAnchorComment" :isSimple="isSimple"/>
    <div class="comment-title" v-if="!isSimple && allCommentList.length > 0">{{ `${$t('comment.title')}（${commentTotal + auditCommentList.length}）` }}</div>
    <div v-for="(item, index) in allCommentList" :key="index" :class="{'comment-view': true, 'comment-view-simple': isSimple}">
      <CommentItem :content="item" @onReply="replyForComment" @onLike="onCommentLike" :commentType="commentType" :gId="gId" @onReset="onReset" @onReport="onReport"/>
      <div v-show="replyType === ReplyModes.ForComment && commentTarget.comment_id === item.comment_id" class="reply-comment-line">
        <ReplyCommentInput @onSend="sendReplyForComment" :replyType="replyType" :replyTarget="commentTarget.user_data?.user_name" :isSimple="isSimple"/>
      </div>
      <ReplyView
        :comment="item"
        :content="item.info"
        :commentTarget="commentTarget"
        :replyType="replyType"
        :commentType="commentType"
        :auditReplyList="auditReplyList"
        :gId="gId"
        :isSimple="isSimple"
        @onDelete="onAuditReplyDelete"
        @onReply="replyForReply"
        @onSend="sendReplyForReply"
        @onReport="onReport"
        />
    </div>
    <div class="more" v-if="commentTotal > commentList.length" @click="onViewMore">
      <div class="tips">{{ $t('comment.viewMore') }}</div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6 5.6L14 7L8 13L2 7L3.4 5.6L8 10.175L12.6 5.6Z" fill="#666666"/>
      </svg>
    </div>
</div>
</template>

<script lang="ts">
import ReplyCommentInput from './components/replyCommentInput.vue';
import CommentItem from './components/commentItem.vue';
import ReplyItem from './components/replyItem.vue';
import GateReport from '@/components/report/index.vue';
import { selectAuditCommentList, selectAuditReplyList, selectCommentList, sendComment, sendReply } from '@/api/comment';
import { litGateSessionKey } from '@/config';
import { CommentTypes, LikeTypes, ReplyModes } from '@/config/stat';
import CommentInput from './components/commentInput.vue';
import ReplyView from './replyView.vue';
import { userLike } from '@/api/userLikeCollect';
import { Local } from '@/utils/storage';
import { GateReportEnvironments } from '@/config/report';
import { ReportModule } from '@/store/modules/report';
// 评论回复
export default {
    name:'ArticleComment',
    props: {
      gId: {
        type: String,
        default: ''
      },
      commentType: {
        type: Number,
        default: CommentTypes.Comment_Type_Lore_Essay
      },
      isSimple: {
        type: Boolean,
        default: false
      },
      pageSize: {
        type: Number,
        default: 10
      }
    },
    components: { ReplyCommentInput, CommentItem, ReplyItem, CommentInput, ReplyView, GateReport },
    data(){
      return {
          ReplyModes,
          replyType: ReplyModes.Invalid,
          commentTarget: {} as any,
          replyTarget: {},
          pages: {
            current: 0,
            total: 8
          },
          commentList: [],
          commentTotal: 0,
          auditCommentList: [],
          auditReplyList: [],
          observer: null,
          hasLogin: false,
      }
    },
    mounted() {
        const gateSession = Local.get(litGateSessionKey);
        this.hasLogin = !!gateSession
        if (this.gId) {
          this.getCommentList();
          this.getUserAuditCommentList();
          this.getUserAuditReplyList();
        }
    },
    methods: {
      onReport(type, options) {
        if (this.commentType === CommentTypes.Comment_Type_Lore_Essay) {
          ReportModule.open({
            gId: options.g_id,
            env:  type === 'reply' ? GateReportEnvironments.Complaint_Lore_Essay_Reply : GateReportEnvironments.Complaint_Lore_Essay_Comment,
            options
          })
        }
        if (this.commentType === CommentTypes.Comment_Type_Works_Game_Deme) {
          ReportModule.open({
            gId: options.g_id,
            env: type === 'reply' ? GateReportEnvironments.Complaint_Works_Reply : GateReportEnvironments.Complaint_Works_Comment,
            options
          })
        }
      },
      onShowAnchorComment(boo) {
        this.$emit('onShowAnchorComment', boo)
      },
      onReset() {
        this.pages = {
          current: 0,
          size: 10,
          total: 8
        };
        if (this.gId) {
          this.getCommentList(true);
          this.getUserAuditCommentList();
          this.getUserAuditReplyList();
        }
      },
      onAuditReplyDelete() {
        this.getUserAuditReplyList();
      },
      onViewMore() {
        this.pages.current ++;
        this.getCommentList()
      },
      replyForComment(commentTarget) {
        console.log('回复评论')
        this.replyType = ReplyModes.ForComment
        this.commentTarget = commentTarget
      },
      replyForReply(replyTarget, commentTarget) {
        console.log('回复for回复', replyTarget)
        this.replyType = ReplyModes.ForReply
        this.replyTarget = replyTarget
        this.commentTarget = commentTarget
      },
      // 对文章评论
      async onSendComment(content){
        console.log('发表评论', content);
        const result = await sendComment({
          comment_type: this.commentType,
          g_id: this.gId,
          content
        })
        console.log(result)
        if (result.code === 0) {
          this.$message.success(this.$t('comment.commentSuccess'))
          this.onReset();
        }
      },
      // 对评论进行回复
      async sendReplyForComment(content){
        console.log('回复评论', content, this.replyType === ReplyModes.ForComment);
        if (this.replyType === ReplyModes.ForComment) {
          const result = await sendReply({
            comment_type: this.commentType,
            g_id: this.gId,
            comment_id: this.commentTarget.comment_id,
            content
          })
          if (result.code === 0) {
            this.replyType = ReplyModes.Invalid
            this.getUserAuditReplyList();
          }
        }
      },
      // 对回复进行回复
      async sendReplyForReply(content) {
        if(this.replyType === ReplyModes.ForReply) {
          const result = await sendReply({
            comment_type: this.commentType,
            g_id: this.gId,
            comment_id: this.commentTarget.comment_id,
            other_reply_id: this.replyTarget.reply_id,
            content
          })
          console.log('reply:', result)
          if (result.code === 0) {
            this.replyType = ReplyModes.Invalid
            this.getUserAuditReplyList();
          }
        }
      },
      async getCommentList(isReset = false) {
        const params = {
          comment_type: this.commentType,
          g_id: this.gId,
          comm_limit: this.pageSize,
          comm_offset: this.pages.current
        }
        const result = await selectCommentList(params);
        console.log('commentList===>', result);
        if (result.code === 0) {
          if (isReset) {
            this.commentList = result.data.info || [];
          } else {
            this.commentList = this.commentList.concat(result.data.info || []);
          }
          this.commentTotal = Number(result.data.comm_total)
        }
      },
      async onCommentLike(isLike, comment) {
        const params = {
          like_type: this.commentType === CommentTypes.Comment_Type_Lore_Essay ? LikeTypes.Like_Type_Lore_Essay_Comment : LikeTypes.Like_Type_Works_Game_Deme_Comment,
          gid: comment.comment_id,
          is_like: isLike ? 1 : 0,
          key_values: ['' + this.commentType, this.gId]
        }
        const result = await userLike(params)
        if (result.code === 0) {
          // 找到当前评论节点，直接修改节点数据
          const targetComment = this.commentList.find(item => item.comment_id === comment.comment_id);
          targetComment.is_like = isLike;
          if (isLike) {
            targetComment.like_num += 1;
          } else {
            targetComment.like_num = targetComment.like_num > 0 ? targetComment.like_num - 1 : 0
          }
        }
      },
      async getUserAuditCommentList() {
        if (!this.hasLogin) {
          return
        }
        const params = {
          comment_type: this.commentType,
          g_id: this.gId,
          comm_limit: 100,
          comm_offset: 0
        }
        const result = await selectAuditCommentList(params);
        if (result.code === 0 && result.data) {
          this.auditCommentList = result.data.info || []
        }
      },
      async getUserAuditReplyList() {
        if (!this.hasLogin) {
          return
        }
        const params = {
          comment_type: this.commentType,
          g_id: this.gId,
          comm_limit: 200,
          comm_offset: 0,
          comment_id: ''
        }
        const result = await selectAuditReplyList(params);
        console.log('getUserAuditReplyList', result)
        if (result.code === 0 && result.data) {
          this.auditReplyList = result.data.info || []
        }
      },
       
    },
    computed: {
      allCommentList() {
        return this.auditCommentList.concat(this.commentList)
      }
    },
    watch: {
      gId() {
        console.log('this.gId:', this.gId)
        if (this.gId) {
          this.commentList = [];
          this.getCommentList();
          this.getUserAuditCommentList();
          this.getUserAuditReplyList();
        }
      }
    },
    beforeDestroy() {
      
    }
}
</script>
<style lang="scss" scoped>
.gate-comment-view{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  .comment-title{
    font-size: 24px;
    color: #ddd;
    font-weight: 500;
  }
  .comment-view{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    .reply-comment-line{
      width: 100%;
    }
  }
  .comment-view-simple{
    gap: 8px;
  }
  .more{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    cursor: pointer;
    margin-bottom: 30px;
    .tips{
      font-size: 14px;
      line-height: 22px;
      color: #666;
    }
  }
  :deep(.el-textarea__inner){
    border: none;
    resize: none;
  }
}
.gate-comment-view-simple{
  gap: 16px;
}

</style>
