<template>
 <el-dialog
    :title="$t('gateReport.title')"
    class="gate-report"
    :visible="visible"
    @close="onClose"
    :width="lang === 'en'? '504px' : '424px'"
    >
  <div class="report-container">
    <div class="report-value-title">{{ $t('gateReport.reportType') }}</div>
    <div class="report-type-container">
      <div :lang="lang" :class="{'report-type-item': true, 'zh': lang !=='en', 'en': lang === 'en',  'normal': currentOption.type !== item.type , 'active': currentOption.type === item.type}" v-for="(item, index) in GateReportOptions" :key="index" @click="selectType(item)">{{ $t(item.label) }}</div>
    </div>
    <div class="report-value-title marginTop16">{{ $t('gateReport.infoTitle') }}</div>
    <div class="report-readme-container">
      <el-input
        type="textarea"
        class="report-readme-input"
        :rows="5"
        :placeholder="$t('gateReport.inputPl')"
        maxlength="500"
        show-word-limit
        v-model="textarea">
      </el-input>
    </div>
    <div :class="{'report-send-btn': true, 'disableBtn': textarea === ''}" @click="reportSubmit">{{ $t('gateReport.submitBtn') }}</div>
  </div>
</el-dialog>
</template>
  
<script lang="ts">
import { GateReportOptions } from '@/config/report';
import { getLanguage } from '@/lang';
import { complaint } from '@/api/gateUser';
import { mapGetters } from 'vuex';
import { ReportModule } from '@/store/modules/report';
export default {
  name: 'gate-report',
  props: {
  },
  data () {
    return {
      GateReportOptions,
      currentOption: {},
      textarea: '',
      lang: 'en',
    }
  },
  mounted () {
    console.log('12313123');
    this.lang = getLanguage();
    this.textarea = ''
  },
  methods: {
    selectType(item) {
      this.currentOption = item;
    },
    onClose() {
      ReportModule.close();
    },
    async reportSubmit() {
      if (this.currentOption.type) {
        if (this.textarea !== '') {
          const result = await complaint({
            c_type: this.reportEnvironments,
            c_type_sub: this.currentOption.type,
            content: this.textarea,
            g_id: this.gId,
            ...this.reportOptions
          })
          if (result.code === 0) {
            this.$message.success(this.$t('gateReport.success'))
            ReportModule.close();
          }
          if (result.code === 1501) {
            this.$message.success(this.$t('gateReport.reportErr'))
            ReportModule.close();
          }
          if (result.code === 1502) {
            this.$message.success(this.$t('gateReport.success'))
            ReportModule.close();
          }
        }
      }
    }
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      // 当语言切换时执行的逻辑
      this.lang = newLocale;
    },
    'visible': function(newLocale) {
      if (newLocale) {
        this.textarea = ''
        this.currentOption = {}
      }
    }
  },
  computed: {
    ...mapGetters(['gId', 'reportEnvironments', 'visible', 'reportOptions'])
  }
}
</script>
  
<style lang="scss">
.gate-report{
  .el-dialog__headerbtn{
    top: 20px;
    right: 20px;
  }
  .el-dialog__body{
    padding: 10px 32px 32px;
  }
  .el-input__count{
    background: transparent;
  }
  .el-dialog{
	background: #141414;
  }
  .el-dialog__title {
    color: #DDD;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  .el-textarea{
    .el-textarea__inner{
      background-color: transparent;
      color: #666 !important;
    }
    .el-textarea__inner::placeholder {
      color: #333; /* 设置占位符颜色为灰色 */
    }
  }
  .report-container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
    .report-value-title{
      font-size: 16px;
      color: #ddd;
      font-weight: 500;
    }
    .marginTop16{
      margin-top: 16px;
    }
    .report-type-container{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;
      
      .report-type-item{
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
      }

      .normal{
        border: 1px solid  rgba(255, 255, 255, 0.10);
        color: #adadad;
      }
      .normal:hover{
        border: 1px solid  rgba(255, 255, 255, 0.30);
      }
      .active{
        border: 1px solid #00FFF6;
        color: #00FFF6;
      }
      .zh{
        width: 112px;
      }
      .en{
        width: 214px;
      }
    }
    .report-readme-container{
      width: 100%;
      .report-readme-input{
        textarea{
          resize: none;
          border: 1px solid  rgba(255, 255, 255, 0.10);
        }
        
        textarea:focus{
          border: 1px solid  rgba(255, 255, 255, 0.30);
        }
        textarea:hover{
          border: 1px solid  rgba(255, 255, 255, 0.30);
        }

        .el-input__count{
          color: #666;
          font-size: 14px;
          background: transparent;
        }
      }
    }
  }
  .report-send-btn{
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00FFF6;
    color: #000;
    cursor: pointer;
  }
  .disableBtn{
    background: rgba(0, 255, 246, 0.3);
    color: #000;
    cursor: default;
  }
}
</style>