<template>
  <div class="token-api-document">
    <div class="side-container"  :style="{height: contentHeight + 'px'}">
      <TinyDirectory ref="tinyDirectory" :content="content" @onActive="directoryActiveChange" :top="110" mainTitle="h2" subLevelTitle="h3"/>
    </div>
    <div class="main-container">
      <!-- <CherryMarkdown ref="myCherryMarkdown" /> -->
      <TinyView ref="tinyView" :content="content" @onContent="onContentMounted" :editorType="Editor_Types.CherryMarkdown" @directoryActive="directoryActive" mainTitle="h2" subLevelTitle="h3"/>
      <!-- <CherryView :content="content"/> -->
      <!-- <div class="container">
        <div class="rotate-animation">
          <img class="test" :src="testFanImg" alt="Your Image" />
        </div>
      </div> -->
    </div>
    <div class="right-container"></div>
  </div>
</template>
  
<script>
import CherryView from '@/components/gateEditor/cherryView.vue';
import TinyView from '@/components/gateEditor/tinyView.vue';
import CherryMarkdown from '@/components/gateEditor/cherryMarkdown.vue';
import TinyDirectory from '@/components/gateEditor/tinyDirectory.vue';
import { ENUM_G_508, Editor_Types, GlobalTypes } from '@/config/stat';
import testFanImg from '@/assets/testFan.png'
import { getGlobalTypeData } from '@/api/gateReport';
export default {
  name: 'token-api-document-main',
  components: { CherryView, CherryMarkdown, TinyDirectory, TinyView },
  data () {
    return {
      Editor_Types,
      testFanImg,
      loading: false,
      demoList: [],
      draftDemoList: [],
      pages: {
        current: 0,
        size: 8,
        total: 8
      },
      target: null,
      contentHeight: 500,
      deleteDialogVisible: false,
      content: ''
    }
  },
  mounted () {
   this.fetchTokenApiDocument()
  },
  methods: {
    async fetchTokenApiDocument() {
      const result = await getGlobalTypeData({
        global_type_id: GlobalTypes.Global_Type_Token_Api_document
      })
      if (result.code === 0 && result.data) {
        console.log(result)
        const { type_info = [] } = result.data;
        const target = type_info.find(item => item.type_id === ENUM_G_508.Token_Api_1)
        if (target) {
          const { param_str } = target;
          if (param_str) {
            this.content = param_str
          }
        }

      }
    },
    onContentMounted(info) {
      this.contentHeight = info.height;
    },
    getContent() {
      const content = this.$refs.myCherryMarkdown.getHtml();
      console.log('content:', content)
      this.content = content;
    },
    directoryActiveChange(targetInfo) {
      this.$refs.tinyView.setActive(targetInfo);
    },
    directoryActive(target) {
      this.$refs.tinyDirectory.setActive(target);
    },
  },
  destroy() {
  }
}
</script>
<style lang="scss" scoped>
.token-api-document{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  padding: 110px 0 40px 0;
  gap: 56px;
  .side-container{
    position: relative;
    width: 232px;
    padding: 8px 8px 8px 12px;
    gap: 8px;
    height: 1000px;
  }
  .main-container{
    display: flex;
    width: 1136px;
    flex-direction: column;
    align-items: flex-start;
    .container {
      width: 200px;
      height: 200px;
      position: relative;
    }

    .rotate-animation {
      width: 100%;
      height: 100%;
      animation: rotate 2s infinite linear;
      transform-origin: center center;
      img{
        width: 100%;
        height: 100%;
      }
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .right-container{
    width: 232px;
    height: 10px;
  }
}
</style>