<template>
  <div class="ai-single-chat-page" v-loading="waitStartGame"  :element-loading-text="step === GroupChatState.CreateGroup ? '正在建立群聊...' : '正在加入群聊...'"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="aside-wrap">
      <GroupSetting ref="groupSettingView" @updateRoomInfo="updateRoomInfo" @onJoinGroup="onJoinGroup" :groupId="groupInfo.group_id" :allRoleList="allRoleList"/>
      <ModeIntroductionVue v-show="step !== GroupChatState.RoomSetting" />
      <PlayerSetting :isCreateGame="isCreateGame" @roleSetting="roleSetting" v-show="step !== GroupChatState.RoomSetting" :changeDisabled="step !== GroupChatState.RoleSetting"/>
      <SinglePlayer
        :player="`玩家${index + 1}(${item.nick})`"
        v-show="step === GroupChatState.Chat"
        :roleInfo="item"
        v-for="(item, index) in roleInfo"
        :key="index"
        @singleChat="singleChat"
        />
    </div>
    <div class="chat-wrap">
      <ChatView :chatMsgs="chatMsgs" :allRoleList="allRoleList" :chatMsgShowMode="chatMsgShowMode" :chatMode="chatMode"/>
    </div>
  </div>
  </template>
  <script>
  // el-icon-circle-plus-outline
  import PlayerSetting from './components/playerSetting.vue'
  import SinglePlayer from './components/singlePlayer.vue';
  import MultiPlayer from './components/multiPlayer.vue';
  import ChatView from './components/chatView.vue';
  import GroupSetting from './components/groupSetting.vue';
  import ModeIntroductionVue from './components/modeIntroduction.vue';
  import { modeTypes, chatMsgShowModes, GroupChatState, chatMsgShowModeTypes, MessageTypes } from '@/config/aiInspect'
  import { endChat, endGame, playAction, playGame, startChat, startGame, userChat, getRoomState, getRoomUsers } from '@/api/gateTown';
  import { formatDate } from '@/utils/format';
  export default {
    name: 'multi-group-chat-page',
    components: { 
      PlayerSetting,
      SinglePlayer,
      MultiPlayer,
      ChatView,
      GroupSetting,
      ModeIntroductionVue
    },
    props: {
      socket: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        GroupChatState,
        step: GroupChatState.RoomSetting,
        modeTypes,
        chatMode: 1,
        chatMsgShowModes,
        chatMsgShowMode: chatMsgShowModeTypes.ALL,
        disabled: false,
        loading: false,
        isCreateGame: true,
        waitStartGame: false,
        groupInfo: {
          group_id: -1,
          group_name: '',
          all_roles: [],
          owner_uid: ''
        },
        roomInfo: {
          imei: '',
          uid: '',
          room_id: 0
        },
        roleInfo: [],
        allRoleList: [],
        chatMsgs: [],
        timer: null,
        updatePlayerTimer: null 
      }
    },
    mounted() {
      console.log(formatDate(new Date()))
    },
    methods: {
      getCurrentGroupInfo() {
        return this.groupInfo
      },
      onServerMessage(message) {
        const { server_push_msg_request } = message;
        console.log(server_push_msg_request);
        const chatRole = this.allRoleList.find(item => item.role_id === server_push_msg_request.role.role_id);
        if (!chatRole) {
          this.updateRoleInGroup()
        }
        this.saveOtherUserChatMsg(server_push_msg_request, server_push_msg_request.role)
      },
      onJoinGroup(groupId) {
        this.isCreateGame = false;
        this.groupInfo.group_id = groupId;
        this.$refs.groupSettingView.joinGroupCb();
        this.step = GroupChatState.RoleSetting;
        console.log('this.step', this.step)
      },
      updateRoomInfo(info, isCreateGame = false) {
        this.groupInfo.owner_uid = info.uid;
        this.groupInfo.group_id = 0;
        this.step = GroupChatState.RoleSetting;
        this.isCreateGame = isCreateGame;
        if (isCreateGame) {
          // this.startUpdatePlayer();
        }
      },
      startUpdatePlayer() {
        if (this.updatePlayerTimer) {
          clearInterval(this.updatePlayerTimer);
        }
        this.updatePlayerTimer = setInterval(() => {
          this.getAllPlayer();
          if (this.step === GroupChatState.Chat) {
            clearInterval(this.updatePlayerTimer);
          }
        }, 3000)
      },
      async roleSetting(roleInfo) {
        this.roleInfo = roleInfo;
        this.step = GroupChatState.CreateGroup
        console.log('this.step:', roleInfo)
        // 如果是在web端创建，则直接开始游戏
        if (this.isCreateGame) {
          this.$emit('onInitSocket', roleInfo[0].uid, () => {
            this.socket.send({
              create_chat_group_request: {
                owner_uid: roleInfo[0].uid
              }
            }, (res) => {
              const { create_chat_group_response } = res;
              if (create_chat_group_response.code === 0) {
                this.groupInfo.group_id = create_chat_group_response.group_id;
                this.groupInfo.owner_uid = create_chat_group_response.owner_uid;
                this.joinGroup(roleInfo.slice(1));
              }
            })
          })
        } else {
          this.$emit('onInitSocket', roleInfo[0].uid, () => {
            this.joinGroup(roleInfo);
          })
        }
        this.startWaitGameStart();
      },
      updateRoleInGroup(cb) {
        this.socket.send({
          group_users_request: {
            group_id: this.groupInfo.group_id,
            uid: this.owner_uid,
          }
        }, (res) => {
          console.log(res)
          const { group_users_response: { group_info } } = res;
          if (group_info.group_id === this.groupInfo.group_id) {
            this.allRoleList = group_info.all_roles;
          }
          cb && cb();
        })
      },
      joinGroup(roleList) {
        this.step = GroupChatState.JoinGroup
        console.log('join group:', roleList);
        let count = 0;
        for(let i = 0; i < roleList.length; i ++) {
          this.socket.send({
            join_chat_group_request: {
              group_id: this.groupInfo.group_id,
              uid: roleList[i].uid
            }
          }, (res) => {
            count ++;
            console.log('join result:', count, res);
            if (res.code === 0) {
              const { join_chat_group_response } = res;
              const { group_info } = join_chat_group_response;
              this.groupInfo.group_name = group_info.group_name;
              this.groupInfo.all_roles = group_info.all_roles;
              this.allRoleList = group_info.all_roles;
            }
            if (count >= roleList.length) {
              this.step = GroupChatState.Chat
            }
            this.updateRoleInGroup();
          })
        }
      },
      startWaitGameStart() {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.waitStartGame = true;
        this.timer = setInterval(async () => {
          if (this.step !== GroupChatState.CreateGroup && this.step !== GroupChatState.JoinGroup) {
            this.waitStartGame = false;
            clearInterval(this.timer);
          }
        }, 3000);
      },
      async singleChat(chatInfo) {
        console.log('single chat:', chatInfo);
        // 开启聊天
        this.saveUserChatMsg(chatInfo.roleInfo,chatInfo.user_ask);
        this.socket.send({
          client_send_msg_request: {
            group_id: this.groupInfo.group_id,
            content: chatInfo.user_ask,
            uid: chatInfo.uid
          }
        }, (res) => {
          console.log('说话的回复：', res);
        })
      },
      saveUserChatMsg(roleInfo, msg) {
        const chatData = {
          type: 'User',
          roleInfo: roleInfo,
          behavior: 'chat',
          msg,
          timer: formatDate(new Date())
        }
        console.log('chatData:', chatData)
        this.chatMsgs.push(chatData);
      },
      socketClose(event) {
        // this.$emit('onInitSocket', this.groupInfo.owner_uid);
        // this.$confirm('等待时间过长,连接已经中断，是否重连?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        // //  this.step = GroupChatState.RoleSetting
        // }).catch(() => {
                
        // });
      },
      saveAiWaitMsg() {
        setTimeout(() => {
          const waitMsg = {
            type: 'AI',
            behavior: 'chat',
            messageType: MessageTypes.Wait,
            timer: formatDate(new Date()),
          }
          this.chatMsgs.push(waitMsg);
        }, 500)
      },
      removeAiWaitMsg() {
        if (this.chatMsgs[this.chatMsgs.length - 1].messageType  === MessageTypes.Wait) {
          this.chatMsgs.pop();
        }
      },
      saveOtherUserChatMsg(msgRequest, chatRole){
       
        const chatData = {
          type: 'OtherUser',
          behavior: 'chat',
          messageType: MessageTypes.Chat,
          timer: formatDate(new Date()),
          feeds: {
            content: msgRequest.content
          },
          role: chatRole ? chatRole : msgRequest.role
        }
        this.chatMsgs.push(chatData);
      },
      async onEndGame() {
        this.loading = true;
        const endResult = await endGame({
            uid: this.roleInfo[0].uid,
            room_id: this.roomInfo.room_id
          });
        this.loading = false;
        if (endResult.code === 0) {
          const endGameMsg = {
            type: 'AI',
            behavior: 'behavior',
            isComment: true,
            messageType: MessageTypes.Comment,
            timer: formatDate(new Date()),
            results: endResult.results,
            roleList: this.roleInfo
          }
          this.chatMsgs.push(endGameMsg);
        }
      },
      async getAllPlayer() {
        const result = await getRoomUsers({
          room_id: this.roomInfo.room_id
        });
        if (result.code === 0) {
          this.allRoleList = result.users;
        }
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
  .ai-single-chat-page{
    width: 100%;
    height: 100%;
    background: #f8f9fa;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
    :deep(.el-button){
      border: 1px solid #dcdfe6;
    }
    .aside-wrap{
      width: 460px;
      min-width: 460px;
      padding: 10px 5px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-self: flex-start;
      align-items: center;
      gap: 10px;
      border-right: 1px solid #cdcdcd;
    }
    .chat-wrap{
      width: calc(100% - 460px);
      .tool-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px;
        gap: 12px;
      }
    }
  }
</style>
  