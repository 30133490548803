<template>
  <div class="game-works-info-card">
    <div class="cover" :style="{backgroundImage: 'url(' + cover + ')'}"></div>
    <div class="info-line">
      <div class="title">{{ $t('views.gameWorksDetail.version') }}</div>
      <div class="value">{{ version }}</div>
    </div>
    <div class="info-line">
      <div class="title">{{ $t('views.gameWorksDetail.lastUpdateDate') }}</div>
      <div class="value">{{ lastUpdateTime }}</div>
    </div>
    <div class="info-line" v-if="platform.length > 0">
      <div class="title">{{ $t('views.gameWorksDetail.supportedPlatform') }}</div>
      <div class="value">
        <div class="platform platform-window" v-if="platform.indexOf(GameWorksPlatform.windows) >= 0"></div>
        <div class="platform platform-ios" v-if="platform.indexOf(GameWorksPlatform.ios) >= 0 || platform.indexOf(GameWorksPlatform.macos) >= 0"></div>
        <div class="platform platform-pc" v-if="platform.indexOf(GameWorksPlatform.pcWeb) >= 0"></div>
        <div class="platform platform-mobile" v-if="platform.indexOf(GameWorksPlatform.h5) >= 0"></div>
      </div>
    </div>
    <div class="info-line">
      <div class="title">{{ $t('views.gameWorksDetail.downloadCounts') }}</div>
      <div class="value">{{ downloadNum }}</div>
    </div>
    <!-- <div class="profile">Here is a description of the update.  Here is a use upd description of the update. </div> -->
    <div class="download-btn download-active" @click="downloadDemo" v-if="canDownload">{{ $t('views.gameWorksDetail.downloadBtn') }}</div>
    <div class="download-btn online-active" @click="onlineExperience" v-if="platform.indexOf(GameWorksPlatform.pcWeb) >= 0 && gameWebUrl">{{ $t('views.gameWorksDetail.onlineBtn') }}</div>
    <el-tooltip class="item" effect="dark" :content="$t('views.gameWorksDetail.downloadDisableTips')" placement="bottom" v-if="!canDownload">
      <div class="download-btn download-disable">{{ $t('views.gameWorksDetail.downloadBtn') }}</div>
    </el-tooltip>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { addCommasToNumber } from '@/utils/statFormat'
import { GameWorksPlatform } from '@/config/gameWorks'
@Component({
    name: 'DemoInfoCard'
})
class DemoInfoCard extends Vue {
  @Prop({ default: 0 }) public upTime!: Number
  @Prop({ default: 0 }) public downloadCount!: Number
  @Prop({ default: '' }) public version!: string
  @Prop({ default: '' }) public cover!: string
  @Prop({ default: '' }) public gameDemo!: string
  @Prop({ default: '' }) public gameWebUrl!: string
  @Prop({ default: () => [] }) public platform!: any[]
  @Prop({ default: true }) public canDownload!: boolean
  public GameWorksPlatform = GameWorksPlatform;

  mounted() {
    console.log('demoCard:', this.platform)
  }

  public formatTime(milliseconds) {
    const date = new Date(Number(milliseconds));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  public downloadDemo() {
    const link = document.createElement('a');
    link.href = this.gameDemo;
    link.download = 'true';
    link.click();
    this.$emit('downloadReport', this.gameDemo)
  }

  public onlineExperience() {
    window.open(this.gameWebUrl);
  }

  public checkPlatform() {
    return 
  }

  get lastUpdateTime() {
    return this.formatTime(this.upTime)
  }

  get downloadNum() {
    return addCommasToNumber(this.downloadCount);
  }
  
}

export default DemoInfoCard
</script>
  
<style lang="scss" scoped>
.game-works-info-card {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 1920px) {
    .cover{
      height: 212px;
    }
    .info-line{
      .value{
        margin-left: 32px;
      }
    }
  }
  @media screen and (max-width: 1919px) {
    .cover{
      height: 182px;
    }
    .info-line{
      .value{
        margin-left: 27px;
      }
    }
  }
  @media screen and (max-width: 1679px) {
    .cover{
      height: 132px;
    }
    .info-line{
      .value{
        margin-left: 20px;
      }
    }
  }
  .cover{
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 8px 0;
  }
  .info-line{
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    .title{
      width: 138px;
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
    .value{
      color: #999;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      .platform{
        width: 12px;
        height: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 4px;
      }
      .platform-window{
        background-image: url('../../../assets/gameWorks/detail/platform_window.png');
      }
      .platform-ios{
        background-image: url('../../../assets/gameWorks/detail/platform_ios.png');
      }
      .platform-mobile{
        background-image: url('../../../assets/gameWorks/detail/platform_mobile.png');
      }
      .platform-pc{
        background-image: url('../../../assets/gameWorks/detail/platform_pc.png');
      }
    }
  }
  .profile{
    margin: 16px 0;
    color: #999;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
  }
  .download-btn{
    margin-top: 24px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 24px;
  }
  .download-active{
    background-color: #00fff6;
    color: #000;
    cursor: pointer;
  }
  .online-active{
    background-color: #000;
    color: #00fff6;
    border: 1px solid #00fff6;
    cursor: pointer;
  }
  .download-disable{
    background: #333;
    color: #666;
  }
}
</style>