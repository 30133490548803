<template>
  <div class="demo-item-wrap">
    <div class="demo-item">
      <div class="info-wrap">
        <div class="icon"></div>
        <div class="txt-wrap">
          <div class="title">{{ resource.name }}</div>
          <div class="info">file size：{{ fileSize }}</div>
        </div>
      </div>
      <div class="tool-wrap">
        <div class="state-tips" v-if="resource.state === UploadStateTypes.Uploading">
          <div class="info-text">{{ resource.progress || 0 }}%</div>
        </div>
        <div class="state-tips" v-if="resource.state === UploadStateTypes.Success">
          <div class="success-icon"></div>
          <div class="success-text">{{ $t('views.postProductImage.uploadCompleted') }}</div>
        </div>
        <div class="icon-wrap" @click="onDelete"></div>
      </div>
    </div>
    <div class="upload-progress" v-if="resource.state !== UploadStateTypes.Ready">
      <div class="progress-bar">
        <el-progress :percentage="resource.progress || 0" status="exception" :color="progressColor" :show-text="false"></el-progress>
      </div>
    </div>
  </div>
</template>
  
<script>
import { UploadStateTypes } from '@/config/stat';
import { formatFileSize } from '@/utils/statFormat';


export default {
  name: 'demo-resource-item',
  components: {  },
  props: {
    resource: {
      type: Object,
      default: () => {}
    },
    progressColor: {
      type: String,
      default: '#00FFF6'
    }
  },
  data () {
    return {
      UploadStateTypes
    }
  },
  mounted () {
    console.log('resource:', this.resource)
  },
  methods: {
    onDelete() {
      this.$emit('onDelete', this.resource)
    }
  },
  computed: {
    fileSize() {
      const size = this.resource.size || 0;
      console.log('size:', this.resource, size)
      return formatFileSize(size);
    }
  }
}
</script>
<style lang="scss">
.demo-item-wrap{
  width: 100%;
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #666;
  gap: 8px;
  .demo-item{
     width: 100%;
     display: flex;
     width: 100%;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     .info-wrap{
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;
       .icon{
         width: 40px;
         height: 40px;
         background: url('@/assets/postApplication/icon_file.png');
         background-size: 100% 100%;
       }
       .txt-wrap{
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         align-items: flex-start;
         .title{
           font-size: 14px;
           font-style: normal;
           font-weight: 500;
           line-height: 22px;
           color: #999;
         }
         .info{
           font-size: 12px;
           font-style: normal;
           font-weight: 400;
           line-height: 18px;
           color: #666;
         }
       }
     }
     .tool-wrap{
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        .state-tips{
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          .wait-icon{
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-image: url('@/assets/postProduct/icon_wait.png');
            background-size: cover;
          }
          .uploading-icon{
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-image: url('@/assets/postProduct/icon_uploading.png');
            background-size: cover;
            animation: rotate 2s linear infinite;
          }
          .success-icon{
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-image: url('@/assets/postProduct/icon_uploaded.png');
            background-size: cover;
          }
          .fail-icon{
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-image: url('@/assets/postProduct/icon_fail.png');
            background-size: cover;
          }
          .success-text{
            color: #00FFF6;
            font-size: 12px;
            font-weight: 400;
          }
          .info-text{
            color: #666;
            font-size: 12px;
            font-weight: 400;
          }
          .fail-text{
            color: #FF5C51;
            font-size: 12px;
            font-weight: 400;
          }
        }
        .icon-wrap{
          width: 24px;
          height: 24px;
          background: url('@/assets/postApplication/icon_delete.png');
          background-size: 100% 100%;
          cursor: pointer;
        }
     }
    }
   .upload-progress{
      width: 100%;
      height: 3px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .progress-bar{
        width: calc(100% - 42px);
      }
   }
}
</style>