<template>
  <div class="activity-token-main">
    <div class="banner">
      <div :class="{'banner-bg': true, 'banner-img-en': lang === 'en', 'banner-img': lang !== 'en'}">
        <div class="activity-submit-bg" :style="{ backgroundImage: 'url(' + getBg() + ')' }">
          <div class="submit-hover" @click="openJoinCreator"></div>
        </div>
      </div>
    </div>
    <div class="activity-token-container" ref="activityContainer">
      <ActivityIntro />
      <ActivityWay />
      <Incentives />
      <Notice />
      <FixedBtn :btnTxt="$t('views.tokenActivity.fixedBtn')" v-if="showFixedBtn" @onClick="openJoinCreator"/>
      <div class="footer"></div>
    </div>
    <JoinCreatorDialog ref="joinCreatorDialog" @onCallback="joinCreatorCallback"/>
  </div>
</template>
  
<script>
import ActivityIntro from './modules/activityIntro.vue'
import ActivityWay from './modules/activityWay.vue'
import Incentives from './modules/incentives.vue'
import Notice from './modules/notice.vue'
import FixedBtn from './components/fixedBtn.vue'
import JoinCreatorDialog from '../application/dialog/joinCreator.vue'
import { Local, verifyUserSession } from '@/utils/storage'
import { cacheViewsPath } from '@/config'
import { Join_Scenes } from '@/config/token'
import submitIcon from '@/assets/activity/token/submit-icon.png'
import submitIconEn from '@/assets/activity/token/submit-icon-en.png'
import { getLanguage } from '@/lang'

export default {
  name: 'activity-token',
  components: {
    ActivityIntro,
    ActivityWay,
    Incentives,
    Notice,
    FixedBtn,
    JoinCreatorDialog
  },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      submitIcon,
      submitIconEn,
      lang: 'en',
      showFixedBtn: false
    }
  },
  mounted () {
    this.lang = getLanguage();
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    getBg() {
      return this.lang === 'en' ? this.submitIconEn : this.submitIcon
    },
    joinActivity() {
      console.log('join us')
    },
    async openJoinCreator() {
      const loginStatus = await verifyUserSession();
      if (loginStatus) {
        this.$refs.joinCreatorDialog.open(Join_Scenes.Act);
        return
      }
      console.log(loginStatus);
      // 记录当前节点路径
      Local.set(cacheViewsPath, {
        path: this.$route.fullPath,
        query: this.$route.query
      });
      this.$router.push({
        path: '/account/login'
      })
    },
    joinCreatorCallback() {
      this.$router.push({
        path: '/application/main'
      })
    },
    handleScroll () {
      const child = this.$refs.activityContainer
      if (child) {
        const currentInfo =  child.getBoundingClientRect();
        if (currentInfo.top < 180) {
          this.showFixedBtn = true;
          return;
        }
        this.showFixedBtn = false;
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    '$i18n.locale': function(newLocale) {
      this.lang = newLocale;
    }
  }

}
</script>
<style lang="scss" scoped>
// body{
//   background: #000;
// }
$content-width: 1360px;
.activity-token-main{
  min-width: $content-width;
  background-image: url('@/assets/activity/token/token-bg.png');
  background-size: 100% auto; /* 横向占满，竖向自适应 */
  background-repeat: repeat-y;
  .banner{
    width: 100%;
    height: 960px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .banner-bg {
      width: 1920px;
      min-width: 1920px;
      height: 991px;
      min-height: 991px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      padding-bottom: 35px;
      .activity-submit-bg{
        width: 682px;
        height: 376px;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .submit-hover {
          width: 460px;
          height: 150px;
          cursor: pointer;
        }
        .submit-txt{
          font-size: 56px;
          font-weight:bold;
          background: linear-gradient(90deg, #191997 100%, #5353E7 60%, #5353E7 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    .banner-img-en{
      background-image: url('@/assets/activity/token/token-banner-en.png');
    }
    .banner-img{
      background-image: url('@/assets/activity/token/token-banner.png');
    }
  }
  .activity-token-container{
    margin-top: -72px;
    width: 100%;
    min-width: $content-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 170px;
    .footer{
      width: 100%;
      height: 60px;
    }
  }
}
</style>