export function lazyLoad(els, checkViewPort = false, offset = 0, videoConfig = {}) {
  // console.log("lazyLoad", els)
  let WindowHeight = getWindowHeight();
  (els || []).forEach((el) => {
    if (checkViewPort) {
      let rect = el.getBoundingClientRect();
      if (rect.top > WindowHeight + offset) return;
    }
    let tagName = el.tagName,
      isVideo = tagName == 'VIDEO';
    // 视频处理，m3u8采用hls格式，mp4采用video模式
    if (isVideo) {
      let isHLS = el.dataset.type == 'm3u8';
      if (isHLS) {
        loadHLSVideo(el, videoConfig);
      } else {
        el.src = el.dataset.src;
      }
    } else {
      let src = el.dataset.src;
      let img = new Image();
      img.src = src;
      img.onload = () => {
        el.src = src;
      };
    }
  });
}

export function copyToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

// 提取目录大纲
export function getDirectoryList(content) {
  const domEle = document.createElement('div');
  domEle.innerHTML = content;
  const title = domEle.querySelectorAll('h1, h2');
  const list = [] as any[];
  title.forEach((item) => {
    list.push({
      title: item.innerHTML,
      tagName: item.tagName,
      node: item
    });
  });
  return list;
}

export function observationElement(element, callback) {
  if (element instanceof Node) {
    var stickyElement = this.$refs.statTool;
    const observer = new IntersectionObserver(function(entries) {
      entries.forEach(function(entry) {
        callback(entry.intersectionRatio);
      });
    });
    observer.observe(stickyElement);
    return observer;
  }
}

export function unObservationElement(observer, element) {
  observer.unobserve(element);
  observer.disconnect();
}
