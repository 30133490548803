export function throttle(func, delay) {
    let timerId;
    let lastExecTime = 0;
    
    return function(...args) {
      const currentTime = Date.now();
      
      if (currentTime - lastExecTime < delay) {
        clearTimeout(timerId);
        
        timerId = setTimeout(() => {
          lastExecTime = currentTime;
          func.apply(this, args);
        }, delay);
      } else {
        lastExecTime = currentTime;
        func.apply(this, args);
      }
    };
  }

  export function debounce(func, delay) {
    let timerId;
    
    return function(...args) {
      clearTimeout(timerId);
      
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }
  