
export const behaviorTargets = ['舞池', '吧台', '飞镖游戏', '制冰机', '点歌机', '书架', '洗手间', '窗户', '美女']

export const behaviorConfig = [
  {
    action_id: 1,
    action_label: '舞池',
    single: [
      { answer_no: 1, label:  '跳30秒'},
      { answer_no: 2, label:  '跳一分钟'},
      { answer_no: 3, label:  '跳两分钟'},
    ],
  },
  {
    action_id: 2,
    action_label: '曲风选择',
    single: [
      { answer_no: 1, label:  '爵士'},
      { answer_no: 2, label:  '流行'},
      { answer_no: 3, label:  '电子'},
      { answer_no: 4, label:  '摇滚'},
    ],
  },
  {
    action_id: 3,
    action_label: '上厕所',
    single: [
      { answer_no: 1, label:  '需要'},
      { answer_no: 2, label:  '不需要'},
    ],
    
  },
  {
    action_id: 4,
    action_label: '吧台',
    single: [
      { answer_no: 1, label:  '长岛冰茶'},
      { answer_no: 2, label:  '玛格丽特'},
      { answer_no: 3, label:  '古风'},
      { answer_no: 4, label:  '椰林飘香'},
      { answer_no: 5, label:  '无酒精鸡尾酒'},
      { answer_no: 6, label:  '果汁'},
    ],
    
  },
  {
    action_id: 5,
    action_label: '制冰机',
    single: [
      { answer_no: 1, label:  '需要'},
      { answer_no: 2, label:  '不需要'},
    ],
    
  },
  {
    action_id: 6,
    action_label: '画作',
    single: [
      { answer_no: 1, label:  '画作1'},
      { answer_no: 2, label:  '画作2'},
      { answer_no: 3, label:  '画作3'},
      { answer_no: 4, label:  '画作4'},
    ],
    
  },
  {
    action_id: 7,
    action_label: '望远镜',
    single: [
      { answer_no: 1, label:  '看向了月球'},
      { answer_no: 2, label:  '看向了木星'},
      { answer_no: 3, label:  '看向了夏季大三角'},
    ],
    
  },
  {
    action_id: 8,
    action_label: '书架',
    single: [
      { answer_no: 1, label:  '《老人与海》'},
      { answer_no: 2, label:  '《龙珠》'},
      { answer_no: 3, label:  '《存在与时间》'},
      { answer_no: 4, label:  '《拿破仑传》'},
    ],
    
  },
  {
    action_id: 9,
    action_label: '城市选择',
    single: [
      { answer_no: 1, label:  '喧嚣的大都市'},
      { answer_no: 2, label:  '宁静的小镇'},
      { answer_no: 3, label:  '无所谓'},
    ],
    
  },
  {
    action_id: 10,
    isGame: true,
    action_label: '飞镖小游戏',
    single: [
      { answer_no: 80, label:  '80分'},
      { answer_no: 100, label:  '100分'},
    ],
    
  },
  {
    action_id: 11,
    action_label: '电视',
    single: [
      { answer_no: 1, label:  '体育'},
      { answer_no: 2, label:  '新闻'},
      { answer_no: 3, label:  '综艺'},
      { answer_no: 4, label:  '电影'},
    ],
    
  },
  {
    action_id: 12,
    action_label: '麦',
    single: [
      { answer_no: 1, label:  '来一首情歌'},
      { answer_no: 2, label:  '来一首说唱'},
      { answer_no: 3, label:  '来一首民谣'},
      { answer_no: 4, label:  '来一首freestyle'},
    ],
    
  },
  {
    action_id: 13,
    action_label: '酒桌游戏',
    single: [
      { answer_no: 1, label:  '真心话大冒险'},
      { answer_no: 2, label:  '数7'},
      { answer_no: 3, label:  '猜骰子'},
      { answer_no: 4, label:  '国王游戏'},
    ],
    
  },
]

export const multiBehaviorConfig = [
  {
    action_id: 101,
    action_label: '舞池',
    single: [],
    option: [
      { answer_no: 1, label: '在舞池一起跳了1分钟【拉丁舞】，{0}跳得【蛮好的】，{1}跳得【笨手笨脚】' },
      { answer_no: 2, label: '在舞池一起跳了1分钟【拉丁舞】，{0}跳得【笨手笨脚】，{1}跳得【蛮好的】' },
      { answer_no: 3, label: '{0}和{1}在舞池一起跳了1分钟【拉丁舞】，双方舞技【旗鼓相当】' },
      { answer_no: 4, label: '在舞池一起跳了1分钟【街舞】，{0}跳得【蛮好的】，{1}跳得【笨手笨脚】' },
      { answer_no: 5, label: '在舞池一起跳了1分钟【街舞】，{0}跳得【笨手笨脚】，{1}跳得【蛮好的】' },
      { answer_no: 6, label: '{0}和{1}在舞池一起跳了1分钟【街舞】，双方舞技【旗鼓相当】' },
    ]
  },
  {
    action_id: 102,
    action_label: '曲风选择',
    option: [
      { answer_no: 1, label:  '{0}和{1}商量了一番，一起决定在点歌机点一首【爵士】音乐'},
      { answer_no: 2, label:  '商量了一番，{0}和{1}决定在点歌机点一首【流行】音乐'},
      { answer_no: 3, label:  '商量了一番，{0}和{1}决定在点歌机点一首【电子】音乐'},
      { answer_no: 4, label:  '商量了一番，{0}和{1}决定在点歌机点一首【摇滚】音乐'},
    ],
  },
  {
    action_id: 103,
    action_label: '上厕所',
    option: [
      { answer_no: 1, label:  '对视了一眼，{0}和{1}决定一起去上厕所'},
      { answer_no: 2, label:  '不需要'},
    ],
  },
  {
    action_id: 104,
    action_label: '吧台',
    option: [
      { answer_no: 1, label:  '在吧台比试酒量，酒过几巡，{0}甘拜下风'},
      { answer_no: 2, label:  '在吧台比试酒量，酒过几巡，{1}甘拜下风'},
      { answer_no: 3, label:  '在吧台比试酒量，酒过几巡，{0}和{1}承认双方酒量都不错'},
    ],
  },
  {
    action_id: 105,
    action_label: '制冰机',
    option: [
      { answer_no: 1, label:  '默契的往{0}和{1}杯子里都加了冰块'},
      { answer_no: 2, label:  '不需要'},
    ],
  },
  {
    action_id: 106,
    action_label: '画作',
    option: [
      { answer_no: 1, label:  '{0}和{1}讨论起了画作1'},
      { answer_no: 2, label:  '{0}和{1}讨论起了画作2'},
      { answer_no: 3, label:  '{0}和{1}讨论起了画作3'},
      { answer_no: 4, label:  '{0}和{1}讨论起了画作4'},
    ],
  },
  {
    action_id: 107,
    action_label: '望远镜',
    option: [
      { answer_no: 1, label:  '{0}和{1}看向了月球'},
      { answer_no: 2, label:  '{0}和{1}看向了木星'},
      { answer_no: 3, label:  '{0}和{1}看向了夏季大三角'},
    ],
  },
  {
    action_id: 108,
    action_label: '书架',
    option: [
      { answer_no: 1, label:  '{0}和{1}在书架前拿起《老人与海》探讨起来'},
      { answer_no: 2, label:  '{0}和{1}在书架前拿起《龙珠》探讨起来'},
      { answer_no: 3, label:  '{0}和{1}在书架前拿起《存在与时间》探讨起来'},
      { answer_no: 4, label:  '{0}和{1}在书架前拿起《拿破仑传》探讨起来'},
    ],
  },
  {
    action_id: 9,
    action_label: '城市选择',
    option: [
      { answer_no: 1, label:  '一起漫谈自己喜欢的城市，{0}表达自己喜欢的城市类型是【喧嚣的大都市】，{1}表达自己喜欢的城市类型是【宁静的小镇】'},
      { answer_no: 2, label:  '一起漫谈自己喜欢的城市，{1}表达自己喜欢的城市类型是【喧嚣的大都市】，{0}表达自己喜欢的城市类型是【宁静的小镇】'},
      { answer_no: 3, label:  '漫谈自己喜欢的城市，{0}和{1}一致认同自己喜欢的城市类型是【喧嚣的大都市】'},
      { answer_no: 4, label:  '漫谈自己喜欢的城市，{0}和{1}一致认同自己喜欢的城市类型是【宁静的小镇】'},
    ]
  },
  {
    action_id: 111,
    action_label: '电视',
    option: [
      { answer_no: 1, label:  '{0}和{1}一起看起了体育频道'},
      { answer_no: 2, label:  '{0}和{1}一起看起了新闻频道'},
      { answer_no: 3, label:  '{0}和{1}一起看起了综艺频道'},
      { answer_no: 4, label:  '{0}和{1}一起看起了电影频道'},
    ],
  },
  {
    action_id: 112,
    action_label: '麦',
    option: [
      { answer_no: 1, label:  '{0}和{1}一起唱起了情歌'},
      { answer_no: 2, label:  '{0}和{1}一起唱起了说唱'},
      { answer_no: 3, label:  '{0}和{1}一起唱起了民谣'},
      { answer_no: 4, label:  '{0}和{1}一起唱起了freestyle'},
    ],
  },
  {
    action_id: 113,
    action_label: '酒桌游戏',
    option: [
      { answer_no: 1, label:  '{0}和{1}一起玩起了真心话大冒险'},
      { answer_no: 2, label:  '{0}和{1}一起玩起了数7'},
      { answer_no: 3, label:  '{0}和{1}一起玩起了猜骰子'},
      { answer_no: 4, label:  '{0}和{1}一起玩起了国王游戏'},
    ],
  },
]


export const modeTypes = [
  { key: 0,  label: '调试模式', },
  { key: 1,  label: '用户模式'},
]

export enum chatMsgShowModeTypes {
  ALL = 0,
  Player1 = 1,
  Player2 = 2,
  Players = 3
}

export const chatMsgShowModes = [
  { key: chatMsgShowModeTypes.ALL,  label: '展示全部信息', },
  { key: chatMsgShowModeTypes.Player1,  label: '仅展示玩家1相关'},
  { key: chatMsgShowModeTypes.Player2,  label: '仅展示玩家2相关'},
  { key: chatMsgShowModeTypes.Players,  label: '仅展示玩家1+2相关'},
]

export enum MessageTypes {
  Chat = 0,
  Behavior = 1,
  Comment = 2,
  Wait = 3
}

export enum SingleChatState {
  RoomSetting = 0,
  RoleSetting = 1,
  Chat = 2,
  End = 3,
  Wait = 4
}

export enum GroupChatState {
  RoomSetting = 0,
  RoleSetting = 1,
  CreateGroup = 2,
  JoinGroup = 3,
  Chat = 4,
  End = 5,
}

export enum GroupLoadingState {
  WaitCreateGroup = 0,
  WaitJoinGroup = 1,
  SocketConnect = 2,
}

export enum ConnectClientTypes {
  NODEFINITION = 0,
  USERPLAYER = 1,
  AIAGENT = 2
}



export const RoleConfig = [
  { key: 1, label: '△ 酒吧客人，男性\n△ 看起来30岁左右\n△ 身高160cm\n△ 蓝色短发\n△ 黄褐色贝雷帽，黄绿黑格子长袖衬衣，褐色长裤，红色板鞋' },
  { key: 2, label: '△ 酒吧客人，男性\n△ 看起来20岁左右\n△ 身高170cm\n△ 褐色短发\n△ 左耳有珍珠耳饰，蓝黑色长袖外套，里面没穿上衣，黑色十字架项链，红色白纹短裤，黑色短靴'},
  { key: 3, label: '△ 酒吧客人，男性\n△ 看起来25岁左右\n△ 身高165cm\n△ 酒色短发\n△ 粉色红纹长袖上衣，上衣有中国特色花纹和装饰，黑色长裤，粉色板鞋'},
  { key: 4, label: '△ 酒吧客人，男性\n△ 看起来20岁左右\n△ 身高175cm\n△ 黑色短发\n△ 头戴白绿色耳机，灰白格子马甲，黑色短袖T恤，右手带有手表，褐色长裤，黑色板鞋'},
  { key: 5, label: '△ 酒吧客人，男性\n△ 看起来25岁左右\n△ 身高180cm\n△ 红色短发\n△ 白色蓝纹短袖球服上衣，白色球服短裤，白色运动长袜，蓝色球鞋'},
  { key: 6, label: '△ 酒吧客人，男性\n△ 看起来30岁左右\n△ 身高170cm\n△ 褐色短发\n△ 白色灰纹西装外套，里面是蓝色衬衣，红色领带，蓝色西裤，褐色皮鞋，左手带着机械手表'}
]

export const GroupAIRoleConfig = [
  {
    key: 10001,
    role: 'AI',
    name: 'Bonny',
    label: '【人物】女性、24岁、经济学专业，目前在游戏行业<br >【爱好】辩论，绘画，烹饪，大自然，各类动物，文学<br >【讨厌】大男子主义，思维迟缓<br >【性格】ENTJ，天生的领导者和组织建设者。喜欢分析、讲求逻辑、在客观上批判的。<br >【言语】简洁高效，逻辑缜密。喜欢突出自己的存在感，对任何话题都能发表自己的见解。<br >【关系】与玩家1情侣关系；与玩家2初次见面；与Clyde初次见面；'
  },
  {
    key: 10002,
    role: 'AI',
    name: 'Clyde',
    label: '【人物】男性、25岁、导演专业，目前在影视行业<br >【爱好】摄影，剧本创作，射箭，二次元，建筑，时政<br >【讨厌】对美没有感知，对人间苦难缺乏同情心的人<br >【性格】INFP。富有好奇心，快速地看见各种可能性，并能以此加速对理念的实践。敏锐、有爱心、为他人着想的。<br >【言语】只有在遇到感兴趣的话题时才会变得侃侃而谈<br >【关系】与玩家2情侣关系；与玩家1初次见面；与Bony初次见面；'
  },
]

export const GroupRoleConfig = [
  {
    key: 1,
    role: 'User',
    label: '【人物】男性、24岁、媒体专业，目前在房地产行业\n【爱好】钢琴，羽毛球，脱口秀，奢侈品，传播学，健身\n【讨厌】机械的日复一日的生活\n【性格】ENTP。不畏惧表达对于任何事情或任何人的不同意。热衷于唇枪舌剑，知识渊博，好奇，具有顽皮的幽默感。\n【言语】擅长用幽默和机智来说话，可以使许多人在说话中感到轻松和愉快。\n【关系】与Bony情侣关系；与玩家2好朋友；与Clyde初次见面；'
  },
  {
    key: 2,
    role: 'User',
    label: '【人物】女性、25岁、会计专业，目前在游戏行业\n【爱好】美食，乙游，网络小说，二次元，美女，户外\n【讨厌】霸道的人，繁文缛节和过度依赖\n【性格】ISTP。喜欢了解事物的机制，并倾向于采取行动。并用冷静的理性主义和热情的好奇心触摸和审视他们周围的世界。\n【言语】冷静理性，实事求是。直言不讳且思想开放。\n【关系】与Clyde情侣关系；与玩家1好朋友；与Bony初次见面；'
  },
]

