<template>
  <div class="consumption-detail">
    <div class="main-container">
      <div class="api-wrap">
        <div class="search-box">
          <div class="search-line">
            <div class="date-picker">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                popper-class="lit-gate-date-picker"
                value-format="timestamp"
                range-separator="-"
                :start-placeholder="$t('views.consumptionDetail.startDate')"
                :end-placeholder="$t('views.consumptionDetail.endDate')">
              </el-date-picker>
            </div>
            <div class="props-box">
              <div class="props-name">{{ $t('views.consumptionDetail.demo') }}</div>
              <el-select v-model="demoId" :placeholder="$t('views.consumptionDetail.demoPH')" :clearable="true" :style="{width: '180px', borderRadius: 'none'}">
                <el-option
                  v-for="item in demoNameOptions"
                  :key="item.project_id"
                  :label="item.project_name"
                  :value="item.project_id">
                </el-option>
              </el-select>
            </div>
            <div class="props-box">
              <div class="props-name">{{ $t('views.consumptionDetail.model') }}</div>
              <el-select v-model="model" placeholder="" :style="{width: '160px', borderRadius: 'none'}">
                <el-option
                  v-for="item in modeOptions"
                  :key="item.key"
                  :label="$t(item.label)"
                  :value="item.key">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-btn" @click="fetchList">{{ $t('views.consumptionDetail.searchBtn') }}</div>
        </div>
        <LitTable :columns="columns" :operates="operates" :tableData="list" :pagination="pagination" @onOperate="onOperate"/>
      </div>
    </div>
  </div>
</template>
  
<script>
import LitTable from '@/components/table/index.vue'
import DeleteDialog from '@/components/dialog/deleteDialog.vue'
import { Currency_Types, Model_Types } from '@/config/token'
import { fetchProjectNames, fetchProjectBillingDetail, fetchTokenBalanceList, requestProjectAddApiKey } from '@/api/token'
import { Table_Column_Types } from '@/config/table'
export default {
  name: 'consumption-detail-manager',
  components: { LitTable, DeleteDialog },
  props: {
    size: {
      type: Number,
      default: 32
    },
    queryDemoId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      Currency_Types,
      loading: false,
      columns: [
        { label: 'views.consumptionDetail.tableTh.requestId', prop: 'request_id', align: 'left' },
        { label: 'views.consumptionDetail.tableTh.time', prop: 'req_time', align: 'left', type: Table_Column_Types.Time, timeFormat: 'YYYY-mm-dd HH:MM:SS' },
        { label: 'views.consumptionDetail.tableTh.demo', prop: 'project_name', align: 'left' },
        { label: 'views.consumptionDetail.tableTh.model', prop: 'model_id', align: 'left', type: Table_Column_Types.Custom, showTxt: (row) => this.showModelTxt(row) },
        { label: 'views.consumptionDetail.tableTh.consumption', prop: 'rsp_amout', align: 'left', type: Table_Column_Types.Custom, showTxt: (value) => `$${Number(value).toFixed(4)}` },
      ],
      operates: [],
      list: [],
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      dateRange: [],
      demoId: '',
      model: Model_Types.Model_OpenAi_INVALID,
      demoNameOptions: [],
      deleteVisible: false,
      modeOptions: [
        { key: Model_Types.Model_OpenAi_INVALID, label: 'views.consumptionDetail.modelAll' },
        { key: Model_Types.Model_OpenAi_Chat_GPT35, label: 'views.consumptionDetail.gpt35' },
        { key: Model_Types.Model_OpenAi_Chat_GPT4, label: 'views.consumptionDetail.gpt4' },
        { key: Model_Types.Model_OpenAi_Chat_GPT4o, label: 'views.consumptionDetail.gpt4o' },
        { key: Model_Types.Model_OpenAi_Image_Dalle3, label: 'views.consumptionDetail.dalle3' },
        { key: Model_Types.Model_OpenAi_Speech_tts1, label: 'views.consumptionDetail.tts1' },
        { key: Model_Types.Model_OpenAi_Speech_tts1hd, label: 'views.consumptionDetail.tts1hd' },
        { key: Model_Types.Model_OpenAi_Trancript_wisper, label: 'views.consumptionDetail.wisper' },
        { key: Model_Types.Model_Claude_Message_haiku, label: 'views.consumptionDetail.claude' },
        { key: Model_Types.Model_Claude_Message_sonnet, label: 'views.consumptionDetail.claude' },
      ]
    }
  },
  mounted () {
    let { demo } = this.$route.query;
    if (this.queryDemoId) {
      this.demoId = this.queryDemoId
    }
    if (demo) {
      this.demoId = demo;
    }
    this.initDateRange()
    this.fetchDemoNamesOptions()
    this.fetchList()

  },
  methods: {
    initDateRange() {
      // 获取当前时间的时间戳
      const now = new Date();
      const nowTimestamp = now.getTime();

      // 获取一周前的时间戳
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(now.getDate() - 7);
      const oneWeekAgoTimestamp = oneWeekAgo.getTime();
      this.dateRange = [
        oneWeekAgoTimestamp,
        nowTimestamp
      ]
    },
    async fetchDemoNamesOptions() {
      const result = await fetchProjectNames({
        comm_limit: 100,
        comm_offset: 0
      })
      if (result.code === 0 && result.data) {
        const { list } = result.data
        this.demoNameOptions = list
      }
      console.log('result:', result)
    },
    showModelTxt(row) {
      const target = this.modeOptions.find(item => item.key === Number(row))
      if (target) {
        return this.$t(target.label)
      }
      return this.$t('views.consumptionDetail.modelDraft') 
    },
    onOperate(row, options) {
      console.log('operate', row, options)
    },
    setCurrency(type){
      this.currency = type;
    },
    showCurrency(num) {
      const currency = this.currency === Currency_Types.Currency_USD ? '$' : '¥';
      return `${currency} ${num}`;
    },
    refetchList() {
      this.pagination.current = 1;
      this.fetchList();
    },
    async fetchList() {
      const params = {
        comm_limit: this.pagination.size,
        comm_offset: this.pagination.current - 1,
        model_id: this.model
      };
      if (this.demoId) params.project_id =  this.demoId;
      
      if (this.dateRange && this.dateRange.length === 2) {
        params.begin_time = parseInt(this.dateRange[0] / 1000);
        params.end_time = parseInt(this.dateRange[1] / 1000);
      }
      if (!this.dateRange || this.dateRange.length === 0){
        // this.$message.error(this.$t('views.consumptionDetail.tableTh.dateRangeTips'))
        const timestampObj = this.getFirstAndLastDayTimestamps();
        params.begin_time = parseInt(timestampObj.firstDayTimestamp / 1000);
        params.end_time = parseInt(timestampObj.lastDayTimestamp / 1000);
      }
      const result = await fetchProjectBillingDetail(params);
      if (result.code === 0 && result.data) {
        const { comm_total, list, ...viewsData } = result.data;
        this.list = list;
        this.pagination.total = Number(comm_total);
        this.viewsData = viewsData; 
      }
    },
    getFirstAndLastDayTimestamps() {
      const now = new Date();

      // 获取当前月份的第一天
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const firstDayTimestamp = firstDay.getTime();

      // 获取当前月份的最后一天
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      const lastDayTimestamp = lastDay.getTime();

      return {
        firstDayTimestamp,
        lastDayTimestamp
      };
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.scrollEnd)
  }
}
</script>
<style lang="scss" scoped>
$txt_color: #666;
$value_color: #DDD;
  /* 修改选中日期的颜色 */
.consumption-detail{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 0px 0px 40px 0;
  :deep(.el-input__inner){
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }


  // /* 修改开始日期的背景颜色 */
  // .el-date-table .available .start-date {
  //   background-color: #4caf50 !important; /* 开始日期的背景颜色 */
  //   color: #fff !important; /* 开始日期的文字颜色 */
  // }

  // /* 修改结束日期的背景颜色 */
  // .el-date-table .available .end-date {
  //   background-color: #f44336 !important; /* 结束日期的背景颜色 */
  //   color: #fff !important; /* 结束日期的文字颜色 */
  // }
  .main-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 1136px;
    background: #0f0f0f;
    padding: 32px;
    gap: 40px;
    .api-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 24px;
      .search-box{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        .search-line {
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          gap: 40px;
          .props-box{
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            gap: 12px;
            .props-name{
              color: #999;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; 
            }
          }
        }
        .search-btn{
          display: flex;
          width: 104px;
          padding: 5px 16px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          background: #00FFF6;
          cursor: pointer;
        }
      }
    }
  }
}
</style>