
export function previewImage(src) {
  const imgStyle = {
    scale: 1,
    rotate: 0,
    fullScreenState: false,
    marginTop: 0,
    marginLeft: 0,
    cacheLeft: 0,
    cacheTop: 0
  };
  var isDragging = false;
  var startOffsetX, startOffsetY;

  const previewEle = document.createElement('div');
  previewEle.classList.add('el-image-viewer__wrapper');
  previewEle.style.zIndex = '9999';
  previewEle.addEventListener('wheel', (e) => {
    e.stopPropagation();
  }, false)
  document.body.style.overflow = 'hidden';

  const mask = document.createElement('div');
  mask.classList.add('el-image-viewer__mask');

  const closeBtn = document.createElement('span');
  closeBtn.classList.add('el-image-viewer__btn', 'el-image-viewer__close');
  const icon = document.createElement('i');
  icon.classList.add('el-icon-close');
  closeBtn.appendChild(icon);

  closeBtn.addEventListener('click', () => {
    document.body.style.removeProperty('overflow');
    document.body.removeChild(previewEle);
  });

  const actionBox = document.createElement('div');
  actionBox.classList.add('el-image-viewer__btn', 'el-image-viewer__actions');
  const actionInner = document.createElement('div');
  actionInner.classList.add('el-image-viewer__actions__inner');
  actionBox.appendChild(actionInner);
  
  // zoom-out
  const zoomOutBtn = document.createElement('i');
  zoomOutBtn.classList.add('el-icon-zoom-out');
  zoomOutBtn.addEventListener('click', zoomOutFunc);
  actionInner.appendChild(zoomOutBtn);

  const zoomInBtn = document.createElement('i');
  zoomInBtn.classList.add('el-icon-zoom-in');
  zoomInBtn.addEventListener('click', zoomInFunc);
  actionInner.appendChild(zoomInBtn);


  const divider_1 = document.createElement('i');
  divider_1.classList.add('el-image-viewer__actions__divider');
  actionInner.appendChild(divider_1);

  const fullScreenBtn = document.createElement('i');
  fullScreenBtn.classList.add('el-icon-full-screen');
  fullScreenBtn.addEventListener('click', fullScreenFunc);
  actionInner.appendChild(fullScreenBtn);

  const divider_2 = document.createElement('i');
  divider_2.classList.add('el-image-viewer__actions__divider');
  actionInner.appendChild(divider_2);

  const refreshLeftBtn = document.createElement('i');
  refreshLeftBtn.classList.add('el-icon-refresh-left');
  refreshLeftBtn.addEventListener('click', refreshLeftFunc);
  actionInner.appendChild(refreshLeftBtn);

  const refreshRightBtn = document.createElement('i');
  refreshRightBtn.classList.add('el-icon-refresh-right');
  refreshRightBtn.addEventListener('click', refreshRightFunc);
  actionInner.appendChild(refreshRightBtn);

  const imgBox = document.createElement('div');
  imgBox.classList.add('el-image-viewer__canvas');
  const img = document.createElement('img');
  img.classList.add('el-image-viewer__img');
  img.style.transform = `scale(${imgStyle.scale}) rotate(${imgStyle.rotate}deg)`;
  img.style.marginLeft = '0px';
  img.style.marginTop = '0px';

  img.style.maxWidth = '100%';
  img.style.maxHeight = '100%';
  // img.style.-webkit-user-drag: none;
  img.style.transition = 'transform 0.3s ease 0s';
  img.draggable = false;
  img.src = src;
  addDrag();
  imgBox.appendChild(img);

  
  previewEle.appendChild(mask);
  previewEle.appendChild(closeBtn);
  previewEle.appendChild(actionBox)
  previewEle.appendChild(imgBox);

  document.body.appendChild(previewEle);

  function zoomInFunc() {
    imgStyle.scale += 0.2;
    renderImg();
  }

  function zoomOutFunc() {
    if (imgStyle.scale <= 0.2) return;
    imgStyle.scale -= 0.2;
    renderImg();
  }

  function refreshLeftFunc() {
    imgStyle.rotate -= 90;
    renderImg();
  }

  function refreshRightFunc() {
    imgStyle.rotate += 90;
    renderImg();
  }

  function fullScreenFunc() {
    imgStyle.scale = 1;
    imgStyle.rotate = 0;
    imgStyle.cacheLeft = 0;
    imgStyle.cacheTop = 0;
    imgStyle.marginLeft = 0;
    imgStyle.marginTop = 0;
    imgStyle.fullScreenState = !imgStyle.fullScreenState;
    refreshImg();
  }

  function renderImg() {
    img.style.transition = 'transform 0.3s ease 0s';
    img.style.transform = `scale(${imgStyle.scale}) rotate(${imgStyle.rotate}deg)`;
    
    img.style.marginLeft = imgStyle.marginLeft + "px";
    img.style.marginTop = imgStyle.marginTop + "px";
  }

  function refreshImg() {
    img.style.removeProperty('transition');
    img.style.transform = `scale(${imgStyle.scale}) rotate(${imgStyle.rotate}deg)`;
    console.log('img:', img, imgStyle)
    if (imgStyle.fullScreenState) {
      img.style.removeProperty('max-width');
      img.style.removeProperty('max-height');
      fullScreenBtn.classList.remove(img.classList[0]);
      fullScreenBtn.classList.add('el-icon-c-scale-to-original');
      return;
    }
    img.style.maxWidth = '100%';
    img.style.maxHeight = '100%';
    img.style.marginLeft = imgStyle.marginLeft + "px";
    img.style.marginTop = imgStyle.marginTop + "px";
    fullScreenBtn.classList.remove(img.classList[0]);
    fullScreenBtn.classList.add('el-icon-full-screen');
  }

  function addDrag() {
    img.addEventListener("mousedown", function(event) {
      isDragging = true;
      startOffsetX = event.clientX;
      startOffsetY = event.clientY;
      imgStyle.cacheLeft = imgStyle.marginLeft;
      imgStyle.cacheTop = imgStyle.marginTop;
    });
    
    document.addEventListener("mousemove", function(event) {
      if (isDragging) {
        imgStyle.marginLeft = event.clientX - startOffsetX + imgStyle.cacheLeft;
        imgStyle.marginTop = event.clientY - startOffsetY + imgStyle.cacheTop;
        renderImg();
      }
    });
    
    document.addEventListener("mouseup", function() {
      isDragging = false;
    });
  }
    
}
