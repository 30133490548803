import { RoutesModule } from '@/store/modules/routes';

  /**
   * 获取有navigation标识的路由数组
   */
  export function getNavigationRoute(targetRoutes) {
    let filterRoute = [] as any[];
    for(let i = 0; i < targetRoutes.length; i ++) {
      const cur = targetRoutes[i];
      if (cur.meta?.isNavigation) {
        filterRoute.push(cur);
      }
      if (cur.children?.length > 0) {
        const result = getNavigationRoute(cur.children);
        if (result.length > 0) {
          filterRoute = filterRoute.concat(result);
        }
      }
    }
    return filterRoute;
  }
  
  /**
   * 生成导航路由
   * 导航路由条件：isNavigation = true
   */
  export async function resetRoute(targetRoutes) {
    const authedRoutes = await getNavigationRoute(targetRoutes);
    console.log(authedRoutes);
  }
