<template>
  <div class="application-list">
    <div class="main-container">
      <div class="instruction-wrap">
        <div class="instruction-box">
          <div class="instruction-title">{{ $t('views.incentiveAccount.title') }}</div>
          <div class="currency-box">
            <div :class="{'currency-btn': true, 'normal-txt': currency !== Currency_Types.Currency_USD, 'active-txt': currency === Currency_Types.Currency_USD}" @click="setCurrency(Currency_Types.Currency_USD)">{{ $t('views.incentiveAccount.usd') }}</div>
            <div :class="{'currency-btn': true, 'normal-txt': currency !== Currency_Types.Currency_CNY, 'active-txt': currency === Currency_Types.Currency_CNY}" @click="setCurrency(Currency_Types.Currency_CNY)">{{ $t('views.incentiveAccount.cny') }}</div>
          </div>
        </div>
        <div class="overview-content">
          <div class="overview-balance-box">
            <div class="balance-title">
              <div class="balance-icon"></div>
              {{ $t('views.incentiveAccount.balance') }}
            </div>
            <div class="balance-count">{{ showCurrency(viewsData.total_balance || 0) }}</div>
            <!-- <div class="balance-tips">Valid until 2024-12-31</div> -->
          </div>
          <div class="line line-color"></div>
          <div class="count-column">
            <div class="count-box">
              <div class="count-title">{{ $t('views.incentiveAccount.totalIncentive') }}</div>
              <div class="count-value">{{ showCurrency(viewsData.total_gifts || 0) }}</div>
            </div>
            <div class="count-box">
              <div class="count-title">{{ $t('views.incentiveAccount.yesterday') }}</div>
              <div class="count-value">{{ showCurrency(viewsData.total_yesterday_consume || 0) }}</div>
            </div>
          </div>
          <div class="line"></div>
          <div class="count-column">
            <div class="count-box">
              <div class="count-title">{{ $t('views.incentiveAccount.monthTotal') }}</div>
              <div class="count-value">{{ showCurrency(viewsData.total_month_consume || 0) }}</div>
            </div>
            <div class="count-box">
              <div class="count-title">{{ $t('views.incentiveAccount.totalConsumption') }}</div>
              <div class="count-value">{{ showCurrency(viewsData.total_consume || 0) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="api-wrap">
        <div class="tool-line">
          <div class="api-title">{{ $t('views.incentiveAccount.detailTitle') }}</div>
          <div class="btn-list">
            <!-- <div class="btn-color create-btn">{{ $t('views.incentiveAccount.createBtn') }}</div> -->
          </div>
        </div>
        <LitTable :columns="columns" :operates="operates" :tableData="list" @onOperate="onOperate"/>
      </div>
    </div>
    <CreateApiKeyDialog ref="createApiKeyDialog" @onClose="createApiKeyDialogClose"/>
    <UsageLimitDialog ref="usageLimitDialog" @onConfirm="limitChangeSuccess"/>
    <IncentiveDetail ref="incentiveDetailDialog" />
    <DeleteDialog
      :title="$t('views.incentiveAccount.dialog.deleteTitle')"
      :content="$t('views.incentiveAccount.dialog.deleteTips')"
      :confirmBtn="$t('views.incentiveAccount.dialog.sure')"
      @onConfirm="createApiKeyAction(currentReNewTarget)"
      @onCancel="deleteVisible = false"
      :visible="deleteVisible"
    />
  </div>
</template>
  
<script>
import LitTable from '@/components/table/index.vue'
import CreateApiKeyDialog from './dialog/createApiKey.vue'
import UsageLimitDialog from './dialog/usageLimit.vue'
import IncentiveDetail from './dialog/incentiveDetail.vue'
import DeleteDialog from '@/components/dialog/deleteDialog.vue'
import { Currency_Types } from '@/config/token'
import { fetchTokenBalanceList, requestProjectAddApiKey, requestBalanceRenew, requestBalanceApply } from '@/api/token'
import { Table_Column_Types } from '@/config/table'
import { getGlobalTypeData } from '@/api/gateReport'
import { ENUM_G_506, ENUM_G_508, GlobalTypes } from '@/config/stat'
import { applicationMenuKey } from '@/config/application'
export default {
  name: 'incentive-account-manager',
  components: { LitTable, CreateApiKeyDialog, DeleteDialog, UsageLimitDialog, IncentiveDetail },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      Currency_Types,
      loading: false,
      columns: [
        { label: 'views.incentiveAccount.tableTh.demo', prop: 'project_name', align: 'left' },
        { label: 'views.incentiveAccount.tableTh.apiKey', prop: 'keys', align: 'left' },
        { label: 'views.incentiveAccount.tableTh.balance', prop: 'balance', type: Table_Column_Types.Balance, align: 'left', showTxt: (value) => `$${Number(value).toFixed(4)}`, onClick: (row) => this.onBalanceApply(row) },
        { label: 'views.incentiveAccount.tableTh.valid', prop: 'expire_time', type: Table_Column_Types.CanRenewTime, align: 'left', onClick: (row) => this.onRenew(row) },
        { label: 'views.incentiveAccount.tableTh.totalIncentive', prop: 'total_gifts', type: Table_Column_Types.Detail, align: 'left', showTxt: (value) => `$${Number(value).toFixed(4)}`, onClick: (row) => this.openIncentiveDetail(row) },
        { label: 'views.incentiveAccount.tableTh.totalConsumption', prop: 'total_consume', type: Table_Column_Types.Detail, align: 'left', showTxt: (value) => `$${Number(value).toFixed(4)}`, onClick: (row) => this.gotoSeeConsumptionDetail(row), width: 155 },
      ],
      operates: [
        { label: 'views.incentiveAccount.tableTh.createBtn', type: 1, onShow: (row) => { return !row.keys}, onClick: (row) => this.createApiKeyAction(row) },
        { label: 'views.incentiveAccount.tableTh.changeBtn', type: 1, onShow: (row) => { return !!row.keys}, onClick: (row) => this.openDeleteDialog(row) },
        { label: 'views.incentiveAccount.tableTh.usage', type: 2, onClick: (row) => this.openUsageLimitDialog(row) }
      ],
      viewsData: {},
      list: [],
      pagination: {
        current: 1,
        size: 10,
        total: 0
      },
      deleteVisible: false,
      currency: Currency_Types.Currency_USD,
      sorting: '',
      systemLimit: 1000,
      moneyRate: 7,
      currentReNewTarget: null
    }
  },
  mounted () {
    this.fetchList()
    this.fetchSystemLimitAndRate()
    // this.fetchMoneyRate()
  },
  methods: {
    async createApiKeyAction(row) {
      const { project_id, keys } = row;
      this.deleteVisible = false;
      const isCreate = !keys;
      if (!project_id) {
        console.log('not found project_id');
        return;
      }
      const result = await requestProjectAddApiKey({
        project_id
      })
      if (result.code === 0 && result.data) {
        this.$refs.createApiKeyDialog.open(result.data.info, isCreate);
      }
    },
    createApiKeyDialogClose(isCreate = false) {
      if (isCreate) {
        this.$message.success(this.$t('views.incentiveAccount.keyGenerated'));
      }
      this.refetchList();
    },
    openUsageLimitDialog(row) {
      console.log('open dialog:', row)
      this.$refs.usageLimitDialog.open(row, this.systemLimit);
    },
    openDeleteDialog(row) {
      this.deleteVisible = true
      this.currentReNewTarget = row;
    },
    async onRenew(row) {
      const result = await requestBalanceRenew({
        project_id: row.project_id
      })
      if (result.code === 0) {
        this.$message.success(this.$t('comment.applySuccess'));
        this.refetchList();
      }
    },
    async onBalanceApply(row) {
      const result = await requestBalanceApply({
        project_id: row.project_id,
        change_value: 50,
        reason: '无'
      })
      if (result.code === 0) {
        this.$message.success(this.$t('comment.applySuccess'));
        this.refetchList();
      }
    },
    openIncentiveDetail(row) {
      this.$refs.incentiveDetailDialog.open(row);
    },
    onOperate(row, options) {
      console.log('operate', row, options)
    },
    setCurrency(type){
      this.currency = type;
    },
    showCurrency(num, len = 4) {
      const currency = this.currency === Currency_Types.Currency_USD ? '$' : '¥';
      if (this.currency === Currency_Types.Currency_USD) {
        return `${currency} ${Number(num).toFixed(4).toString()}`;
      }
      let formattedNum = (parseFloat((num * this.moneyRate) / 100).toFixed(len)).toString();
      // formattedNum = Number(formattedNum);
      console.log(formattedNum)
      return `${currency} ${formattedNum}`;
    },
    refetchList() {
      this.pagination.current = 1;
      this.fetchList();
    },
    async fetchList() {
      const result = await fetchTokenBalanceList({
        comm_limit: this.pagination.size,
        comm_offset: this.pagination.current - 1
      });
      if (result.code === 0 && result.data) {
        const { comm_limit: total, list, ...viewsData } = result.data;
        this.list = list;
        this.pagination.total = total;
        this.viewsData = viewsData; 
      }
    },
    async fetchSystemLimitAndRate() {
      const result = await getGlobalTypeData({
        global_type_id: GlobalTypes.Global_Type_Token
      })
      if (result.code === 0 && result.data) {
        const { type_info } = result.data;
        console.log('result ==>', type_info)
        // 提取汇率
        const target = type_info.find(item => item.type_id === ENUM_G_506.Token_FX)
        if (target) {
          const { param } = target;
          const moneyRate = Number(param);
          if (moneyRate > 0){
            this.moneyRate = moneyRate;
          }
        }
        // 提取每日消耗限额
        const limitTarget = type_info.find(item => item.type_id === ENUM_G_506.Token_project_day_limit)
        if (limitTarget) {
          const { param } = limitTarget;
          this.systemLimit = Number(param);
        }
      }
    },
    async fetchMoneyRate() {
      const result = await getGlobalTypeData({
        global_type_id: GlobalTypes.Global_Type_Token_Api_document
      })
      if (result.code === 0 && result.data) {
        const { type_info } = result.data;
        const target = type_info.find(item => item.type_id === ENUM_G_506.Token_FX)
        if (target) {
          const { param } = target;
          const moneyRate = Number(param);
          if (moneyRate > 0){
            this.moneyRate = moneyRate;
          }
        }
      }
    },
    limitChangeSuccess(projectInfo, limitInfo) {
      if (projectInfo && limitInfo) {
        this.list.forEach(item => {
          if (item.project_id === limitInfo.project_id) {
            item.day_limit = limitInfo.day_limit;
            item.limit_turn_on = limitInfo.limit_turn_on;
          }
        })
      }
    },
    gotoSeeConsumptionDetail(row) {
      this.$emit('gotoOtherMenu', applicationMenuKey.billingDetail, { demo: row.project_id })
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.scrollEnd)
  }
}
</script>
<style lang="scss" scoped>
$txt_color: #666;
$value_color: #DDD;
.application-list{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 0px 0px 40px 0;
  .main-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 1136px;
    background: #0f0f0f;
    padding: 32px;
    gap: 40px;
    .instruction-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .instruction-box{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 100%;
        .instruction-title{
          color: #DDD;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .currency-box{
          display: flex;
          justify-content: center;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          .currency-btn{
            display: flex;
            width: 64px;
            padding: 3px 8px;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            background: #141414;
            cursor: pointer;
          }
          .normal-txt{
            color: #666;
          }
          .active-txt{
            color: #00FFF6;
          }
        }
      }
      .overview-content{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        padding: 24px;
        height: 175px;
        width: 100%;
        background: #141414;
        gap: 32px;
        .overview-balance-box{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: flex-start;
          width: 200px;
          .balance-title{
            color: $txt_color;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: flex-start;
            .balance-icon{
              width: 24px;
              height: 24px;
              background-image: url('@/assets/postApplication/icon_balance.png');
              background-size: 100% 100%;
            }
          }
          .balance-count{
            padding-left: 5px;
            color: $value_color;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            white-space: nowrap;
          }
          .balance-tips{
            color: #666;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; 
          }
        }
        .line{
          width: 1px;
          height: 122px;
        }
        .line-color{
          background: rgba(255, 255, 255, 0.1);
        }
        .count-column{
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: flex-start;
          gap: 24px;
          min-width: 200px;
          .count-box{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            .count-title{
              color: $txt_color;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
            .count-value{
              color: $value_color;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px; 
            }

          }
        }
      }
    }
    .api-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 24px;
      .tool-line {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        .api-title{
          color:#DDD;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .btn-list{
          display: flex;
          justify-content: flex-end;
          flex-direction: row;
          align-items: center;
          .btn-color{
            background: #00FFF6;
          }
          .create-btn{
            display: flex;
            width: 104px;
            padding: 5px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>