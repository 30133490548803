import request from '@/utils/request';

// 用户相关接口
const BaseUrl = `/api/basesys`;
const BaseUserUrl = `/api/basesys/user`;

// 获取全局类型数据
export async function getUserCollect(data: object) {
  return request({
    url: `${BaseUrl}/getglobaltypedata`,
    method: 'POST',
    data
  })
}

// 获取广告信息
export async function getAdvertise(data: object) {
  return request({
    url: `${BaseUrl}/getadvertise`,
    method: 'POST',
    data
  })
}
// 获取广告位信息
export async function getAdvertisePos(data: object) {
  return request({
    url: `${BaseUrl}/getadvertisepos`,
    method: 'POST',
    data
  })
}

// 获取Lit用户信息
export async function getLitUserData(data: object) {
  return request({
    url: `${BaseUrl}/getlituserdata`,
    method: 'POST',
    data
  })
}

// 修改Lit用户信息
export async function setLitUserData(data: object) {
  return request({
    url: `${BaseUserUrl}/setlituserdata`,
    method: 'POST',
    data
  })
}
// 查询全局统计数据
export async function getGlobalStatData(data: any) {
  return request({
    url: `${BaseUrl}/getglobalstatdata`,
    method: 'post',
    data
  });
}

// 举报
export async function complaint(data: any) {
  return request({
    url: `${BaseUserUrl}/complaint`,
    method: 'post',
    data
  });
}
