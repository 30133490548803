import request from '@/utils/request';

const BaseUrl = `/api/loreessay`;
const BasUserUrl = `/api/loreessay/user`;
const BaseGuestUrl = `/api/loreessay`;

// 查询文章投稿详情
export async function queryArticleDraft(data: any) {
  return request({
    url: `${BasUserUrl}/getloreessayaudit`,
    method: 'post',
    data
  });
}
// 获取文章投稿
export async function queryArticleAudit(data: any) {
  return request({
    url: `${BasUserUrl}/getloreessayauditall`,
    method: 'post',
    data
  });
}
// 保存文章投稿
export async function saveArticle(data: any) {
  return request({
    url: `${BasUserUrl}/setloreessayaudit`,
    method: 'post',
    data
  });
}
// 删除文章投稿
export async function deleteArticle(data: any) {
  return request({
    url: `${BasUserUrl}/delloreessayaudit`,
    method: 'post',
    data
  });
}
// 查询文章
export async function getArticle(data: any) {
  return request({
    url: `${BaseUrl}/getloreessay`,
    method: 'post',
    data
  });
}
// 查询所有知识文章
export async function getAllArticle(data: any) {
  return request({
    url: `${BaseUrl}/getloreessayall`,
    method: 'post',
    data
  });
}
// 批量条件查询知识文章
export async function batchGetArticle(data: any) {
  return request({
    url: `${BaseUrl}/batchgetloreessay`,
    method: 'post',
    data
  });
}

// 查询个人知识文章
export async function getLoreEssayUserAll(data: any) {
  return request({
    url: `${BasUserUrl}/getloreessayuserall`,
    method: 'post',
    data
  });
}
// 查询个人知识文章
export async function getLoreEssayHotAll(data: any) {
  return request({
    url: `${BaseUrl}/getloreessayhotall`,
    method: 'post',
    data
  });
}
// 查询个人知识文章
export async function getLoreEssayHome(data: any) {
  return request({
    url: `${BaseUrl}/getloreessayhome`,
    method: 'post',
    data
  });
}

// 查询个人中心知识文字
export async function getPersonKnowledge(data: any) {
  return request({
    url: `${BasUserUrl}/getauditpostsall`,
    method: 'post',
    data
  });
}

// 查询个人中心知识文字
export async function getGuestKnowledge(data: any) {
  return request({
    url: `${BaseGuestUrl}/getloreuserhome`,
    method: 'post',
    data
  });
}







