import Vue from 'vue';
import Vuex from 'vuex';
import { IUserState } from './modules/user';
import { IRoutesState } from './modules/routes';
import { IKeepAliveState } from './modules/keepAlive';

Vue.use(Vuex);

export interface IRootState {
  user: IUserState
  routes: IRoutesState
  keepAlive: IKeepAliveState
}

export default new Vuex.Store<IRootState>({});
