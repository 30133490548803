import { RouteConfig } from 'vue-router';

/**
 * 定义默认路由
 * @description 当单独打包时，会设置默认路由
 */
export const defaultRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'gateHome',
    component: () => import('@/views/gateHome/index.vue'),
    meta: {
      noSession: true
    },
  },
];

/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，
 * 会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteConfig> = [
  {
    path: '/gate',
    name: 'gateHome',
    component: () => import('@/views/gateHome/index.vue'),
    meta: {
      noSession: true,
      isKeepAlive: true
    },
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import(/* webpackChunkName: "gate-editor" */'@/layout/article.vue'),
    redirect: '/editor/article',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'article',
        name: 'article',
        component: () => import(/* webpackChunkName: "gate-editor" */ '@/views/editor/article.vue'),
        meta: {
          title: 'route.article.add',
          noSession: false,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/postproduct',
    name: 'postproduct',
    component: () => import(/* webpackChunkName: "gate-postproduct" */'@/layout/article.vue'),
    redirect: '/postproduct/image',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'image',
        name: 'image',
        component: () => import(/* webpackChunkName: "gate-postproduct" */ '@/views/postProduct/imageResource.vue'),
        meta: {
          title: 'route.article.add',
          noSession: false,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/application',
    name: 'application',
    component: () => import(/* webpackChunkName: "gate-postproduct" */'@/layout/application.vue'),
    redirect: '/application/main',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'main',
        name: 'main',
        component: () => import(/* webpackChunkName: "gate-postproduct" */ '@/views/application/main.vue'),
        meta: {
          title: 'route.application.list',
          noSession: false,
          fitH5: true,
        },
      },
      {
        path: 'editworks',
        name: 'editworks',
        component: () => import(/* webpackChunkName: "gate-postproduct" */ '@/views/application/workEdit.vue'),
        meta: {
          title: 'route.application.create',
          noSession: false,
          fitH5: true,
        },
      },
      {
        path: 'api',
        name: 'api',
        component: () => import(/* webpackChunkName: "gate-postproduct" */ '@/views/apiDocument/index.vue'),
        meta: {
          title: 'route.application.list',
          noSession: false,
          fitH5: true,
        },
      }
    ],
  },
  {
    path: '/document',
    name: 'document',
    component: () => import(/* webpackChunkName: "gate-postproduct" */'@/layout/apiDocument.vue'),
    redirect: '/document/token',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'token',
        name: 'token',
        component: () => import(/* webpackChunkName: "gate-postproduct" */ '@/views/apiDocument/index.vue'),
        meta: {
          title: 'route.application.list',
          noSession: true,
          fitH5: true,
        },
      }
    ],
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import(/* webpackChunkName: "gate-knowledge" */ '@/layout/knowledge.vue'),
    redirect: '/knowledge/home',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'home',
        name: 'knowledgeHome',
        component: () => import(/* webpackChunkName: "gate-knowledge" */ '@/views/knowledge/index.vue'),
        meta: {
          title: 'route.knowledge.home',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'more',
        name: 'knowledgeMore',
        component: () => import(/* webpackChunkName: "gate-knowledge" */ '@/views/knowledge/more.vue'),
        meta: {
          title: 'route.knowledge.more',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'detail',
        name: 'knowledgeDetail',
        component: () => import(/* webpackChunkName: "gate-knowledge" */ '@/views/knowledge/detail.vue'),
        meta: {
          title: 'route.knowledge.detail',
          noSession: true,
          fitH5: true,
        },
      }
    ],
  },
  {
    path: '/gameworks',
    name: 'gameworks',
    component: () => import(/* webpackChunkName: "gate-game-works" */ '@/layout/gameWorks.vue'),
    redirect: '/knowledge/home',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'home',
        name: 'gameWorksHome',
        component: () => import(/* webpackChunkName: "gate-game-works" */ '@/views/gameWorks/index.vue'),
        meta: {
          title: 'route.gameWorks.home',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'detail',
        name: 'gameWorksDetail',
        component: () => import(/* webpackChunkName: "gate-game-works" */ '@/views/gameWorks/detail.vue'),
        meta: {
          title: 'route.gameWorks.detail',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'more',
        name: 'gameWorksMore',
        component: () => import(/* webpackChunkName: "gate-game-works" */ '@/views/gameWorks/more.vue'),
        meta: {
          title: 'route.gameWorks.more',
          noSession: true,
          fitH5: true,
        },
      },
    ]
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import(/* webpackChunkName: "gate-game-works" */ '@/layout/activity.vue'),
    redirect: '/activity/token',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'token',
        name: 'token',
        component: () => import(/* webpackChunkName: "gate-game-works" */ '@/views/activity/tokenBalance.vue'),
        meta: {
          title: 'route.gameWorks.home',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'detail',
        name: 'gameWorksDetail',
        component: () => import(/* webpackChunkName: "gate-game-works" */ '@/views/gameWorks/detail.vue'),
        meta: {
          title: 'route.gameWorks.detail',
          noSession: true,
          fitH5: true,
        },
      },
      {
        path: 'more',
        name: 'gameWorksMore',
        component: () => import(/* webpackChunkName: "gate-game-works" */ '@/views/gameWorks/more.vue'),
        meta: {
          title: 'route.gameWorks.more',
          noSession: true,
          fitH5: true,
        },
      },
    ]
  },
  {
    path: '/personal',
    name: 'personal',
    component: () => import('@/layout/personal.vue'),
    redirect: '/personal/home',
    meta: {
      isKeepAlive: true,
    },
    children: [
      {
        path: 'home',
        name: 'personalHome',
        component: () => import(/* webpackChunkName: "gate-personal" */ '@/views/personalCenter/index.vue'),
        meta: {
          title: 'route.personal.home',
          noSession: false,
          fitH5: true,
        },
      },
      {
        path: 'guest',
        name: 'guestHome',
        component: () => import(/* webpackChunkName: "gate-personal" */ '@/views/personalCenter/guest.vue'),
        meta: {
          title: 'route.personal.home',
          noSession: true,
          fitH5: true,
        },
      },
    ],
  },
  {
    path: '/guide',
    name: 'guide',
    component: () => import('@/views/gateHome/index.vue'),
    meta: {
      noSession: true
    },
  }
];
