<template>
  <div class="relevant-rank-list">
    <div class="list-head" v-if="detailList.length > 0">
      <div class="title">{{ title }}</div>
    </div>
    <div class="list-content">
      <CardHot class="list-item" v-for="(item, index) in detailList" :key="index" :value="item"
        :rank="index+1" @click="gotoDetail(item)" :showRank="false"></CardHot>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CardHot from '@/components/card/cardHot.vue'
import { batchGetArticle } from '@/api/article'

@Component({
    name: 'relevantArticle',
    components: {
      CardHot,
    },
})
class RelevantArticle extends Vue {
  @Prop({
    required: true,
    default: '',
  }) public title;
  @Prop({
    required: true,
    default: () => [],
  }) public list;
  

  public detailList = [];

  mounted() {
    this.batchGetArticleDetail();
  }

  public gotoDetail(item) {
    window.open(`/knowledge/detail?id=${item.lore_essay_id}`);
  }
  

  public batchGetArticleDetail() {
    if (this.list.length === 0) {
      return;
    }
    batchGetArticle({
      lore_essay_id: this.list
    }).then(res => {
      console.log('res:', res);
      if (res.code === 0 && res.data) {
        const { base_data = [] } = res.data;
        this.detailList = base_data;
      }
    }).catch(e => {})
  }

  @Watch('list')
  onVisibleChanged(val: string) {
    this.batchGetArticleDetail();
  }
}

export default RelevantArticle
</script>
  
<style lang="scss" scoped>
.relevant-rank-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .list-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        color: #ddd;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .extra {
        color: #666;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
    }

    .list-content {
      width: 100%;
      display: flex;
      margin-top: 16px;
      justify-content: flex-start;
      flex-direction: column;

      .list-item {
          margin-bottom: 24px;
      }
    }
}
</style>