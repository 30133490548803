<template>
  <div class="gate-guide-event">
    <div class="gate-guide-contain">
      <guideTitle :title="$t('views.guide.events.title')" :hasMore="false"/>
      <div class="gate-event">
        <activityCard :timeRange="$t('views.guide.events.eventTimeRange1')" :address="$t('views.guide.events.eventAddress1')" :title="$t('views.guide.events.eventTitle1')" :introduction="$t('views.guide.events.eventIntro1')" :bg="actCigaBg" more-link="https://www.gmhub.com/jams/aijam2023" />
        <!-- <activityCard :timeRange="$t('views.guide.events.eventTimeRange2')" :address="$t('views.guide.events.eventAddress2')" :title="$t('views.guide.events.eventTitle2')" :introduction="$t('views.guide.events.eventIntro2')" :bg="actGGJBg" /> -->
        <noActivityCard :timeRange="$t('views.guide.events.eventTimeRange2')" :address="$t('views.guide.events.eventAddress2')" :title="$t('views.guide.events.eventTitle2')" :introduction="$t('views.guide.events.eventIntro2')" />
      </div>
    </div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import activityCard from './components/activityCard.vue';
import noActivityCard from './components/noActivityCard.vue';
export default {
  name: 'guide-event',
  components: { guideTitle, activityCard, noActivityCard },
  data() {
    return {
      actCigaBg: 'https://cdn.litgate.ai/gate/guide/act_ciga.webp',
      actGGJBg: 'https://cdn.litgate.ai/gate/guide/act_GGJ.png',
    }
  },
  mounted() {
     ;
  },
}

</script>

<style lang="scss" scoped>
.gate-guide-event {
  margin: 0;
  height: 760px;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .gate-guide-contain{
    width: 1200px;
    height: 600px;
    .gate-guide-title{
      width: 100%;
      height: 90px;
      background-color: #cdcdcd;
      align-items: center;
      color: #000;
    }
    .gate-event{
      margin-top: 60px;
      width: 100%;
      height: 480px;
      display: flex;
      flex-direction: row;
    }
  }
}
</style>
