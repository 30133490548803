// 统计相关定义

// 模块类型
export enum ModeTypes {
  Type_INVALID = 0,
  Type_Works_Game_Deme = 1, //作品库-GameDome
  Type_Lore_Essay = 2, //知识库-文章
}

// 点赞类型
export enum LikeTypes {
  Like_Type_INVALID = 0,
  Like_Type_Works_Game_Deme = 1, //作品库-GameDome
  Like_Type_Lore_Essay = 2, //知识库-文章
  Like_Type_Works_Game_Deme_Comment = 3,    //作品库-GameDome-评论
  Like_Type_Works_Game_Deme_Reply = 4,    //作品库-GameDome-回复
  Like_Type_Lore_Essay_Comment = 5,    //知识库-文章-评论
  Like_Type_Lore_Essay_Reply = 6   //知识库-文章-回复
}
// 收藏类型
export enum CollectTypes {
  Collect_Type_INVALID = 0,
  Collect_Type_Works_Game_Deme = 1,  //作品库-GameDome
  Collect_Type_Lore_Essay = 2,  //知识库-文章
}
//作品类型
export enum WorksTypes {
  Works_Type_Game_Demo = 1,
  Works_Type_Game_Resource = 2,
  Works_Type_Art_Creativity = 3
}
// 全局事件上报类型表-类型-    通用上报事件类型
export enum GateReportTypes {
  Report_Event_Type_INVALID = 0,
  Report_Event_Type_PV = 1,  // PV  即访问量，这个是以次数为标准的，也就是说一个用户来你家几次就算几次，也就是累计访问次数
  Report_Event_Type_LoreEssayBrowse = 100, // 知识库100-199 知识库文章浏览 参数: param_str 第一个代表文章ID
  Report_Event_Type_LoreEssayShare = 101,  // 知识库100-199 知识库文章分享 参数: param_str 第一个代表文章ID
  Report_Event_Type_WorksBrowse = 200, // 作品库200-299 作品库Demo浏览 参数: param_str 第一个代表作品ID
  Report_Event_Type_WorksShare = 201,  // 作品库200-299 作品库Demo分享 参数: param_str 第一个代表作品ID
  Report_Event_Type_WorksDownload = 202 // 作品下载
}

export enum AdvertiseTypes {  //全局广告位表-类型-
  Pos_INVALID = 0, 
  Pos_Works_Home = 1,     //作品位广告 -- 作品精选推荐
  Pos_Knowledge = 2
}

export enum GlobalStateType {
  Global_No = 0,    // 无效
  Global_Yes = 1,   // 全局统计
}

export enum EditorTypes {  //知识文章 编辑器类型
  Editor_Type_0 = 0,    //其他
  Editor_Type_1 = 1,      //富文本
}

export enum CommentTypes {
    Comment_Type_0 = 0,       //无效
    Comment_Type_Works_Game_Deme = 1,     //作品库-GameDome
    Comment_Type_Lore_Essay = 2,     //知识库-文章
}

export enum ReplyModes {
  Invalid = 0,
  Comment = 1, // 评论
  ForComment = 2, // 回复评论
  ForReply = 3,// 回复回复
}

export enum UploadStateTypes {
  Wait = 0,
  Uploading = 1,
  Fail = 2,
  Success = 3,
  Ready = 4, // 可以设置cover
  Cover = 5
}

// 路由跳转后，附带操作
export enum RouteActions {
  None = 'none',
  Comment = 'comment', // 跳转到评论
  Play = 'play'
}

// 详情页打开模式 预览模式 ｜ 正常模式
export enum PageModes {
  Normal = 0,
  Preview = 1
}

export enum Language_Types {  //语言类型
  Language_Draft = 0, //不限专区
  Language_CN = 1,    //中文专区
  Language_EN = 2    //英文专区
}

export enum GlobalTypes {  //全局类型表-类型-    global_type_id  注意id分段
  Global_Type_INVALID = 0,
  Global_Type_Token = 1000,     //Token类型  sub ENUM_G_506 单项目每日消耗上限
  Global_Type_Token_Api_document = 1100,   //Token类 sub ENUM_G_508 api 文档
}

export enum ENUM_G_508 {  //全局类型表-Sub类型-  Global_Type_Token   type_id
  Token_Api_INVALID = 0,
  Token_Api_1 = 1,     //api 文档
}
export enum ENUM_G_506 {  //全局类型表-Sub类型-  Global_Type_Token   type_id
  Token_INVALID = 0,
  Token_project_day_limit = 1,     //单项目每日消耗上限
  Token_FX = 2,    //汇率
}

export enum Editor_Types {
  Tiny = 0,
  CherryMarkdown = 1
}
