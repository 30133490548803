<template>
  <div class="application-main">
    <SideMenu @onChange="changeActive" :active="activeMenu"/>
    <div class="top-main-container">
      <DemoManagement v-if="activeMenu === applicationMenuKey.demoManagement"/>
      <!-- <ApiKeyManager v-if="activeMenu === applicationMenuKey.apiKey"/> -->
      <incentiveAccount v-if="activeMenu === applicationMenuKey.incentiveAccount" @gotoOtherMenu="changeActive"/>
      <consumptionDetail v-if="activeMenu === applicationMenuKey.billingDetail" :queryDemoId="queryDemoId" />
    </div>
  </div>
</template>
  
<script>
import { applicationMenuKey } from '@/config/application'
import SideMenu from './components/sideMenu.vue'
import DemoManagement from './demoManager.vue';
import ApiKeyManager from './apiKeyManager.vue';
import incentiveAccount from './incentiveAccount.vue';
import consumptionDetail from './consumptionDetail.vue';


export default {
  name: 'post-demo-list',
  components: {  SideMenu, DemoManagement, ApiKeyManager, incentiveAccount, consumptionDetail },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      loading: false,
      applicationMenuKey,
      activeMenu: -1,
      queryDemoId: ''
    }
  },
  mounted () {
    let { tab = applicationMenuKey.demoManagement } = this.$route.query;
    console.log('now tab:', tab)
    if (isNaN(tab)) {
      tab = applicationMenuKey.demoManagement
    }
    this.activeMenu = Number(tab);
  },
  methods: {
    changeActive(key, query = {}) {
      if (this.activeMenu === key) return;
      if (key === applicationMenuKey.developmentDocuments) {
        this.$router.push({
          path: '/document/token'
        })
        return;
      }
      this.activeMenu = key;
      const { demo } = query;
      if (demo) {
        this.queryDemoId = demo
      }
      this.$router.replace({
        path: '/application/main',
        query: {
          tab: key,
          ...query
        }
      })
    }
  }
}
</script>
<style lang="scss">
.application-main{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  padding: 40px 0;
  gap: 56px;
  .top-main-container{
    width: 1424px;
  }
}
</style>