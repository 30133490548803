// 上报 && 举报
export const aegisId = '10086';

export const LogType = {
  custom: { logType: 'custom', name: '自定义测速' },
  event: { logType: 'event', name: '自定义事件' },
  log: { logType: 'log', name: '日志' },
  performance: { logType: 'performance', name: '页面测速' },
  pv: { logType: 'pv', name: '页面PV' },
  speed: { logType: 'speed', name: '接口和静态资源测速' },
  vitals: { logType: 'vitals', name: 'web vitals' }
};

export const LogLevel = {
  while: { level: 1, name: '白名单日志' },
  normal: { level: 2, name: '一般日志' },
  error: { level: 4, name: '错误日志' },
  promise: { level: 8, name: 'Promise 错误' },
  ajax: { level: 16, name: 'Ajax 请求异常' },
  js: { level: 32, name: 'JS 加载异常' },
  image: { level: 64, name: '图片加载异常' },
  css: { level: 128, name: 'css 加载异常' },
  console: { level: 256, name: 'console.error' },
  media: { level: 512, name: '音视频资源异常' },
  code: { level: 1024, name: 'retcode 异常' },
  aegis: { level: 2048, name: 'aegis report' },
  pv: { level: 4096, name: 'PV' },
  custom: { level: 8192, name: '自定义事件' },
  noPage: { level: 16384, name: '小程序 页面不存在' },
  websocket: { level: 32768, name: 'websocket错误' },
  jsBridge: { level: 65536, name: 'js bridge错误' }
};

export enum GateReportTypes {  //举报投诉类型
  Complaint_Sub_0 = 0,        //无效
  Complaint_Sub_Unfriendly = 1,        //不友善
  Complaint_Sub_SpamAds = 2,        //垃圾广告
  Complaint_Sub_Violation = 3,        //违法违规
  Complaint_Sub_Porn = 4,       //色情低俗
  Complaint_Sub_Infringement = 5,        //涉嫌侵权
  Complaint_Sub_underage = 6,        //涉未成年
  Complaint_Sub_kill = 7,        //白杀自残
  Complaint_Sub_FalseInformation = 8,        //不实信息
  Complaint_Sub_CyberViolence = 9,        //网络暴力
}

export const GateReportOptions = [
  { type: GateReportTypes.Complaint_Sub_Unfriendly, label: 'gateReport.hostility' },
  { type: GateReportTypes.Complaint_Sub_SpamAds, label: 'gateReport.advertising' },
  { type: GateReportTypes.Complaint_Sub_Violation, label: 'gateReport.violateLaws' },
  { type: GateReportTypes.Complaint_Sub_Porn, label: 'gateReport.eroticism' },
  { type: GateReportTypes.Complaint_Sub_Infringement, label: 'gateReport.suspectedInfringement' },
  { type: GateReportTypes.Complaint_Sub_CyberViolence, label: 'gateReport.cyberViolence' },
  { type: GateReportTypes.Complaint_Sub_underage, label: 'gateReport.underageInvolvement' },
  { type: GateReportTypes.Complaint_Sub_kill, label: 'gateReport.suicide' },
  { type: GateReportTypes.Complaint_Sub_FalseInformation, label: 'gateReport.falseInformation' },
]

export enum GateReportEnvironments {  //举报投诉场景类型
  Complaint_0 = 0,       //无效
  Complaint_Works = 100,        //作品
  Complaint_Works_Comment = 101,        //作品评论
  Complaint_Works_Reply = 102,        //作品回复  
  Complaint_Lore_Essay = 200,        //文章
  Complaint_Lore_Essay_Comment = 201,        //文章评论
  Complaint_Lore_Essay_Reply = 202,        //文章回复
}

