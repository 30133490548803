<template>
  <div class="guide-title">
    <!-- <div class="guide-title-around around-left"></div> -->
    <div class="gate-guide-title">{{ title }}</div>
    <div class="guide-title-around around-right">
      <div class="more" v-if="hasMore" @click="clickMore">
        {{ $t('views.guide.more') }}
        <div class="icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guide-title',
  props: {
    title: {
      type: String,
      default: 'ENENTS'
    },
    hasMore: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  mounted() {
     ;
  },
  methods: {
    clickMore() {
      this.$emit('onMore');
    }
  }
}

</script>

<style lang="scss" scoped>
.guide-title{
  width: 1200px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  .guide-title-around{
    flex: 1;
    height: 90px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    .more{
      color: #FFF;
      text-align: right;
      
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 15px;
      .icon{
        width: 24px;
        height: 24px;
        background: url("https://cdn.litgate.ai/gate/guide/icon_Union.png") no-repeat center center / cover;
      }
    }
  }
  .around-right{
    background: url('https://cdn.litgate.ai/gate/guide/guide_title_right.png') no-repeat;
    background-position: left top;
    background-size: cover;
  }
  .around-left{
    background: url('https://cdn.litgate.ai/gate/guide/guide_title_left.png') no-repeat;
    background-size: 100% 100%;
  }
  .gate-guide-title{
    // color: #fff;
    text-align: center;
    
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    flex: 0 0 auto;
    white-space: nowrap;
    padding: 0 12px 0 0;
    background: linear-gradient(0deg, #FFF 7.78%, #BFFFE7 92.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
