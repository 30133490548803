<template>
  <div class="upload-image-list-view">
    <el-upload
      class="upload-box"
      :file-list="uploadList"
      :show-file-list="false"
      :limit="uploadLimit"
      action=""
      :before-upload="beforeUpload"
      :on-change="onChange"
      :on-exceed="onExceed"
      accept="image/jpeg, image/png, image/jpg"
      multiple>
      <div class="upload-box">
        <div class="add-icon"></div>
        <div class="add-tips">Add img</div>
        <div class="info">{{ `(${uploadList.length}/${this.uploadLimit})` }}</div>
      </div>
    </el-upload>
    <div class="cover-box">
      <UploadStep :uploadModule="getCoverObject()" v-if="uploadList.length > 0" @onDelete="onDelete"/>
    </div>
    <div class="upload-list-wrap" ref="parentNode">
      <div class="left-btn" v-show="leftBtnVisible" @click="onLeft"></div>
      <div class="upload-list-container" ref="childNode" :style="{transform: 'translateX(-'+ getTranslateX() +'px)'}">
        <UploadStep :uploadModule="item" v-for="(item, index) in getUploadList()" :key="index" @setCover="setCover" @onDelete="onDelete"/>
      </div>
      <div class="right-btn" v-show="rightBtnVisible" @click="onRight"></div>
    </div>
  </div>
</template>
  
  <script>
  import UploadStep from './uploadStep.vue'
  export default {
    name: 'upload-list-view',
    props: {
      uploadList: {
        type: Array,
        default: () => []
      },
      uploadLimit: {
        type: Number,
        default: 20
      }
    },
    components: {
      UploadStep
    },
    data() {
      return {
        translateIndex: 0,
        uploadStepWidth: 166,
        gap: 32,
        rightBtnVisible: false,
        leftBtnVisible: false
      }
    },
    mounted() {
      this.countTranslateInfo()
    },
    methods: {
      onChange(file) {
        this.$emit('onChange', file)
      },
      onExceed() {
        console.log('onExceed')
        this.$message.error(this.$t('views.postProductImage.limitError'))
      },
      beforeUpload() {
        return false;
      },
      getTranslateX() {
        return this.translateIndex * this.uploadStepWidth + this.translateIndex * this.gap;
      },
      getCoverObject() {
        return this.uploadList.find(item => item.isCover);
      },
      getUploadList() {
        return this.uploadList.filter(item => !item.isCover)
      },
      countTranslateInfo() {
        const targetList = this.getUploadList() || [];
        if (targetList.length < 5) {
          this.leftBtnVisible = false;
          this.rightBtnVisible = false;
          return
        }
        const len = targetList.length;
        const listWidth = this.uploadStepWidth * len + Math.max(len - 1, 0) * this.gap;
        const translateX = this.getTranslateX();
        const parentWidth = this.$refs.parentNode.offsetWidth;
        if (listWidth - translateX > parentWidth) {
          this.rightBtnVisible = true;
        } else {
          this.rightBtnVisible = false;
        }
        if (this.translateIndex > 0) {
          this.leftBtnVisible = true;
        } else {
          this.leftBtnVisible = false;
        }
      },
      onRight() {
        this.translateIndex ++;
        this.countTranslateInfo();
      },
      onLeft() {
        if (this.translateIndex > 0) {
          this.translateIndex --;
          this.countTranslateInfo();
        }
      },
      setCover(target) {
        this.$emit('setCover', target)
      },
      onDelete(target) {
        console.log('onDelete', target)
        this.$emit('onDelete', target)
      }
    },
    watch: {
      uploadList() {
        this.countTranslateInfo()
      }
    },
    destroyed() {

    }
  }
  </script>
  <style lang="scss">
    .upload-image-list-view{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 96px;
      gap: 16px;
      .upload-box{
        width: 166px;
        height: 94px;
        background: #141414;
        min-width: 166px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .add-icon{
          width: 24px;
          height: 24px;
          background-image: url('@/assets/postProduct/add.png');
          background-size: cover;
        }
        .add-tips{
          color: #666;
          font-size: 14px;
          line-height: 22px;
          font-weight: 500;
        }
        .info{
          color: #666;
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }
      }
      .cover-box{
        min-width: 170px;
      }
      .upload-list-wrap{
        position: relative;
        overflow: hidden;
        .upload-list-container{
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          gap: 32px;
          transition: transform 0.3s linear ;
        }
        .left-btn{
          position: absolute;
          left: 6px;
          top: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background: url('@/assets/postProduct/icon_arrow_left.png');
          background-size: cover;
          cursor: pointer;
          z-index: 1000;
        }
        .right-btn{
          position: absolute;
          right: 6px;
          top: calc(50% - 10px);
          width: 20px;
          height: 20px;
          background: url('@/assets/postProduct/icon_arrow_right.png');
          background-size: cover;
          cursor: pointer;
          z-index: 1000;
        }
      }

    }
  </style>