<template>
  <div class="personalCenter">
    <div class="container">
      <div class="head"></div>
      <div class="main">
        <div class="nav-wrap">
          <div class="nav">
            <div v-for="item in mainMenus" :class="{ 'nav-item': true, 'active': navIndex == item.key }"
              :key="item.name" @click="onChangeNav(item.key)">{{ $t(item.name) }}
              <span v-if="item.key === PersonalNavTypes.Draft">（{{item.count}}）</span>
            </div>
          </div>
          <div class="rightMenu">
            <div v-for="item in rightMenus" :class="{ 'nav-item': true, 'active': navIndex == item.key }"
              :key="item.name" @click="onChangeNav(item.key)">{{ $t(item.name) }}
              <span v-if="item.key === PersonalNavTypes.Draft">（{{item.count}}）</span>
            </div>

          </div>
        </div>
        <div class="content">
          <div class="list" v-if="navIndex === PersonalNavTypes.Article">
            <div class="list-content">
              <template v-if="currentNavInfo.list.length > 0">
                <CardLong class="list-item" v-for="(item, index) in currentNavInfo.list" :key="index" :value="item"
                  @click="onClickCard" @clickLike="handleClickLike" @clickCollect="handleCollect"
                  @clickEdit="handleEdit" @clickDelete="handleDelete"
                  :options="['knowledge','collect','views','likes', 'comment', 'settings', 'time']"></CardLong>
              </template>
              <!-- 暂无内容 -->
              <template v-else>
                <div class="no-data-warp">
                  <div class="no-data-box">
                    <img src="@/assets/knowledge/no-data-icon.png" alt="">
                    <div>{{ $t('personCard.noData') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="list" v-if="navIndex === PersonalNavTypes.Favorite">
            <div class="list-content">
              <template v-if="currentNavInfo.list?.length > 0">
                <CardLong class="list-item" v-for="(item, index) in currentNavInfo.list" :key="index" :value="item"
                  @click="onClickCard" :options="['collect','views','likes', 'comment','avatar']" @clickLike="handleClickLike"
                  @clickCollect="handleCollect"></CardLong>
              </template>
              <!-- 暂无内容 -->
              <template v-else>
                <div class="no-data-warp">
                  <div class="no-data-box">
                    <img src="@/assets/knowledge/no-data-icon.png" alt="">
                    <div>{{ $t('personCard.noData') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="list" v-if="navIndex === PersonalNavTypes.Demo">
            <div class="list-content">
              <template v-if="currentNavInfo.list.length > 0">
                <CardLong class="list-item" v-for="(item, index) in currentNavInfo.list" :key="index" :value="item"
                  @click="onClickCard" @clickLike="handleClickLike" @clickCollect="handleCollect"
                  @clickEdit="handleEdit" @clickDelete="handleDelete" @clickComment="handleComment"
                  :options="['knowledge','collect','views','likes','settings','time','comment']"></CardLong>
              </template>
              <!-- 暂无内容 -->
              <template v-else>
                <div class="no-data-warp">
                  <div class="no-data-box">
                    <img src="@/assets/knowledge/no-data-icon.png" alt="">
                    <div>{{ $t('personCard.noData') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="list" v-if="navIndex === PersonalNavTypes.Image">
            <div class="list-content">
              <template v-if="currentNavInfo.list.length > 0">
                <CardLong class="list-item" v-for="(item, index) in currentNavInfo.list" :key="index" :value="item"
                  @click="onClickCard" @clickLike="handleClickLike" @clickCollect="handleCollect"
                  @clickEdit="handleEdit" @clickDelete="handleDelete" @clickComment="handleComment"
                  :options="['knowledge','collect','views','likes','settings','time','comment']"></CardLong>
              </template>
              <!-- 暂无内容 -->
              <template v-else>
                <div class="no-data-warp">
                  <div class="no-data-box">
                    <img src="@/assets/knowledge/no-data-icon.png" alt="">
                    <div>{{ $t('personCard.noData') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="list" v-if="navIndex === PersonalNavTypes.Following">
            <div class="list-content">
              <!-- <template v-if="currentNavInfo.list.length > 0"> -->
              <template v-if="currentNavInfo.list.length == 0">
                <div class="Following-nav">
                  <div v-for="(nav, index) in FolloMenus" :key="index">
                    <div :class="{'follo-item': true,'followers': index !== 0, 'active' : folloIndex === nav.key}" @click="currentFolloData(nav.key)">{{ $t(nav.name) }}</div>
                  </div>
                </div>
                <div v-if="FolloMenus[folloIndex].list.length > 0"> 
                  <div v-for="(item, index) in FolloMenus[folloIndex].list" :key="index">
                    <CardFollo :types="FolloMenus[folloIndex].key" :value="item" 
                    :options="['button']" @clickFollowing="handleFollo" @clickAvatar="gotoSeeGuest"></CardFollo>
                  </div>
                </div>
                <!-- 暂无内容 -->
                <div v-else>
                  <div class="no-data-warp no-follow">
                    <div class="no-data-box">
                      <img src="@/assets/knowledge/no-data-icon.png" alt="">
                      <div>{{ $t('personCard.noData') }}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="list" v-if="navIndex === PersonalNavTypes.Draft">
            <div class="list-content">
              <template v-if="currentNavInfo.list?.length">
                <CardLong class="list-item" v-for="(item, index) in currentNavInfo.list" :key="index" :value="item"
                  :options="['edit', 'delete', 'time']" @clickEdit="handleEdit" @clickDelete="handleDelete"></CardLong>
              </template>
              <!-- 暂无内容 -->
              <template v-else>
                <div class="no-data-warp">
                  <div class="no-data-box">
                    <img src="@/assets/knowledge/no-data-icon.png" alt="">
                    <div>{{ $t('personCard.noData') }}</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <PersonalInfo />
      </div>
    </div>
    <div class="delete-pop-mask" v-if="isShowDelete">
      <div class="delete-pop-content">
        <div class="delete-pop-top">
          <div class="delete-title">{{ $t('deleteDraft.title') }}</div>
          <div class="delete-clone" @click="cloneDelPop"></div>
        </div>
        <div class="delete-content" v-if="isDelDraft">{{ $t('deleteDraft.content') }}</div>
        <div class="delete-content" v-else>{{ $t('deleteDraft.content1') }}</div>
        <div class="delete-btn-warp">
          <div class="btn-cancel" @click="cloneDelPop">{{ $t('deleteDraft.cancel') }}</div>
          <div class="btn-confirm" @click="deleteDraftBox">{{ $t('deleteDraft.confirm') }}</div>
        </div>
      </div>
    </div>
    <ArtsDetails ref="artDetail"></ArtsDetails>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { queryArticleAudit, deleteArticle } from '@/api/article'
import CardLong from '@/components/card/cardLong.vue'
import CardFollo from '@/components/card/cardFollo.vue'
import UserAvatar from '@/components/userAvatar/index.vue'
import ArtsDetails from '@/components/artDialog/artsDetails.vue'
import { exportPV } from '@/utils/auth'
import { CollectTypes, LikeTypes, WorksTypes } from '@/config/stat'
import { deleteUserCollect, updateUserCollect, userLike, getUserCollect } from '@/api/userLikeCollect'
import { batchGetArticle, getPersonKnowledge } from '@/api/article';
import { batchGetGameWorks, getAllGameWorks, getUserWorks, getUserWorksDraft, deleteGameWorksAudit } from '@/api/gameWorks';
import PersonalInfo from './component/personalInfo.vue'
import { PersonalNavTypes, Follo, FolloStatus } from '@/config/personal'
import { getFollowers, getFollower, followOption } from '@/api/following'

@Component({
  name: 'PersonalCenter',
  components: {
    CardLong,
    UserAvatar,
    ArtsDetails,
    PersonalInfo,
    CardFollo
  },
})
class PersonalCenter extends Vue {
  public PersonalNavTypes = PersonalNavTypes;
  public mainMenus: any[] = [
    {
      name: 'personNav.favorite',
      list: [],
      count: 0,
      key: PersonalNavTypes.Favorite
    },
    {
      name: 'personNav.article',
      list: [],
      count: 0,
      key: PersonalNavTypes.Article
    },
    {
      name: 'personNav.image',
      list: [],
      count: 0,
      key: PersonalNavTypes.Image
    },
    {
      name: 'personNav.demo',
      list: [],
      count: 0,
      key: PersonalNavTypes.Demo
    },
    {
      name: 'personNav.follow',
      list: [],
      count: 0,
      key: PersonalNavTypes.Following
    }
  ]
  public rightMenus: any[] = [
    {
      name: 'personNav.draftBox',
      list: [],
      count: 0,
      key: PersonalNavTypes.Draft
    }
  ]

  public FolloMenus: any[] = [
    {
      name: 'following.following',
      list: [],
      count: 0,
      key: Follo.Following
    },
    {
      name: 'following.followers',
      list: [],
      count: 0,
      key: Follo.Followers
    },
  ]
  public folloIndex: number = Follo.Following

  public navIndex: any = PersonalNavTypes.Article

  public showEdit: boolean = false
  public isShowDelete: boolean = false;
  public isDelDraft: boolean = false;
  public cardInfo: any = {};

  mounted () {
    this.fetchKnowledgeData()
    this.fetchDraftBoxData()
    this.fetchCollect()
  
    exportPV()

    if (this.$route.query.tabIndex) {
      let tabIndex = parseInt(this.$route.query.tabIndex);
      this.onChangeNav(tabIndex);
    }

    //滚动到顶部
    window.scrollTo(0, 0);
  }

  public onChangeNav (key: PersonalNavTypes) {
    console.log('onChangeNav', key)
    this.navIndex = key;
    this.fetchCurrentData();
  }

  public fetchCurrentData() {
    if (this.navIndex === PersonalNavTypes.Article) {
      this.fetchKnowledgeData();
      return;
    }
    if (this.navIndex === PersonalNavTypes.Favorite) {
      this.fetchCollect();
      return;
    }
    if (this.navIndex === PersonalNavTypes.Image) {
      this.fetchWorks();
      return;
    }
    if (this.navIndex === PersonalNavTypes.Draft) {
      this.fetchDraftBoxData();
      return;
    }
    if (this.navIndex === PersonalNavTypes.Following) {
      this.fetchFollowingData()
      return;
    }
    if (this.navIndex === PersonalNavTypes.Demo) {
      this.$router.push({ path: '/application/main' });
      return;
    }

  }

  /* 点击卡片 */
  public onClickCard (e: any) {
    // console.log('携带的参数', e)
    if (e.works_id || e.works_type) {
      //美术创意并且审核通过
      if (e.works_type === WorksTypes.Works_Type_Art_Creativity) {
        this.$refs.artDetail?.openPop(e);
        return
      }
      window.open(`/gameworks/detail?id=${ e.works_id }`)
      return;
    }
    window.open(`/knowledge/detail?id=${ e.lore_essay_id }`)
  }

  public async fetchKnowledgeData () {
    const res: any = await getPersonKnowledge({
      lore_essay_type: 0,
      lore_essay_limit: 300,
      lore_essay_offset: 0,
    })

    const { code, data = {}, message = '' } = res
    console.log('getPersonKnowledge data', res)
    if (code === 0) {
      const { audit_base_data, lore_essay_total = 0 } = data
      this.saveListByNavKey(audit_base_data, PersonalNavTypes.Article, lore_essay_total);
      return;
    }
    this.$message.error('获取内容失败:' + message)
  }

  public async fetchDraftBoxData () {
    let list: any = [];
    //获取知识草稿
    const res: any = await queryArticleAudit({
      lore_essay_type: 0,
      lore_essay_limit: 300,
      lore_essay_offset: 0,
    })
    const { code, data = {}, message = '' } = res
    if (code !== 0) {
      this.$message.error('获取内容失败:' + message)
      return
    }
    const { audit_base_data, lore_essay_total = 0 } = data

    //获取作品草稿
    const res1: any = await getUserWorksDraft({
      works_type: 0,
      common_limit: 300,
      common_offset: 0
    })
    if (res1.code !== 0) {
      this.$message.error('获取内容失败:' + res1.message)
      return
    }
    const audit_base_data1 = res1.data.audit_base_data;
    console.log('getUserWorksDraft res', audit_base_data1)

    //合并知识 作品草稿
    list = [...audit_base_data, ...audit_base_data1]
    this.saveListByNavKey(list, PersonalNavTypes.Draft, list.length);
  }

  public async fetchWorks() {
    const res: any = await getUserWorks({
      common_limit: 300,
      common_offset: 0,
      works_type: WorksTypes.Works_Type_Art_Creativity,
    })
    const { code, data = {}, message = '' } = res
    if (code === 0) {
      const { audit_base_data, common_total = 0 } = data
      this.saveListByNavKey(audit_base_data, PersonalNavTypes.Image, common_total);
      return
    }
    this.$message.error('获取内容失败:' + message)
  }

  public async fetchCollect () {
    //初始化收藏列表
    let list = []

    const res: any = await getUserCollect({
      collect_type: CollectTypes.Collect_Type_Lore_Essay,
    })

    const res2: any = await getUserCollect({
      collect_type: CollectTypes.Collect_Type_Works_Game_Deme,
    })
    const data2 = res2.data;

    const { code, data = {}, message = '' } = res
    if (code !== 0){
      this.$message.error('获取内容失败:' + message)
      return;
    }

    //知识id列表
    let knowledgeIdList = [];
    //作品id列表
    let worksIdList = [];

    if (data && data.get_data.length > 0) {
      knowledgeIdList = data.get_data;
    }

    if (res2.data && res2.data.get_data.length > 0) {
      worksIdList = data2.get_data;
    }

    //无数据
    if (knowledgeIdList.length === 0 && worksIdList.length === 0) {
      this.saveListByNavKey(list, PersonalNavTypes.Favorite);
      return;
    }

    let gidList: any = [];
    let gidList2: any = [];
    knowledgeIdList.forEach((item: any) => {
      gidList.push(item.gid)
    })

    worksIdList.forEach((item: any) => {
      gidList2.push(item.gid)
    })

    //查询知识
    const res1: any = await batchGetArticle({
      lore_essay_id: gidList
    })

    //查询作品
    const works: any = await batchGetGameWorks({
      works_id: gidList2
    })
    // console.log('works', works)
    let list1 = res1.data.base_data;
    let list2 = works.data.base_data;
    list = list1.concat(list2)
    list.sort((a, b) => {
      return parseInt(b.up_time) - parseInt(a.up_time);
    })
    this.saveListByNavKey(list, PersonalNavTypes.Favorite, res1.data.base_data.length);

  }

  public saveListByNavKey(list: any[], key: PersonalNavTypes, total = 0) {
    let target = this.mainMenus.find((item) => item.key === key);
    if (!target) {
      target = this.rightMenus.find((item) => item.key === key);
    }
    target.list = list;
    target.count = total;
  }

  //点赞
  public async handleClickLike (e: any) {
    let like_type = LikeTypes.Like_Type_Lore_Essay;
    let gid = e.lore_essay_id;
    //作品类型
    if (e.works_title || e.works_type) {
      gid = e.works_id;
      like_type = LikeTypes.Like_Type_Works_Game_Deme;
    }

    let isLike = e.is_like ? 0 : 1;
    const params = {
      like_type,
      gid,
      is_like: isLike,
    }
    const result = await userLike(params)
    if (result.code === 0) {
      this.fetchCurrentData();
    }
    console.log('result', result)
  }

  //收藏
  public async handleCollect (e: any) {
    let gid = e.lore_essay_id;
    let collect_type = CollectTypes.Collect_Type_Lore_Essay;
    //作品类型
    if (e.works_title || e.works_type) {
      gid = e.works_id;
      collect_type = CollectTypes.Collect_Type_Works_Game_Deme;
    }
    const params = {
      collect_type,
      gid,
    }
    // console.log('params', params)
    let result: any;
    if (!e.is_collect) {
      result = await updateUserCollect(params)
    } else {
      result = await deleteUserCollect(params)
    }
    if (result.code === 0) {
      this.fetchCurrentData();
    }
  }

  //修改
  public handleEdit (e: any) {
    //作品类型
    if (e.works_title || e.works_type) {
      //美术创意
      if (e.works_type === WorksTypes.Works_Type_Art_Creativity) {
        this.$router.push({
          path: `/postproduct/image?id=${ e.works_id }`,
        })
      }
      //游戏demo
      if (e.works_type === WorksTypes.Works_Type_Game_Demo) {
        this.$router.push({
          path: `/application/editworks?id=${ e.works_id }`,
        })
      }
      return
    }
    //文章
    this.$router.push({
      path: `/editor/article?id=${ e.lore_essay_id }`,
    })
  }

  //删除草稿
  public async deleteDraftBox () {
    if (this.cardInfo.lore_essay_id) {
      this.delKnowledge()
    } else if (this.cardInfo.works_id) {
      this.delWorks()
    }
  }

  //删除知识
  public async delKnowledge () {
    const res = await deleteArticle({
      lore_essay_id: this.cardInfo.lore_essay_id
    })
    if (res.code === 0) {
      this.isShowDelete = false;
      if (this.isDelDraft) {
        this.fetchDraftBoxData()
      } else {
        this.fetchKnowledgeData()
      }
    }
  }

  //删除作品
  public async delWorks () {
    const res = await deleteGameWorksAudit({
      works_id: this.cardInfo.works_id
    })
    if (res.code === 0) {
      this.isShowDelete = false;
      if (this.isDelDraft) {
        this.fetchDraftBoxData()
      } else {
        this.fetchWorks()
      }
    }
  }

  //删除弹框
  public handleDelete (e: any) {
    console.log('handleDelete', e)
    this.isDelDraft = e.status === 0 ? true : false;
    this.isShowDelete = true;
    this.cardInfo = e;
  }

  public cloneDelPop () {
    this.isShowDelete = false;
  }

  //评论点击
  public handleComment (e: any) {
    console.log('handleComment', e)
    this.onClickCard(e)
  }

  get currentNavInfo() {
    let target = this.mainMenus.find(item => item.key === this.navIndex);
    if (target) {
      return target;
    }
    return this.rightMenus.find(item => item.key === this.navIndex)
  }

  public currentFolloData (key:number) {
    this.folloIndex = key;
    if (this.folloIndex === Follo.Following) {
      this.fetchFollowingData();
    } else if (this.folloIndex === Follo.Followers) {
      this.fetchFollowersData();
    }
  }

  //获取我关注的
  public async fetchFollowingData () {
    const res: any = await getFollower({
      comm_limit: 300,
      comm_offset: 0
    })
    console.log('fetchFollowingData res', res)
    const { code, data = {}, message = '' } = res
    if (code === 0) {
      const { info, comm_total = 0 } = data
      this.FolloMenus[Follo.Following].list = info;
      this.FolloMenus[Follo.Following].count = comm_total;
      return
    }
    this.$message.error('获取内容失败:' + message)
  }

  //获取关注我的
  public async fetchFollowersData () {

    const res: any = await getFollowers({
      comm_limit: 300,
      comm_offset: 0
    })
    console.log('getFollowers res', res)
    const { code, data = {}, message = '' } = res
    if (code === 0) {
      const { info, comm_total = 0 } = data
      this.FolloMenus[Follo.Followers].list = info;
      this.FolloMenus[Follo.Followers].count = comm_total;
      return;
    }
    this.$message.error('获取内容失败:' + message)
  }

  //关注-取消关注
  public async handleFollo(userId: string, operate: number, types: number) {
    const res: any = await followOption({
      user_id: userId,
      operate
    })
    console.log('handleFollo res', res)

    if (res.code === 0) {
      if (types === Follo.Following) {
        this.fetchFollowingData();
      } else if (types === Follo.Followers){
        this.fetchFollowersData();
      }
    }
  }
  //跳转个人空间
  public gotoSeeGuest(id:string) {
    this.$router.push({ path: '/personal/guest', query: { id } })
  }

}
export default PersonalCenter
</script>

<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.personalCenter {
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;

  >.container {
    width: 100%;
    min-height: 100%;
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;

    >.head {
      width: 100%;
      height: 462px;
      background: url(@/assets/knowledge/user-center-head-bg1.png) no-repeat center;
      // background-size: cover;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 80%;
        // background: linear-gradient(to bottom, #000, transparent);
        top: 0;
        left: 0;
      }
    }

    .main {
      // width: 1712px;
      min-height: calc(100vh - 86px);
      margin-left: 104px;
      margin-right: 104px;
      padding-right: 476px;
      position: relative;

      .nav-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 0 24px 0;

        .nav {
          display: flex;
          align-items: center;

        }
        .nav-item+.nav-item {
          margin-left: 50px;
        }

        .nav-item {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active {
            color: #00fff6;
          }

          color: $gray_9;
          font-size: 20px;
          cursor: pointer;
        }

        .rightMenu {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row;
        }
      }

      >.content {
        width: 100%;

        >.list {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .list-content {
            width: 100%;
            // overflow: hidden;

            .no-data-warp {
              width: 100%;
              border-top: 1px solid rgba(255, 255, 255, 0.15);

              .no-data-box {
                width: 100%;
                text-align: center;
                margin-top: 100px;

                &>img {
                  width: 160px;
                  height: 160px;
                }

                &>div {
                  color: #666;
                  font-size: 16px;
                  font-weight: 500;
                  margin-top: 13.3px;
                }
              }
            }
            .no-follow{
              border-top: 0
            }
            .Following-nav{
              display: flex;
              align-items: center;
              color: var(--text-text-3666, #666);
              font-size: 16px;
              font-weight: 400;
              padding-left: 8px;
              border-top: 1px solid rgba(255, 255, 255, 0.15);
              .follo-item{
                cursor: pointer;
                margin-top: 24px;
              }
              .followers{
                margin-left: 24px;
              }
              .active{
                color: var(--Brand, #00FFF6);
              }
            }
          }
        }
      }
    }
  }

  .delete-pop-mask {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.90);

    .delete-pop-content {
      width: 480px;
      height: 204px;
      padding: 32px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #141414;
      color: #999;
      font-size: 14px;
      font-weight: 500;

      .delete-pop-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .delete-title {
          color: #DDD;
          font-size: 16px;
        }

        .delete-clone {
          width: 16px;
          height: 16px;
          background: url(@/assets/knowledge/close_info.png) no-repeat center;
          background-size: 100% 100%;
          cursor: pointer;
        }
      }

      .delete-content {
        margin-top: 16px;
        margin-bottom: 24px;
      }

      .delete-btn-warp {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-cancel {
          width: 81px;
          height: 32px;
          color: #666;
          text-align: center;
          line-height: 32px;
          border: 1px solid #666;
          cursor: pointer;
        }

        .btn-confirm {
          width: 90px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          background: #00FFF6;
          color: #000;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  }
}

.layout-gate-footer {
  bottom: 0;
}

@media screen and (min-width: 1920px) {
  .personalCenter>.container .main {
    width: 1712px;
  }

  .personalCenter>.container .head {
    background: url(@/assets/knowledge/user-center-head-bg1.png) no-repeat center;
    background-size: 100% auto;
  }
}

@media screen and (max-width: 1919px) {
  .personalCenter>.container .main {
    width: 1472px;
  }

  .personalCenter>.container .head {
    background: url(@/assets/knowledge/user-center-head-bg1.png) no-repeat center;
    background-size: cover;
  }
}

@media screen and (max-width: 1679px) {
  .personalCenter>.container .main {
    width: 1072px;
  }

  .personalCenter>.container .head {
    background: url(@/assets/knowledge/user-center-head-bg1.png) no-repeat center;
    background-size: cover;
  }
}
</style>