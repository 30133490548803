<template>
  <div>
    <div class="game-works-detail" v-if="!isDelete">
        <div class="knowledge-spacer" ref="spacerEle"></div>
        <div class="game-works-spacer"></div>
        <div class="game-works-container">
          <div class="float-box">
            <AnchorActionBar
              :isLiked="isLike"
              :isFavorite="isCollect"
              :likeCount="statData.like_num"
              :commentCount="statData.comment_num"
              :showStatTool="showStatTool"
              :showTopBtn="showTopBtn"
              classType="gameWorks"
              :showAnchorComment="showAnchorComment"
              @gotoComment="gotoComment"
              @like="likeFunc"
              @onSendComment="onSendComment"
              @collect="collectFunc" @share="shareFunc" />
          </div>
          <div class="detail-container">
            <div class="title">{{ title }}</div>
            <Statistics :seeCount="statData.browse_num" :likeCount="statData.like_num" :isLiked="isLike" @like="likeFunc"/>
            <div class="style-spacer"></div>
            <div class="content">
              <VideoCarousel :movie="medias.movie" :picture="medias.pictures" />
              <div class="introduction-title" v-if="profiles">{{ $t('views.gameWorksDetail.introduction') }}</div>
              <div class="introduction-content" v-html="profiles"></div>
            </div>
            <div class="style-spacer"></div>
            <StatInfoTool
              :isLiked="isLike" :isFavorite="isCollect" :likeCount="statData.like_num" :commentCount="statData.comment_num" :postTime="lastUpdateTime" :worksId="worksId"
              @like="likeFunc" @collect="collectFunc" @share="shareFunc"  @onShowAnchorBar="onShowAnchorBar" @onReport="onReport"
              />
            <div class="tag">
              <StatTag :tags="baseData.label" />
            </div>
            <div class="style-spacer" ref="commentAnchor"></div>
            <CommentView :gId="worksId" :commentType="CommentTypes.Comment_Type_Works_Game_Deme" ref="commentView" @onShowAnchorComment="onShowAnchorComment"/>
            <div class="style-spacer" :style="{height: '36px'}"></div>
          </div>
          <div class="info-container">
            <ArticleAuthorCard  :userId="authorId"/>
            <div class="style-spacer"></div>
            <DemoInfoCard :gameDemo="otherData.game_demo" :gameWebUrl="otherData.game_web_url" :cover="medias.cover" :platform="baseData.platform" :version="worksVersion" :upTime="lastUpdateTime" :downloadCount="statData.download_num" @downloadReport="gateReportEvent" :canDownload="checkDownload()"/>
            <div class="style-spacer"></div>
            <articleRelevant :list="otherData.lore_essay_id" :title="showTitle('views.gameWorksDetail.related')" v-if="otherData.lore_essay_id.length > 0"/>
          </div>
        </div>
    </div>
    <div class="game-works-detail-not-found" v-if="isDelete">
      <pageNotFound />
    </div>
  </div>
</template>
  
<script>
import Statistics from '@/components/statStatus/statistics.vue'
import StatTag from '@/components/statStatus/statTag.vue'
import StatInfoTool from '@/components/statStatus/statInfoTool.vue'
import AnchorActionBar from '@/components/statStatus/anchorActionBar.vue'
import tinyView from '@/components/gateEditor/tinyView.vue'
import ArticleAuthorCard from '@/components/card/authorCard.vue';
import pageNotFound from '@/components/exception/pageNotFound.vue'
import articleRelevant from '@/components/relatedList/relevantArticle.vue'
import VideoCarousel from './component/videoCarousel.vue';
import DemoInfoCard from './component/demoInfoCard.vue'
import CommentView from '@/components/comment/index.vue'
import { getGameWorks } from '@/api/gameWorks'
import { CollectTypes, GateReportTypes, LikeTypes, EditorTypes, CommentTypes, PageModes, RouteActions } from '@/config/stat';
import { DownloadState } from '@/config/gameWorks';
import { exportPV } from '@/utils/auth'
import { gateReport } from '@/api/gateReport'
import { deleteUserCollect, updateUserCollect, userLike } from '@/api/userLikeCollect';
import { GateReportEnvironments } from '@/config/report'
export default {
  name: 'game-works-detail',
  components: {
    Statistics,
    tinyView,
    StatInfoTool,
    StatTag,
    ArticleAuthorCard,
    VideoCarousel,
    pageNotFound,
    DemoInfoCard,
    articleRelevant,
    AnchorActionBar,
    CommentView
  },
  props: {},
  data() {
    return {
      CommentTypes,
      worksId: '', // 作品id
      authorId: '',
      type: PageModes.Normal, // 页面浏览类型 preview | normal
      title: '', // 作品标题
      profiles: '', // 简介
      content: '', //内容
      like: 0, // 点赞数
      see: 0, // 浏览数
      isLike: false, // 是否点赞 
      isCollect: false, // 是否收藏
      isDelete: false, // 是否删除 : 当查不到数据时 => isDelete =true
      lastUpdateTime: Date.now(), // 最后更新时间
      worksVersion: '',
      releaseTime: 0, // 发布时间
      statData: {
        browse_num: 0, // 浏览次数
        collect_num: 0, // 点赞次数
        like_num: 0, // 收藏次数
        share_num: 0, // 分享次数
        comment_num: 0,
        download_num: 0
      },
      otherData: {
        download: 0,
        game_demo: '',
        tools_id: [],
        lore_essay_id: [],
      },
      medias: {
        cover: '',
        movie: [],
        pictures: [] 
      }, // 宣传相关
      baseData: {
        platform: [],
        label: [],
      },
      editorType: EditorTypes.Editor_Type_0,
      showStatTool: true,
      showTopBtn: true,
      showAnchorComment: false
    }
  },
  mounted() {
      const { id, type = PageModes.Normal, actionType = RouteActions.None } = this.$route.query;
      if (id) {
        this.worksId = id
        this.type = type
        this.getGameWorksData()
      }
      this.gateReportBrowse();
      this.stickAnchorElement();
      if (actionType === RouteActions.Comment) {
        setTimeout(() => {
          this.gotoComment();
        } , 1000);
        return;
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
  },
  methods: {
    onReport() {
      ReportModule.open({
        gId: this.worksId,
        env: GateReportEnvironments.Complaint_Works
      });
    },
    onSendComment(content) {
      this.$refs.commentView.onSendComment(content)
    },
    gotoComment() {
      window.scrollTo({
        top: (this.$refs.commentAnchor.offsetTop  || 0) - 60,
        behavior: 'smooth'
      });
    },
    onShowAnchorBar(boo) {
      this.showStatTool = boo;
    },
    onShowAnchorComment(boo) {
      this.showAnchorComment = boo;
    },
    showTitle(target) {
      return this.$t(target);
    },
    async getGameWorksData() {
      this.getGameWorksDetail();
    },
    async getGameWorksDetail() {
      if (!this.worksId){
        return;
      }
      const result = await getGameWorks({
        works_id: this.worksId
      })
      console.log('detail:', result);
      if(result.code === 0) {
        const { data: { base_data } } = result;
        console.log('base_data ====>', base_data)
        if (base_data) {
          this.parseArticleData(base_data);
        } else {
          this.isDelete = true;
        }
      }
    },
    parseArticleData(data) {
      const { profiles, works_title="Unnamed game", works_version, stat_data = {}, is_collect, is_del_status, is_like, up_time = 0, release_time = 0, user_id, base_data = {}, other_data = {}, editor_type } = data;
      this.title = works_title;
      this.profiles = profiles;
      this.worksVersion = works_version;
      this.releaseTime = release_time;

      // 统计数据
      this.statData = stat_data;
      this.isLike = is_like;
      this.isCollect = is_collect;
      this.isDelete = is_del_status;
      this.lastUpdateTime = Number(up_time) * 1000;
      this.authorId = user_id;

      // 作品基础数据
      this.baseData = base_data;
      this.otherData = other_data;
      console.log('lore_essay_id:', this.otherData.lore_essay_id)
      this.medias = other_data?.medias || {};

      // 其他信息
      this.editorType = editor_type;
    },
    // 点赞
    async likeFunc(isLike){
      const params = {
        like_type: LikeTypes.Like_Type_Works_Game_Deme,
        gid: this.worksId,
        is_like: isLike
      };
      const result = await userLike(params);
      if (result.code === 0) {
        this.getGameWorksData()
      }
    },
    async collectFunc(isCollect) {
      const params ={ 
        collect_type: CollectTypes.Collect_Type_Works_Game_Deme,
        gid: this.worksId
      }
      let result
      if (isCollect) {
        result = await updateUserCollect(params)
      } else {
        result = await deleteUserCollect(params)
      }
      if (result.code === 0) {
        this.getGameWorksData()
      }
    },
    shareFunc() {
      this.gateReportShare();
    },
    async gateReportBrowse () {
      gateReport({
        event_type: GateReportTypes.Report_Event_Type_WorksBrowse,
        param: [ Math.floor(Date.now() / 1000) ],
        param_str: [
          this.worksId,
          this.$route.path,
          this.$route.fullPath,
        ],
      }).catch(e=> {});
      exportPV([
        this.worksId,
        this.$route.path,
        this.$route.fullPath
      ]);
    },
    async gateReportEvent (url) {
      // 上报接口不能报错。 上报成功download num才会变化，才需要刷新数据，
      gateReport({
        event_type: GateReportTypes.Report_Event_Type_WorksDownload,
        param: [ Math.floor(Date.now() / 1000) ],
        param_str: [
          this.worksId,
          this.$route.path,
          this.$route.fullPath,
          url
        ],
      }).then(res => {
        this.getGameWorksData();
      }).catch(e=> {});
    },
    gateReportShare () {
      gateReport({
        event_type: GateReportTypes.Report_Event_Type_WorksShare,
        param: [ Math.floor(Date.now() / 1000) ],
        param_str: [
          this.worksId,
          this.$route.path,
          this.$route.fullPath,
          window.location.href
        ],
      }).catch(e=> {});
    },
    checkDownload() {
      console.log('this.otherData.download == DownloadState.Download_Yes', this.otherData.download == DownloadState.Download_Yes)
      return this.otherData.download == DownloadState.Download_Yes
    },
    stickAnchorElement() {
      const self = this;
      var stickyElement = this.$refs.spacerEle;
      this.observer = new IntersectionObserver(function(entries) {
        console.log('entries:', entries);
        entries.forEach(function(entry) {
          if (entry.intersectionRatio <= 0) {
            self.showTopBtn = true;
          } else {
            self.showTopBtn = false;
          }
        });
      });
      this.observer.observe(stickyElement);
    }
  }
}
</script>
<style lang="scss">
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;
@media screen and (min-width: 1920px) {
  .game-works-detail{
    min-width: 1920px;
  }
}
@media screen and (max-width: 1919px) {
  .game-works-detail{
    min-width: 1680px;
  }
}
@media screen and (max-width: 1679px) {
  .game-works-detail{
    min-width: 1280px;
  }
}
.game-works-detail {
  min-height: calc(100vh - 86px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .game-works-spacer {
      width: 100%;
      height: 78px;
  }
  .game-works-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 32px 104px 0 104px;
      @media screen and (min-width: 1920px) {
        .detail-container{
          width: 1280px;
        }
        .info-container{
          width: 376px;
          margin-left: 56px;
        }
      }
      @media screen and (max-width: 1919px) {
        .detail-container{
          width: 1100px;
        }
        .info-container{
          width: 323px;
          margin-left: 48px;
        }
      }
      @media screen and (max-width: 1679px) {
        .detail-container{
          width: 796px;
        }
        .info-container{
          width: 240px;
          margin-left: 35px;
        }
      }
      .detail-container {
          min-height: 300px;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          .title {
              color: #ddd;
              font-size: 34px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
          }
          .style-spacer {
              width: 100%;
              height: 56px;
          }
          .lead {
              color: #999;
              /* Body3 */
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
          }
          .lead::before {
              color: #ddd;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 150%;
              border-left: 2px solid #ddd;
              padding: 0 8px 0 3px;
              content: 'lead';
          }
          .content {
              width: 100%;
              // overflow: hidden;
              .introduction-title{
                margin-top: 40px;
                color: #999;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 30px */
              }
              .introduction-content{
                color: #999;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; 
                white-space: pre-line;
                margin-top: 16px;
                word-wrap: break-word;
              }
          }
          .tag {
              margin-top: 16px;
              width: 100%;
          }
      }

      .info-container {
        min-height: 400px;
        .style-spacer{
          height: 36px;
        }
      }
  }
}
.game-works-detail-not-found{
  width: 100vw;
  height: 100vh;
}
</style>