<template>
  <div class="card-follo-wrap" @click="onClick">
    <div class="card">
      <div class="user-info-warp">
        <div class="user-avatar-warp" @click="onClickAvatar">
          <UserAvatar :src="value.data.user_url" :userName="value.data.user_name"></UserAvatar>
        </div>
        <div class="user-info">
          <div class="user-name" @click="onClickAvatar">{{ value.data.user_name }}</div>
          <div class="user-profiles">{{ value.data.user_profiles }}</div>
          <div class="works-info">
            <!-- <span>{{ value.data.stat_data.lore_essay_posts_num }} {{ $t('following.submissions') }}</span>
            <span class="info-item">{{ value.data.stat_data.lore_essay_browse_self_num }} {{ $t('following.views') }}</span>
            <span class="info-item">{{ value.data.stat_data.lore_essay_like_num }} {{ $t('following.likes') }}</span> -->
          </div>
        </div>
      </div>
      <div v-if="options.indexOf('button') >= 0">
        <div class="follo-warp" v-if="types === follo.following">
          <div class="follo-button" @click="onClickFollow(folloStatus.unfollow)">
            <img src="@/assets/personal/following/following-icon.png" alt="">
            <div>{{ $t('following.unfollow') }}</div>
          </div>
        </div>
        <div class="follo-warp" v-else-if="types === follo.followers">
          <div class="follo-button" @click="onClickFollow(folloStatus.unfollow)" v-if="value.mutuals">
            <img src="@/assets/personal/following/following-icon.png" alt="">
            <div>{{ $t('following.unfollow') }}</div>
          </div>
          <div class="follo-button" @click="onClickFollow(folloStatus.follow)" v-else>
            <img src="@/assets/personal/following/mutual-following-icon.png" alt="">
            <div>{{ $t('following.mutualFollowing') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { normalizeDate, formatDate } from '@/utils/format'
import { abbreviateNumber } from '@/utils/statFormat'
import UserAvatar from '@/components/userAvatar/index.vue'
import { Follo, FolloStatus } from '@/config/personal'

@Component({
  name: 'CardLong',
  components: {
    UserAvatar
  },
})
class CardLong extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
  })
  public value:any

  @Prop({
    default: () => ([])
  })
  public options:any

  @Prop({
    required: true,
    default: () => ({}),
  })
  public types:any

  public follo = {
    following: Follo.Following,
    followers: Follo.Followers,
  }

  public folloStatus = {
    follow: FolloStatus.follow,
    unfollow: FolloStatus.unfollow,
  }

  mounted() {
    
  }

  public onClick () {
    this.$emit('click', this.value)
  }

  public onClickFollow(operate:number) {
    this.$emit('clickFollowing', this.value.data.user_id, operate, this.types);
  }

  public onClickAvatar() {
    this.$emit('clickAvatar', this.value.data.user_id)
  }

}

export default CardLong
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.card-follo-wrap {
  width: 100%;
  padding: 40px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  .card{
    width: 100%;
    height: 164px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-info-warp{
      display: flex;
      align-items: center;
      .user-avatar-warp{
        cursor: pointer;
      }
      .UserAvatar{
        width: 64px !important;
        height: 64px !important;
        border-radius: 50%;
      }
      .user-info{
        color: var(--text-text-3666, #666);
        font-size: 14px;
        font-weight: 400;
        margin-left: 16px;
        .user-name{
          color: var(--text-text-1-ddd, #DDD);
          font-size: 24px;
          font-weight: 400;
          cursor: pointer;
        }
        .user-profiles{
          margin-top: 8px;
        }
        .works-info{
          margin-top: 4px;
          .info-item{
            margin-left: 16px;
          }
        }
      }
    }
    .follo-warp{
      .follo-button{
        min-width: 100px;
        height: 32px;
        border: 1px solid var(---border-1, rgba(255, 255, 255, 0.30));
        padding: 5px 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(---border-1, rgba(255, 255, 255, 0.30));
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        &>img{
          width: 15px;
          height: 15px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>