import request from '@/utils/request';

const LsCosBaseUrl = `/api/basesys/user`;

export async function initLsCosUpload(data: object) {
  return request({
    url: `${LsCosBaseUrl}/inituploadlscos`,
    method: 'POST',
    data,
  });
}

