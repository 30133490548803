<template>
  <div class="user-avatar-warp">
    <div class="user-avatar">
      <UserAvatar class="avatar" :src="userInfo.user_url" :userName="userInfo.user_name" :size="size"></UserAvatar>
      <div class="user-menu-warp">
        <div class="user-menu-box">
          <img class="triangle" src="@/assets/triangle.png" alt="">
          <div class="personal-warp menu-item" @click="toPath('/personal')">
            <div class="menu-icon"></div>
            <div>{{ $t('personHome.center') }}</div>
          </div>
          <div class="logout-warp menu-item" @click="logout">
            <div class="menu-icon"></div>
            <div>{{ $t('personHome.logout') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Logo from '@/components/logo/index.vue';
import { clearUserSession } from '@/utils/storage';
import UserAvatar from '@/components/userAvatar/index.vue'
import { UserModule } from '@/store/modules/user'
import { mapGetters } from 'vuex';

export default {
  name: 'publish-user',
  components: { Logo, UserAvatar },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      personNav: [
        { label: 'personHome.center', key: 'center' },
        { label: 'personHome.logout', key: 'logout' },
      ]
    }
  },
  mounted () {
    this.fetchUserData();
  },
  methods: {
    async fetchUserData () {
      if (this.userInfo && this.userInfo.user_url) return
      UserModule.updateUserInfo();
    },
    onSubmit () {

    },
    gotoHome () {
      this.$router.push({ path: '/gameworks/home' })
    },
    gotoPerson () {
      this.$router.push({ path: '/personal/home' })
    },
    toPath (path) {
      this.$router.push({ path })
    },
    logout () {
      clearUserSession();
      window.location.href = `${ location.origin }/account/login`;
    },
  },
  computed: {
    ...mapGetters(['userInfo'])
  }
}
</script>
<style lang="scss">
.user-avatar-warp {
  position: relative;

  .user-avatar {
    cursor: pointer;

    &>img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }

    &:hover {
      .user-menu-warp {
        display: block;
      }
    }
  }

  .user-menu-warp {
    display: none;
    position: absolute;
    width: 160px;
    height: 105px;
    left: -63px;
    top: 22px;

    .user-menu-box {
      position: relative;
      padding: 8px;
      margin-top: 20px;
      width: 160px;
      height: 86px;
      background: #141414;
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);

      .triangle {
        width: 10px;
        height: 6px;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
      }

      .menu-item {
        color: #666;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 6px 8px;
        cursor: pointer;

        .menu-icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }

      .personal-warp {
        .menu-icon {
          background: url('@/assets/user-profile-icon.png') no-repeat;
          background-size: 100% 100%;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.10);
          color: #DDD;

          .menu-icon {
            background: url('@/assets/profile-icon-hover.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }

      .logout-warp {
        .menu-icon {
          background: url('@/assets/logout-icon.png') no-repeat;
          background-size: 100% 100%;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.10);
          color: #DDD;

          .menu-icon {
            background: url('@/assets/logout-icon-hover.png') no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>