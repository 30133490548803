<template>
  <div class="knowledge-category">
    <div class="title">{{$t('views.knowledge.category')}}</div>
    <div class="content">
      <div :class="{'category-item': true, active: insideIndex == index}" :key="index" v-for="(item, index) in menu"
        @click="onCheckCategory(index)">
        <div class="icon">
          <img :src="insideIndex == index ? item.activeIcon : item.icon" alt="" />
        </div>
        <div class="text">{{ $t(item.name) }}</div>
        <!-- <div class="text">{{ $t(item.name) }}{{ `(${formatCount(item.count)}) `}}</div> -->
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { abbreviateNumber } from '@/utils/statFormat'

@Component({
  name: 'Category',
  components: {},
})
class Category extends Vue {
  @Prop({
    default: () => ([]),
  })
  public menu

  @Prop({
    required: true,
    default: 0,
  })
  public activeIndex

  public insideIndex = 0

  mounted () {
    this.insideIndex = this.activeIndex
  }

  onCheckCategory (val: any) {
    this.insideIndex = val

    this.$emit('change', val)
  }

  @Watch('activeIndex')
  onValueChanged (val: any) {
    this.insideIndex = val
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }
}

export default Category
</script>
  
<style lang="scss" scoped>
.knowledge-category {
  width: 272px;
  height: 100%;
  background-color: #000;
  flex-shrink: 0;

  .title {
    width: 100%;
    font-size: 24px;
    color: #ddd;
  }

  .content {
    width: 100%;
    margin-top: 24px;

    .category-item+.category-item {
      margin-top: 8px;
    }

    .category-item {
      width: 100%;
      padding: 9px 8px;
      display: flex;
      cursor: pointer;
      position: relative;

      &:hover {
        background-color: #141414;
      }

      &.active {
        background-color: #141414;

        .text {
          color: #ddd;
        }
      }

      .icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        color: #666;
        margin-left: 10px;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 22px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
