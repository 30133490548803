<template>
   <div class="gate-state-more-dropdownList">
      <div class="link-options" @click="onReport">
        <div class="text">
          {{ $t('comment.report') }}
        </div>
      </div>
    </div>
</template>
  
<script lang="ts">
import { clearAndLogout } from '@/utils/request';
import { mapGetters } from 'vuex';

export default {
  name: 'gate-comment-more-view',
  components: {
  },
  props: {
    isSelf: {
      type: Boolean,
      default: true
    },
    commentText: {
      type: String,
      default: ''
    },
  },
  data() {
    return {}
  },
  methods: {
    onReport() {
      if(this.userInfo.user_id) {
        this.$emit('onReport')
        return;
      }
      clearAndLogout()
    }
  },
  computed: {
      ...mapGetters(['userInfo'])
    },
}
</script>
  
<style lang="scss" scoped>
 .gate-state-more-dropdownList{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      background:  #141414;
      padding: 4px 8px;
      border: none;
      width: 80px;
      .link-options{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        padding: 1px 0;
        .icon{
          width: 20px;
          height: 20px;
        }
        .link-icon{
          background: url('@/assets/knowledge/detail/icon_link.png') no-repeat;
          background-size: 100%;
        }
        
        .text{
          overflow: hidden;
          color: #666;
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
        
      }
      .link-options:hover{
        .link-icon{
          background: url('@/assets/knowledge/detail/icon_link_active.png') no-repeat;
          background-size: 100%;
        }
        .text{
          color: #ddd;
        }
      }
    }
</style>