<template>
  <div class="waterfall" :style="{ height: waterfallHeight + 'px' }">
    <div class="loading-warp" v-if="initData">数据加载中 <span>...</span></div>
    <div v-else>
      <div class="image-item-warp" v-for="(item, index) in dataList" :key="index" :style="{ left: item.left + 'px', top: item.top + 'px' }">
        <div class="image-item" @click="onClick(item, index)">
          <div class="label-warp">
            <div class="label-item" v-for="(label, indexs) in item.base_data.label" :key="indexs">{{ label }}</div>
          </div>
          <div class="image-container">
            <img :src="item.other_data.medias.cover" alt="">
          </div>
          <div class="bottom-warp">
            <div class="bottom-container">
              <div class="author-info-warp">
                <div class="avatar-warp">
                  <!-- <img :src="item.user_data.user_url" alt=""> -->
                  <UserAvatar :src="item.user_data.user_url" :userName="item.user_data.user_name"></UserAvatar>
                </div>
                <div class="works-name">{{ item.user_data.user_name }}</div>
              </div>
              <div class="works-title">{{ item.works_title }}</div>
              <div class="option-warp">
                <!-- 浏览 -->
                <div class="option-item">
                  <div class="icon">
                    <img src="@/assets/statIcon/icon_browse.png" />
                  </div>
                  <div class="count">{{formatStat(item.stat_data && item.stat_data.browse_num)}}</div>
                </div>
                <!-- 点赞 -->
                <div class="option-item">
                  <div class="icon">
                    <img src="@/assets/statIcon/icon_likes.png" />
                  </div>
                  <div class="count">{{formatStat(item.stat_data && item.stat_data.like_num)}}</div>
                </div>
                <!-- 评论 -->
                <div class="option-item">
                  <div class="icon">
                    <img src="@/assets/statIcon/icon_comment.png" />
                  </div>
                  <div class="count">{{formatStat(item.stat_data && item.stat_data.comment_num)}}</div>
                </div>
                <!-- 下载 -->
                <!-- <div class="option-item">
                  <div class="icon">
                    <img src="@/assets/statIcon/icon_download.png" />
                  </div>
                  <div class="count">{{formatStat(item.stat_data && item.stat_data.download_num)}}</div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loading-warp" v-if="isLoading">数据加载中 <span>...</span></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { abbreviateNumber } from '@/utils/statFormat'
import UserAvatar from '@/components/userAvatar/index.vue'

@Component({
  name: 'CardLong',
  components: {
    UserAvatar,
  }
})
class CardLong extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
  })

  public dataList: any;
  public options: any;
  public columnWidth = 300;
  public gutter = 16;
  public columns = 4;
  public waterfallHeight = 0;
  public windowWidth:any;
  public initData = true;
  public isLoading = false;

  mounted() {
    this.updateLayout();
    window.addEventListener('resize', this.handleResize);
  }
  
  public onClick (item: any, index: number) {
    item.index = index;
    this.$emit('click', item)
  }

  public updateLayout() {
    let containerWidth:number = this.$el.offsetWidth;
    containerWidth = containerWidth - 210

    const columnWidth = (containerWidth - (this.columns - 1) * this.gutter) / this.columns

    // 将每个图片的加载操作封装成 Promise
    const promises = this.dataList.map((item:any, index:number) => {
      return new Promise((resolve:any, reject:any) => {
        const img = new Image()
        img.src = item.other_data.medias.cover
        img.onload = () => {
          const height = img.height * columnWidth / img.width
          this.dataList[index].height = height
          resolve()
        }
        img.onerror = () => {
          // 加载失败时，将图片高度设置为 0
          this.dataList[index].height = 0
          resolve()
        }
      })
    })

    let sortedCount = 0 // 统计已排序完成的图片数量
    // 等待所有 Promise 完成后，再计算每个图片的位置
    Promise.all(promises).then(() => {
      let columnsHeight = new Array(this.columns).fill(0)
      this.dataList.forEach((item:any, index:number) => {
        const columnIndex = this.getShortestColumnIndex(columnsHeight)
        const left = columnIndex * (columnWidth + this.gutter)
        const top = columnsHeight[columnIndex]
        columnsHeight[columnIndex] += item.height + this.gutter
        this.dataList[index].left = left
        this.dataList[index].top = top
        this.$forceUpdate()
        sortedCount++
        if (sortedCount === this.dataList.length) {
          //加载完成
          setTimeout(() => {
            this.$emit('artloading')
            this.initData = false;
            this.isLoading = false;
          }, 300)
        }
      })
      // 设置外层容器的高度
      this.waterfallHeight = Math.max(...columnsHeight)
    })

    // console.log('dataList', this.dataList)
  }

  public getShortestColumnIndex(columnsHeight) {
    let shortestIndex = 0
    let shortestHeight = columnsHeight[0]
    for (let i = 1; i < columnsHeight.length; i++) {
      if (columnsHeight[i] < shortestHeight) {
        shortestIndex = i
        shortestHeight = columnsHeight[i]
      }
    }
    return shortestIndex
  }

  public formatStat(num:string) {
    return abbreviateNumber(Number(num));
  }

  //监控宽度发送变化更新布局
  public handleResize() {
    this.updateLayout()
  }

  @Watch('dataList', { deep: true })
  onDataListChange() {
    this.isLoading = true;
    this.updateLayout();
  }

}

export default CardLong
</script>

<style lang="scss" scoped>

@media screen and (max-width: 1919px) {
  .waterfall{
    min-width: 1680px;
  }
  .image-item-warp {
    width: 360px !important;
  }
}
@media screen and (max-width: 1679px) {
  .waterfall{
    min-width: 1280px;
  }
  .image-item-warp {
    width: 260px !important;
  }
}
@media screen and (min-width: 1920px) {
  .waterfall{
    min-width: 1920px;
  }
  .image-item-warp {
    width: 416px !important;
  }
}


.waterfall {
  column-count: 3;
  column-gap: 10px;
  position: relative;
  .image-item-warp {
    position: absolute;
    width: 416px;
    box-sizing: border-box;
    cursor: pointer;
    .image-item {
      position: relative;
      width: 100%;
      .image-container{
        overflow: hidden;
        img{
          // width: 416px;
          transition: transform 0.3s ease;
          width: 100%;
          height: auto;
        }
      }
      &::after {
        position: absolute;
        content: '';
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 3px solid #00fff6;
        box-shadow: 0px 12px 32px 0px rgba(0, 255, 246, 0.3);
        display: none;
        pointer-events: none;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
      &:hover::after {
        display: block; 
      }
      &:hover{
        .image-container{
          img{
            transform: scale(1.1);
          }
        }
      }
      .label-warp{
        position: absolute;
        height: 26px;
        left: 8px;
        top: 16px;
        display: flex;
        overflow: hidden;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        z-index: 10;
        .label-item{
          padding-left: 8px;
          padding-right: 8px;
          min-width: 36px;
          height: 26px;
          margin-left: 8px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          line-height: 26px;
          color: var(--text-text-1-ddd, #DDD);
          background: rgba(0, 0, 0, 0.50);
        }
      }
      .bottom-warp{
        // width: 416px;
        width: 100%;
        height: 118px;
        position: absolute;
        bottom: 0;
        .bottom-container{
          position: relative;
          width: 100%;
          height: 100%;
          background: rgba(20, 20, 20, 0.40);
          backdrop-filter: blur(15px);
        }
        .author-info-warp{
          .avatar-warp{
            position: absolute;
            left: 16px;
            top: -24px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.30);
            backdrop-filter: none;
            .UserAvatar{
              width: 46px !important;
              height: 46px !important;
              margin-left: 1.1px;
              margin-top: 1.1px;
              border-radius: 50%;
            }
            img{
              width: 44px;
              height: 44px;
              margin-left: 2px;
              margin-top: 2px;
              border-radius: 50%;
            }
          }
          .works-name{
            margin-left: 72px;
            padding-top: 5px;
            font-size: 12px;
            font-weight: 400;
            color: var(--text-text-6-fff-60, rgba(255, 255, 255, 0.60));
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .works-title{
          color: var(--text-text-1-ddd, #DDD);
          font-size: 20px;
          font-weight: 500;
          margin-left: 16px;
          margin-top: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .option-warp{
          width: 100%;
          display: flex;
          align-items: center;
          margin-left: 16px;
          margin-top: 12px;
          .option-item{
            display: flex;
            align-items: center;
            margin-right: 16px;
            .icon {
              width: 24px;
              height: 24px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .count {
              font-size: 12px;
              margin-left: 2px;
              color: var(--text-text-6-fff-60, rgba(255, 255, 255, 0.60));
            }
          }
        }
      }
    }
    &:hover{
      .image-item{
        .image-container{
          img{
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .loading-warp{
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    margin-top: 15px;
    color: #fff;
    font-size: 16px;
    span{
      display: inline-block;
      height: 1em;
      line-height: 1;
      text-align: left;
      vertical-align: -0.25em;
      overflow: hidden;
    }
    span::before{
      display: block;
      content: '...\A..\A.';
      white-space: pre-wrap;
      animation: span 1.5s infinite step-start both;
		}
    @keyframes span{
      33%{ transform: translateY(-2em); }
      66%{ transform: translateY(-1em); }
    }
  }
}


</style>