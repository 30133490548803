import request from '@/utils/request';

const BaseUrl = `/api/token/user`;

// 申请加入创作者
export async function requestJoinCreator(data: any) {
  return request({
    url: `${BaseUrl}/projectapplyadd`,
    method: 'post',
    data
  });
}

// 查询创作者申请状态
export async function requestJoinCreatorStatus(data: any) {
  return request({
    url: `${BaseUrl}/projectapplyget`,
    method: 'post',
    data
  });
}

// 查询项目额度列表
export async function fetchTokenBalanceList(data: any) {
  return request({
    url: `${BaseUrl}/projectbalancelist`,
    method: 'post',
    data
  });
}

// 项目添加key
export async function requestProjectAddApiKey(data: any) {
  return request({
    url: `${BaseUrl}/projectaddkey`,
    method: 'post',
    data
  });
}

// 修改每日限额
export async function editProjectDayLimit(data: any) {
  return request({
    url: `${BaseUrl}/projectdaylimitedit`,
    method: 'post',
    data
  });
}

// 消耗明细
export async function fetchProjectBillingDetail(data: any) {
  return request({
    url: `${BaseUrl}/projectbillingdetails`,
    method: 'post',
    data
  });
}

// 项目名称列表
export async function fetchProjectNames(data: any) {
  return request({
    url: `${BaseUrl}/projectname`,
    method: 'post',
    data
  });
}

//token项目额度赠送流水
export async function fetchIncentiveDetail(data: any) {
  return request({
    url: `${BaseUrl}/projectopdetails`,
    method: 'post',
    data
  });
}

//token项目续期申请
export async function requestBalanceRenew(data: any) {
  return request({
    url: `${BaseUrl}/projectbalancerenew`,
    method: 'post',
    data
  });
}

//token项目额度申请
export async function requestBalanceApply(data: any) {
  return request({
    url: `${BaseUrl}/projectbalanceapply`,
    method: 'post',
    data
  });
}


