<template>
  <!-- <div class="card-wrap" @click="onClick"> -->
  <div class="card-wrap">
    <div class="card">
      <div class="thumb" @click="onClick"
        v-lazy-container="{ selector: 'img', error: require('@/assets/knowledge/card-normal-default.png'), loading: require('@/assets/knowledge/card-normal-default.png') }">
        <img :data-src="value.other_data.medias.cover" alt="">
        <div v-if="options.indexOf('knowledge') >= 0" class="review-status-warp">
          <!-- 未审核 -->
          <!-- <div class="review-draft" v-if="value.status === 1">{{ $t('personCard.draft') }}</div> -->
          <!-- 审核不通过 -->
          <div class="review-failed" v-if="value.status == reviewStatus.fail">
            <div>{{ $t('personCard.reviewFailed') }}</div>
            <div class="review-failed-icon"></div>
            <div class="failed-reason-warp">
              <div class="failed-reason-content">{{ value.audit_opinion }}</div>
            </div>
          </div>
        </div>
        <!-- 浏览量 -->
        <div class="views-warp" v-if="value.status == reviewStatus.success">
          <div class="views-icon"></div>
          <div class="count">{{ value.stat_data && value.stat_data.browse_num }} Views</div>
        </div>
      </div>
      <div class="mixed">
        <div class="head">
          <div class="title" @click="onClick">{{value.title ? value.title : value.works_title }}</div>
        </div>
        <div class="card-content">
          <div class="top">
            <div class="user-info-warp" v-if="options.indexOf('avatar') >= 0">
              <div class="user-info">
                <UserAvatar class="avatar" :src="value.user_data.user_url" :userName="value.user_data.user_name"
                  :size="24"></UserAvatar>
                <div class="user-name">{{ value.user_data.user_name }}{{ value.user_data.user_profiles ? '，' : ''}}
                </div>
              </div>
              <div class="evaluate">{{ value.user_data.user_profiles }}</div>
            </div>
            <div class="focus" v-html="value.profiles"></div>
          </div>
          <div class="bottom">
            <!-- 发布时间 -->
            <div class="time">
              {{ options.indexOf('time') >= 0 ? (options.indexOf('edit') >= 0 ? $t('personCard.editDate') +
              formatTime(value.up_time) :
              $t('publishStatus.defaultStatus') + '·' + formatTime(value.release_time)) : ''
              }}
            </div>
            <div class="option">
              <!-- 浏览量 -->
              <!-- <div class="option-item browse-item"
                v-if="options.indexOf('views') >= 0 && value.status == reviewStatus.success" @click.stop>
                <div class="icon">
                  <img src="@/assets/statIcon/icon_views.png" alt="" />
                </div>
                <div class="count">{{value.stat_data && value.stat_data.browse_num}}</div>
              </div> -->
              <!-- 点赞 -->
              <div :class="{'option-item': true, active: value.is_like == 1 }" @click.stop="onClickLike"
                v-if="options.indexOf('likes') >= 0 && value.status == reviewStatus.success">
                <div class="icon">
                  <img v-if="value.is_like" src="@/assets/knowledge/icon_like_active.png" alt="" />
                  <img v-else src="@/assets/knowledge/icon-likes.png" alt="" />
                </div>
                <div class="count">{{formatCount(value.stat_data && value.stat_data.like_num)}}</div>
              </div>
              <!-- 评论 -->
              <div :class="{'option-item': true, active: value.is_like == 1 }" @click.stop="onClickComment"
                v-if="options.indexOf('comment') >= 0 && value.status == reviewStatus.success">
                <div class="icon">
                  <img v-if="value.is_comment" src="@/assets/knowledge/comment-icon-active.png" alt="" />
                  <img v-else src="@/assets/knowledge/comment-icon.png" alt="" />
                </div>
                <div class="count">{{formatCount(value.stat_data && value.stat_data.comment_num)}}</div>
              </div>
              <!-- 设置作品详情 -->
              <div class="option-item" v-if="options.indexOf('edit') >= 0" @click.stop="onClickEdit">
                <div class="icon">
                  <img src="@/assets/knowledge/icon-edit.png" alt="" />
                </div>
                <div class="edit-size">{{ $t('personInfo.edit') }}</div>
              </div>
              <!-- 删除 -->
              <div class="option-item" v-if="options.indexOf('delete') >= 0" @click.stop="onClickDelete">
                <div class="icon">
                  <img src="@/assets/knowledge/icon-delete.png" alt="" />
                </div>
                <div class="edit-size">{{ $t('personInfo.delete') }}</div>
              </div>
              <!-- 收藏 -->
              <div class="option-item" @click.stop="onClickCollect"
                v-if="options.indexOf('delete') < 0 && value.status == reviewStatus.success">
                <div class="icon">
                  <img v-if="value.is_collect" src="@/assets/knowledge/icon_favorite_active.png" alt="">
                  <img v-else src="@/assets/knowledge/icon_favorite.png" alt="">
                </div>
                <div class="count">{{value.stat_data && value.stat_data.collect_num}}</div>
              </div>
              <!-- 设置 -->
              <div class="option-item settings-item" v-if="options.indexOf('settings') >= 0" @click.stop>
                <div class="icon">
                  <img src="@/assets/knowledge/settings_icon.png" alt="" />
                </div>
                <div class="settings-menu-warp">
                  <div class="settings-menu-box">
                    <div class="menu-item" @click.stop="onClickEdit">{{ $t('personCard.edit') }}</div>
                    <div class="menu-item" @click.stop="onClickDelete">{{ $t('personCard.delete') }}</div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { normalizeDate, formatDate } from '@/utils/format'
import { abbreviateNumber } from '@/utils/statFormat'
import { ArticleAuditStatus } from '@/config/article'
import UserAvatar from '@/components/userAvatar/index.vue'

@Component({
  name: 'CardLong',
  components: {
    UserAvatar
  },
})
class CardLong extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
  })
  public value:any

  @Prop({
    default: () => ([ 'views', 'likes', 'collect' ])
  })
  public options:any;

  public reviewStatus:any = {
    draft: ArticleAuditStatus.Status_Draft,
    release: ArticleAuditStatus.Status_Release_Ing,
    fail: ArticleAuditStatus.Status_Audit_Fail,
    success: ArticleAuditStatus.Status_Audit_OK,
  }

  public onClick () {
    this.$emit('click', this.value)
  }

  public onClickLike () {

    // if (this.value.is_like) {
    //   return
    // }
    this.$emit('clickLike', this.value)
  }

  public onClickCollect () {
    this.$emit('clickCollect', this.value)
  }

  public onClickComment () {
    // this.$emit('clickComment', this.value)
    this.$emit('click', this.value)
  }

  public onClickEdit () {
    console.log('onClickEdit', this.value)
    this.$emit('clickEdit', this.value)
  }

  public onClickDelete () {
    this.$emit('clickDelete', this.value)
  }

  public formatTime (time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime, 'YYYY/mm/dd')
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }
}

export default CardLong
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.card-wrap {
  width: 100%;
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  .card {
    width: 100%;
    height: 180px;
    // cursor: pointer;
    display: flex;
    align-items: center;

    .thumb {
      width: 320px;
      height: 100%;
      flex-shrink: 0;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .review-status-warp {
        position: absolute;
        top: 16px;
        left: 16px;
        font-size: 12px;
        font-weight: 400;

        .review-draft {
          width: 49px;
          height: 26px;
          padding: 4px 8px;
          color: $gray_d;
          text-align: center;
          background: rgba(0, 0, 0, 0.50);
        }

        .review-failed {
          width: 109px;
          height: 26px;
          background: rgba(255, 92, 81, 0.80);
          backdrop-filter: blur(1px);
          color: $gray_d;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          &:hover{
            .failed-reason-warp{
              display: block;
            }
          }
          .review-failed-icon {
            width: 10px;
            height: 10px;
            margin-left: 2px;
            background: url('@/assets/knowledge/review-failed-icon.png') no-repeat;
            background-size: 100% 100%;
          }
          .failed-reason-warp{
            min-width: 220%;
            height: auto;
            position: absolute;
            left: 0;
            top: 36px;
            text-align: center;
            padding: 13px 16px;
            background: #000;
            display: none;
            &::before{
              content: '';
              width: 10px;
              height: 6px;
              position: absolute;
              left: 33%;
              top: -6px;
              transform: translateX(-50%);
              background: url('@/assets/triangle.png') no-repeat;
              background-size: 100% 100%;
            }
            .failed-reason-content{
              color: var(--text-text-2999, #999);
              font-size: 14px;
              font-weight: 400;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
              overflow: hidden;

            }
          }
        }
      }

      .views-warp{
        position: absolute;
        left: 16px;
        bottom: 8px;
        display: flex;
        align-items: center;
        .views-icon{
          width: 24px;
          height: 24px;
          background: url('@/assets/knowledge/views-icon.png') no-repeat;
          background-size: 100% 100%;
        }
        .count{
          margin-left: 3px;
          font-size: 12px;
          font-weight: 400;
          text-wrap: nowrap;
          color: var(--text-text-6-fff-60, rgba(255, 255, 255, 0.60));
        }
      }
    }

    .mixed {
      flex: 1;
      flex-shrink: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-left: 24px;

      .head {
        width: 100%;
        position: relative;
        color: $gray_d;
        font-size: 24px;
        padding-right: 24px;
        flex-shrink: 0;

        .title {
          width: 620px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      >.card-content {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
          width: 100%;

          .user-info-warp {
            width: 100%;
            display: flex;
            align-items: center;
            font-weight: 500;
            flex-shrink: 0;
            margin-top: 8px;

            .user-info {
              display: flex;
              align-items: center;
              color: #adadad;
              font-size: 16px;
              flex-shrink: 0;

              &>img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }

              .user-name {
                margin-left: 8px;
              }
            }

            .evaluate {
              margin-left: 8px;
              font-size: 16px;
              color: #adadad;
              flex-shrink: 0;
              width: 40%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .focus {
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            color: $gray_9;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            word-break: break-all;
            white-space: wrap;
            margin-top: 8px;

            &>p {
              color: $gray_9;
              font-size: 14px;
              line-height: 24px;
            }
          }
        }

        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .time {
            color: $gray_9;
            font-size: 12px;
          }

          .option {
            display: flex;
            align-items: center;

            .option-item+.option-item {
              margin-left: 24px;
            }

            .option-item {
              display: flex;
              align-items: center;
              cursor: pointer;
              .icon {
                width: 24px;
                height: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .count {
                font-size: 12px;
                margin-left: 2px;
                color: $gray_6;
              }

              .edit-size {
                color: #666;
                font-size: 14px;
                font-weight: 500;
                margin-left: 2px;
              }
            }

            .browse-item {
              cursor: default;
            }

            .settings-item {
              position: relative;

              &:hover {
                .settings-menu-warp {
                  display: block;
                }
              }

              .settings-menu-warp {
                display: none;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 20px;
                width: 100px;
                height: 105px;
                z-index: 1000;

                &::before {
                  content: '';
                  width: 10px;
                  height: 6px;
                  position: absolute;
                  top: 14px;
                  left: 50%;
                  transform: translateX(-50%);
                  background: url('@/assets/triangle.png') no-repeat;
                  background-size: 100% 100%;
                }

                .settings-menu-box {
                  width: 100px;
                  height: 80px;
                  margin-top: 20px;
                  padding: 6px;
                  color: #666;
                  font-size: 14px;
                  font-weight: 500;
                  background: #141414;
                  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);

                  .menu-item {
                    width: 88px;
                    height: 34px;
                    padding: 6px 8px;
                    text-align: center;

                    &:hover {
                      color: #DDD;
                      background: rgba(255, 255, 255, 0.10);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>