<template>
  <div class="activity-notice-main">
    <ActivityTitle :title="$t('views.tokenActivity.notice.title')" subTitle="EVENT NOTICE"/>
    <div class="content">
      <div class="list-box">
        <div class="list-item" v-for="(item, index) in leftList" :key="index">
          <div class="list-content">{{ $t(item) }}</div>
        </div>
      </div>
      <div class="list-box">
        <div class="list-item" v-for="(item, index) in rightList" :key="index">
          <div class="list-content">{{ $t(item) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import ActivityTitle from '../components/activityTitle.vue'
export default {
  components: { ActivityTitle },
  name: 'activity-notice-main',
  data () {
    return {
      leftList: [
        'views.tokenActivity.notice.tips1',
        'views.tokenActivity.notice.tips2',
        'views.tokenActivity.notice.tips3',
        'views.tokenActivity.notice.tips4'
      ],
      rightList: [
        'views.tokenActivity.notice.tips5',
        'views.tokenActivity.notice.tips6',
      ]
    }
  },
  mounted () {
  },

}
</script>
<style lang="scss" scoped>
$content-width: 1360px;
.activity-notice-main{
  min-width: $content-width;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  .content{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    width: $content-width;
    gap: 12px;
    .list-box{
      width: 693px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .list-item:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 9px;
        width: 8px;
        height: 8px;
        background-color: #22FFE9;
        border-radius: 50%;
        margin-right: 8px;
      }
      .list-item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-left: 14px;
        gap: 24px;
        .list-content{
          font-size: 19px;
          color: #CFDCDB;
          line-height: 24px;
        }
      }
    }
  }

}
</style>