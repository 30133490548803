// 定义内容
export default {
  menus: {
    home: '主页',
    knowledge: '知识库',
    gameProduct: '游戏作品库',
    creator: '创作者计划',
    aiToolkit: 'AI工具库',
    questions: '等你来答'
  },
  editorMenus: {
    articlePublish: '文章投稿',
    postProduct: '发布作品',
    gameDemo: '游戏Demo',
    gameResource: '游戏资源',
    artCreativity: '美术创意'
  },
  applicationMenus: {
    demoManagement: 'Demo管理',
    apiKey: 'API key管理',
    incentiveAccount: '激励账户',
    billingDetail: '消耗明细',
    developmentDocuments: '开发文档',
    demoTitle: '我的Demo'
  },
  personHome: {
    center: '个人中心',
    logout: '退出登录'
  },
  personNav: {
    article: '文章',
    knowledge: '知识',
    favorite: '收藏',
    draftBox: '草稿箱',
    product: '作品',
    image: '图片',
    demo: 'Demo',
    follow: '关注'
  },
  following: {
    following: '我关注的人',
    followers: '关注我的人',
    guestFollow: '他关注的人',
    guestFollowers: '关注他的人',
    submissions: '发布',
    views: '浏览',
    likes: '点赞',
    followed: '关注',
    unfollow: '取消关注',
    mutualFollowing: '互相关注',
    followBtn: '关注',
    followedBtn: '已关注',
    followMutualBtn: '互相关注',
  },
  addNav:{
    publish: '发布',
    knowledge: '写文章',
    image: '发图片',
    demo: '我的Demo',
    answer: '提问',
    product: '作品投稿',
    posts: '投稿量',
    views: '浏览量',
    likes: '点赞量',
  },
  personInfo: {
    userName: '昵称',
    description: '介绍',
    save: '保存',
    cancel: '取消',
    edit: '修改',
    delete: '删除',
    title: '个人信息',
    tips: '保存成功',
    upload: '上传中',
  },
  personCard: {
    editDate: '编辑于',
    edit: '编辑',
    delete: '删除',
    draft: '草稿',
    reviewFailed: '审核失败',
    noData: '还没有内容',
  },
  deleteDraft: {
    title: '提示',
    content: '您是否确定删除此草稿?',    
    content1: '您是否确定删除此文章?',
    workContent: '您是否确定删除此作品?',
    cancel: '取消',
    confirm: '确认',
  },
  publishStatus: {
    defaultStatus: '文章发布于',
    status0: '草稿',
    status1: '审核中',
    status2: '通过审核',
    status3: '未通过审核',
  },
  publishState: {
    draft: '待发布',
    underReview: '审核中',
    published: '审核通过',
    fail: '未通过审核',
  },
  route: {
    setting: {
      general: '基础设置',
      password: '密码设置',
      connect: '连接',
      developer: '成为开发者',
    },
    home: {
      browser: '主页',
      client: '主页',
    },
    account: {
      login: '登录',
      register: '注册',
      reset: '找回密码',
      bind: '绑定账号',
      redirect: '登录重定向',
    },
    oauth: {
      login: '授权登录',
      consent: '授权登录',
      redirect: '授权登录',
    },
    developer: {
      home: '首页',
      register: '开发者注册',
      application: '我的作品',
      sdk: 'SDK与AI工具',
      document: '文档',
    },
    article: {
      add: '文章'
    },
    knowledge: {
      home: '知识库',
      more: '模块细分',
      detail: '详情',
    },
    gameWorks: {
      home: '游戏作品库',
      more: 'Module breakdown',
      detail: '游戏作品详情'
    },
    error: {
      index: '发生错误',
      401: '无权限',
      404: '未找到资源',
    },
    applications: {
      list: 'Demo',
      create: '创建一个新Demo'
    },
    personal: {
      home: '个人中心'
    }
  },
  views: {
    signUp: {
      title: '加入 LitGate',
      userNamePh: '昵称',
      emailPh: '邮箱',
      passwordPh: '密码',
      codePh: '邮箱验证码',
      send: '发送验证码',
      sending: '发送中',
      signUpBtn: '注册',
      haveAccount: '已经注册?',
      signInTxt: '去登录',
      checkPre: '我已经阅读并同意',
      terms: '服务协议',
      and: '和',
      policy: '隐私协议',
      passwordTips: '密码长度需在6-30位，需包含字母和数字，且不允许有空格。',
      success: '您已注册成功！',
      checkPolicy: '请先同意用户协议'
    },
    signIn: {
      title: '密码登录',
      signInBtn: '登录',
      forget: '忘记密码?',
      noAccount: "还没有注册?",
      signUpLink: '去注册',
      success: '登录成功'
    },
    forgetPage: {
      title: '忘记密码?',
      sendBtn: '发送邮件',
      noEmail: "没有收到邮件?",
      sendResultTips: "我们已向您的邮箱发送重设密码邮件。如果几分钟后，您仍没有正常收到邮件，建议您检查邮箱的垃圾文件夹。",
      reSend: '重新发送'
    },
    passwordReset: {
      title: '重设密码',
      passwordPh: '请输入您的新密码',
      passwordSd: '确认新密码',
      confirm: '确认',
      success: '密码重设成功！',
      toSignIn: '去登录'
    },
    game: {
      info: '游戏信息',
    },
    guide: {
      more: 'More Details',
      success: '订阅成功!',
      emailCheckFail: '请输入正确的邮箱',
      banner: {
        tips1: 'AI赋能游戏：知识、向导、资源与更多...',
        slogan1: '从LitGate社区启程',
        scrollSlogan: ['游戏创作', 'AI应用', '资讯&洞见', '职业成长'],
        slogan3: 'WITH LITGATE COMMUNITY',
        emailTips: '功能即将开放，立即订阅上线通知。',
        tips2: 'LitGate社区致力于保护您的隐私和确保个人信息的安全。我们承诺您提供的电子邮件地址仅用于官方发送产品更新通知。未经授权与明确同意，我们绝不会披露或与任何第三方共享您的电子邮件信息。',
        subscribe: '订阅',
        placeholder: '请输入您的邮件地址',
        discord: 'Discord',
        wechat: '微信',
      },
      events: {
        title: '活动',
        waitContent: '敬请期待 更多活动',
        eventTimeRange1: '2023年10月20~22日',
        eventTitle1: 'CiGA GENE AI＋游戏开发季',
        eventAddress1: '上海/武汉/广州/深圳',
        eventIntro1: 'AIGT（中国AI游戏巡回赛）是由CiGA主办，LitGate赞助的一个AI主题极限游戏创作赛事。在赛事中，你将尝试将AI技术运用至游戏Demo中，冲刺奖项。优秀者将获得丰富的曝光机会与奖杯，同时你也将结交到广泛的行业人士、游戏同好与其他创作者。',
        eventMore: '更多',
        eventTimeRange2: '2024年1月22~28日',
        eventTitle2: '全球Gamejam极限创作',
        eventAddress2: '全球线上线下多站点',
        eventIntro2: 'Global Gamejam（GGJ）是全球最大的游戏创作活动，自2008年以来拥有数以万计的参与者（即Jammer），覆盖了100多个国家的线上和线下场地。通过每年组织的48小时的Gamejam极限游戏创作活动，GGJ旨在激励全球各地创作者学习、创意交流和共同创作。 2024年，LitGate将出席作为中国赞助方之一，提供AI相关游戏创作主题，并为创作者提供资源支持。'
      },
      aboutUs: {
        title: '关于LitGate',
        introTitle1: '开放活跃的AI游戏生态',
        introTitle11: '开放活跃的',
        introTitle12: 'AI游戏',
        introTitle13: '生态',
        introContent1: "无论你是经验丰富的创作者，还是拥抱AI浪潮的游戏用户，LitGate都欢迎你在这儿分享想法观点、工具资源与创作历程，又或是参与LiGate举行的创作赛事与活动，成为社区的一份子。",
        stations: [
          { title: '游戏作品库', label: 'AI游戏应用的发布和分享站'},
          { title: '极客社区', label: '新鲜信息资讯与知识人才交流'},
          { title: '前沿科技', label: 'AI赋能游戏开发的专业工具站'},
          { title: '职业发展', label: '助力技术、孵化、投资与成长'},
        ],
        introTitle2: ['不仅是', '，还是更多可能'],
        introTitleGreen2: '社区',
        introContent2: "LitGate还为创作者提供各类开源工具与服务，以及自研或赞助的付费资源使用权限。在动态更新的信息流和文章专栏中，你也能随时了解到新鲜资讯与独家文章。",
        introTitle3: ['创作活动 与'],
        introTitleGreen3: 'GAMEJAM',
        introContent3: "LitGate在全球范围内举办丰富的创作活动和Gamejam，帮助创作者脱颖而出。在这里，你不仅可以通过线上或线下的交流会，接触到行业先驱和知名嘉宾，还有机会获得潜在的融资与孵化机会。",
      },
      case: {
        title: '作品分享',
        carousel1: {
          title: '往届赛事优秀作品',
          intro: '在往届的AI主题游戏赛事中，参赛者积极探索将AI工具融入游戏开发，延展了玩法与设计的边界，极尽创思创想。 ',
        },
        banner1: {
          title: '囚灵',
          intro: "本游戏利用了由ChatGPT4提供支持的AI实时能力，玩家能够在每次游戏中体验随机生成全新叙事内容。每次游戏启动时，系统将载入约10万字的详细提示，确保AI能够清楚地理解上下文。",
          tags: ['Roguelike', '恐怖', '实时生成'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner2: {
          title: 'AI美少女梦工厂',
          intro: '本游戏是一款以二次元生活模拟RPG，玩家可以体验到"养女儿"的虚拟乐趣。游戏根据不同的选择和角色点数，经由Stable Diffusion实时计算得到视觉素材，使玩家能获得到独属于自己的女儿形象。',
          tags: ['人生模拟', '角色扮演', '实时生成'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner3: {
          title: '融合',
          intro: '这款卡牌游戏设计了丰富的卡牌装备和多样化的关卡，复玩性极高。游戏的所有视觉素材由Midjourney和Stable Diffusion生成，音乐生产则使用了AIVA。此外，文本、图像和卡牌效果均由ChatGPT和SD实时计算得到。',
          tags: ['卡牌', '策略', '实时生成'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
        banner4: {
          title: '神谕',
          intro: '用嘴也能玩的动作游戏，你就是游戏里的神！游戏允许玩家通过说话来“操控”角色与“编程”环境试着跟小机器人对话，让它动起来并帮你收集能量球。',
          tags: ['沙盒', '冒险', '动作'],
          video: 'https://doc.weixin.qq.com/doc/w3_AUkAdQbcAF8HiV0Bf13QS6NAK6siy?scode=AJEAIQdfAAoXpECel6'
        },
      },
      partner: {
        title: '商业合作伙伴'
      },
      contact: {
        title: '联系我们',
        tips: '如果您对合作机会感兴趣，或是有任何相关问题，请随时联系我们的邮箱',
        email: 'litgate_official@gmail.com',
        listTitle: 'OUR COSIALS:',
        Wechat: '微信群',
        wechatOpen: '公众号',
        Discord: 'Discord',
        Twitter: 'Twitter'
      },
    },
    editorArticle: {
      titlePh: '请输入标题（最多100个字）',
      introPh: '请输入导语（选填）',
      contentPh: '请输入正文',
      setting: '发布设置',
      category: '文章分类',
      categoryPh: '请选择领域',
      articleTag: '文章标签',
      tagAdd: '添加标签',
      addCover: '添加封面',
      relevantContent: '相关内容',
      relevantAdd: '添加内容',
      backToTop: '回到顶部',
      saveTipsPre: '草稿保存于',
      saveTipsSur: '分钟前',
      total: '字数：',
      preview: '预览',
      save: '保存',
      post: '发布',
      rePostBtn: '重新发布',
      postSuccess: '发布成功',
      postError: '发布失败',
      saveError: '保存失败',
      saveSuccess: '保存成功',
      titleEmpty: '请输入标题',
      contentEmpty: '请输入文章内容',
      relatedPost: '添加相关内容',
      search: '搜索',
      keywords: '关键词',
      selectCategory: '查找分类',
      searchList: '结果列表',
      selected: '已选择',
      cancel: '取消',
      coverFormat: '图片支持格式：JPEG、JPG、PNG, 限制一张'
    },
    postProductImage: {
      setting: '设置',
      title: '标题',
      titlePl: '请输入标题',
      imgTag: '图片标签',
      introduction: '介绍',
      introductionPl: '请填写游戏特效介绍，让更多人找到你的游戏特效',
      cancelBtn: '取消',
      saveBtn: '保存',
      postBtn: '发布',
      rePostBtn: '重新发布',
      uploadCompleted: '上传完成',
      uploadFail: '格式错误',
      setAsCoverBtn: '设置为封面',
      coverTips: '封面',
      waitUpload: '等待上传',
      uploading: '上传中',
      imageNotReady: '图片未就绪',
      dragUpload: '拖拽图片到此或点击上传',
      dragUploadLimit: '最多支持上传20张',
      releaseUpload: '释放上传图片',
      uploadBtn: '上传',
      draftBtn: '草稿',
      sizeTips: '图片大小',
      sizeTipsContent: '不超过30MB',
      resolutionTips: '图片规格',
      resolutionTipsContent: '不超过1080P',
      formatTips: '图片格式',
      formatTipsContent: '推荐使用png、jpg、jpeg、webp、gif',
      postSuccess: '发布成功',
      postError: '发布失败',
      saveError: '保存失败',
      saveSuccess: '保存成功',
      limitError: '抱歉，图片最多可以上传20张'
    },
    createApplication: {
      generalSetting: '基础信息',
      publishSetting: '发布设置',
      createBtn: '创建新Demo',
      name: '名称',
      introduction: '简介',
      platform: '支持平台',
      gameDemo: '游戏Demo',
      demoUrl: 'Demo地址',
      recentPublishedDate: '最新发布日期',
      titlePlaceHolder: '请输入标题',
      introductionPlaceHolder: '请填写游戏演Demo资料，以便更多的人可以找到你的游戏Demo',
      demoUrlPlaceHolder: '请填写游戏Demo体验地址',
      promotionMaterials: '宣传视频',
      limitError: '抱歉，图片最多可以上传20张',
      cover: '封面图',
      tags: '标签',
      uploadBtn: '上传压缩文件',
      uploadFormatTips: '只能上传压缩包文件，文件大小不能超过1GB',
      uploadPromotionMaterials: '上传材料',
      promotionMaterialsError: '请上传宣传材料',
      demoError: '请上传Demo包',
      demoUrlError: '请填写demo体验地址',
      joinCreatorBtn: '加入创作者计划',
      reJoinCreatorBtn: '查看',
      materialsTips: {
        supported: '· 格式：支持视频和图片的常用格式，最多只能上传 20 个素材',
        size: '· 大小：视频大小为1G以内，图片大小为30MB以内',
        resolution: '· 分辨率：16:9',
        format: '· 格式: 视频支持MP4视频格式, 图片支持 JPEG, JPG, PNG；',
      },
      joinCreator: {
        createOption: '创建新Demo',
        title: '加入创作者计划',
        tips: '成功加入创造者计划可以获得免费的Tokens激励，请填写以下资料并提交申请。',
        replyTips: '请您修改如下的申请信息，并重新提交审核。',
        demoName: '作品名称',
        demoNamePH: '请输入您的作品名称',
        selectDemo: '选择作品',
        selectDemoPH: '请选择一个作品',
        intro: '作品简介',
        introPH: '请介绍一下您的作品，以方便我们更快速了解您的想法',
        reSubmit: '重新提交',
        submit: '提交',
        updateDraftTitle: '更新草稿',
        updateDraftContent: '当前信息与草稿信息不一致,是否将当前信息同步更新至草稿',
        updateDraftSubmit: '确定',
        updateDraftCancel: '取消',
        successMsg: '申请成功',
        joinStatus: '加入创作者计划',
      },
      apiDocument: 'API文档'
    },
    apiKeyManager: {
      instruction: '使用说明',
      instructionList: [
        "成功加入LitGate的创建者程序后，您可以为您的项目创建LitGate的独家APl密钥，使用此APl密钥，您的项目将能够使用免费的token配额。",
        "不要与他人共享您的APl密钥或将其暴露给客户端代码。",
        "一旦它们被发现被泄露，为了保护您的帐户安全，LitGate可能会禁用APl密钥。"
      ],
      tableTitle: 'API Key',
      createBtn: 'New',
      tableTh: {
        name: '名字',
        creationTime: '创建时间',
        key: 'Key',
        binding: 'BinDing Demo',
        operate: '操作',
        editBtn: '编辑',
        deleteBtn: '删除'
      },
      dialog: {
        createTitle: '创建 LitGate API Key',
        createTips: '每个成功加入创建者程序的项目只能创建1个密钥。',
        name: 'Key的名字',
        demo: 'Demo',
        submit: '提交',
        resultTitle: 'API Key',
        resultTips: '请将此API Key保存在安全且可访问的地方。出于安全原因，您将无法通过您的LitGate平台的激励账户再次查看。如果您丢失了此密钥，请重新生成一个。',
        sure: '确认',
        editTitle: '编辑',
        namePH: '请输入您的名字',
        demoPH: '请选择一个Demo',
        deleteTitle: '提示',
        deleteTips: '您是否确定更换此API Key？',
        cancel: '取消',
        
      }
    },
    incentiveAccount: {
      title: '账户总览',
      balance: '剩余额度',
      usd: 'USD',
      cny: 'CNY',
      totalIncentive: '激励总额',
      yesterday: "昨日消耗",
      totalConsumption: '总消耗',
      monthTotal: "月消耗",
      detailTitle: '账户明细',
      keyGenerated: '已初始化一个新的Key',
      tableTh: {
        demo: 'Demo',
        apiKey: 'API Key',
        balance: '剩余额度',
        valid: '有效期至',
        totalIncentive: '激励总额',
        totalConsumption: '消耗总额',
        operate: '操作',
        createBtn: '创建Key',
        changeBtn: '更换Key',
        usage: '用量限制',
        incentiveTime: '时间',
        incentiveGift: '激励额度',
        balanceLessTips: {
          tips1: '您的余额还有',
          tips2: '天到期，请尝试',
          tips3: '申请延期',
          tips4: '您的余额太少，请尝试',
          tips5: '申请增加余额',
          submitBalanceSuccess: '您已经成功提交了增加余额的申请，请耐心等待审核。',
          submitDateSuccess: '您已经成功提交了延期的申请，请耐心等待审核。',
        }
      },
      dialog: {
        usageLimitTitle: '用量限制',
        limitProps: '限制每日的最高用量',
        limitLimitError: '每日的用量限制最高设置为',
        limitPH: '输入每日的最高用量限制，最高可设置为',
        cancel: '取消',
        submit: '提交',
        incentiveDetailTitle: '激励明细',
        sure: '确认',
        deleteTitle: '提示',
        deleteTips: '您是否确定更换此API Key？',
      }
    },
    consumptionDetail: {
      startDate: '开始日期',
      endDate: '结束日期',
      demo: 'Demo',
      model: '模型',
      searchBtn: '搜索',
      modelDraft: 'unknown',
      modelAll: '全部',
      openAI: 'Open AI',
      gpt35: 'GPT 3.5',
      gpt4: 'OpenAi GPT4.0',
      gpt4o: 'OpenAi GPT4o',
      dalle3: 'OpenAI Dalle 3',
      tts1: 'OpenAi tts-1',
      tts1hd: 'OpenAi tts-1-hd',
      wisper: 'OpenAi wisper',
      claude: 'Claude haiku',
      claudeSonnet: 'Claude sonnet',
      demoPH: '请选择一个作品',
      tableTh: {
        requestId: '请求ID',
        time: '时间',
        demo: 'Demo',
        model: '模型',
        consumption: '消耗额度',
        dateRangeTips: '请选择日期范围'
      }
    },
    productImageDetail: {
      viewMore: '查看更多',
      share: '分享',
    },
    gameWorks: {
      releaseTime: '发布日期',
      gameDemoTitle: '游戏Demo',
      creativityTitle: '美术创意',
      category1: '游戏Demo',
    },
    gameWorksDetail: {
      views: '浏览',
      introduction: '作品介绍',
      posted: '发布于',
      share: '分享',
      version: '版本',
      lastUpdateDate: '更新日期',
      supportedPlatform: '支持平台',
      downloadCounts: '体验次数',
      downloadBtn: '下载体验',
      onlineBtn: '在线体验',
      downloadDisableTips: '暂未开放',
      related: '相关教程'
    },
    gameWorksMore: {
      category1Desc: '主要包括通过AIGC生成游戏要素、引入AIGC实现玩法创新的游戏作品'
    },
    knowledge: {
      category: '知识栏目',
      category0: '精选推荐',
      category1: '2D艺术',
      category2: '3D模型',
      category3: '视频动画',
      category4: '音频生成',
      category5: '文本生成',
      category6: '编码协助',
      category7: 'AI游戏生产实践',
      category8: 'AI游戏创作实例',
      category9: 'AI工具与测评',
      category10: '学术交流讨论',
      category11: '资讯时事',
      edit: '上传知识文章',
      follow: '关注',
      hot: '热门知识',
      more: '查看更多',
      articleViews: '文章浏览数',
      favoriteArticle: '文章收藏数',
      writeArticle: '文章发布数',
      postBtn: '写文章'
    },
    knowledgeMore: {
      category7Desc: '了解AI技术如何赋能游戏生产的各个环节',
      category8Desc: 'AI游戏的优秀实践和开发实例，汲取灵感与经验',
      category9Desc: 'AI技术测评与安利，获取工具与解决方案',
      category10Desc: '交流前沿学术观点与技术探索',
      category11Desc: '资讯与热点，掌握行业动态',
    },
    knowledgeDetail: {
      lead: '导语',
      relevant: '相关知识',
      directory: '目录',
    },
    tokenActivity: {
      fixedBtn: 'join',
      intro: {
        title: '活动介绍',
        subTitle: 'EVENT DESCRIPTION',
        date: '申请时间：2024.07.01-09.01',
        markTxt: '这是一个大AI时代，每一个想法都值得尊重与尝试',
        platformTips: '<span class="high-light-txt">LitGate</span>平台发起<span class="high-light-txt">#AI创作者激励计划#，</span>',
        target: '旨在帮助AI创作者可以<span class="high-light-txt">更低成本</span>的验证想法，快来<span class="high-light-txt">申请加入</span>吧！'
      },
      way: {
        title: '参与方式',
        subTitle: 'WAY OF PARTICIPATION',
        submitTitle: '提交申请',
        submitIntro: '仅需填写作品名称、作品介绍等信息',
        submitTips: '(作品包括但不限于工具、游戏、泛娱乐等类型)',
        auditTitle: '平台审核',
        auditIntro: '平台将在提交后3个工作日内完成审核',
        joinTitle: '加入计划',
        joinIntro: '成功加入计划的作品将享有免费API Token激励',
        joinTips: '（关于如何使用Token激励，详细可见<span class="high-light-txt to-api-link" >API文档</span>）'
      },
      incentives: {
        title: '激励机制',
        subTitle: 'INCENTIVES',
        beginStep: {
          title: '《启航激励》',
          content1: '成功加入',
          tips1: '激励百万免费Token',
        },
        onlineStep: {
          title: '《上线激励》',
          content1: '发布上线',
          tips1: '最高激励2亿免费Token'
        },
        gloryStep: {
          title: '《荣耀激励》',
          content1: '分享创作背后的故事',
          tips1: '每次激励百万免费Token',
          content2: '作品出圈，持续激励',
          tips2: '每次激励最高2亿免费Token'
        },
        extraTips1: '以上免费Token额度是以GPT3.5作为参考，我们支持的模型包括',
        extraTips2: 'GPT4o/GPT4/GPT3.5/Claude3',
        extraTips3: '关于免费Token的使用，详细可查看',
        extraTips4: 'API文档',
      },
      notice: {
        title: '活动须知',
        subTitle: 'EVENT NOTICE',
        tips1: '创作者需确保提交的信息真实有效，如有虚假，将取消参与资格；',
        tips2: '本次活动不收取任何费用，请勿向任何第三方支付费用以获取参与资格；',
        tips3: '本次活动目的为鼓励探索创新， 平台赠予的Token仅限于本次活动中创作的作品使用，不可转让或兑换现金；',
        tips4: '所有作品严禁包含政治、低俗、色情、宗教等内容，不得违反相关法律法规；',
        tips5: '若作品涉及侵权纠纷，需由创作者自行承担相应的法律责任；',
        tips6: ' LitGate平台保留对活动规则的最终解释权，如有关于本活动的疑问，请联系LitGate平台客服；'
      }
    },
    page404: {
      title: '页面未找到',
      btn: '回到主页'
    }
  },
  common: {
    ok: '确认',
    cancel: '取消',
    and: '和',
    continue: '继续',
    nickname: '昵称',
    email: '邮箱',
    country: '国家',
    password: '密码',
    curPassword: '原密码',
    newPassword: '新密码',
    sndPassword: '确认密码',
    save: '保存',
    giveup: '放弃',
    tips: '提示',
    logoutTips: '此操作将退出登录, 是否继续?',
    logouting: '退出中',
    favorite: '收藏',
    favorited: '已收藏',
    copyLink: '复制链接'
  },
  plchld: {
    email: '请输入邮箱',
    password: '请输入密码',
    passwordCur: '请输入原密码',
    passwordNew: '请输入新密码',
    passwordSnd: '请确认密码',
    country: '请选择国家',
    nickname: '请输入昵称',
    verifyCode: '请输入验证码',
    firstname: '请输入姓氏',
    lastname: '请输入名字',
  },
  tips: {
    charLen: '英文占用1个字符，中文2个字符',
    succ: {
      get: '拉取数据成功',
      connect: '连接成功',
      disconnect: '解绑成功',
      add: '添加成功',
      edit: '修改成功',
      del: '删除成功',
      login: '登录成功',
      submit: '提交成功',
      copy: '复制成功'
    },
    fail: {
      get: '拉取数据失败',
      connect: '连接失败',
      disconnect: '解绑失败',
      add: '添加失败',
      edit: '修改失败',
      del: '删除失败',
      closed: '暂不可用',
      submit: '提交失败',
    },
  },
  comment: {
    viewMore: '点击查看更多',
    title: '评论',
    delete: '删除',
    report: '举报',
    copy: '复制',
    commentPlaceHolder: '输入你的看法',
    loginTips: '请登录后再发表评论',
    loginBtn: '登录',
    replyFor: '回复',
    commentSuccess: '评论成功',
    replySuccess: '回复成功',
    deleteSuccess: '删除成功',
    applySuccess: '申请成功',
    postBtn: '发布'
  },
  gateReport: {
    title: '举报',
    reportType: '请填写举报类型',
    hostility: '不友善',
    advertising: '垃圾广告',
    violateLaws: '违法违规',
    eroticism: '色情低俗',
    suspectedInfringement: '涉嫌侵权',
    cyberViolence: '网络暴力',
    underageInvolvement: '涉未成年',
    suicide: '自杀自残',
    falseInformation: '不实信息',
    infoTitle: '请填写举报信息',
    submitBtn: '提交',
    inputPl: '请输入内容',
    success: '举报成功',
    repeat: '已举报',
    reportErr: '系统异常，请稍后再试'
  },
  error: {
    userNameEmpty: '用户昵称不可以空',
    userNameLengthError: '用户昵称长度应在2-20位之间',
    userNameStringError: '用户昵称不能包含特殊字符',
    userNameFirstCharError: '用户昵称的首位不能包含数字',
    emailEmpty: '邮箱不可以空',
    emailInvalid: '邮箱地址无效或已被占用',
    emailSignInInvalid: '邮箱地址无效',
    passwordEmpty: '密码不可以空',
    passwordLengthError: '密码长度必须在6到30位之间',
    passwordLevelError: '密码应包含字母和数字',
    verifyCodeEmpty: '邮箱验证码不可以空',
    content: '请输入内容',
    email: '请输入邮箱',
    emailFormat: '请输入正确的邮箱地址',
    password: '请输入密码',
    passwordCur: '请输入原密码',
    passwordNew: '请输入新密码',
    passwordSnd: '请确认密码',
    pswNotSame: '两次密码不一致',
    pswOldSame: '新旧密码一样请重新输入',
    country: '请选择国家',
    nickname: '请输入昵称',
    verifyCode: '请输入验证码',
    verifyCodeFormat: '请输入正确格式的验证码',
    lengthAt: '长度在',
    notBlank: '之间，不能包含空格',
    withSpecialChar: '，可包含@#$等特殊字符',
    sameEmailPsw: '密码和邮箱过于相似',
    protocol: '请先同意协议',
    overMax: '长度超过上限',
    format: '格式错误',
    backspace: '不能包含空格',
    fileSizeLimit: '文件大小超过限制',
    fileRatioLimit: '文件宽高比例不符合规则'
  },
  accountCode: {
    code1301: '你的账户异常，请稍后再试',
    code1302: '你的账户异常，请稍后再试',
    code1303: '用户昵称不可以为空',
    code1304: '用户昵称长度应在2-20个字符之间',
    code1305: '用户昵称不能包含特殊字符',
    code1306: '邮箱地址无效',
    code1307: '邮箱地址无效或已被占用',
    code1308: '重新发送邮件需要在60秒之后，请稍后再试',
    code1309: '今日发送验证码次数已达上限',
    code1310: '邮箱或密码有误',
    code1311: '昵称不符合规则',
    code1312: '邮箱地址无效或已被占用',
    code1313: '邮箱验证码错误',
    code1314: '邮箱验证码过期，请重新获取',
    code1315: '今日登录失败次数已达上限',
    code1316: '邮箱或密码有误',
    code1317: '你的账户异常，请稍后再试',
    code1318: '密码格式有误，请重新输入',
  },
  options: {
    articleCategory: {
      category1: '2D艺术',
      category2: '3D模型',
      category3: '视频动画',
      category4: '音频生成',
      category5: '文本生成',
      category6: '编码协助',
      category7: 'AI游戏生产实践',
      category8: 'AI游戏创作实例',
      category9: 'AI工具与测评',
      category10: '学术交流讨论',
      category11: '资讯时事',
    }
  },
  auditState: {
    wait: '申请中',
    success: '成功',
    fail: '失败'
  },
};
