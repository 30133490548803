<template>
  <div class="image-setting-option">
    <el-form ref="optionForm" :model="form" :rules="rules" label-width="160px" >
      <el-form-item :label="$t('views.postProductImage.title')" prop="title" style="marginLeft: -10px;" label-width="170px">
        <el-input
          class="input-title"
          v-model="form.title"
          maxlength="40"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('views.postProductImage.imgTag')" prop="label">
        <el-tag
          :key="tag"
          v-for="tag in form.label"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          maxlength="30"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-if="showAddTagBtn" class="button-new-tag" size="small" @click="showInput" >+ {{ $t('views.editorArticle.tagAdd') }}</el-button>
      </el-form-item>
      <el-form-item :label="$t('views.postProductImage.introduction')" prop="profiles" label-width="160px">
        <el-input
          class="input-title"
          type="textarea"
          v-model="form.profiles"
          :autosize="{ minRows: 6, maxRows: 6}"
          maxlength="1000"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
  </template>
  
  <script>
  import { ArticleCategoryOptions } from '@/config/article';
  import { batchGetArticle } from '@/api/article';
  export default {
    name: 'image-setting-form',
    components: { },
    data() {
      return {
        ArticleCategoryOptions,
        fileList: [],
        form: {
          title: '',
          profiles: '',
          label: [],
        },
        rules: {
          title: [
            { required: true, message: '', trigger: 'submit' },
          ],
          // label: [
          //   { required: true, message: 'please add tag', trigger: 'submit' },
          // ],
        },
        inputVisible: false,
        maxTagLimit: 5,
        inputValue: '',
        relatedVisible: false
      }
    },
    mounted() {
    },
    methods: {
      submitForm() {
        return new Promise(async (resolve) => {
          this.$refs.optionForm.validate((valid, errs = {}) => {
            if (valid) {
              return resolve(this.form);
            } else {
              return false;
            }
          });
        });
      },
      restoreForm(formData) {
        console.log('formData:', formData)
        const { title='', label= [], profiles=''  } = formData;
        if (label) {
          this.form.label = label;
        }
        this.form.title = title;
        this.form.profiles = profiles;
      },
      resetForm() {
        this.$refs.optionForm.resetFields();
      },
      handleClose(tag) {
        this.form.label.splice(this.form.label.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          if (this.form.label.indexOf(inputValue) < 0) {
            this.form.label.push(inputValue);
          }
        }
        this.inputVisible = false;
        this.inputValue = '';
      }
    },
    computed: {
      showAddTagBtn() {
        return !this.inputVisible && this.form.label.length < this.maxTagLimit
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  $border_color: #666;
  .image-setting-option{
    :deep(.el-select){
      width: 320px;
    }

    :deep(.el-button){
      width: 241px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      font-size: 14px;
    }
    :deep(.el-form-item__label){
      text-align: left;
    }
    :deep(.el-input__inner){
      border: 1px solid #333;
      color: #999;
    }
    :deep(.el-upload--picture-card){
      width: 241px;
      height: 136px;
    }
    :deep(.el-textarea__inner) {
      color: #999 !important;
    }
    :deep(textarea) {
      resize: none;
      border: 1px solid #333;
    }
    :deep(.el-input__count){
      background: transparent;
      color: #666;
    }
    :deep(.el-input__count-inner){
      background: transparent;
      color: #666;
    }
  }
  
  </style>
  