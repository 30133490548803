<template>
  <div class="UserAvatar" :style="{ width: size + 'px', height: size + 'px'}">
    <img :src="finalAvatar" alt="">
  </div>
</template>
  
<script lang="ts">
export default {
  name: 'UserAvatar',
  props: {
    src: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 120,
    },
    userName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
    isEnglishLetters (str) {
      let pattern = /^[a-zA-Z]+$/; // 定义只能由大小写字母组成的正则表达式

      return pattern.test(str); // 返回测试结果
    }
  },
  computed: {
    finalAvatar () {
      if (this.src) return this.src
      if (!this.userName) return require('@/assets/knowledge/avatar-default.png')

      let first = this.userName.charAt(0)
      let isZH = this.isEnglishLetters(first)

      if (isZH) {
        return `https://cdn.litgate.ai/gate/avatar/96/${ first.toUpperCase() }.png`
      } else {
        return require('@/assets/knowledge/avatar-default.png')
      }
    }
  }
}
</script>
  
<style lang="scss" >
.UserAvatar {
  border-radius: 50%;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
</style>