import request from '@/utils/request';

// 收藏和点赞
const BaseUrl = `/api/sns/user`;
const BaseGuest = `/api/sns`;

// 获取用户收藏数据
export async function getUserCollect(data: object) {
  return request({
    url: `${BaseUrl}/getcollect`,
    method: 'POST',
    data
  })
}

export async function getGuestCollect(data: object) {
  return request({
    url: `${BaseGuest}/getcollectuser`,
    method: 'POST',
    data
  })
}

// 替换收藏数据
export async function updateUserCollect(data: object) {
  return request({
    url: `${BaseUrl}/replacecollect`,
    method: 'POST',
    data
  })
}

// 删除收藏数据
export async function deleteUserCollect(data: object) {
  return request({
    url: `${BaseUrl}/delcollect`,
    method: 'POST',
    data
  })
}

// 点赞
export async function userLike(data: object) {
  return request({
    url: `${BaseUrl}/like`,
    method: 'POST',
    data
  })
}
