import request from '@/utils/request';


const BaseUrl = `/api/sns/user`;
const BaseGuestUrl = `/api/sns`;

//查询关注我的人
export async function getFollowers(data: any) {
  return request({
    url: `${BaseUrl}/getfollowee`,
    method: 'post',
    data
  })
}

//查询我关注的人
export async function getFollower(data: any) {
  return request({
    url: `${BaseUrl}/getfollower`,
    method: 'post',
    data
  })
}

//查询他关注的人
export async function getGuestFollower(data: any) {
  return request({
    url: `${BaseGuestUrl}/getfolloweruser`,
    method: 'post',
    data
  })
}

//查询关注他的人
export async function getGuestFollowers(data: any) {
  return request({
    url: `${BaseGuestUrl}/getfolloweeuser`,
    method: 'post',
    data
  })
}

//关注操作
export async function followOption(data: any) {
  return request({
    url: `${BaseUrl}/followop`,
    method: 'post',
    data
  })
}



