<template>
  <div :class="{'gate-editor': true, 'gate-editor-scrollTop': isScrollTop}" ref="gateEditor" :style="{minHeight: height + 'px'}">
    <div ref="anchorEle" class="anchor-line"></div>
   <Editor
    :api-key="tinyApiKey"
    :init="getEditorConfig()"
    tag-name="div"
    :height="height"
    v-model="editorData"
    ref="tinyEditor"
    @input="handleInput"
    ></Editor>
  </div>
</template>

<script lang="ts">
import Editor from '@tinymce/tinymce-vue'
import { gateEditorConfig, tinyApiKey } from '@/config/editor'

// tinymce富文本编辑器
export default {
  name: 'tiny-editor',
  components: { Editor },
  props: {
    height: {
      type: Number,
      default: 500
    },
    hasAnchor: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tinyApiKey: tinyApiKey,
      editorData: '',
      editorConfig: {
        ...gateEditorConfig,
        selector: '#textarea',
        placeholder: 'init',
        height: this.height
      },
      wordCount: 0,
      isScrollTop: false
    }
  },
  mounted() {
    if (this.hasAnchor){
      this.stickAnchorElement();
    }
  },
  methods: {
    getContent() {
      return this.editorData;
    },
    setContent(content) {
      this.editorData = content;
    },
    handleInput() {
      const editor = this.$refs.tinyEditor.editor;
      const wordCount = editor.plugins.wordcount.body.getCharacterCount();
      this.wordCount = wordCount;
      this.$emit('wordCountChange', wordCount);
    },
    stickAnchorElement() {
      const self = this;
      var stickyElement = this.$refs.anchorEle;
      this.observer = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
          if (entry.intersectionRatio <= 0) {
            self.isScrollTop = true;
          } else {
            self.isScrollTop = false;
          }
        });
      });
      this.observer.observe(stickyElement);
    },
    getEditorConfig() {
      this.editorConfig.placeholder = this.$t('views.editorArticle.contentPh')
      return this.editorConfig;
    }
    
  },
  beforeDestroy() {
    if (this.hasAnchor){
      var stickyElement = this.$refs.anchorEle;
      this.observer.unobserve(stickyElement);
      this.observer.disconnect();
    }
  }
}
</script>

<style lang="scss" scoped>
.gate-editor{
  .anchor-line{
    height: 1px;
    width: 1px;
  }
  :deep(.tox-tinymce){
    border: none;
  }
  :deep(.tox .tox-toolbar__group){
    border-right: none !important;
  }
  :deep(.tox .tox-tbtn--bespoke .tox-tbtn__select-label){
    width: 40px;
  }
}
</style>
