<template>
    <div class="knowledge-user-card" v-if="!!userInfo.user_id">
      <div class="user-info">
        <div class="thumb">
          <div class="avatar">
            <img :src="userInfo?.user_url || defaultAvatar" alt="" />
          </div>
          <div class="info">
            <div class="name">{{userInfo.user_name}}</div>
            <div class="introduce">{{userInfo.user_profiles}}</div>
          </div>
        </div>
        <div class="count">
          <div class="count-item">
            <div class="num">{{ userInfo.stat_data?.lore_essay_browse_num || 0 }}</div>
            <div class="name">{{ $t('views.knowledge.articleViews') }}</div>
          </div>
          <div class="count-item">
            <div class="num">{{ userInfo.stat_data?.lore_essay_collect_num || 0 }}</div>
            <div class="name">{{ $t('views.knowledge.favoriteArticle') }}</div>
          </div>
          <div class="count-item">
            <div class="num">{{ userInfo.stat_data?.lore_essay_posts_num || 0 }}</div>
            <div class="name">{{ $t('views.knowledge.writeArticle') }}</div>
          </div>
        </div>
      </div>
      <div class="btn"  @click="onSkipEdit">{{ $t('views.knowledge.postBtn') }}</div>
    </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import defaultAvatar from '@/assets/knowledge/avatar-default.png'
@Component({
    name: 'knowledge-user-card',
    computed: {
      ...mapGetters(['userInfo'])
    }
})
class KnowledgeUserCard extends Vue {

  public userInfo: any;
  public defaultAvatar = defaultAvatar;

  public onSkipEdit () {
    const { userInfo } = this

    if (!userInfo.user_id) {
      this.$router.push({
        path: `/account/login`,
      })
      return
    }

    this.$router.push({
      path: `/editor/article`,
    })
  }
}

export default KnowledgeUserCard
</script>
  
<style lang="scss" scoped>
 .knowledge-user-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    padding-bottom: 64px;
    .user-info{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 16px;
      .thumb {
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        gap: 16px;
  
        .avatar {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #141414;
          flex-shrink: 0;
  
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
  
        .info {
          flex: 1;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          gap: 10px;
  
          .name {
            color: #ddd;
            font-size: 24px;
          }
  
          .introduce {
            color: #666;
            font-size: 14px;
          }
        }
      }
  
      .count {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
  
        .count-item {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .num {
            color: #999;
            font-size: 32px;
          }
  
          .name {
            color: #999;
            font-size: 12px;
          }
        }
      }

    }

    >.btn {
      width: 100%;
      height: 40px;
      background-color: #00fff6;
      font-size: 16px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
</style>