<template>
  <div class="player-setting-card">
    <el-collapse accordion  v-model="activeNames" @change="handleChange">
      <el-collapse-item name='1'>
        <template slot="title">
         <div class="title">角色设定</div>
        </template>
        <div class="option-content">
          <div class="role-setting-wrap" v-for="(item, index) in roleList" :key="index">
            <div class="role-name">玩家{{ index + 1 }}</div>
            <div class="choice">
              <el-select v-model="item.user_suit_id" placeholder="选择套装" style="width: 180px" :disabled="changeDisabled">
                <el-option
                  v-for="rItem in RoleConfig"
                  :key="rItem.key"
                  :label="'套装' + rItem.key"
                  :value="rItem.key">
                </el-option>
              </el-select>
              <el-input
                :disabled="changeDisabled"
                placeholder="输入昵称"
                v-model="item.nick">
              </el-input>
            </div>
            <div class="show">
              <el-input
                type="textarea"
                disabled
                :autosize="{ minRows: 6, maxRows: 6}"
                placeholder="请输入内容"
                style="width: 100%;resize: none"
                v-model="RoleConfig[item.user_suit_id - 1].label">
              </el-input>
            </div>
          </div>
          <el-button type="primary" :loading="loading" @click="submitRoleSetting" v-show="!changeDisabled">确定</el-button>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  </template>
  
  <script>
  import { RoleConfig } from '@/config/aiInspect';
  import { getUid, joinRoom, setRole } from '@/api/gateTown'
  export default {
    props: {
      roomInfo: {
        type: Object,
        default: () => {}
      },
      changeDisabled: {
        type: Boolean,
        default: false
      },
      isCreateGame: {
        type: Boolean,
        default: false
      }
    },
    name: 'player-setting-card',
    components: { 
      
    },
    data() {
      return {
        RoleConfig,
        playerSetting1: '',
        activeNames: ['1'],
        disabled: false,
        loading: false,
        roleList: [
          {
            nick: '',
            user_suit_id: 1,
            uid: '',
            state: false
          },
          {
            nick: '',
            user_suit_id: 1,
            uid: '',
            state: false
          },
        ]
      }
    },
    mounted() {
      
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      submitRoleSetting() {
        if (this.checkInput()) {
          this.loading = true;
          for(let i = 0; i < this.roleList.length; i ++) {
            if (i === 0) {
              this.roleList[i].uid = this.roomInfo.uid;
            }
            if (this.roleList[i].uid) {
              this.setRoleFunc(i);
              continue;
            }
            this.setRoleFunc(i);
          }
          return;
        }
        this.$message.error('请输入昵称');
      },
      async setRoleFunc(index) {
        if (this.roleList[index].uid === '') {
          const uidInfo = await joinRoom({
            room_id: this.roomInfo.room_id
          });
          this.roleList[index].uid = uidInfo.uid;
          console.log('新生成uid:', uidInfo.uid)
        }
        const result = await setRole({
          uid: this.roleList[index].uid,
          room_id: this.roomInfo.room_id,
          nick: this.roleList[index].nick,
          user_suit_id: this.roleList[index].user_suit_id
        });
        if (result.code === 0) {
          this.roleList[index].state = true;
          this.checkSettingResult();
        }
      },
      checkInput() {
        let status = true;
        this.roleList.forEach(item => {
          if (item.nick === '') {
            status = false;
          }
        })
        return status;
      },
      checkSettingResult() {
        let status = true;
        this.roleList.forEach(item => {
          if (!item.state) {
            status = false;
          }
        });
        if (status) {
          // 全部角色创建完毕
          console.log('全部角色创建完毕', this.roleList);
          this.$emit('roleSetting', this.roleList)
        }
        this.loading = false;
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .player-setting-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    :deep(.el-collapse-item__wrap){
      border-bottom: none;
    }
    :deep(.el-collapse){
      border-top: none;
      border-bottom: none;
    }
    :deep(.el-collapse-item__header){
      border-bottom: none;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
    }
    .option-content{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      .role-setting-wrap{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        gap: 8px;
        .role-name{
          font-size: 14px;
          font-weight: bold;
        }
        .choice{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          gap: 8px;
        }
        .show{
          width: 100%;
          :deep(.el-textarea__inner){
            resize: none;
          }
        }
      }
    }
  }
</style>
  