import Vue from 'vue';
import VueI18n from 'vue-i18n';

// import { getLanguage } from '@/utils/storage'
import { Local } from '@/utils/storage';

import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';


import enLocale from './en';
import zhLocale from './zh-cn';
import { Language_Types } from '@/config/stat';
import { languageKey } from '@/config';

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  'zh-cn': {
    ...zhLocale,
    ...elementZhLocale,
  },
};
export const getLocale = () => {
  const cookieLanguage = getLanguage();
  console.log('cookieLanguage', cookieLanguage);
  if (cookieLanguage) {
    return cookieLanguage;
  }

  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'zh-cn';
};

export const getCurrentLanguageType = () => {
  const localeLanguage = getLocale();
  return localeLanguage === 'zh-cn' ? Language_Types.Language_CN : Language_Types.Language_EN
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages,
});

export function getLanguage() {
  return Local.get(languageKey);
}
export function setLanguage(language: string) {
  i18n.locale = language;
  return Local.set(languageKey, language);
}

export default i18n;
