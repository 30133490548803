<template>
  <div class="room-setting-wrap">
    <div class="version-list-warp">
      <el-select v-model="tokenVersion" placeholder="请选择AI版本">
        <el-option
          v-for="(item, index) in modelVersion"
          :key="index"
          :label="item.name"
          :value="item.name">
        </el-option>
      </el-select>
      <el-button type="primary" @click="onAddChat">新增会话<i class="el-icon-circle-plus-outline el-icon--right"></i></el-button>
    </div>

  </div>
</template>
  
<script>
import { modelVersion } from '@/config/tokenTest';

export default {
  name: 'room-setting-wrap',
  data() {
    return {
      modelVersion,
      tokenVersion: '',
    }
  },
  mounted() {
  },
  methods: {
    onAddChat() {
      if (!this.tokenVersion) {
        this.$message.error('请选择A版本');
        return
      }
      let chatId = this.createChatId();
      this.$emit('addChat', chatId, this.tokenVersion)
    },
    createChatId() {
      const min = 10000;
      const max = 99999; 
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }
}
</script>
  
<style lang="scss" scoped>
.room-setting-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  .title{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
  .version-list-warp{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    
  }

}
</style>
  