<template>
    <div class="gate-lang">
      <!-- <div v-for="item in langTypes" :key="item.key" :class="{'lang-option': true, 'active': lang === item.key}" @click="setLang(item)">{{ item.label }}</div> -->
      <div class="lang-box-warp">
        <div class="lang-box">
          <img class="lang-icon" src="@/assets/lang-icons.png" alt="">
          <div>{{ lang }}</div>
          <img class="lang-arrow" src="@/assets/lang-arrow-icon.png" alt="">
        </div>
        <!-- <div class="lang-nav-icon"></div> -->
        <!-- <div>{{ lang }}</div> -->
        <!-- <img src="../../assets/lang-icon.png" alt=""> -->
        <div class="lang-list-warp">
          <div class="lang-list-box">
            <div v-for="item in langTypes" :key="item.key" :class="{'lang-option': true, 'active': lang === item.value}" @click="setLang(item)">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  import { setLitUserData } from '@/api/gateUser';
import { litGateSessionKey } from '@/config';
import { Language_Types } from '@/config/stat';
import { getLanguage, setLanguage } from '@/lang';
import { Local } from '@/utils/storage';
import { mapGetters } from 'vuex';
  export default {
    name: 'lang-dropdown',
    components: { },
    props: {
      currentMenu: {
        type: String,
        default: 'article'
      }
    },
    data() {
      return {
        langTypes: [
          { key: 'zh-cn', label: '简体中文', value: '简体中文' },
          { key: 'en', label: 'English', value: 'EN' },
        ],
        lang: 'en'
      }
    },
    mounted() {
      const cacheLang = getLanguage();

      this.langTypes.forEach(item => {
        if (cacheLang === item.key) {
          this.lang = item.value;
        }
      })

      if (cacheLang) {
        setLanguage(cacheLang);
      } else {
        setLanguage(this.lang);
      }
    },
    methods: {
      setLang(item) {
        this.lang = item.value;
        this.saveUserLanguage(item.key);
      },
      async saveUserLanguage(language) {
        const languageType = language === 'zh-cn' ? Language_Types.Language_CN : Language_Types.Language_EN
        const key = Local.get(litGateSessionKey);
        if (!key){
          setLanguage(language);
          return
        }
        const { user_url, ...otherInfo } = this.userInfo;
        const res = await setLitUserData({
          ...otherInfo,
          user_url: {
            url: user_url,
            platform: 0,
            filesize: 0,
            sha1: '',
          },
          language: languageType
        })
        setLanguage(language);
      }
    },
    watch: {
      '$i18n.locale': function(newLocale) {
        // 当语言切换时执行的逻辑
        this.langTypes.forEach(item => {
          if (newLocale === item.key && this.lang !== item.value) {
            this.lang = item.value;
          }
        })
      },
    },
    computed: {
      ...mapGetters(['userInfo'])
    }
  }
  </script>
  <style lang="scss">
    .gate-lang{
      // height: 30px;
      // display: flex;
      // justify-content: center;
      position: relative;
      .lang-option {
        width: 116px;
        height: 34px;
        padding: 6px 8px;
        // opacity: 0.45;
        cursor: pointer;
        &:hover{
          background: rgba(255, 255, 255, 0.10);
        }
      }
      // .lang-option + .lang-option::before {
      //   content: "/ ";
      //   opacity: 0.45;
      //   font-family: "guideENR", serif !important;
      //   font-size: 16px !important;
      // }
      .active {
        opacity: 1;
        color: #DDD;
        font-weight: 500;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 8px;
          width: 16px;
          height: 16px;
          background: url('@/assets/component-icon.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      .lang-box-warp{
        // width: 82px;
        height: 36px;
        color: var(--text-text-7-adadad, #ADADAD);
        font-size: 16px;
        font-weight: 500;
        // margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .lang-box{
          display: flex;
          align-items: center;
          justify-content: center;
          .lang-icon{
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
          .lang-arrow{
            width: 12px;
            height: 12px;
            margin-left: 2px;
          }
        }
        .lang-nav-icon{
          margin-top: -5px;
          width: 24px;
          height: 24px;
          background: url('@/assets/lang-nav-icon.png') no-repeat;
          background-size: 100% 100%;
        }
        &:hover{
          .lang-list-warp{
            display: block;
          }
        }
        &>img{
        width: 12px;
        height: 12px;
        margin-left: 4px;
      }
      .lang-list-warp{
        width: 132px;
        height: 105px;
        font-size: 14px;
        font-weight: 400;
        color: #999;
        text-align: center;
        position: absolute;
        top: 25px;
        display: none;
        .lang-list-box{
          width: 132px;
          height: 86px;
          padding: 8px;
          margin-top: 18px;
          background: #141414;
          box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
  </style>