import { generateUUID } from "./file";

enum MessageTypes {
  Invalid = 0,
  CreateGroup = 1, // 创建群聊
  JoinGroup = 2, // 加入群聊
  Notice = 3, // 通知
  ServerMessage = 4, // 服务器推送的消息
  ClientMessage = 5, // 客户端推送消息
}

export class WebSocketClient {
    private socket: WebSocket;
    private sendMessages = [] as any[];
    private listener = {} as any;
    private url;
    private uid;
  
    constructor(url: string, uid, listener = {}) {
      this.url = url;
      this.uid = uid;
      this.listener = listener;
      this.connect();
    }
  
    private onOpen(event: Event) {
      console.log('WebSocket connection opened', event);
      if (this.listener.onOpen) {
        this.listener.onOpen(event);
        this.listener.onOpen = null;
        return;
      }
      this.listener.onReConnect && this.listener.onReConnect();
    }

    private connect() {
      this.socket = new WebSocket(this.url, this.uid);
      this.socket.onopen = this.onOpen.bind(this);
      this.socket.onmessage = this.onMessage.bind(this);
      this.socket.onclose = this.onClose.bind(this);
      this.socket.onerror = this.onError.bind(this);
    }

    private dispatch(message) {
      
      if (message.group_notice_request) {
        console.log('接到通知:', message)
        this.listener.onNotice && this.listener.onNotice(message);
        return
      }
      if (message.server_push_msg_request) {
        this.listener.onMessage && this.listener.onMessage(message);
        return
      }
      const target = this.sendMessages.find(item => item.requestId === message.request_id);
      if (target && target.callback) {
        target.callback(message);
      }
    }
  
    private onMessage(event: MessageEvent) {
      let result = event.data;
      try {
        result = JSON.parse(event.data);
        this.dispatch(result);
      } catch (e) {

      }
    }
  
    private onClose(event: CloseEvent) {
      console.log('WebSocket connection closed', event);
      this.listener.onClose && this.listener.onClose(event)
      console.log('重连');
      this.connect();
    }
  
    private onError(event: Event) {
      console.error('WebSocket error:', event);
    }
  
    public send(message: Object, callback: Function) {
      console.log('send message ===>', message)
      const requestId = generateUUID();
      const requestData = {
        timestamp: Date.now(),
        request_id: requestId,
        ...message
      }
      console.log('requestData:', requestData)
      this.sendMessages.push({
        requestId,
        requestData,
        callback: callback
      });
      this.socket.send(JSON.stringify(requestData));
    }
  
    public close() {
      this.socket.close();
    }
  }
  
//   // 使用示例
//   const ws = new WebSocketClient('wss://example.com/ws');
//   ws.send('Hello, WebSocket!');
//   ws.close();