<template>
  <div class="gate-anchor-comment-input-wrap" ref="anchorCommentInput">
    <UserAvatar :src="userInfo.user_url" :userName="userInfo.user_url" :size="40"></UserAvatar>
    <div class="reply-wrap">
      <div class="reply-info" >
        <el-input
          type="textarea"
          :class="{'reply-input': true, 'active': isFocus, 'blur': !isFocus}"
          autosize
          :maxlength="3000"
          :placeholder="$t('comment.commentPlaceHolder')" 
          @focus="isFocus = true"
          v-model="replyComment">
        </el-input>
      </div>
      <div class="reply-tool-container" v-if="isFocus">
        <div class="tool-wrap"></div>
        <el-button :class="{'sendBtn': true, 'disableBtn': replyComment === ''}" plain @click="sendComment" :loading="isLoading">{{ $t('comment.postBtn') }}</el-button>
      </div>
    </div>
    <div class="reply-noLogin-wrap" v-if="false">
      <div class="tips">{{ $t('comment.loginTips') }}</div>
      <div class="login-btn" @click="gotoLogin">{{ $t('comment.loginBtn') }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import UserAvatar from '@/components/userAvatar/index.vue'
import { clearAndLogout } from '@/utils/request';
import { mapGetters } from 'vuex';
export default {
  name: 'gate-comment-input',
  components: {
    UserAvatar
  },
  data() {
    return {
      isLoading: false,
      replyComment: '',
      isFocus: false
    }
  },
  mounted() {
    document.addEventListener('click', this.outsideFunc)
  },
  methods: {
    onDivInput: function(e) {
      this.replyComment = e.target.innerHTML;
    },
    async sendComment() {
      if (this.replyComment === '') return;
      this.$emit('onSendComment', this.replyComment);
      this.replyComment = ''
    },
    gotoLogin() {
      clearAndLogout();
    },
    onFocus() {
      this.isFocus = true;
    },
    outsideFunc(e) {
      const container = this.$refs.anchorCommentInput;
      if (container === e.target || container.contains(e.target)) {
        return;
      } 
      this.isFocus = false;
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  beforeDestroy() {
    document.removeEventListener('click', this.outsideFunc)
  },
}
</script>
<style lang="scss" scoped>
.gate-anchor-comment-input-wrap{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  :deep(.el-textarea__inner){
    border: none;
    resize: none;
    .focus{
      
    }
  }
  .reply-wrap{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    width: calc(100% - 64px);
    gap: 8px;
    .reply-info {
      padding: 0px;
      background: #141414;
      width: 100%;
      .reply-input {
        line-height: 22px;
        font-size: 14px;
        color: #999;
        background-color: #141414;
        border-radius: 5px;

        &:empty {
          &:before {
            content: attr(placeholder);
            color: #333;
          }
        }
      }
      .active{
        min-height: 98px;
        padding: 4px 0;
      }
      .blur{
        min-height: 0px;
        padding: 8px 0;
      }
    }
    .reply-tool-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .tool-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
      }
      .sendBtn{
        background: #00FFF6;
        color: #000;
        width: 96px;
        padding: 5px 1px;
        border: none;
        border-radius: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
      .disableBtn{
        background: rgba(0, 255, 246, 0.3);
        color: #000;
        cursor: default;
      }
      .reply-btn-box {
        width: 96px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00FFF6;
        cursor: pointer;
      }
    }
  }
  .reply-noLogin-wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 64px);
    gap: 5px;
    padding: 16px;
    height: 48px;
    background: #141414;
    .tips {
      color: #333;
      font-size: 14px;
    }
    .login-btn{
      width: 96px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00FFF6;
      cursor: pointer;
    }
  }
}
</style>
