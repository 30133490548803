<template>
  <div class="detail-author-card">
    <div class="container">
      <div class="avatar" @click="gotoSeeGuest">
        <img :src="userInfo.user_url || defaultAvatar" />
      </div>
      <div class="info">
        <div class="name" @click="gotoSeeGuest">{{ userInfo.user_name }}</div>
        <div class="introduction">{{ userInfo.user_profiles }}</div>
      </div>
    </div>
    <!-- 关注按钮 -->
    <div v-if="isShowFollow" class="follow-warp">
      <div class="follow-btn" v-if="userInfo.is_followee" @click="handleFollo(folloStatus.unfollow)">{{ $t('following.followedBtn') }}</div>
      <!-- <div class="follow-btn no-follow" v-else-if="userInfo.is_follower && !userInfo.is_followee" @click="handleFollo(folloStatus.follow)">{{ $t('following.mutualFollowing') }}</div> -->
      <div class="follow-btn no-follow" v-else @click="handleFollo(folloStatus.follow)">{{ $t('following.followBtn') }}</div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { getLitUserData } from '@/api/gateUser';
import { followOption } from '@/api/following'
import { FolloStatus } from '@/config/personal'
import defaultAvatar from '@/assets/knowledge/avatar-default.png'
import { Local } from '@/utils/storage';
import { litGateSessionKey } from '@/config';

@Component({
    name: 'detailUserCard'
})
class DetailUserCard extends Vue {
  @Prop({ default: '' }) public userId!: String

  public userInfo = {
    user_name: 'anonymous user',
    user_profiles: "This guy didn't say anything"
  } as any;
  public defaultAvatar = defaultAvatar

  public isShowFollow = true;
  public folloStatus = {
    follow: FolloStatus.follow,
    unfollow: FolloStatus.unfollow,
  }

  mounted() {}

  public async getUserInfo() {
    this.isShowFollow = true;
    getLitUserData({
      user_id: this.userId
    }).then(res => {
      console.log('res=>', res);
      const { data } = res;
      if (data) {
        this.userInfo = data.data;
        const { user_id = '' } =  Local.get(litGateSessionKey) || {};
        //用户是当前作者
        if (user_id && user_id === this.userInfo.user_id) {
          this.isShowFollow = false;
        }
      }
    }).catch((e) => {
      console.log('e:', e);
    })
    // const result = await getLitUserData({
    //   user_id: this.userId
    // });
  }

  public gotoSeeGuest() {
    this.$router.push({ path: '/personal/guest' , query: { id: this.userId }})
  }

  public async handleFollo(operate: number) {
    const res: any = await followOption({
      user_id: this.userId,
      operate
    })
    console.log('handleFollo res', res)

    if (res.code === 0) {
     this.getUserInfo()
    }
  }

  @Watch('userId')
  onUserIdChanged(newUserId: string, oldUserId: string) {
    this.getUserInfo();
  }
}

export default DetailUserCard
</script>
  
<style lang="scss" scoped>
$buttonColor: #00FFF6;
$textColor: #666666;

.detail-author-card {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  .container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    .avatar{
      width: 80px;
      height: 80px;
      min-width: 80px;
      border-radius: 80px;
      overflow: hidden;
      cursor: pointer;
      // background: url(<path-to-image>), lightgray 50% / cover no-repeat, #FFF;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .info{
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
      .name {
        color: #DDD;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
        cursor: pointer;
      }
      .introduction{
        color:  #666;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin-top: 5px;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
      }
    }
  }
  .follow-warp{
    width: 100%;
    .follow-btn{
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: var(--text-text-3666, $textColor);
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      margin-top: 20px;
      border: 1px solid var(--text-text-3666, $textColor);
      &:hover{
        border: 1px solid var(--text-text-2999, #999);
      }
    
    }
    .no-follow{
      color: var(--text-text-2999, $buttonColor);
      border: 1px solid $buttonColor;
      &:hover{
        border: 1px solid #99FFFB;;
      }
    }
  }
}
</style>