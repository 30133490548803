<template>
  <div class="gate-guide-partner">
    <div class="gate-guide-contain">
      <guideTitle :title="$t('views.guide.partner.title')" :hasMore="false"/>
      <div class="guide-partner-container">
        <partnerCard v-for="(item, index) in partnerList" :key="index" :iconPath="item.icon" :title="item.name" :openLink="item.openLink"/>
      </div>
    </div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import partnerCard from './components/partnerCard.vue';
export default {
  name: 'guide-partner',
  components: { guideTitle, partnerCard },
  data() {
    return {
      partnerList: [
        { name: 'CiGA', icon: 'https://cdn.litgate.ai/gate/guide/partnerIcon/CiGA.png', openLink: 'https://www.ciga.me/' },
        { name: 'GameTrigger', icon: 'https://cdn.litgate.ai/gate/guide/partnerIcon/GameTrigger.png', openLink: 'https://www.gametriggervc.com/' },
        { name: 'wayToAGI', icon: 'https://cdn.litgate.ai/gate/guide/partnerIcon/waytoAGI.png', openLink: 'http://waytoagi.com' },
      ]
    }
  },
  mounted() {
     ;
  },
}

</script>

<style lang="scss" scoped>
.gate-guide-partner {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .gate-guide-contain{
    width: 1200px;
    .guide-partner-container{
      margin-top: 60px;
      width: 100%;
      // height: 284px;
      height: 100%;
      // min-height: 284px;
      margin-bottom: 150px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: space-between;
      flex-wrap: wrap;
    }
    .guide-partner-container>div:nth-child(4n+1){
      margin-left: 0px;
      margin-bottom: 5px;
    }
    .guide-partner-container>div{
      margin-left: 4px;
    }
  }
}
</style>
