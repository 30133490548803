<template>
  <div class="article-footer">
    <div class="menu-container">
      <div class="box">
        <div class="info">{{ $t('views.editorArticle.saveTipsPre') }} {{ waitSaveTime }} {{ $t('views.editorArticle.saveTipsSur') }}</div>
        <div class="info">{{ $t('views.editorArticle.total') }} {{ total }}</div>
        <div class="info arrow_up" @click="backToTop">{{ $t('views.editorArticle.backToTop') }}</div>
      </div>
      <div class="box">
        <el-button class="articleBtn normalBtn"  plain @click="onPreview">{{ $t('views.editorArticle.preview') }}</el-button>
        <el-button class="articleBtn normalBtn" plain @click="onSave" v-if="isDraft">{{ $t('views.editorArticle.save') }}</el-button>
        <el-button class="articleBtn postBtn" plain @click="onPost">{{  isDraft ? $t('views.editorArticle.post') :  $t('views.editorArticle.rePostBtn') }}</el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'publish-header',
    props: {
      total: {
        type: Number,
        default: 0
      },
      autoSaveTime: {
        type: Number,
        default: 5
      },
      isDraft: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        timer: null,
        lastSaveTime: Date.now(),
        waitSaveTime: 0,
      }
    },
    mounted() {
      this.initAutoSaveTimer();
    },
    methods: {
      backToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      },
      onPreview() {
        this.$emit('onPreview');
      },
      onSave() {
        this.$emit('onSave');
      },
      onPost() {
        this.$emit('onPost');
      },
      initAutoSaveTimer() {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.countTimeDistance();
        }, 30 * 1000);
      },
      countTimeDistance() {
        const currentTime = Date.now();
        const distance = currentTime - this.lastSaveTime;
        const minutes = Math.floor(distance / (1000 * 60));
        if (minutes >= this.autoSaveTime) {
          this.waitSaveTime = 0;
          this.lastSaveTime = currentTime;
          this.onSave();
          return;
        }
        this.waitSaveTime = minutes;
      }
    },
    destroyed() {
      if (this.timer) {
        clearInterval(this.timer);
      }
    }
  }
  </script>
  <style lang="scss">
  .article-footer {
    padding: 32px 0;
    margin: 0;
    z-index: 1000;
    width: 100%;
    height: 96px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    .menu-container{
      width: 1136px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .info{
          color: #666;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-right: 32px;
          cursor: pointer;
          .info:first-child{
            margin-left: 0px;
          }
        }
        .arrow_up{
          position: relative;
        }
        .arrow_up:after{
          content: "";
          position: absolute;
          top: -2px;
          right: -24px;
          background: url('../../../assets/article/icon/icon_up_arrow.png') no-repeat;
          background-size: 100% 100%;
          width: 24px;
          height: 24px;
        }
        .postBtn:hover{
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #00FFF6;
          background-blend-mode: lighten, normal;
        }
        .postBtn{
          background: #00FFF6;
          color: #000;
        }
        .normalBtn{
          color: #666;
        }
        .normalBtn:hover{
          border: 1px solid #999;

        }
        .articleBtn{
          width: 72px;
          padding: 5px 1px;
          border: 1px solid #666;
          
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
      }
    }
  }
  </style>