// export const TOKEN_NAME = 'kratos_token'
// export const KratosUrl = 'http://127.0.0.1:4433'
export const SessionKey = 'oasis_session';
export const litGateSessionKey = 'litGate_verify_data';
export const ContinueQueryKey = 'oasis_continue_query';
// 登录记录path
export const cacheViewsPath = 'user_views_path';
// session postMessage
export const SessionMsgName = 'oasis_ssn';
export const SessionCbMsgName = 'oasis_ssn_cb';
// language key
export const languageKey = 'language';

export const LangList = [
  {
    key: 'zh-cn',
    name: '中文',
    name_en: '中文',
  },
  {
    key: 'en',
    name: 'ENGLISH',
    name_en: 'ENGLISH',
  },
];

export const CountryList = [
  {
    id: 'China',
    name: '中国',
    name_en: 'China',
  },
  {
    id: 'USA',
    name: '美国',
    name_en: 'United States',
  },
  {
    id: 'UK',
    name: '英国',
    name_en: 'United Kingdom',
  },
  {
    id: 'Japan',
    name: '日本',
    name_en: 'Japan',
  },
  {
    id: 'Korea',
    name: '韩国',
    name_en: 'Korea',
  },
];

export const IssueExpireTime = 60 * 60 * 1000;   // issue过期时间，执行setting时判断

// 平台相关协议配置
export const Policy = {
  MinPasswordLength: 6,
  MaxPasswordLength: 20,
  MinNicknameLength: 2,
  MaxNicknameLength: 20,
  VerifyCodeLength: 6,
  MinIdentifierPasswordDist: 5,
  MaxIdentifierPasswordSubstrThreshold: 0.5,
  // 测试账号
  MinTestAccUserNameLength: 6,
  MaxTestAccUserNameLength: 30,
};


export const verifySessionEffective = 1;

export enum ResponseCode {
  SUCCESS = 0,
  SESSION_INVALID = 100, // session失效
}
