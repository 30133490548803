<template>
  <div class="gate-sign-up">
    <div class="sign-box" v-if="!isSuccess">
      <div class="title">{{ $t('views.signUp.title') }}</div>
      <el-form label-width="0px" ref="form" :model="form" :rules="rules" :style="{ width: '100%'}" class="signup-dynamic">
        <el-form-item label="" prop="userName">
          <el-input v-model.trim="form.userName" :placeholder="$t('views.signUp.userNamePh')"></el-input>
        </el-form-item>
        <el-form-item label="" prop="email">
          <el-input v-model.trim="form.email" :placeholder="$t('views.signUp.emailPh')"></el-input>
        </el-form-item>
        <el-form-item label="" prop="password">
          <el-input v-model.trim="form.password" :placeholder="$t('views.signUp.passwordPh')" show-password></el-input>
          <el-tooltip placement="bottom">
            <div slot="content" class="tips-content" :style="{width: '240px'}">{{ $t('views.signUp.passwordTips') }}</div>
            <div class="tips-icon"></div>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="" prop="verifyCode">
          <el-input v-model.number="form.verifyCode" :placeholder="$t('views.signUp.codePh')" maxlength="6">
            <span
              class="send-btn"
              slot="suffix"
              @click="sendPhoneCode" v-if="sendCodeTime === 0 && sendCodeStat === false">
              {{  $t('views.signUp.send')}}
            </span>
            <span
              class="sending-stat"
              slot="suffix"
              v-if="sendCodeStat">
              {{  $t('views.signUp.sending')}}
            </span>
            <span class="time" slot="suffix" v-if="sendCodeTime > 0">{{ sendCodeTime }}s</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="tips-box">
        <el-checkbox v-model="policyStat"></el-checkbox>
        <div class="sign-up-tips-box">
          <div class="tips">{{ $t('views.signUp.checkPre') }}</div>
          <div class="sign-up-link" @click="gotoTerms">{{ $t('views.signUp.terms') }}</div>
          <div class="tips">{{ $t('views.signUp.and') }}</div>
          <div class="sign-up-link" @click="gotoPolicy">{{ $t('views.signUp.policy') }}</div>
        </div>
      </div>
      <el-button type="primary" class="sign-btn" :loading="loading" @click="startSubmit">{{ $t('views.signUp.signUpBtn') }}</el-button>
      <div class="tool-box">
        <div class="sign-up-tips-box">
          <div class="tips">{{ $t('views.signUp.haveAccount') }}</div>
          <div class="sign-up-link" @click="gotoSignIn">{{ $t('views.signUp.signInTxt') }}</div>
        </div>
      </div>
    </div>
    <div class="sign-result-box" v-if="isSuccess">
      <div class="result-img"></div>
      <div class="result-txt">{{ $t('views.signUp.success') }}</div>
      <el-button type="primary" class="confirm-btn" :loading="loading" @click="gotoSignIn">{{ $t('views.passwordReset.toSignIn') }}</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { accountGetCode, accountRegister } from '@/api/account';
import { cacheViewsPath, litGateSessionKey } from '@/config';
import { RegEmail } from '@/utils/regs';
import { Local } from '@/utils/storage';
import { validateEmail, validateLitGatePasswordReg, validateUserName, validateVerifyCode } from '@/utils/validator';


export default {
  name: 'signIn',
  components: {  },
  data() {
    return {
      rules: {
        userName: [
          { validator: validateUserName, trigger: 'submit' }
        ],
        email: [
          { validator: validateEmail, trigger: 'submit' },
        ],
        password: [
          { validator: validateLitGatePasswordReg, trigger: 'submit' }
        ],
        verifyCode: [
          { validator: validateVerifyCode, trigger: 'submit' }
        ],
      },
      form: {
        userName: '',
        email: '',
        password: '',
        verifyCode: '',
      },
      policyStat: false,
      sendCodeTime: 0,
      sendCodeStat: false,
      maxWaitTime: 60,
      timer: null,
      loading: false,
      isSuccess: false
    }
  },
  mounted() {
   
  },
  methods: {
    gotoTerms() {
      window.open('https://cdn.litgate.ai/gate/doc/agreement/litgatetermsofservice.html');
    },
    gotoPolicy() {
      window.open('https://cdn.litgate.ai/gate/doc/agreement/litgateprivacypolicy.html');
    },
    startSubmit() {
      
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          if (!this.policyStat) {
            this.$message.error(this.$t('views.signUp.checkPolicy'))
            return;
          }
          this.submitSignUp();
        } else {
          return false;
        }
      });
    },
    async submitSignUp() {
      this.loading = true;
      const params = {
        email: this.form.email,
        user_name: this.form.userName,
        user_password: this.form.password,
        check_code: Number(this.form.verifyCode)
      };
      const result = await accountRegister(params);
      this.loading = false;
      if (result.code === 0) {
        Local.set(litGateSessionKey, result.data)
        const cachePath = Local.get(cacheViewsPath);
        if (cachePath){
          Local.remove(cacheViewsPath);
          this.$router.push(cachePath);
          return;
        }
        this.gotoHome();
      }
      if (result.code){
        this.$message.error(this.$t(`accountCode.code${result.code}`))
      }
    },
    async sendPhoneCode() {
      this.$refs.form.validateField(['email'], async (err)=> {
        if (err) {
          return;
        }
        this.sendCodeStat = true;
        const result = await accountGetCode({
          email: this.form.email
        });
        this.sendCodeStat = false;
        console.log(result);
        if (result.code === 0) {
          this.startWaitTime();
          return;
        }
        if (result.code){
          this.$message.error(this.$t(`accountCode.code${result.code}`))
        }
      });
    },
    startWaitTime() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.sendCodeTime = this.maxWaitTime;
      this.timer = setInterval(() => {
        if (this.sendCodeTime > 1) {
          this.sendCodeTime --;
        } else {
          this.sendCodeTime = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    checkEmail() {
      return RegEmail.test(this.form.email);
    },
    gotoSignIn() {
      this.$router.push({ path: '/account/login' })
    },
    gotoHome() {
      this.$router.push({ path: '/gate' })
    }
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
.gate-sign-up{
  background-image: url('@/assets/account/bg.png');
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  min-width: 1280px;
  padding-top: 80px;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .sign-box{
    width: 560px;
    display: inline-flex;
    padding: 40px 64px 64px 64px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    border: 2px solid;
    border-image: linear-gradient(215deg, rgba(56, 254, 238, 0.38), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)) 1;
    background: radial-gradient(115.63% 103.73% at 126.07% -32.66%, rgba(0, 69, 64, 0.99) 0%, rgba(12, 12, 12, 0.99) 100%);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(2.0190839767456055px);
    .title{
      color: #DDD;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 16px;
    }
    .signup-dynamic{
      :deep(.el-form-item__content){
        position: relative;
      }
      :deep(.tips-content){
        width: 240px;
      }
      .tips-icon{
        width: 24px;
        height: 24px;
        background-image: url('@/assets/account/icon_tips.png');
        background-size: 100% 100%;
        position: absolute;
        right: -28px;
        top: 12px;
      }
    }
    .send-btn{
      color: #00FFF6;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      padding: 0 5px;
    }
    .sending-stat{
      color: #00fff790;
      line-height: 40px;
      font-size: 14px;
      padding: 0 5px;
    }
    .time{
      color: #333;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      padding: 0 5px;
    }
    .tips-box{
      display: flex;
      width: 100%;
      align-items: center;
      
      .remember{
        color: #666;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .forget{
        color: #00FFF6;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
      }
    }
    .sign-btn{
      display: flex;
      width: 100%;
      padding: 14px 24px;
      background: #00FFF6;
      justify-content: center;
      color:  #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
    }
    .sign-up-tips-box{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .tips{
          color: #666;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; 
          margin-left: 5px;
        }/* 150% */
        .sign-up-link{
          color: #00FFF6;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          cursor: pointer;
          margin-left: 5px;
        }
      }
    .tool-box{
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
      margin-top: 16px;
      .back-box{
        color: #666;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
      
    }
  }
  .sign-result-box{
    width: 560px;
    display: inline-flex;
    padding: 40px 64px 64px 64px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border: 2px solid rgba(56, 254, 238, 0.38);
    background: radial-gradient(115.63% 103.73% at 126.07% -32.66%, rgba(0, 69, 64, 0.99) 0%, rgba(12, 12, 12, 0.99) 100%);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(2.0190839767456055px);
    .title{
      color: #DDD;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .result-img{
      width: 120px;
      height: 120px;
      background-image: url('@/assets/account/icon_reset_success.png');
      background-size: 100% 100%;
    }
    .result-txt{
      color: #DDD;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }
  }
  .confirm-btn{
    display: flex;
    width: 100%;
    padding: 14px 24px;
    background: #00FFF6;
    color:  #000;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }
}
</style>
