<template>
  <div class="upload-container" ref="dragBox">
    <el-upload
      class="upload-box"
      :file-list="[]"
      :limit="uploadLimit"
      drag
      action=""
      :before-upload="beforeUpload"
      :on-change="onChange"
      :on-exceed="onExceed"
      accept="image/jpeg, image/png, image/jpg"
      multiple>
      <div :class="{'upload-icon': true, 'upload-drag': isDrag}"></div>
      <div class="upload-tips">
        <div class="tips-bold">
          {{ isDrag ? $t('views.postProductImage.releaseUpload') : $t('views.postProductImage.dragUpload') }}
        </div>
        <div class="tips-normal">
          {{ $t('views.postProductImage.dragUploadLimit') }}
        </div>
      </div>
      <div class="btn-box">
        <div class="upload-btn">{{ $t('views.postProductImage.uploadBtn') }}</div>
        <div class="upload-btn" @click.stop="gotoDrafts">{{ $t('views.postProductImage.draftBtn') }}</div>
      </div>
    </el-upload>
    <div class="upload-tips-box">
      <div class="tips-box">
        <div class="tips-title">{{ $t('views.postProductImage.sizeTips') }}</div>
        <div class="tips-content">{{ $t('views.postProductImage.sizeTipsContent') }}</div>
      </div>
      <div class="tips-line"></div>
      <div class="tips-box">
        <div class="tips-title">{{ $t('views.postProductImage.resolutionTips') }}</div>
        <div class="tips-content">{{ $t('views.postProductImage.resolutionTipsContent') }}</div>
      </div>
      <div class="tips-line"></div>
      <div class="tips-box">
        <div class="tips-title">{{ $t('views.postProductImage.formatTips') }}</div>
        <div class="tips-content">{{ $t('views.postProductImage.formatTipsContent') }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PersonalNavTypes } from '@/config/personal';

export default {
  name: 'post-product-upload-view',
  components: { },
  props: {
    uploadLimit: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      isDrag: false
    }
  },
  mounted() {
    this.addDragListener();
  },
  methods: {
    addDragListener() {
      const target = this.$refs.dragBox;
      if (target) {
        target.addEventListener('dragover', this.onDragover);
        target.addEventListener('dragleave', this.leaveDragover);
      }
    },
    onDragover(e) {
      e.preventDefault();
      this.isDrag = true;
    },
    leaveDragover() {
      this.isDrag = false;
    },
    onExceed() {
      console.log('onExceed')
      this.$message.error(this.$t('views.postProductImage.limitError'))
    },
    onChange(file, files) {
      const checkResult = this.checkImage(file);
      if (!checkResult) return;
      this.$emit('onUpload', file)
      return false;
    },
    beforeUpload(file) {
      return false
    },
    gotoDrafts() {
      this.$router.push({path: `/personal/home?tabIndex=${PersonalNavTypes.Draft}`})
    },
    checkImage(file) {
      if (file.raw) {
        const { size  } = file.raw;
        if (size > 30 * 1012 * 1024) {
          return false
        }
        return true;
      }
      return false;
    }
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped>
  .upload-container{
    margin-top: 80px;
    width: 1136px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .upload-box{
      width: 100%;
      height: 340px;
      background: #0F0F0F;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      :deep(.el-upload){
        width: 100%;
        height: 100%;
        .el-upload-dragger{
          width: 100%;
          height: 340px;
          border: none;
          background: transparent;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 24px;
        }
      }
      .upload-icon{
        width: 120px;
        height: 120px;
        background-image: url('@/assets/postProduct/icon_upload.png');
        background-size: 100% 100%;
      }
      .upload-drag{
        background-image: url('@/assets/postProduct/icon_upload_active.png');
        background-size: contain;
      }
      .upload-tips{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        .tips-bold{
          font-size: 24px;
          font-weight: 400;
          color: #ddd;
        }
        .tips-normal{
          font-size: 14px;
          font-weight: 500;
          color: #666;
        }
      }
      .btn-box{
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        gap: 40px;
        .upload-btn{
          padding: 4px 24px;
          color: #999;
          font-size: 16px;
          border: 1px solid #999;
          font-weight: 500;
        }
      }
    }
    .upload-tips-box{
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
      gap: 56px;
      .tips-box{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        .tips-title{
          color: #999;
          font-size: 16px;
          font-weight: 400;
        }
        .tips-content{
          color: #666;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .tips-line{
        width: 1px;
        background: rgba(255, 255, 255, 0.15);
        height: 24px;
      }
    }
  }
</style>
