<template>
    <div class="case-carousel-card" :style="{ background: 'url(' + bgPath + ') no-repeat', backgroundSize: '100% 100%', width: width + 'px', height: height + 'px' }">
      <div class="info">
        <div class="title">
          {{ title }}
        </div>
        <div class="introduction">
          {{ introduction }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'case-carousel-image-card',
    props: {
      width: {
        type: Number,
        default: 1200
      },
      height: {
        type: Number,
        default: 680
      },
      title: {
        type: String,
        default: ''
      },
      bgPath: {
        type: String,
        default: ''
      },
      introduction: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
      }
    },
    mounted() {
       ;
    },
    methods: {
      clickMore() {
        this.$emit('onMore');
      }
    }
  }
  
  </script>
  
  <style lang="scss" scoped>
  .case-carousel-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .info{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px 24px;
      .title{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; 
      }
      .introduction{
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        opacity: 0.6;
      }
    }
  }
  </style>
  