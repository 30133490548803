import request from '@/utils/request';

const BaseUrl = `/api/auth`;

// 账号注册
export async function accountRegister(data: any) {
  return request({
    url: `${BaseUrl}/accountregister`,
    method: 'post',
    data
  });
}
// 账号登录
export async function accountLogin(data: any) {
  return request({
    url: `${BaseUrl}/accountlogin`,
    method: 'post',
    data
  });
}
// 重置密码
export async function accountResetPwd(data: any) {
  return request({
    url: `${BaseUrl}/accountresetpwd`,
    method: 'post',
    data
  });
}
// 账号重置密码验证码
export async function accountResetPwdCode(data: any) {
  return request({
    url: `${BaseUrl}/accountresetpwdcode`,
    method: 'post',
    data
  });
}
// 账号注册获取验证码
export async function accountGetCode(data: any) {
  return request({
    url: `${BaseUrl}/accountgetcode`,
    method: 'post',
    data
  });
}

