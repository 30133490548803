<template>
  <div class="card-wrap" @click="onClick">
    <div class="card">
      <div class="thumb" v-lazy-container="{ selector: 'img', error: require('@/assets/knowledge/card-normal-default.png'), loading: require('@/assets/knowledge/card-normal-default.png') }">
        <img
          :data-src="value?.other_data?.medias?.cover"
          alt="">
      </div>
      <div class="mixed">
        <div class="info">
          <UserAvatar class="avatar" :src="value.user_data?.user_url" :userName="value.user_data?.user_name" :size="46"></UserAvatar>
          <div class="name">{{value?.user_data?.user_name}}</div>
          <div class="time">{{formatTime(value.release_time)}}</div>
        </div>
        <div class="content" v-html="value.title || value.works_title"></div>
        <div class="option">
          <div class="option-item">
            <div class="icon">
              <img src="@/assets/statIcon/icon_views.png" alt="" />
            </div>
            <div class="count">{{formatCount(value.stat_data && value.stat_data.browse_num)}}</div>
          </div>
          <div :class="{'option-item': true, active: value.is_like == 1 }">
              <div class="icon">
                  <img src="@/assets/knowledge/icon-likes.png" alt="" />
              </div>
              <div class="count">{{formatCount(value.stat_data && value.stat_data.like_num)}}</div>
          </div>
          <div class="option-item">
            <div class="icon">
                <img src="@/assets/knowledge/icon-comments.png" alt="" />
            </div>
            <div class="count">{{ value.stat_data && formatCount((Math.max(value.stat_data.comment_num, 0))) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { normalizeDate, formatDate } from '@/utils/format'
import { abbreviateNumber } from '@/utils/statFormat'
import UserAvatar from '@/components/userAvatar/index.vue'

@Component({
  name: 'CardNormal',
  components: {
    UserAvatar
  },
})
class CardNormal extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
  })
  public value

  public onClick () {
    this.$emit('click', this.value)
  }

  public formatTime (time: string) {
    let nTime = normalizeDate(time)
    if (!nTime) return ''
    return formatDate(nTime, 'YYYY/mm/dd')
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }
}

export default CardNormal
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.card-wrap {
  width: 320px;
  overflow: visible;

  .card {
    width: 100%;
    background-color: $black_light;
    cursor: pointer;
    overflow: visible;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 3px solid #00fff6;
      box-shadow: 0px 12px 32px 0px rgba(0, 255, 246, 0.3);
      display: none;
      pointer-events: none;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    &:hover::after {
      display: block;
    }
    &:hover{
      .thumb{
        img{
          transform: scale(1.1);
        }
      }
    }

    .thumb {
      width: 100%;
      height: 180px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }
    }

    .mixed {
      width: 100%;
      padding: 6px 16px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .info {
        padding-left: 56px;
        width: 100%;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .avatar {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: -24px;
          background-color: $black_light;
          border: 1px solid rgba(255, 255, 255, 0.30);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          color: $gray_9;
          font-size: 12px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .time {
          color: $gray_6;
          font-size: 12px;
          flex-shrink: 0;
          margin-left: 8px;
        }
      }

      .content {
        width: 100%;
        height: 48px;
        font-size: 16px;
        line-height: 24px;
        color: $gray_d;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin: 12px 0;
        word-break: break-all;
      }

      .option {
        width: 100%;
        display: flex;
        align-items: center;

        .option-item+.option-item {
          margin-left: 16px;
        }

        .option-item {
          display: flex;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .count {
            font-size: 12px;
            margin-left: 2px;
            color: $gray_6;
          }
        }
      }
    }
  }
}
</style>