import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from '@/lang';
import store from './store';
import { AReport } from '@/api/report';
import VueLazyload from 'vue-lazyload'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/index.scss';

Vue.use(VueLazyload)

Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value),
});

Vue.prototype.$report = AReport;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    // Vue.prototype.$bus = this
  },
}).$mount('#app');
