<template>
  <div class="gate-editor" id="gateEditor">
   <div :id="cherryId" ></div>
  </div>
</template>

<script lang="ts">
import 'cherry-markdown/dist/cherry-markdown.css';
import Cherry from 'cherry-markdown';
import { markdownEditorConfig, cherryId } from '@/config/editor'

// cherry-markdown 编辑器
export default {
  name: 'cherry-markdown-editor',
  components: { Cherry },
  props: {
    height: {
      type: Number,
      default: 500
    },
  },
  data() {
    return {
      tinyApiKey: 'ub8oh8oe6s7gfeda06lspuse75v5qdhk1i39zmxziaw30x3y',
      editorData: '',
      cherryId: cherryId,
      editorConfig: {
        ...markdownEditorConfig,
        id: cherryId,
        height: this.height
      },
      cherryEditor: null
    }
  },
  mounted() {
    console.log('height:', this.height)
    this.initEditor();
  },
  methods: {
    initEditor() {
      console.log('cherry config:',this.editorConfig)
      console.log('cherry config:', Cherry)
      this.cherryEditor = new Cherry({
        id: this.cherryId,
        value: '# welcome to cherry editor!'
      });
      console.log('cherry:', this.cherryEditor)
    },
    getContent() {
      console.log('get content')
      return this.cherryEditor.getMarkdown();
    },
    getHtml() {
      console.log('get content')
      return this.cherryEditor.getHtml();
    },
    setContent(content) {
      this.cherryEditor.setMarkdown(content);
    }
  }
}
</script>

<style lang="scss" scoped>
.gate-editor{
  .ck-content { height:500px; }
  ::v-deep .ck-editor__editable{
    min-height: 400px !important;
  }
}
</style>
