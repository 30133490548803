<template>
  <div class="gate-game-works">
    <div class="game-works-banner" v-if="advertise_info.length > 0" :style="{backgroundImage: 'url(' + getAdvertiseCover(advertise_info[currentIndex]) + ')'}">
      <div class="banner-mask"></div>
      <div class="banner-mask-gradient"></div>
      <div class="game-works-banner-container">
        <BannerCarousel @change="changeCover">
          <div class="game-works-banner-cover" v-for="(item, index) in advertise_info" :key="index" :style="{backgroundImage: getAdvertiseStyle(item, index)}" @click="gotoDetailFromBanner(item)">
            <div class="advertise-content" :style="{ opacity: currentIndex == index ? 1 : 0 }">
              <div class="top">
                <div class="title">{{ getAdvertiseTitle(item) }}</div>
                <div class="release-time">{{ $t('views.gameWorks.releaseTime') }}: {{ showAdvertiseTime(item) }}</div>
                <div class="tags" v-if="item.g_id">
                  <div class="tag" v-for="(curTag, index) in getAdvertiseTag(item)" :key="index">{{ curTag }}</div> 
                </div>
                <div class="introduction">{{ item.description }}</div>
              </div>
              <div class="bottom"></div>
            </div>
          </div>
        </BannerCarousel>
      </div>
    </div>
    <div class="game-works-banner-spacer" v-if="advertise_info.length === 0"></div>
    <!-- 游戏demo -->
    <div class="game-works-game-demo">
      <div class="game-demo-container">
        <div class="list-head">
          <div class="title">{{ $t('views.gameWorks.gameDemoTitle') }}</div>
          <div class="more" v-if="gameDemoData.length >= 6 && artCreativityDemo.length > 0" @click="onClickMore('1')">{{$t('views.knowledge.more')}} ></div>
        </div>
        <div class="content">
          <div class="card-box" v-for="(item, index) in gameDemoData" :key="index">
            <InfoCard
                class="list-item"
                :value="item"
                :cover="item.other_data.medias.cover"
                :tags="item.base_data.label || []"
                @click="onClickCard"
            ></InfoCard>
          </div>
        </div>
      </div>
    </div>
    <!-- 美术创意 -->
    <div class="game-works-game-demo" v-if="artCreativityDemo.length !== 0">
      <div class="game-demo-container art-creativity-warp">
        <div class="title">{{ $t('views.gameWorks.creativityTitle') }}</div>
        <div class="content">
          <div class="img-card-box">
            <ImgCard :dataList="artCreativityDemo" @click="onClickImgCard" @artloading="artloading"></ImgCard>
          </div>
        </div>
      </div>
    </div>
    <!-- 美术创意弹框 -->
    <div>
      <ArtsDetails  @updateArtValue="getWorksById" ref="artDetail"></ArtsDetails>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import InfoCard from '@/components/card/infoCard.vue'
import BannerCarousel from './component/bannerCarousel.vue';
import ImgCard from './component/imgCard.vue'
import ArtsDetails from '@/components/artDialog/artsDetails.vue';
import { getAdvertise } from '@/api/gateUser';
import { AdvertiseTypes } from '@/config/stat';
import { batchGetGameWorks, getAllGameWorks, getGameWorks } from '@/api/gameWorks';
import { GameWorksTypes } from '@/config/gameWorks';
import { DateTimeFormatOptions } from 'vue-i18n';
import { exportPV } from '@/utils/auth';
import { getLanguage } from '@/lang';

@Component({
    name: 'gameWorks',
    components: {
      InfoCard,
      ImgCard,
      BannerCarousel,
      ArtsDetails
    },
})
class GameWorksHome extends Vue {
  public currentIndex= 0;

  public advertise_info = [] as any[];
  public advertise_works = [] as any[];
  public gameDemoPages= {
    current: 0,
    size: 8,
    total: 8
  };
  public gameDemoData = [] as any[];
  public loading = false;
  public artCreativityPages = {
    works_limit: 12,
    works_offset: 0,
    total: 0
  }
  // 美术创意
  public artCreativityDemo = [] as any[];
  public artValue = {};
  public artCreativityLoading = true;

  mounted() {
    this.fetchAdvertisePos();
    this.fetchGameWorksAll();
    
    if (this.$route.query.pictDetailsId) {
      this.getWorksById(this.$route.query.pictDetailsId);
    }
    window.addEventListener('scroll', this.scrollEnd)
    exportPV();
  }

  @Watch('$i18n.locale')
  onI18nLocaleChange(val: string) {
    this.resetPage();
  }

  public async resetPage() {
    window.removeEventListener('scroll', this.scrollEnd)
    this.gameDemoPages.current = 0;
    this.artCreativityPages.works_offset = 0;
    this.artCreativityDemo = [];
    this.gameDemoData = [];
    await this.fetchArtCreativitysAll();
    await this.fetchGameWorksAll();
    window.addEventListener('scroll', this.scrollEnd)
  }
  public onClickFollow(e: any) {
    console.log('follow', e)
  }

  public changeCover(item) {
    this.currentIndex = item;
  }
  public async fetchAdvertisePos() {
    const result = await getAdvertise({
      advertise_type: AdvertiseTypes.Pos_Works_Home
    })
    if (result.code === 0 && result.data) {
      const { data: { advertise_info = [] } } = result;
      if (advertise_info){
        this.advertise_info = advertise_info;
        const g_ids = [] as any[];
        advertise_info.forEach((item) => {
          if (item.g_id) {
            g_ids.push(item.g_id);
          }
        });
        this.fetchAdvertiseDetail(g_ids);
      }
    }
    this.fetchArtCreativitysAll();
  }
  public async fetchAdvertiseDetail(ids) {
    const result = await batchGetGameWorks({
      works_id: ids
    })
    if (result.code === 0) {
      if (result.data) {
        const { data: base_data = [] } = result;
        this.advertise_works = base_data.base_data || [];
      }
      
    }
    console.log('fetchAdvertiseDetail:', result)
  }

  public gotoDetailFromBanner(item) {
    if (item.g_id) {
      this.gotoGameWorksDetail(item.g_id);
      return;
    }
    const target = item.web_url.indexOf('http') == 0 ? item.web_url : `https://${item.web_url}`; 
    window.open(target);
  }

  public gotoGameWorksDetail(id) {
    window.open(`/gameworks/detail?id=${id}`);
  }

  public async fetchGameWorksAll() {
    console.log('fetchGameWorksAllfetchGameWorksAllfetchGameWorksAll')
    const result = await getAllGameWorks({
      works_limit: this.gameDemoPages.size,
      works_offset: this.gameDemoPages.current,
      works_type: GameWorksTypes.Works_Type_Game_Demo,
      // search_name: ''
    });
    if (result.code === 0) {
      const { data: { base_data = [], works_total = 0 } } = result;
      this.gameDemoData = this.gameDemoData.concat(base_data);
      this.gameDemoPages = {
        ...this.gameDemoPages,
        total: Number(works_total)
      };
    }
  }

  public onClickCard(item) {
    window.open(`/gameworks/detail?id=${item.works_id}`);
  }

  public onClickCardLike(value: any) {
    console.log('onClickCardLike', value)
  }

  public scrollEnd() {
    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    var clientHeight = document.documentElement.clientHeight;
    var scrollHeight = document.documentElement.scrollHeight;
    //有美术创意数据
    if (this.artCreativityDemo.length > 0) {
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        if (this.artCreativityDemo.length >= this.artCreativityPages.total || this.artCreativityLoading) {
          return;
        }
        this.fetchArtCreativutyNext();
      }
      return;
    }

    if (scrollTop + clientHeight >= scrollHeight - 20) {
      if (this.gameDemoData.length >= this.gameDemoPages.total || this.loading) {
        return;
      }
      console.log('fetch')
      this.fetchNextPage();
    }
  }

  public async fetchNextPage() {
    this.gameDemoPages.current ++;
    this.loading = true;
    await this.fetchGameWorksAll();
    this.loading = false;
  }

  public getAdvertiseCover(item) {
    if (item.images) {
      return item.images;
    }
    if (!item.g_id) {
      return '';
    }
    const result = this.advertise_works.find(cur => {
      return cur.works_id == item.g_id
    });
    return result?.other_data?.medias?.cover || '';
  }
  public getAdvertiseTag(item) {
    if (!item.g_id) {
      return [];
    }
    const result = this.advertise_works.find(cur => {
      return cur.works_id == item.g_id
    });
    return result?.base_data?.label || [];
  }

  public getAdvertiseBrowseNum(item) {
    if (!item.g_id) {
      return 0;
    }
    const result = this.advertise_works.find(cur => {
      return cur.works_id == item.g_id
    });
    return result?.stat_data?.browse_num || 0;
  }
  public getAdvertiseTitle(item) {
    if (item.advertise_name) {
      return item.advertise_name;
    }
    const result = this.advertise_works.find(cur => {
      return cur.works_id == item.g_id
    });
    return result?.works_title || '';
  }


  public showAdvertiseTime(item) {
    const { up_time, start_time, end_time } = item;
    if (up_time || Number(up_time) !== 0) {
      return this.formatTime(up_time);
    }
    if (start_time || Number(start_time) !== 0) {
      return this.formatTime(start_time);
    }
    return this.formatTime(end_time);
  }

  public formatTime(seconds) {
    const date = new Date(Number(seconds) * 1000);
    const options = { year: 'numeric', month: 'short', day: 'numeric' } as DateTimeFormatOptions;
    if (getLanguage() == 'zh-cn') {
      return date.toLocaleDateString('zh-CN', options);
    }
    return date.toLocaleDateString('en-US', options);
  }

  public getAdvertiseStyle(item, index) {
    if (this.currentIndex === index) {
      return 'linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), url(' + this.getAdvertiseCover(item) + ')'
    }
    return 'linear-gradient(0deg, rgba(0, 0, 0, 0.50) 100%, rgba(0, 0, 0, 0.50) 100%), url(' + this.getAdvertiseCover(item) +')';
  }

  //获取美术创意数据
  public async fetchArtCreativitysAll() {
    const result = await getAllGameWorks({
      works_limit: this.artCreativityPages.works_limit,
      works_offset: this.artCreativityPages.works_offset,
      works_type: GameWorksTypes.Works_Type_Art_Creativity,
    });
    console.log('fetchArtCreativitysAll:', result);
    if (result.code === 0) {
      this.artCreativityPages.total = parseInt(result.data.works_total);
      this.artCreativityDemo = result.data.base_data;
      if (this.artCreativityDemo.length === 0 && this.advertise_info.length === 0) {
        this.fetchNextPage();
      }
    }
  }

  //美术创意数据分页加载
  public async fetchArtCreativutyNext() {
    this.artCreativityLoading = true;
    this.artCreativityPages.works_offset ++;
    const result = await getAllGameWorks({
      works_limit: this.artCreativityPages.works_limit,
      works_offset: this.artCreativityPages.works_offset,
      works_type: GameWorksTypes.Works_Type_Art_Creativity,
    });

    if (result.code === 0 && result.data.base_data.length > 0) {
      let newList = result.data.base_data;
      let oldList = this.artCreativityDemo;
      this.artCreativityDemo = [...oldList, ...newList]
    }

  }

  public artloading() {
    this.artCreativityLoading = false;
  }

  public getMinColumn (arr) {
    let min = Math.min(...arr);
    return arr.indexOf(min) !== -1 ? arr.indexOf(min) : 0;
  }

  public onClickImgCard(item: any) {
    console.log('onClickImgCard', item)
    this.artValue = item;
    this.$refs.artDetail.openPop(item);
  }

  //id查询作品
  public async getWorksById(id: any, option=null) {
    const result = await getGameWorks({works_id: id});
    if (result.code === 0) {
      //修改数据
      if (option === 'change') {
        this.artValue = result.data.base_data;
        return
      }
      this.onClickImgCard(result.data.base_data);
    }
  }

  //打开更多
  public onClickMore(id: string) {
    this.$router.push({
      path: `/gameworks/more?id=${id}`,
    })
  }

  destroy() {
    window.removeEventListener('scroll', this.scrollEnd)
  }
}
export default GameWorksHome
</script>

<style lang="scss">
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$light_txt: #fff;
$black_light: #141414;
$container_width: 1712px;
@media screen and (max-width: 1919px) {
  .gate-game-works{
    min-width: 1680px;
    .game-works-banner{
      height: 744px;
    }
  }
}
@media screen and (max-width: 1679px) {
  .gate-game-works{
    min-width: 1280px;
    .game-works-banner{
      height: 567px;
    }
  }
}
@media screen and (min-width: 1920px) {
  .gate-game-works{
    min-width: 1920px;
    .game-works-banner{
      height: 851px;
    }
  }
}
.gate-game-works{
  min-height: calc(100vh - 86px);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(199deg, rgba(0, 0, 0, 0.60) 25.14%, rgba(0, 0, 0, 0.00) 59.56%);
  position: relative;
  .game-works-banner{
    width: 100%;
    padding: 120px 0 36px 0;
    display: flex;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100% auto;
    background-position: top;
    position: relative;
    .banner-bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .banner-mask{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.60);
    }
    .banner-mask-gradient{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg,rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 100%);
      // background: linear-gradient(199deg, rgba(0, 0, 0, 0.60) 25.14%, rgba(0, 0, 0, 0.00) 59.56%);
    }
    @media screen and (min-width: 1920px) {
      .game-works-banner-container{
        width: 1712px;
        height: 635px;
      }
      .game-works-banner-cover{
        width: 1136px;
        padding: 56px;
      }
    }
    @media screen and (max-width: 1919px) {
      .game-works-banner-container{
        width: 1472px;
        height: 545px;
      }
      .game-works-banner-cover{
        width: 976px;
        padding: 48px;
      }
    }
    @media screen and (max-width: 1679px) {
      .game-works-banner-container{
        width: 1072px;
        height: 398px;
      }
      .game-works-banner-cover{
        width: 711px;
        padding: 35px;
      }
    }
   
    .game-works-banner-container{
    }
    .game-works-banner-cover{
      height: 100%;
      background-repeat:  no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      .advertise-content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        transition: all ease-in-out 0.5s;
        .top{
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          .title{
            color: $light_txt;
            font-size: 60px;
            font-style: normal;
            font-weight: 600;
            line-height: 90px; /* 150% */
          }
          .release-time{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 150% */
          }
          .tags{
            margin-top: 8px;
            display: flex;
            height: 30px;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            overflow: hidden;
            .tag{
              margin-right: 8px;
              background: rgba(0, 0, 0, 0.30);
              padding: 5px 8px;
              color: $light_txt;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
          .introduction{
            margin-top: 18px;
            color: rgba(255, 255, 255, 0.80);
            // width: calc(50% - 56px);
            width: 462px;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 120% */
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
        .bottom{
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          align-items: flex-start;
          .views{
            color: $light_txt;
            font-size: 34px;
            font-style: normal;
            font-weight: 500;
            line-height: 50px; /* 264.706% */
          }
          .view-tips{
            color: rgba(255, 255, 255, 0.80);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 214.286% */
          }
          .more{
            margin-top: 18px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            padding: 3px 16px;
            color: $light_txt;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 214.286% */
          }
        }
      }
    }
  }
  .game-works-banner-spacer{
    width: 100%;
    height: 165px;
  }
  .game-works-game-demo{
    width: 100%;
    display: flex;
    padding: 0 104px;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1920px) {
      .game-demo-container{
        width: 1712px;
      }
    }
    @media screen and (max-width: 1919px) {
      .game-demo-container{
        width: 1472px;
        .content{

        }
      }
    }
    @media screen and (max-width: 1679px) {
      .game-demo-container{
        width: 1072px;
      }
    }
    
    
    .game-demo-container{
      height: 100%;
      .list-head{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .more{
          color: #999;
          font-size: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      .title{
        color: $gray_d;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      
      .content{
        padding: 32px 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .card-box{
          margin-left: 16px;
          margin-bottom: 16px;
        }
        .card-box:nth-child(4n + 1){
          margin-left: 0;
        }
      }
      
    }
  }
  .art-creativity-warp{
    // position: relative;
    // min-height: 1000px;
    .img-card-box{
      // position: absolute;
      // left: 0;
      // top: 0;
    }
  }
  
}
</style>