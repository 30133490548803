<template>
  <div class="single-player-card">
    <el-collapse accordion  v-model="activeNames" @change="handleChange">
      <el-collapse-item name='1'>
        <template slot="title">
         <div class="title">{{ player }}</div>
        </template>
        <div class="option-content">
          <!-- <div class="switch">
            <div :class="{'switch-btn': true, 'active': isChat}" @click="isChat = true">聊天</div>
            <div :class="{'switch-btn': true, 'active': !isChat}" @click="isChat = false">行为</div>
          </div> -->
          <div class="behavior-wrap" v-show="isChat">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 4}"
              placeholder="请输入内容"
              v-model="chatContent">
            </el-input>
          </div>
          <div class="behavior-wrap" v-show="!isChat">
            <div class="behavior">
              <el-select v-model="behaviorTarget" placeholder="交互对象" style="width: 120px">
                <el-option
                  v-for="item in behaviorConfig"
                  :key="item.action_id"
                  :label="item.action_label"
                  :value="item.action_id">
                </el-option>
              </el-select>
              <el-select v-model="behavior" placeholder="行为结果" style="width: 300px" v-if="!currentBehaviorIsGame">
                <el-option
                  v-for="item in getBehaviorList"
                  :key="item.answer_no"
                  :label="item.label"
                  :value="item.answer_no">
                </el-option>
              </el-select>
              <el-input
                v-if="currentBehaviorIsGame"
                placeholder="输入分数"
                type="number"
                :min="0"
                v-model="gameScore">
              </el-input>
            </div>
          </div>
          <div class="btn-wrap">
            <el-button type="primary" :disabled="disabled" @click="submitPlayerBehavior">提交</el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  </template>
  
  <script>
  import { behaviorConfig } from '@/config/aiInspect'
  
  export default {
    name: 'player-setting-card',
    props: {
      player: {
        type: String,
        default: '玩家A'
      },
      roleInfo: {
        type: Object,
        default: () => {}
      }
    },
    components: { 
      
    },
    data() {
      return {
        isChat: true,
        behaviorConfig,
        chatContent: '',
        activeNames: ['1'],
        disabled: false,
        behaviorTarget: '',
        behavior: '',
        gameScore: 0
      }
    },
    mounted() {
      
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      async submitPlayerBehavior() {
        if (this.isChat) {
          if (this.chatContent !== '') {
            const result = await this.$emit('singleChat', {
              uid: this.roleInfo.uid,
              user_ask: this.chatContent,
              roleInfo: this.roleInfo
            });
            this.chatContent = '';
            return;
          }
          this.$message.error('请输入聊天内容　')
          return;
        }
        if (this.behaviorTarget !== ''){
          const targetAction = behaviorConfig.find(item => item.action_id === this.behaviorTarget);
          console.log(targetAction);
          if (targetAction.isGame) {
            this.$emit('singleGame', {
              uid: this.roleInfo.uid,
              roleInfo: this.roleInfo,
              action_type: 1,
              action_id: this.behaviorTarget,
              action_repeat: 1,
              game_score: Number(this.gameScore)
            });
            return;
          }
          if (this.behavior !== '') {
            this.$emit('singleAction', {
              uid: this.roleInfo.uid,
              roleInfo: this.roleInfo,
              action_type: 1,
              action_id: this.behaviorTarget,
              answer_no: this.behavior,
              action_repeat: 1
            });
          }
        }
      }
    },
    computed: {
      getBehaviorList: function() {
        const targetBehavior = this.behaviorConfig.find(item => item.action_id === this.behaviorTarget);
        if (targetBehavior) {
          return targetBehavior.single
        }
        return []
      },
      currentBehaviorIsGame: function() {
        const targetBehavior = this.behaviorConfig.find(item => item.action_id === this.behaviorTarget);
        if (targetBehavior) {
          return targetBehavior.isGame || false
        }
        return false
      },

    }
  }
  </script>
  
<style lang="scss" scoped>
  .single-player-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    :deep(.el-collapse-item__wrap){
      border-bottom: none;
    }
    :deep(.el-collapse){
      border-top: none;
      border-bottom: none;
    }
    :deep(.el-collapse-item__header){
      border-bottom: none;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
    }
    .option-content{
      .switch{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .switch-btn {
          border: 1px solid #dcdfe6;
          border-radius: 20px;
          padding: 4px 30px;
          cursor: pointer;
        }
        .active{
          color: #409eff;
          border: 1px solid #409eff;
        }
      }
      .behavior-wrap{
        margin-top: 12px;
        .player-name{
          font-size: 14px;
          font-weight: bold;
          padding: 5px 0;
        }
        .behavior{
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
        }
      }
      .btn-wrap{
        margin-top: 16px;
      }
      
      .fade-enter-active, .fade-leave-active {
        transition: opacity 0s;
      }

      .fade-enter, .fade-leave-to {
        opacity: 0;
      }
    }
  }
</style>
  