<template>
  <div class="inspect-chat-list">
    <div v-for="(item, index) in chatList" :key="index">
      <div :class="item.activity ? 'chat-list-warp activity' : 'chat-list-warp'" @click="onClickChat(item.id)">
        <div class="chat-list-item">
          <div :class="item.version.indexOf('gpt') >= 0 ? 'chat-gpt-icon': 'chat-claude-icon'"></div>
          <div class="chat-info-warp">
            <div class="chat-info">
              <div class="chat-version">{{ item.version }}</div>
              <div class="chat-msg" v-if="item.chatInfo.msgType === MsgType.Text || item.chatInfo.msgType === MsgType.Image">{{ item.chatInfo.msg }}</div>
              <div class="chat-audio-warp" v-if="item.chatInfo.msgType === MsgType.TextConvert">
                <div>音频文件</div>
                <img :src="require('@/assets/aiInspect/tokenTest/chat-audio-icon.png')" alt="">
              </div>
            </div>
            <div class="chat-option-warp">
              <div class="chat-time">{{ item.chatInfo.timer }}</div>
              <div class="chat-option">
                <div class="delete-btn">
                  <el-popover
                    placement="bottom-end"
                    width="150"
                    v-model="item.isDelPop">
                    <p>是否删除对话？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="item.isDelPop=false">取消</el-button>
                      <el-button type="primary" size="mini" @click="onDelChat(item, index)">确定</el-button>
                    </div>
                    <i class="el-icon-delete" slot="reference"></i>
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MsgType } from '@/config/tokenTest';

export default {
  name: 'inspect-chat-list',
  props: {
    chatInfo: {
      type: Object,
      default: () => {}
    },
    chatList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return{
      MsgType,
    }
  },
  methods: {
    onClickChat(id) {
      this.$emit('clickChat', id);
    },
    onDelChat(item, index) {
      this.chatList[index].isDelPop = false;
      this.$emit('delChat', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.inspect-chat-list{
  width: 100%;
  gap: 12px;
}
.activity{
  background: #e5effa;
  border-radius: 8px;
}
.chat-list-warp{
  width: 100%;
  gap: 12px;
  .chat-list-item{
    border-radius: 8px;
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    margin-bottom: 5px;
    padding: 0 14px;
    position: relative;
    min-height: 71px;
    .chat-gpt-icon{
      width: 42px;
      height: 38px;
      background: url('@/assets/aiInspect/tokenTest/gpt4.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 15px;
      border-radius: 50%;
    }
    .chat-claude-icon{
      width: 42px;
      height: 38px;
      background: url('@/assets/aiInspect/tokenTest/claude-3.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 15px;
      border-radius: 50%;
    }
    .chat-info-warp{
      width: 100%;
      min-height: 71px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .chat-info{
        height: 100%;
        min-height: 71px;
        .chat-version{
          color: #0a0a0a;
          font-weight: 600;
          margin-top: 10px;
          line-height: 25px;
        }
        .chat-msg{
          width: 230px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .chat-audio-warp{
          display: flex;
          align-items: center;
          &>img{
            width: 35px;
            margin-top: 1px;
            margin-left: 5px;
          }
        }
      }
      .chat-option-warp{
        .chat-time{
          min-height: 12px;
          font-size: 11px;
          color: #a2aeb8;
        }
        .chat-option{
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 5px;
          .delete-btn{
            cursor: pointer;
            &:hover{
             .el-icon-delete{
                color: #5cb6ff;
             } 
            }
          }
        }
      }
    }
  }
}

.el-popover{
  background: #FFFFFF !important;
}
</style>