<template>
  <div class="gate-password-reset-in">
    <div class="reset-box" v-if="!isSuccess">
      <div class="title">{{ $t('views.passwordReset.title') }}</div>
      <el-form label-width="0px" ref="form" :model="form" :rules="rules" :style="{ width: '100%'}" class="password-reset-dynamic">
        <el-form-item label="" prop="password">
          <el-input v-model.trim="form.password" :placeholder="$t('views.passwordReset.passwordPh')" show-password></el-input>
          <el-tooltip placement="bottom">
            <div slot="content" :style="{width: '240px'}">{{ $t('views.signUp.passwordTips') }}</div>
            <div class="tips-icon"></div>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="" prop="checkPassword">
          <el-input v-model.trim="form.checkPassword" :placeholder="$t('views.passwordReset.passwordSd')" show-password @keyup.enter.native="sendPassword"> </el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" class="confirm-btn" :loading="loading" @click="sendPassword">{{ $t('views.passwordReset.confirm') }}</el-button>
    </div>
    <div class="reset-box" v-if="isSuccess">
      <div class="result-img"></div>
      <div class="result-txt">{{ $t('views.passwordReset.success') }}</div>
      <el-button type="primary" class="confirm-btn" :loading="loading" @click="gotoSignIn">{{ $t('views.passwordReset.toSignIn') }}</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { accountResetPwd } from '@/api/account';
import { validateLitGatePasswordReg } from '@/utils/validator'

export default {
  name: 'passwordReset',
  components: {  },
  data() {
    const validatePasswordSnd = (rule: any, value: any, callback: any) => {
      // @ts-ignore
      const password = this?.form?.password;
      if (value === '') {
        callback(new Error(this.$t('error.passwordSnd') as string));
      } else if (value !== password) {
        callback(new Error(this.$t('error.pswNotSame') as string));
      } else {
        callback();
      }
    };
    return {
      rules: {
        password: [
          { validator: validateLitGatePasswordReg,  trigger: 'blur' }
        ],
        checkPassword: [
          { validator: validatePasswordSnd,  trigger: 'blur' }
        ],
      },
      form: {
        password: '',
        checkPassword: '',
      },
      key: '',
      value: '',
      loading: false,
      isSuccess: false
    }
  },
  mounted() {
    this.checkEmailAndCode();
  },
  methods: {
    checkEmailAndCode() {
      const { key, value } = this.$route.query;
      this.key = key;
      this.value = value;
      this.isSuccess = false;
    },
    sendPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitPasswordReset();
        }
      })
    },
    async submitPasswordReset() {
      if (this.key === '' || this.value === '') {
        this.$message.error('链接无效');
        return;
      }
      const params = {
        key: this.key,
        value: this.value,
        newPassword: this.form.password
      }
      this.loading = true;
      const result = await accountResetPwd(params);
      this.loading = false;
      if (result.code === 0) {
        // this.$message.success(this.$t('views.passwordReset.success'));
        this.isSuccess = true;
        return;
      }
      if (result.code){
        this.$message.error(this.$t(`accountCode.code${result.code}`))
      }
    },
    gotoSignIn() {
      this.$router.push({ path: '/account/login' })
    }
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
.gate-password-reset-in{
  background-image: url('@/assets/account/bg.png');
  background-size: 100% 100%;
  width: 100vw;
  min-width: 1280px;
  padding-top: 80px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .reset-box{
    width: 560px;
    display: inline-flex;
    padding: 40px 64px 64px 64px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border: 2px solid;
    border-image: linear-gradient(215deg, rgba(56, 254, 238, 0.38), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)) 1;
    background: radial-gradient(115.63% 103.73% at 126.07% -32.66%, rgba(0, 69, 64, 0.99) 0%, rgba(12, 12, 12, 0.99) 100%);
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(2.0190839767456055px);
    .title{
      color: #DDD;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .result-img{
      width: 120px;
      height: 120px;
      background-image: url('@/assets/account/icon_reset_success.png');
      background-size: 100% 100%;
    }
    .result-txt{
      color: #DDD;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .password-reset-dynamic{
      :deep(.el-form-item__content){
        position: relative;
      }
      .tips-icon{
        width: 24px;
        height: 24px;
        background-image: url('@/assets/account/icon_tips.png');
        background-size: 100% 100%;
        position: absolute;
        right: -28px;
        top: 12px;

      }
    }
  }
  .confirm-btn{
    display: flex;
    width: 100%;
    padding: 14px 24px;
    background: #00FFF6;
    color:  #000;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
  }
  .reset-success-box{
    
  }
}
</style>
