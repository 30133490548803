<template>
  <div class="player-chat-mg">
    <div class="container-wrap" >
      <div class="name">{{ msgInfo.role.nick_name }}</div>
      <div class="msg-container" >
        <div class="msg">{{ msgInfo.feeds?.content }}</div>
        <div class="time">{{ msgInfo.timer }}</div>
      </div>
    </div>
    <div :class="{'other-avatar': true, 'bonny-avatar': msgInfo.role.nick_name === 'Bonny', 'clyde-avatar': msgInfo.role.nick_name === 'Clyde'}">
      <!-- <div class="ai-avatar-txt">AI</div> -->
    </div>
  </div>
  </template>
  
  <script>
  import defaultAvatar from '@/assets/knowledge/avatar-default.png'
  import { chatMsgShowModeTypes, MessageTypes } from '@/config/aiInspect'
  export default {
    name: 'player-chat-mg',
    props: {
      msgInfo: {
        type: Object,
        default: () => { return { type: '玩家A', msg: { content: 'this is a test', score: 90 } }},
      },
      allRoleList: {
        type: Array,
        default: () => []
      },
      chatMode: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        defaultAvatar,
        MessageTypes,
        chatMsgShowModeTypes
      }
    },
    mounted() {
      console.log(this.msgInfo, 123, this.allRoleList)
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      getRelationshipLabel() {
        if (!this.msgInfo.feeds) return '';
        const { relationship_change_value = 0, relationship_value, relationship_change_reason = '' } = this.msgInfo.feeds;
        const relationship_value_label = `当前好感度${relationship_value}；`;
        const changeLabel = relationship_change_value >= 0 ? `好感度+${relationship_change_value}，` : `好感度${relationship_change_value}，`
        if (relationship_change_value !== 0) {
          return `AI->${this.msgInfo.targetUser.nick}：${changeLabel}${relationship_value_label}${relationship_change_reason}`
        } else {
          return `AI->${this.msgInfo.targetUser.nick}：好感度无变化，${relationship_value_label}${relationship_change_reason}`
        }
      },
      getRankOne() {
        const targetComment = this.msgInfo.results.find(item => item.user_rank === 1);
        if (targetComment.feeds.relationship_value > 60) {
          const targetUser = this.allRoleList.find(item => item.uid === targetComment.uid);
          return `最终，AI酒保给${targetUser.nick}留下了微信二维码`
        }
        return '没有玩家获得AI酒保的芳心';
      },
      getCommentLabel(comment) {
        const targetUser = this.allRoleList.find(item => item.uid === comment.uid);
        if (targetUser) {
          return `AI->${targetUser.nick}: ${comment.role_comment}`;
        } else {
          return `${comment.role_comment}`;
        }
      },

    }
  }
  </script>
  
<style lang="scss" scoped>
  .player-chat-mg{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    .other-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      border: 1px solid #999;
      background-repeat: no-repeat;
      background-size: cover;
      .ai-avatar-txt {
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 36px;
        font-size: 24px;
      }
    }
    .bonny-avatar{
      background-image: url('@/assets/aiInspect/bonny_avatar.png');
    }
    .clyde-avatar{
      background-image: url('@/assets/aiInspect/clyde_avatar.png');
    }
    
    .container-wrap{
      margin-right: 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-end;
      gap: 8px;
      .name{
        color: #999;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
      }
      .msg-container{
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-end;
        padding: 12px;
        gap: 5px;
        max-width: 540px;
        .title{
          font-size: 14px;
          font-weight: bold;
          color: #000;
          margin-top: 5px;
        }
        .msg{
          font-size: 14px;
          color: #333;
        }
        .time{
          font-size: 12px;
          font-weight: 400;
          color: #999;
        }
      }
      .wait-container{
        width: 240px;
        .wait-txt{
          width: 100%;
          text-align: left;
        }
      }
    }
   
  }
</style>
  