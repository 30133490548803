

export enum Join_Scenes { // 申请加入时的场景
  Edit = 0, // 编辑时
  Act = 1, // 活动页
  Reply = 2 // 重新申请
}

export enum Token_Types {  //tokensvr 申请类型
  Apply_Type_INVALID = 0,         //未知
  Apply_Type_Project = 1,         //加入项目激烈计划
  Apply_Type_Balance = 2,         //申请额度
  Apply_Type_Expire_Time = 3,     //申请额度续期
}

export enum Audit_State { //审核状态
  Status_Default = -1,     // 默认，还未获取状态
  Status_Draft = 0,           //未审核
  Status_Audit_OK = 1,         //审核通过
  Status_Audit_Fail = 2       //审核失败
}

export enum Currency_Types {
  Currency_USD = 0,
  Currency_CNY = 1,
}

// export enum Model_Types {  //模型类型
//   Model_Draft = 0,         //未知
//   Model_OpenAi = 1,         //微软云openai
//   Model_GPT35 = 2,        //GPT3.5
//   Model_GPT4 = 3,        //GPT4
//   Model_Claude = 4,         //aws claude
// }

export enum Model_Types {  //模型类型
  Model_OpenAi_INVALID = 0,
  Model_OpenAi_Chat_GPT35 = 1010,     //OpenAi GPT3.5
  Model_OpenAi_Chat_GPT4 = 1011,     //OpenAi GPT4 
  Model_OpenAi_Chat_GPT4o = 1012,     //OpenAi gpt4o  

  Model_OpenAi_Image_Dalle3 = 1020,     //OpenAi Dalle3

  Model_OpenAi_Speech_tts1 = 1030,     //OpenAi tts-1
  Model_OpenAi_Speech_tts1hd = 1031,     //OpenAi tts-1-hd

  Model_OpenAi_Trancript_wisper = 1040,     //OpenAi wisper

  Model_Claude_Message_haiku = 2010,     //Claude haiku
  Model_Claude_Message_sonnet = 2011,     //Claude sonnet
}

// 申请状态
export enum Token_Apply_State {  //Gate Yes No ENUM_G_8
  Gate_No = 0,       //No
  Gate_Yes = 1,     //Yes
}
