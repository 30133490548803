import { gateReport } from "@/api/gateReport";
import { GateReportTypes } from "@/config/stat";

export function checkLogin() {
    
}

export function exportPV(param_str = []) {
  const currentHref = window.location.href;
  gateReport({
    event_type: GateReportTypes.Report_Event_Type_PV,
    param: [ Math.floor(Date.now() / 1000) ],
    param_str: [currentHref, ...param_str]
  }).catch(e => {});
}
