<template>
  <div class="application-list">
    <div class="main-container">
      <div class="post-box" @click="gotoCreateDemo">
        <div class="create-icon"></div>
        <div class="create-btn">{{ $t('views.createApplication.createBtn') }}</div>
      </div>
      <div class="list-box">
        <DemoListCard v-for="(demo, index) in draftDemoList" :key="'draft' + index"  :value="demo" @onClickDelete="onDelete" @onCollect="handleCollect" @onLike="handleClickLike"/>
        <DemoListCard v-for="(demo, index) in demoList" :key="'demo' + index"  :value="demo" @onClickDelete="onDelete"  @onCollect="handleCollect" @onLike="handleClickLike"/>
      </div>
    </div>
    <DeleteDialog :visible="deleteDialogVisible" :content="$t('deleteDraft.workContent')" @onCancel="cancelDelete"  @onConfirm="delWorks"/>
  </div>
</template>
  
<script>
import { deleteGameWorksAudit, getUserWorks, getUserWorksDraft } from '@/api/gameWorks';
import { GameWorksTypes } from '@/config/gameWorks';
import DemoListCard from './components/demoListCard.vue'
import DeleteDialog from '@/components/dialog/deleteDialog.vue'
import { ArticleAuditStatus } from '@/config/article';
import { CollectTypes, LikeTypes } from '@/config/stat';
import { deleteUserCollect, updateUserCollect, userLike } from '@/api/userLikeCollect';


export default {
  name: 'post-demo-list',
  components: { DemoListCard, DeleteDialog },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      loading: false,
      demoList: [],
      draftDemoList: [],
      pages: {
        current: 0,
        size: 8,
        total: 8
      },
      target: null,
      deleteDialogVisible: false
    }
  },
  mounted () {
    this.fetchWorksList();
    this.fetchWorksDraft();
    window.addEventListener('scroll', this.scrollEnd)
  },
  methods: {
    gotoCreateDemo() {
      this.$router.push({ path: '/application/editworks' })
    },
    async fetchWorksList() {
      const res = await getUserWorks({
        common_limit: this.pages.size,
        common_offset: this.pages.current,
        works_type: GameWorksTypes.Works_Type_Game_Demo,
      })
      if (res.code === 0 && res.data) {
        const { audit_base_data, common_total } = res.data;
        this.pages.total = common_total;
        if (this.pages.current === 0) {
          this.demoList = audit_base_data;
          return;
        }
        this.demoList = this.demoList.concat(audit_base_data);
      }
    },
    async fetchWorksDraft() {
      //获取作品草稿
      const res = await getUserWorksDraft({
        works_type: GameWorksTypes.Works_Type_Game_Demo,
        common_limit: 300,
        common_offset: 0
      })
      if (res.code === 0 && res.data) {
        const { audit_base_data, common_total } = res.data;
        this.draftDemoList = audit_base_data;
      }
    },
    onDelete(item) {
      this.target = item;
      this.deleteDialogVisible = true;
    },
    cancelDelete() {
      this.deleteDialogVisible = false;
    },
    //删除作品
    async delWorks () {
      const res = await deleteGameWorksAudit({
        works_id: this.target.works_id
      })
      this.deleteDialogVisible = false;
      if (res.code === 0) {
        console.log('this.target.status', this.target.status);
        if (this.target.status === ArticleAuditStatus.Status_Draft) {
          this.fetchWorksDraft();
        } else {
          this.pages.current = 0;
          this.fetchWorksList();
        }
      }
    },
      //点赞
    async handleClickLike (item) {
      let isLike = item.is_like ? 0 : 1;
      const { is_like } = item;
      const params = {
        like_type: LikeTypes.Like_Type_Works_Game_Deme,
        gid: item.works_id,
        is_like: isLike,
      }
      const result = await userLike(params)
      if (result.code === 0) {
        item.is_like = !is_like;
        if (is_like) {
          item.stat_data.like_num = Number(item.stat_data.like_num) - 1;
          return;
        }
        item.stat_data.like_num = Number(item.stat_data.like_num) + 1;
      }
    },

     //收藏
    async handleCollect (item) {
      const params = {
        collect_type: CollectTypes.Collect_Type_Works_Game_Deme,
        gid: item.works_id,
      }
      const { is_collect } = item;
      let result
      if (!is_collect) {
        result = await updateUserCollect(params)
      } else {
        result = await deleteUserCollect(params)
      }
      if (result.code === 0) {
        // this.fetchCurrentData();
        item.is_collect = !is_collect;
        if (is_collect) {
          item.stat_data.collect_num = Number(item.stat_data.collect_num) - 1;
          return;
        }
        item.stat_data.collect_num = Number(item.stat_data.collect_num) + 1;
      }
    },
    async fetchNextPage() {
      this.pages.current ++;
      this.loading = true;
      await this.fetchWorksList();
      this.loading = false;
    },
    scrollEnd() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      var clientHeight = document.documentElement.clientHeight;
      var scrollHeight = document.documentElement.scrollHeight;

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        if (this.demoList.length >= this.pages.total || this.loading) {
          return;
        }
        this.fetchNextPage();
      }
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.scrollEnd)
  }
}
</script>
<style lang="scss">
.application-list{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 0px 0px 40px 0;
  .main-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 1136px;
    background: #0f0f0f;
    padding: 32px;
    gap: 40px;
    .post-box{
      height: 176px;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      cursor: pointer;
      padding-bottom: 8px;
      .create-icon{
        width: 120px;
        height: 120px;
        background-image: url('@/assets/postProduct/icon_upload.png');
        background-size: 100% 100%;
      }
      .create-btn{
        padding: 5px 16px;
        font-size: 14px;
        color: #999;
        background: #00FFF6;
        font-style: normal;
        font-weight: 500;
        color: #000;
      }
    }
    .list-box{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>