<template>
  <div class="gate-reply-comment-wrap">
    <div class="reply-wrap" v-if="userInfo.user_id">
      <div class="reply-info" >
        <el-input
          type="textarea"
          :class="{'reply-input': true, 'reply-input-simple': isSimple}"
          autosize
          :maxlength="3000"
          :placeholder="`${$t('comment.replyFor')} @${replyTarget}`" 
          v-model="replyComment">
        </el-input>
      </div>
      <div class="reply-tool-container">
        <div class="tool-wrap"></div>
        <el-button :class="{'sendBtn': true, 'disableBtn': replyComment === ''}" plain @click="sendComment" :loading="isLoading">{{ $t('comment.postBtn') }}</el-button>
      </div>
    </div>
    <div class="reply-noLogin-wrap" v-if="!userInfo.user_id">
      <div class="tips">{{ $t('comment.loginTips') }}</div>
      <div class="login-btn" @click="gotoLogin">{{ $t('comment.loginBtn') }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import UserAvatar from '@/components/userAvatar/index.vue'
import { ReplyModes } from '@/config/stat';
import { clearAndLogout } from '@/utils/request';
import { mapGetters } from 'vuex';
export default {
  name: 'gate-reply-comment',
  components: {
    UserAvatar
  },
  props: {
    replyType: {
      type: Number,
      default: ReplyModes.ForComment
    },
    replyTarget: {
      type: String,
      default: ''
    },
    isSimple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      replyComment: '',
      ReplyModes
    }
  },
  mounted() {
  },
  methods: {
    onDivInput: function(e) {
      this.replyComment = e.target.innerHTML;
    },
    sendComment() {
      if (this.replyComment === '') return;
      this.$emit('onSend', this.replyComment);
      this.replyComment = ''
    },
    gotoLogin() {
      clearAndLogout();
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped>
.gate-reply-comment-wrap{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  .avatar{
    width: 32px;
  }
  .reply-wrap{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    width: calc(100% - 64px);
    gap: 8px;
    .reply-info {
      padding: 0px;
      background: #141414;
      width: 100%;
      .reply-input {
        min-height: 98px;
        line-height: 22px;
        font-size: 14px;
        color: #999;
        padding: 4px 0;
        background-color: #141414;
        border-radius: 5px;

        &:empty {
          &:before {
            content: attr(placeholder);
            color: #333;
          }
        }
        &:focus {
          border: 1px solid #666;
        }
      }
      .reply-input-simple{
        border: 1px solid #333;
        min-height: 36px;
        :deep(.el-textarea__inner){
          max-height: 98px;
        }
      }
    }
    .reply-tool-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .tool-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
      }
      .sendBtn{
        background: #00FFF6;
        color: #000;
        width: 96px;
        padding: 5px 1px;
        border: none;
        border-radius: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
      .disableBtn{
        background: rgba(0, 255, 246, 0.3);
        color: #000;
        cursor: default;
      }
      .reply-btn-box {
        width: 96px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00FFF6;
        cursor: pointer;
      }
    }
  }
  .reply-noLogin-wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 64px);
    gap: 5px;
    padding: 16px;
    height: 48px;
    background: #141414;
    .tips {
      color: #333;
      font-size: 14px;
    }
    .login-btn{
      width: 96px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00FFF6;
      cursor: pointer;
    }
  }
}
</style>
