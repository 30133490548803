<template>
  <div class="single-player-card">
    <el-collapse accordion  v-model="activeNames" @change="handleChange">
      <el-collapse-item name='1'>
        <template slot="title">
         <div class="title">{{ cardTitle() }}</div>
        </template>
        <div class="option-content">
          <div class="switch">
            <div :class="{'switch-btn': true, 'active': isChat}" @click="isChat = true">聊天</div>
            <div :class="{'switch-btn': true, 'active': !isChat}" @click="isChat = false">行为</div>
          </div>
          <div v-if="isChat">
            <div class="chatLine" v-for="(chat, index) in chatList" :key="index">
              <el-select v-model="chat.target" placeholder="聊天方式" style="width: 120px">
                <el-option
                  v-for="item in chatTargets"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
              <el-input v-model="chat.content" placeholder="请输入内容" style="width: 240px"></el-input>
              <el-button icon="el-icon-remove-outline" circle @click="removeChatLine(index)" :disabled="index === 0"></el-button>
            </div>
            <div class="behavior-btn">
              <el-button icon="el-icon-plus"  plain style="width: 100%; height: 40px;" @click="addOneChat">添加对话</el-button>
            </div>
          </div>
          <div class="behavior" v-if="!isChat">
            <el-select v-model="behaviorTarget" placeholder="交互对象" style="width: 120px">
              <el-option
                v-for="item in multiBehaviorConfig"
                :key="item.action_id"
                :label="item.action_label"
                :value="item.action_id">
              </el-option>
            </el-select>
            <el-select v-model="behavior" placeholder="行为结果" style="width: 300px">
              <el-option
                v-for="item in getBehaviorList"
                :key="item.answer_no"
                :label="fillUser(item.label)"
                :value="item.answer_no">
              </el-option>
            </el-select>
          </div>
          <div class="btn-wrap">
            <el-button type="primary" :disabled="disabled" @click="onSubmit">提交</el-button>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
  </template>
  
  <script>
  import { multiBehaviorConfig } from '@/config/aiInspect'
  
  export default {
    name: 'player-setting-card',
    props: {
      players: {
        type: Array,
        default: () => []
      },
      roleList: {
        type: Array,
        default: () => []
      }
    },
    components: { 
      
    },
    data() {
      return {
        isChat: false,
        multiBehaviorConfig,
        activeNames: ['1'],
        disabled: false,
        chatList: [
          { target: '1->2', content: '' }
        ],
        behaviorTarget: [],
        behavior: '',
        chatTargets: ['A->B', 'B->A'],
        chatTarget: ''
      }
    },
    mounted() {
      this.setChatTargetArr();
    },
    methods: {
      addChatLine() {

      },
      handleChange(val) {
        console.log(val);
      },
      addOneChat() {
        this.chatList.push({
          target: this.chatTargets[0],
          content: ''
        })
      },
      onSubmit() {
        if (this.isChat) {
          if (this.chatList.length <= 1 && this.chatList[0].content === '') {
            this.$message.error('请输入聊天内容')
            return;
          }
          this.$emit('multiChat', {
            action_type: 1,
            action_id: 0,
            answer_no: 0,
            action_repeat: 1,
            chatList: this.chatList
          });
          return;
        }
        if (this.behaviorTarget !== '' && this.behavior !== '') {
          const targetAction = multiBehaviorConfig.find(item => item.action_id === this.behaviorTarget);
          const targetAnswer = targetAction.option.find(item => item.answer_no === this.behavior);
          if (targetAction.isGame) {
            // this.$emit('singleGame', {
            //   uid: this.roleInfo.uid,
            //   roleInfo: this.roleInfo,
            //   action_type: 1,
            //   game_id: this.behaviorTarget,
            //   action_repeat: 1,
            //   game_score: this.gameScore
            // });
            return;
          }
          this.$emit('multiAction', {
            action_type: 1,
            action_id: this.behaviorTarget,
            answer_no: this.behavior,
            action_repeat: 1,
            action_desc: this.fillUser(targetAnswer.label)
          });
        }

      },
      removeChatLine(index) {
        if (index === 0) return;
        this.chatList.splice(index, 1);
      },
      cardTitle() {
        let title = '玩家';
        let len = this.roleList.length;
        const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].slice(0, len);
        title += `${arr.join('+')}`;
        return title
      },
      fillUser(label){
        const result = label.replace(/\{0\}/g, this.roleList[0].nick).replace(/\{1\}/g, this.roleList[1].nick);
        return result
      },
      setChatTargetArr() {
        const newArr = [];
        for(let i = 0; i < this.roleList.length; i ++) {
          for (let j = 0; j < this.roleList.length; j ++) {
            if (j !== i) {
              newArr.push(`${i + 1}-> ${j + 1}`)
            }
          }
        }
        this.chatTargets = newArr;
      }
    },
    computed: {
      getBehaviorList: function() {
        const targetBehavior = this.multiBehaviorConfig.find(item => item.action_id === this.behaviorTarget);
        if (targetBehavior) {
          return targetBehavior.option
        }
        return []
      }
    },
    watch: {
      players() {
        if (this.players.length > 2) {

        }
      },
      roleList() {
        const newArr = [];
        for(let i = 0; i < this.roleList.length; i ++) {
          for (let j = 0; j < this.roleList.length; j ++) {
            if (j !== i) {
              newArr.push(`${i + 1}-> ${j + 1}`)
            }
          }
        }
        this.chatTargets = newArr;
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .single-player-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    :deep(.el-collapse-item__wrap){
      border-bottom: none;
    }
    :deep(.el-collapse){
      border-top: none;
      border-bottom: none;
    }
    :deep(.el-collapse-item__header){
      border-bottom: none;
    }
    :deep(.el-button.is-circle){
      border-color: transparent;
    }
    .title{
      font-size: 18px;
      font-weight: bold;
    }
    .option-content{
      .switch{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        .switch-btn {
          border: 1px solid #dcdfe6;
          border-radius: 20px;
          padding: 4px 30px;
          cursor: pointer;
        }
        .active{
          color: #409eff;
          border: 1px solid #409eff;
        }
      }
      .behavior-btn{
        display: flex;
        justify-content: center;
        align-content: center;
        // height: 40px;
        padding: 8px 0;
      }
      .player-name{
        font-size: 14px;
        font-weight: bold;
        padding: 5px 0;
      }
      .btn-wrap{
        margin-top: 10px;
      }
      .behavior{
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        .icon{
          width: 24px;
          height: 24px;
          min-width: 20px;
        }
      }
      .chatLine{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        .icon{
          width: 24px;
          height: 24px;
          min-width: 20px;
        }
      }
    }
  }
</style>
  