import request from '@/utils/request';

// 用户相关接口
const BaseUrl = `/api/works`;
const BaseUserUrl = `/api/works/user`;

// 查询作品投稿
export async function getGameWorksAudit(data: object) {
  return request({
    url: `${BaseUserUrl}/getworksaudit`,
    method: 'POST',
    data
  })
}
// 查询所有作品投稿
export async function getAllGameWorksAudit(data: object) {
  return request({
    url: `${BaseUserUrl}/getworksauditall`,
    method: 'POST',
    data
  })
}
// 设置作品投稿
export async function setGameWorksAudit(data: object) {
  return request({
    url: `${BaseUserUrl}/setworksaudit`,
    method: 'POST',
    data
  })
}

// 删除作品投稿
export async function deleteGameWorksAudit(data: object) {
  return request({
    url: `${BaseUserUrl}/delworksaudit`,
    method: 'POST',
    data
  })
}

// batch查询作品
export async function batchGetGameWorks(data: object) {
  return request({
    url: `${BaseUrl}/batchgetworks`,
    method: 'POST',
    data
  })
}

// 查询作品
export async function getGameWorks(data: object) {
  return request({
    url: `${BaseUrl}/getworks`,
    method: 'POST',
    data
  })
}

// 查询所有作品
export async function getAllGameWorks(data: object) {
  return request({
    url: `${BaseUrl}/getworksall`,
    method: 'POST',
    data
  })
}

// 查询个人作品
export async function getUserWorks(data: object) {
  return request({
    url: `${BaseUserUrl}/userfinish`,
    method: 'POST',
    data
  })
}

// 查询他人作品
export async function getGuestWorks(data: object) {
  return request({
    url: `${BaseUrl}/getworksuserall`,
    method: 'POST',
    data
  })
}

//查询个人作品草稿
export async function getUserWorksDraft(data: object) {
  return request({
    url: `${BaseUserUrl}/userdraft`,
    method: 'POST',
    data
  })
}

