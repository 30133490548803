<template>
  <div :class="{'anchor-action-bar': true, 'anchor-action-bar-normal': classType === 'normal', 'anchor-action-bar-work': classType !== 'normal'}">
    <div class="stat-info-tool" >
      <div class="tool">
        <div class="btn" @click="likeFunc" v-show="showStatTool">
          <div :class="{ 'icon': true, 'like': !isLiked, 'like-active': isLiked }" ></div>
          <div class="info">{{ likeNum }}</div>
        </div>
        <div class="btn" @click="commentFunc" v-show="showStatTool">
          <div class="icon comment"></div>
          <div class="info">{{ commentNum }}</div>
        </div>
        <div class="btn" @click="collectFunc" v-show="showStatTool">
          <div :class="{'icon': true, 'favorite': !isFavorite, 'favorite-active': isFavorite}" ></div>
        </div>
        <el-popover
          v-show="showStatTool"
          placement="bottom"
          v-model="popoverVisible"
          title=""
          width="160"
          trigger="click">
          <ShareCard @share="copyFunc"/>
          <div class="btn shareBtn" slot="reference" >
            <div class="icon share"></div>
          </div>
        </el-popover>
        <div class="btn" @click="toTopFunc" v-show="showTopBtn">
          <div class="icon toTop" ></div>
        </div>
      </div>
    </div>
    <div class="comment-box" v-show="showAnchorComment">
      <anchorCommentInput @onSendComment="onSendComment"/>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ShareCard from './shareCard.vue'
import anchorCommentInput from '@/components/comment/components/anchorCommentInput.vue'
@Component({
    name: 'anchorActionBar',
    components: { ShareCard, anchorCommentInput }
})
class AnchorActionBar extends Vue {
  @Prop({ default: false }) public showStatTool!: boolean
  @Prop({ default: false }) public showAnchorComment!: boolean
  @Prop({ default: false }) public showTopBtn!: boolean
  @Prop({ default: 0 }) public likeCount!: number
  @Prop({ default: false }) public isLiked!: boolean
  @Prop({ default: 0 }) public commentCount!: number
  @Prop({ default: false }) public isFavorite!: boolean
  @Prop({ default: 'normal' }) public classType!: string

  public popoverVisible = false;
  public observer;

  public formatCount(num){
    if (num >= 1000) {
      return `${(num / 1000).toFixed(1)}K`
    }
    return Math.max(num, 0);
  }

  public formatTime(milliseconds) {
    const date = new Date(Number(milliseconds));
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  }

  public onSendComment(content) {
    this.$emit('onSendComment', content)
  }
  public likeFunc() {
    this.$emit('like', !this.isLiked ? 1 : 0)
  }

  public commentFunc() {
    this.$emit('gotoComment')
  }

  public collectFunc() {
    this.$emit('collect', !this.isFavorite)
  }

  public copyFunc() {
    this.popoverVisible = false;
    this.$emit('share');
  }

  public stickAnchorElement() {
    console.log('init');
    var stickyElement = this.$refs.statTool;
    this.observer = new IntersectionObserver(function(entries) {
      console.log('entries:', entries);
      // entries.forEach(function(entry) {
        
      // });
    });
    this.observer.observe(stickyElement);
  }

  public toTopFunc() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  

  get likeNum() {
    return this.formatCount(this.likeCount);
  }

  get commentNum() {
    return this.formatCount(this.commentCount);
  }

}

export default AnchorActionBar
</script>
  
<style lang="scss" scoped>
@media screen and (min-width: 1920px) {
  .anchor-action-bar-normal{
    width: 992px;
  }
  .anchor-action-bar-work{
    width: 1280px;
  }
}
@media screen and (max-width: 1919px) {
  .anchor-action-bar-normal{
    width: 853px;
  }
  .anchor-action-bar-work{
    width: 1100px;
  }
}
@media screen and (max-width: 1679px) {
  .anchor-action-bar-normal{
    width: 613px;
  }
  .anchor-action-bar-work{
    width: 796px;
  }
}
.anchor-action-bar{
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  z-index: 1000;
  gap: 20px;
  .stat-info-tool{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    
    .info{
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }
    .tool {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
      background: linear-gradient(0deg, color(display-p3 0 0 0 / 0.60) 0%, color(display-p3 0 0 0 / 0.60) 100%);
      backdrop-filter: blur(10px);
      padding: 8px 20px;
      pointer-events: all;
      gap: 26px;
      .btn{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .icon{
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
        .icon:first-of-type{
          margin-left: 0;
        }
        .like{
          background: url('../../assets/knowledge/icon_like.png') no-repeat;
          background-size: 100%;
        }
        .like-active{
          background: url('../../assets/knowledge/icon_like_active.png') no-repeat;
          background-size: 100%;
        }
        .favorite{
          background: url('../../assets/knowledge/icon_favorite.png') no-repeat;
          background-size: 100%;
        }
        .favorite-active{
          background: url('../../assets/knowledge/icon_favorite_active.png') no-repeat;
          background-size: 100%;
        }
        .comment{
          background: url('../../assets/knowledge/icon_comment.png') no-repeat;
          background-size: 100%;
        }
        .share{
          background: url('../../assets/knowledge/detail/icon_share.png') no-repeat;
          background-size: 100%;
        }

        .toTop{
          background: url('../../assets/knowledge/detail/icon_to_top.png') no-repeat;
          background-size: 100%;
        }
        .toTop:hover{
          background: url('../../assets/knowledge/detail/icon_to_top_active.png') no-repeat;
          background-size: 100%;
        }

        
        .info {
          color: #666;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; 
          margin-left: 3px;
        }
      }
      .btn-margin-left {
        margin-left: 28px;
      }
      .shareBtn:hover{
        .share{
          background: url('../../assets/knowledge/detail/icon_share_active.png') no-repeat;
          background-size: 100%;
        }
        .info{
          color: #00FFF6;
        }
      }
      .dropdownList{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        background:  #141414;
        padding: 8px;
        box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
        .link-options{
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          align-items: center;
          .icon{
            width: 20px;
            height: 20px;
          }
          .link-icon{
            background: url('../../assets/knowledge/detail/icon_link.png') no-repeat;
            background-size: 100%;
          }
          .text{
            overflow: hidden;
            color: #666;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
          }
          .text:hover{
            color: #ddd;
          }
        }
      }
      
    }
  }
  .comment-box{
    background: #000;
    padding: 20px 12px;
    width: 100%;
    pointer-events: all;
  }
  
}

</style>