<template>
  <div class="font-set" :lang="lang">
    <router-view />
    <GateReport />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import utils from '@/utils'
import { getLanguage, getLocale, setLanguage } from './lang';
import GateReport from '@/components/report/index.vue';
@Component({
  name: 'app',
  components: { GateReport },
})
class App extends Vue {

  public lang = 'en';

  @Watch('route.path')
  onRoutePathChanged(val: string) {
    utils.useTitle()
  }
  @Watch('$i18n.locale')
  onLocaleChanged(val: string) {
    this.lang = val;
  }

  mounted() {
    console.log('getLocale', getLocale())
    const localeLanguage = getLocale();
    setLanguage(localeLanguage);
    this.lang = getLanguage();
  }
}
export default App
</script>
<style lang="scss" scoped>
.font-set {
  padding: 0px;
  // :lang(zh-cn) {
  //   font-family:  sans-serif;
  // }
  
  :lang(en) {
    font-family: 'Poppins';
  }
}
/* 中文语言环境下的字体 */
</style>
