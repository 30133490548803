<template>
  <div class="token-activity-intro-main">
    <ActivityTitle :title="$t('views.tokenActivity.intro.title')" subTitle="ACTIVITY DESCRIPTION"/>
    <div class="content">
      <div class="content-title">
        {{ $t('views.tokenActivity.intro.date') }}
      </div>
      <div class="content-marks">
        {{ $t('views.tokenActivity.intro.markTxt') }}
      </div>
      <div class="content-line" v-html="$t('views.tokenActivity.intro.platformTips')">
      </div>
      <div class="content-line" v-html="$t('views.tokenActivity.intro.target')">
      </div>
    </div>
  </div>
</template>
  
<script>
import ActivityTitle from '../components/activityTitle.vue'
export default {
  components: { ActivityTitle },
  name: 'activity-intro-main',
  data () {
    return {
      activityTime: '2024.06.01-09.01'
    }
  },
  mounted () {
  },

}
</script>
<style lang="scss">
.token-activity-intro-main{
  min-width: 1360px;
  height: 682px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  .content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 1360px;
    height: 490px;
    background: url('@/assets/activity/token/intro-content.png');
    background-size: 100% 100%;
    gap: 24px;
    .content-title{
      min-width: 610px;
      padding: 0 12px;
      height: 88px;
      background: url('@/assets/activity/token/intro-title-border.png');
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00FFEE;
      font-size: 40px;
      font-weight: bold;
    }
    .content-marks{
      width: 713px;
      background: url('@/assets/activity/token/intro-marks.png');
      background-size: contain;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 26px;
      padding: 0 24px;
      text-align: center;
    }
    .content-line{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 26px;
      .high-light-txt{
        color: #00FFEE;
      }
      .pointer {
        text-decoration: underline;
      }
    }
  }

}
</style>