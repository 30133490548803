<template>
  <div class="card-wrap">
    <div class="card">
      <div class="thumb">
        <img src="@/assets/knowledge/avatar-default.png" alt="" />
      </div>
      <div class="mixed">
        <div class="name">unique</div>
        <div class="desc">unique</div>
      </div>
      <div class="btn" @click="onClick">Follow</div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { abbreviateNumber } from '@/utils/statFormat'

@Component({
  name: 'CardAuthor',
  components: {},
})
class CardAuthor extends Vue {
  @Prop({
    required: true,
    default: () => ({})
  })
  public value

  public onClick () {
    this.$emit('click', this.value)
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }
}

export default CardAuthor
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.card-wrap {
  width: 100%;

  .card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .thumb {
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      background-color: $black_light;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .mixed {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0 16px;

      .name {
        color: $gray_9;
        font-size: 20px;
      }

      .desc {
        color: $gray_6;
        font-size: 14px;
      }
    }

    >.btn {
      flex-shrink: 0;
      width: 76px;
      height: 32px;
      font-size: 14px;
      color: $gray_6;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray_6;
      cursor: pointer;

      &:hover {
        border-color: $gray_9;
      }
    }
  }
}
</style>