<template>
  <div class="publish-header">
    <div class="menu-container">
      <div class="logo" @click="gotoHome"><Logo /></div>
      <div class="menu-wrap">
        <div class="menu active'">{{ currentMenu }}</div>
      </div>
      <div class="right-wrap">
        <ApiDocBtn v-if="showApiDoc" />
        <LangDropdown />
        <PublishEntrance />
        <UserCard />
      </div>
    </div>
  </div>
</template>
  
  <script>
  import Logo from '@/components/logo'
  import UserCard from '@/components/card/userWarp.vue'
  import PublishEntrance from '@/layout/components/publishEntrance/index.vue'
  import LangDropdown from '@/layout/components/langDropdown/index.vue'
  import ApiDocBtn from '@/layout/components/apiDocBtn'
  export default {
    name: 'publish-header',
    components: { UserCard, Logo, PublishEntrance, LangDropdown, ApiDocBtn },
    props: {
      currentMenu: {
        type: String,
        default: 'article'
      },
      showApiDoc: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      onSubmit() {
        
      },
      gotoHome() {
        this.$router.push({path: '/gameworks/home'})
      },
      gotoPerson() {
        this.$router.push({path: '/personal/home'})
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  .publish-header {
    padding: 32px 0;
    margin: 0;
    width: 100%;
    height: 113px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;
   
    border-bottom: 1px solid rgba(255, 255, 255, 0.23);
    .menu-container{
      width: 1136px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .logo{
        margin-right: 10px;
        cursor: pointer;
        position: absolute;
        left:  -288px;
      }
      .menu-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .icon-arrow-left{
          width: 24px;
          height: 24px;
          background: url('@/assets/article/icon/arrow_left.png') no-repeat;
          background-size: cover;
        }
        .menu{
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-right: 42px;
        }
      }
      .right-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: row;
        gap: 24px;
      }
    }
    
  }
  </style>