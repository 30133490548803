

export enum Table_Column_Types { // 申请加入时的场景
  Value = 0, // 正常值
  Time = 1, // 时间
  Custom = 2, // 自定义,会调用showTxt函数进行显示，返回的值会显示在表格里
  Detail = 3,
  Action = 4,
  Balance = 5, // 余额
  CanRenewTime = 6, // 可续期时间
}
