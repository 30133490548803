<template>
  <div class="gate-comment-input-wrap" ref="commentInputEle" >
    <UserAvatar :src="userInfo.user_url" :userName="userInfo.user_name" :size="40"></UserAvatar>
    <div class="reply-wrap" v-if="userInfo.user_id">
      <div class="reply-info" >
        <el-input
          type="textarea"
          :class="{'reply-input': true, 'reply-input-simple': isSimple}"
          :maxlength="3000"
          autosize
          :placeholder="$t('comment.commentPlaceHolder')" 
          v-model="replyComment">
        </el-input>
      </div>
      <div class="reply-tool-container">
        <div class="tool-wrap"></div>
        <el-button :class="{'sendBtn': true, 'disableBtn': replyComment === ''}" plain @click="sendComment" :loading="isLoading">{{ $t('comment.postBtn') }}</el-button>
      </div>
    </div>
    <div class="reply-noLogin-wrap" v-if="!userInfo.user_id">
      <div class="tips">{{ $t('comment.loginTips') }}</div>
      <div class="login-btn" @click="gotoLogin">{{ $t('comment.loginBtn') }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import UserAvatar from '@/components/userAvatar/index.vue'
import { clearAndLogout } from '@/utils/request';
import { mapGetters } from 'vuex';
export default {
  name: 'gate-comment-input',
  components: {
    UserAvatar
  },
  props: {
    isSimple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      replyComment: ''
    }
  },
  mounted() {
    this.stickAnchorElement()
    console.log('commentInput:', this.userInfo)
  },
  methods: {
    onDivInput: function(e) {
      this.replyComment = e.target.innerHTML;
    },
    async sendComment() {
      if (this.replyComment === '') return;
      this.$emit('onSend', this.replyComment);
      this.replyComment = ''
    },
    gotoLogin() {
      clearAndLogout();
    },
    stickAnchorElement() {
      const self = this;
      var stickyElement = this.$refs.commentInputEle;
      this.observer = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
          const windowScroll = window.scrollY || 0;
          const offsetTop =  stickyElement.offsetTop;
          if (entry.intersectionRatio <= 0 && windowScroll > (offsetTop - 20)) {
            self.$emit('onShowAnchorComment', true);
          } else {
            self.$emit('onShowAnchorComment', false);
          }
        });
      });
      this.observer.observe(stickyElement);
    },
    
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  beforeDestroy() {
    var stickyElement = this.$refs.commentInputEle;
    this.observer.unobserve(stickyElement);
    this.observer.disconnect();
  },
}
</script>
<style lang="scss" scoped>
.gate-comment-input-wrap{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  .reply-wrap{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    width: calc(100% - 64px);
    gap: 8px;
    .reply-info {
      padding: 0px;
      background: #141414;
      width: 100%;
      .reply-input {
        min-height: 98px;
        line-height: 22px;
        font-size: 14px;
        padding: 4px 0;
        color: #999;
        background-color: #141414;
        border-radius: 5px;
        

        &:empty {
          &:before {
            content: attr(placeholder);
            color: #333;
          }
        }
        // &:focus {
        //   min-height: 78px;
        //   border: 1px solid transparent;
        //   box-shadow: none;
        //   outline: none;
        //   &:before {
        //     content: none;
        //   }
        // }
      }
      .reply-input-simple{
        border: 1px solid #333;
        min-height: 36px;
        :deep(.el-textarea__inner){
          max-height: 98px;
        }
      }
    }
    .reply-tool-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      .tool-wrap{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
      }
      .sendBtn{
        background: #00FFF6;
        color: #000;
        width: 96px;
        padding: 5px 1px;
        border: none;
        border-radius: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
      .disableBtn{
        background: rgba(0, 255, 246, 0.3);
        color: #000;
        cursor: default;
      }
      .reply-btn-box {
        width: 96px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00FFF6;
        cursor: pointer;
      }
    }
  }
  .reply-noLogin-wrap{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 64px);
    gap: 5px;
    padding: 16px;
    height: 48px;
    background: #141414;
    .tips {
      color: #333;
      font-size: 14px;
    }
    .login-btn{
      width: 96px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00FFF6;
      cursor: pointer;
    }
  }
}
</style>
