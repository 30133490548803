// import { TOKEN_NAME } from '@/config'
// import i18n from '@/lang'
// import Cookies from 'js-cookie'
import { verifySession } from '@/api/auth';
import { litGateSessionKey, SessionKey, verifySessionEffective } from '@/config';

/**
 * window.localStorage 浏览器永久缓存
 * @method set 设置永久缓存
 * @method get 获取永久缓存
 * @method remove 移除永久缓存
 * @method clear 移除全部永久缓存
 */
export const Local = {
  // 设置永久缓存
  set(key: string, val: any) {
    let json = '';
    if (typeof val === 'string') json = val;
    else {
      try {
        json = JSON.stringify(val);
      } catch (e) {
        json = val;
      }
    }
    window.localStorage.setItem(key, json);
  },
  // 获取永久缓存
  get(key: string) {
    const json: any = window.localStorage.getItem(key);
    try {
      const pJson = JSON.parse(json);
      return pJson;
    } catch (e) {
      return json;
    }
  },
  // 移除永久缓存
  remove(key: string) {
    window.localStorage.removeItem(key);
  },
  // 移除全部永久缓存
  clear() {
    window.localStorage.clear();
  },
};

/**
 * window.sessionStorage 浏览器临时缓存
 * @method set 设置临时缓存
 * @method get 获取临时缓存
 * @method remove 移除临时缓存
 * @method clear 移除全部临时缓存
 */
export const Session = {
  // 设置临时缓存
  set(key: string, val: any) {
    window.sessionStorage.setItem(key, JSON.stringify(val));
  },
  // 获取临时缓存
  get(key: string) {
    const json: any = window.sessionStorage.getItem(key);
    return JSON.parse(json);
  },
  // 移除临时缓存
  remove(key: string) {
    window.sessionStorage.removeItem(key);
  },
  // 移除全部临时缓存
  clear() {
    window.sessionStorage.clear();
  },
};

export const clearUserSession = () => {
  Local.remove(SessionKey);
  Local.remove(litGateSessionKey);
  // Local.remove('userInfo')
  // Local.remove(TOKEN_NAME)
};

export const verifyUserSession = async () => {
  const oasisSession = Local.get(litGateSessionKey);
  if (oasisSession) {
    const verifyResult = await verifySession(oasisSession)
      // 校验session通过，放行
    if (verifyResult?.data?.in_effect === verifySessionEffective) {
      return true;
    }
    clearUserSession();
  }
  return false;
} 

// const languageKey = 'language'
// export const getLanguage = () => {
// 	Local.get(languageKey)
// }
// export const setLanguage = (language: string) => {
// 	// console.log("i18n", i18n)
// 	Local.set(languageKey, language)
// }
