<template>
  <div class="mixed">
    <div class="user">
      <div class="icon-edit" @click="editPersonalInfo"></div>
      <div class="thumb">
        <div class="avatar">
          <UserAvatar :src="userInfo.user_url" :userName="userInfo.user_name"></UserAvatar>
        </div>
        <div class="name">{{userInfo.user_name}}</div>
        <div class="introduce">{{userInfo.user_profiles}}</div>
      </div>
      <div class="option">
        <div class="option-item" @click="onSkipEdit">
          <div class="icon">
            <img src="@/assets/knowledge/icon-personal-center-1.png" alt="">
          </div>
          <div class="name">{{ $t('addNav.knowledge') }}</div>
        </div>
        <div class="option-item option-filter" @click="onProductEdit">
          <div class="icon">
            <img src="@/assets/knowledge/icon-personal-center-2.png" alt="">
          </div>
          <div class="name">{{ $t('addNav.image') }}</div>
        </div>
        <div class="option-item" @click="gotoDemoPage">
          <div class="icon">
            <img src="@/assets/gateNav/post_gameDemo_icon.png" alt="">
          </div>
          <div class="name">{{ $t('addNav.demo') }}</div>
        </div>
        <!-- <div class="option-item option-disabled">
          <div class="icon">
            <img src="@/assets/knowledge/icon-personal-center-3.png" alt="">
          </div>
          <div class="name">{{ $t('addNav.answer') }}</div>
        </div> -->
      </div>
      <!-- 个人中心统计数据 暂时隐藏 -->
      <!-- <div class="count">
        <div class="count-item">
          <div class="num">{{ postsNum }}</div>
          <div class="name">{{ $t('addNav.posts') }}</div>
        </div>
        <div class="count-item">
          <div class="num">{{ browseSelfNum }}</div>
          <div class="name">{{ $t('addNav.views') }}</div>
        </div>
        <div class="count-item">
          <div class="num">{{ likeNum }}</div>
          <div class="name">{{ $t('addNav.likes') }}</div>
        </div>
      </div> -->
    </div>
    <!-- 个人信息下图片 暂时隐藏 -->
    <!-- <img src="@/assets/knowledge/user-center-pendant.png" alt class="pendant" /> -->
    <InfoEditDialog ref="infoEditDialog" :limit="50"></InfoEditDialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import UserAvatar from '@/components/userAvatar/index.vue'
import InfoEditDialog from './infoEditDialog.vue'

@Component({
  name: 'PersonalCenter',
  components: { UserAvatar, InfoEditDialog },
  computed: {
    ...mapGetters(['userInfo'])
  }
})
class PersonalInfo extends Vue {
  public userInfo: any
  public postInfo = [
    { key: 'addNav.knowledge' },
    { key: 'addNav.product' },
    { key: 'addNav.answer' },
    { key: 'addNav.posts' },
    { key: 'addNav.views' },
    { key: 'addNav.likes' },
  ]

  get postsNum() {
    return this.formatNumber(this.userInfo.stat_data?.lore_essay_posts_num)
  }

  get browseNum() {
    return this.formatNumber(this.userInfo.stat_data?.lore_essay_browse_num)
  }

  get likeNum() {
    return this.formatNumber(this.userInfo.stat_data?.lore_essay_like_num)
  }

  get browseSelfNum() {
    return this.formatNumber(this.userInfo.stat_data?.lore_essay_browse_self_num)
  }

  get likeSelfNum () {
    return this.formatNumber(this.userInfo.stat_data?.lore_essay_like_self_num)
  }
  mounted () {}

  //转换数字
  public formatNumber (num: any) {
    num = parseInt(num)
    if (num >= 1000) {
      const units = [ 'k', 'M', 'B', 'T' ];
      const unitIndex = Math.floor((num.toString().length - 1) / 3) - 1;
      const unitName = units[ unitIndex ];
      const numValue = Math.floor(num / Math.pow(1000, unitIndex + 1));
      return numValue.toString().endsWith('.0') ? numValue.toString().slice(0, -2) + unitName : numValue.toString() + unitName;
    }
    return num.toString();
  }

    /* 点击Post Knowledge */
  public onSkipEdit () {
    this.$router.push({
      path: '/editor/article',
    })
  }

   /* 点击post product */
  public onProductEdit () {
    this.$router.push({
      path: '/postproduct/image',
    })
  }

  public gotoDemoPage() {
    this.$router.push({
      path: '/application/main',
    })
  }

  public editPersonalInfo() {
    this.$refs.infoEditDialog?.openPop(this.userInfo)
  }
}
export default PersonalInfo
</script>

<style lang="scss" scoped>
  .mixed {
    position: absolute;
    top: -240px;
    right: 0;
    width: 416px;
    flex-shrink: 0;

    .user {
      width: 100%;
      background-color: rgba(20, 20, 20, 0.8);
      backdrop-filter: blur(15px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 48px;
      position: relative;

      .icon-edit {
        width: 40px;
        height: 40px;
        background: url(@/assets/knowledge/edit-icon.png) no-repeat center;
        background-size: 100% 100%;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }

      .thumb {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 72px 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .avatar {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #141414;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .name {
          color: #fff;
          font-size: 32px;
          margin: 13px 0 5px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
        }

        .introduce {
          color: rgba(255, 255, 255, 0.5);
          font-size: 20px;
          width: 100%;
          word-wrap: break-word;
          text-align: center;
        }
      }

      .option {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 48px;

        .option-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          .icon {
            width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            color: #999;
            font-size: 12px;
            margin-top: 8px;
          }
        }

        .option-filter{
          .icon {
            img {
              filter: brightness(2.5);
            }
          }
        }

        .option-disabled {
          cursor: not-allowed
        }

      }

      .count {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 48px;

        .count-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;

          .num {
            color: #5fece2;
            font-size: 32px;
          }

          .name {
            color: #999;
            font-size: 12px;
          }
        }
      }

      >.btn {
        margin-top: 40px;
        width: 100%;
        height: 40px;
        background-color: #00fff6;
        font-size: 16px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .pendant {
      width: 100%;
      height: auto;
      margin-top: 24px;
    }
  }
</style>
<style>
</style>

