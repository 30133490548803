<template>
    <div class="drop-down-box">
        <el-select v-model="insideValue" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'Dropdown',
    components: {},
})
class Dropdown extends Vue {
    @Prop({
        required: true,
        default: [],
    })
    public options

    @Prop({
        required: true,
        default: '',
    })
    public value

    public insideValue = ''

    mounted() {
        this.insideValue = this.value
    }

    @Watch('value')
    onValueChanged(val: any) {
        this.insideValue = val
    }

    @Watch('insideValue')
    onInsideValueChanged(val: any) {
        this.$emit('input', val)
    }
}

export default Dropdown
</script>
  
<style lang="scss" >
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;
// .drop-down-box{
//     .el-select-dropdown.el-popper {
//         background-color: $black_light;
//         border: 0;
    
//         .el-select-dropdown__item + .el-select-dropdown__item {
//             margin-top: 2px;
//         }
    
//         .el-select-dropdown__item {
//             background-color: $black_light;
//             padding: 0 8px;
    
//             &.selected {
//                 color: $gray_d;
//                 background-color: rgba(255, 255, 255, 0.1);
//             }
    
//             &:hover {
//                 color: $gray_d;
//                 background-color: rgba(255, 255, 255, 0.1);
//             }
//         }
    
//         .popper__arrow {
//             display: none !important;
//         }
    
//         .el-select-dropdown__list {
//             padding: 6px;
//         }
//         .el-input {
//             &:hover {
//                 background-color: $black_light;
//             }
        
//             .el-input__inner {
//                 width: auto;
//                 min-width: 0;
//                 border: 0!important;
//             }
        
//             .el-icon-arrow-up {
//                 background: url(@/assets/icon-dropdown-arrow.png) no-repeat center
//                     center;
//                 background-size: 16px 16px;
        
//                 &::before {
//                     display: none;
//                 }
//             }
//         }
//     }
// }

</style>