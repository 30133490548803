<template>
  <div class="player-chat-mg">
    <div class="container-wrap">
      <div class="name">{{ msgInfo.roleInfo.nick }}</div>
      <div class="msg-container" v-if="msgInfo.behavior === 'chat'">
        <div class="msg" v-if="msgInfo.msgType === MsgType.Text || msgInfo.msgType === MsgType.TextConvert">{{ msgInfo.msg }}</div>
        <div v-if="msgInfo.msgType === MsgType.Image">
          <div class="image-list" v-for="(item, index) in msgInfo.files" :key="index">
            <img :src="item" alt="">
          </div>
          <div class="msg">{{ msgInfo.msg }}</div>
        </div>
        <div v-if="msgInfo.msgType === MsgType.Audio" class="audio">
          <audio v-if="msgInfo.files.audioUrl" controls :src="msgInfo.files.audioUrl"></audio>
        </div>
        <div class="time">{{ msgInfo.timer }}</div>
      </div>
    </div>
    <div class="user-avatar">
      <img :src="defaultAvatar" />
    </div>
  </div>
  </template>
  
  <script>
  import defaultAvatar from '@/assets/knowledge/avatar-default.png'
  import { MsgType } from '@/config/tokenTest';

  export default {
    name: 'player-chat-mg',
    props: {
      msgInfo: {
        type: Object,
        default: () => { return { type: '玩家A', msg: { content: 'this is a test', score: 90 } }},
      }
    },
    data() {
      return {
        defaultAvatar,
        MsgType
      }
    },
    mounted() {
      console.log(this.msgInfo);
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
  .player-chat-mg{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    .user-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #999;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .container-wrap{
      margin-right: 12px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
      .name{
        color: #999;
        font-size: 14px;
        font-weight: bold;
      }
      .msg-container{
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        padding: 12px;
        gap: 5px;
        max-width: 80%;
        margin-left: 20%;
        min-width: 140px;
        overflow: hidden;
        background-color: rgb(207, 230, 253);
        .title{
          font-size: 14px;
          font-weight: bold;
          color: #000;
        }
        .msg{
          font-size: 14px;
          color: #333;
        }
        .time{
          font-size: 12px;
          font-weight: 400;
          color: #999;
        }
        .image-list{
          img{
            margin-bottom: 5px;
            max-width: 100%;
          }
        }
        .audio{
          min-width: 395px;
        }
      }
    }
  }
</style>
  