<template>
  <div class="gate-post-product">
    <PublishHeader :currentMenu="$t('editorMenus.postProduct')" />
    <uploadView @onUpload="onUpload" v-show="uploadList.length === 0" :uploadLimit="uploadLimit"/>
    <div class="product-setting-container" v-show="uploadList.length > 0">
      <uploadListView :uploadList="uploadList"  @setCover="setCover" @onDelete="onDelete" @onChange="onChange" :uploadLimit="uploadLimit"/>
      <div class="setting-box">
        <ImageSetting ref="imageSetting" />
      </div>
    </div>
    <ImageResourceFooter v-show="uploadList.length > 0" @onSave="onSave"  @onPost="onPost" @onCancel="onCancel" :isDraft="isDraft"/>
  </div>
</template>

<script lang="ts">
import PublishHeader  from '@/layout/publishHeader/index.vue';
import uploadView from './components/uploadView.vue'
import { LiteGateCosUpload } from '@/utils/lsCosUpload';
import { EditorTypes, UploadStateTypes } from '@/config/stat';
import UploadStep from './components/uploadStep.vue';
import ImageSetting from './components/imageSetting.vue';
import ImageResourceFooter from './components/imageResourceFooter.vue';
import UploadListView from './components/uploadListView.vue';
import { throttle } from '@/utils/throttle';
import { DownloadState, GameWorksTypes } from '@/config/gameWorks';
import { getGameWorksAudit, setGameWorksAudit } from '@/api/gameWorks';
import { ArticleAuditStatus } from '@/config/article';
import { generateUUID } from '@/utils/file';
import { PersonalNavTypes } from '@/config/personal';
export default {
  name: 'gate-post-product',
  components: { uploadView, UploadStep, ImageSetting, ImageResourceFooter, UploadListView, PublishHeader },
  data() {
    return {
      uploadList: [],
      imageWorkId: '',
      workId: '',
      uploadLimit: 20,
      isDraft: true
    }
  },
  mounted() {
    const workId = this.$route.query.id;
      if (workId){
        this.workId = workId;
        this.getDraft();
      }
  },
  methods: {
    onChange(file) {
      this.onUpload(file);
    },
    beforeUpload() {
      return false;
    },
    onUpload(file) {
      const uid = file.uid;
      console.log('file:', file);
      const uploadModules = {
        ...file,
        progress: 0,
        state: 0,
        uploadUrl: '',
        isCover: this.uploadList.length > 0 ? false : true
      }
      LiteGateCosUpload.getInstance().upload(file.raw, (url) => {
        this.onSuccess(uid, url);
      }, () => {
        this.onFail(uid);
      }, 'file', (progress) => {
        this.onProgress(uid, progress)
      });
      this.uploadList.unshift(uploadModules);
    },
    onSuccess(uid, url) {
      const target = this.findUploadModulesByUid(uid);
      if (!target) return;
      target.uploadUrl = url;
      target.state = UploadStateTypes.Success
      setTimeout(() => {
        target.state = UploadStateTypes.Ready
      }, 3000);
    },
    onFail(uid) {
      const target = this.findUploadModulesByUid(uid);
      if (!target) return;
      target.state = UploadStateTypes.Fail
    },
    onProgress(uid, progress) {
      const target = this.findUploadModulesByUid(uid);
      if (!target) return;
      target.state = UploadStateTypes.Uploading
      target.progress = progress;
    },
    findUploadModulesByUid(uid) {
      return this.uploadList.find(item => item.uid === uid);
    },
    setCover(target) {
      this.uploadList.forEach(item => {
        if(item.uid === target.uid) {
          item.isCover = true;
        } else {
          item.isCover = false;
        }
      })
    },
    onDelete(target) {
      const index = this.uploadList.findIndex(item => item.uid === target.uid)
      const deleteTarget = this.uploadList.splice(index, 1);
      if (target.isCover) {
        if (this.uploadList.length > 0) {
          this.uploadList[0].isCover = true;
        }
      }
    },
    async getDraft() {
      const result = await getGameWorksAudit({
        works_id: this.workId
      });
      console.log('draft:', result);
      if (result.code === 0 && result.data) {
        const { base_data } = result.data;
        this.restoreDraft(base_data);
        if (base_data.status !== ArticleAuditStatus.Status_Draft) {
          this.isDraft = false;
        }
      }
    },
    onCancel: throttle(function() {
      // this.$router.go(-1);
      this.$refs.imageSetting.resetForm();
      this.uploadList = [];
    }, 2000),
    onSave: throttle(async function() {
      const option = await this.$refs.imageSetting.submitForm();
      console.log(option);
      const params = this.checkOption(option);
      if (params) {
        console.log('params', params);
        params.status = ArticleAuditStatus.Status_Draft;
        const result = await setGameWorksAudit(params);
        if(result.code === 0 && result.data) {
          const { audit_base_data } = result.data;
          const { works_id } = audit_base_data;
          this.$message.success(this.$t('views.postProductImage.saveSuccess'))
          if (works_id && this.workId !== works_id) {
            this.$router.replace({
              path: '/postproduct/image',
              query: {
                id: works_id
              }
            })
          }
          return;
        }
        this.$message.error(this.$t('views.postProductImage.saveError'))
      }
    }, 2000),
    onPost: throttle(async function() {
      const option = await this.$refs.imageSetting.submitForm();
      console.log(option);
      const params = this.checkOption(option);
      if (params) {
        params.status = ArticleAuditStatus.Status_Release_Ing;
        const result = await setGameWorksAudit(params);
        if(result.code === 0 && result.data) {
          this.$message.success(this.$t('views.postProductImage.postSuccess'))
          this.$router.push({path: `/personal/home?tabIndex=${PersonalNavTypes.Image}`})
          return
        }
        this.$message.error(this.$t('views.postProductImage.postError'))
      }
    }, 2000),
    checkOption(option) {
      if (this.uploadList.length < 1) {
        return false;
      }
      const notReadyList = this.uploadList.filter(item => item.state !== UploadStateTypes.Ready)
      if (notReadyList.length > 0) {
        this.$message.error(this.$t('views.postProductImage.imageNotReady'))
        return false;
      }
      // 提取宣传图
      const cover = this.uploadList.find(item => item.isCover);
      const baseData = {
        platform: [],
        label: option.label
      };
      const otherData = {
        tools_id: [],
        lore_essay_id: [],
        medias: {
          cover: cover.uploadUrl
        },
        download: DownloadState.Download_No
      };
      const photoData = this.tidyingUpPhoto();
      const params = {
        works_type: GameWorksTypes.Works_Type_Art_Creativity,
        works_title: option.title,
        profiles: option.profiles,
        works_version: '0.0.1',
        editor_type: EditorTypes.Editor_Type_0,
        base_data: baseData,
        other_data: otherData,
        photo_data: photoData
      } as any;
      if (this.workId) {
        params.works_id = this.workId;
      }
      return params;
    },
    restoreDraft(baseData) {
      const { base_data, profiles, works_title, photo_data, other_data } = baseData;
      const coverUrl = other_data.medias.cover;
      const settingOption = {
        label: base_data.label,
        profiles: profiles,
        title: works_title
      };
      this.$refs.imageSetting.restoreForm(settingOption);
      const uploadList = [] as any[];
      photo_data.forEach(ele => {
        const uploadModules = {
          uid: generateUUID(),
          progress: 100,
          state: UploadStateTypes.Ready,
          uploadUrl: ele.photo,
          isCover: coverUrl === ele.photo
        };
        uploadList.push(uploadModules);
      })
      this.uploadList = uploadList;
    },
    tidyingUpPhoto() {
      const photo = [] as any[];
      this.uploadList.forEach(element => {
        if (element.state === UploadStateTypes.Ready){
          const photoData = {
            prompt: '',
            negative_prompt: '',
            sampler: '',
            model: '',
            cfg_scale: '',
            steps: 0,
            seed: 0,
            photo: element.uploadUrl
          };
          photo.push(photoData);
        }
      });
      return photo;
    }
  },
  beforeDestroy() {
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
.gate-post-product{
  display: flex;
  justify-content: flex-start;
  background: #000;
  flex-direction: column;
  align-items: center;
  min-width: 1280px;
  height: 100vh;
  .product-setting-container{
    width: 1136px;
    margin-top: 80px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    .title-box{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: center;
      .upload-box{
        .upload-text{
          color: #fff;
        }
      }
    }
    .setting-box{
      width: 100%;
      background: #0F0F0F;
      padding: 32px;
    }
  }
}
</style>
