<template>
  <div class="upload-audio-container">
    <el-upload
      class="upload-box"
      :file-list="[]"
      :limit="uploadLimit"
      drag
      action=""
      :before-upload="beforeUpload"
      :on-change="onChange"
      :on-exceed="onExceed"
      accept=".mp3, .webm, .wav, .mpeg, .mpga"
      multiple>
      <div class="upload-img-btn"></div>
    </el-upload>
  </div>
</template>

<script>
import { MsgType } from '@/config/tokenTest';

export default {
  name: 'chat-upload-audio',
  props: {
    uploadLimit: {
      type: Number,
      default: 1
    }
  },
  methods: {
    onExceed() {
      console.log('onExceed')
    },
    onChange(file) {
      console.log('file', file)
      this.$emit('onUpload', file, MsgType.Audio)
      return false;
    },
    beforeUpload() {
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-audio-container{
    text-align: center;
   .upload-img-btn{
    width: 130px;
    height: 120px;
    background: url('@/assets/aiInspect/tokenTest/upload-audio-icon.png') no-repeat;
    background-size: 100% 100%;
  }
  :deep(.el-upload-dragger){
    width: 130px;
    height: 120px;
    border: 0;
    border-radius: 0;
    background: transparent;
  }
}
</style>