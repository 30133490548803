import { render, staticRenderFns } from "./groupSetting.vue?vue&type=template&id=16dd70fc&scoped=true"
import script from "./groupSetting.vue?vue&type=script&lang=js"
export * from "./groupSetting.vue?vue&type=script&lang=js"
import style0 from "./groupSetting.vue?vue&type=style&index=0&id=16dd70fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16dd70fc",
  null
  
)

export default component.exports