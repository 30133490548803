<template>
   <div class="dropdownList">
      <div class="link-options" @click="copyLinkFunc">
        <div class="link-icon icon"></div>
        <div class="text">
          {{ $t('common.copyLink') }}
        </div>
      </div>
    </div>
</template>
  
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { copyToClipboard } from '@/utils/dom';
@Component({
    name: 'shareCard'
})
class ShareCard extends Vue {
  public copyLinkFunc() {
    copyToClipboard(window.location.href);
    const tips = this.$t('tips.succ.copy');
    this.$message.success(tips as String);
    this.$emit('share', 'link')
  }
}

export default ShareCard
</script>
  
<style lang="scss" scoped>
 .dropdownList{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      background:  #141414;
      padding: 8px;
      box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
      .link-options{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        .icon{
          width: 20px;
          height: 20px;
        }
        .link-icon{
          background: url('../../assets/knowledge/detail/icon_link.png') no-repeat;
          background-size: 100%;
        }
        
        .text{
          overflow: hidden;
          color: #666;
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
        }
        
      }
      .link-options:hover{
        .link-icon{
          background: url('../../assets/knowledge/detail/icon_link_active.png') no-repeat;
          background-size: 100%;
        }
        .text{
          color: #ddd;
        }
      }
    }
</style>