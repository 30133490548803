<template>
  <div class="layout-gate-footer">
    <div class="container">
      <Logo styleType="dark" :width="97" :height="30"/>
      <div class="copyright">
        <a>Copyright © {{ getCurrentYear() }}  litgate.ai  All rights reserved</a>
      </div>
      <div class="follow-row">
        <div class="follow-btn" @click="gotoDisCord">
          <div class="icon icon-discord"></div>
        </div>
        <el-popover
          width="200"
          height="200"
          trigger="hover">
          <div :style="{ width: '200px', height: '200px', background: 'url(' + wechatHoverIcon + ') no-repeat', backgroundSize: 'contain' }"></div>
          <div class="follow-btn" slot="reference">
            <div class="icon icon-wechat"></div>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template> 

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Logo from '@/components/logo/index.vue';
import wechatHoverIcon from '../../assets/guide/contact/wechat_hover.png';
@Component({
  name: 'layoutNavFooter',
  components: { Logo },
})
class layoutNavFooter extends Vue {
  @Prop({ default: 'knowledge' }) public currentTab!: String
  @Prop({ default: 0 }) public minWidth!: number

  public wechatHoverIcon = wechatHoverIcon;

  public gotoDisCord() {
    window.open('https://discord.gg/XpAUWcQwuM');
  }

  mounted () {

  }

  public getCurrentYear() {
    return new Date().getFullYear()
  }
  destroy () {
  }
}
export default layoutNavFooter
</script>

<style scoped lang="scss">
@media screen and (min-width: 1920px) {
  .layout-gate-footer{
    min-width: 1920px;
    .container{
      width: 1712px;
    }
  }
}
@media screen and (max-width: 1919px) {
  .layout-gate-footer{
    min-width: 1680px;
    .container{
      width: 1472px;
    }
  }
}
@media screen and (max-width: 1679px) {
  .layout-gate-footer{
    min-width: 1280px;
    .container{
      width: 1072px;
    }
  }
}

.layout-gate-footer {
  width: 100%;
  position: absolute;
  padding: 0 104px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .container{
    height: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .copyright{
      color: #666;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;/* 150% */
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .follow-row{
        margin-top: 5px;
        display: flex;
        .follow-btn{
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 2px;
          margin-left: 8px;
          cursor: pointer;
          .icon{
            width: 24px;
            height: 24px;
          }
          .icon-wechat{
            background: url('../../assets/guide/icon_wechat_dark.png') no-repeat;
            background-size: 100% 100%;
          }
          .icon-discord{
            background: url('../../assets/guide/icon_discord_dark.png') no-repeat;
            background-size: 100% 100%;
          }
          .title{
            text-align: center;
            // 
            font-size: 14px;
            font-style: normal;
            color: #fff;
            font-weight: 400;
            line-height: 150%; 
          }
        }
      }
  }

}

</style>
