<template>
  <div class="ai-single-chat-page" v-loading="waitStartGame"  element-loading-text="等待开始游戏..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.1)">
    <div class="aside-wrap">
      <RoomSetting @updateRoomInfo="updateRoomInfo" :roomId="roomInfo.room_id" :allRoleList="allRoleList"/>
      <PlayerSetting :roomInfo="roomInfo" :isCreateGame="isCreateGame" @roleSetting="roleSetting" v-show="step !== SingleChatState.RoomSetting" :changeDisabled="step !== SingleChatState.RoleSetting"/>
      <SinglePlayer
        :player="`玩家${index + 1}(${item.nick})`"
        v-show="step === SingleChatState.Chat"
        :roleInfo="item"
        v-for="(item, index) in roleInfo"
        :key="index"
        @singleChat="singleChat"
        @singleAction="singleAction"
        @singleGame="singleGame"
        />
      <MultiPlayer
        :roleList="roleInfo"
        v-show="step === SingleChatState.Chat"
        @multiAction="multiAction"
        @multiChat="multiChat"
      /> 
    </div>
    <div class="chat-wrap">
      <div class="tool-wrap">
        <el-select v-model="chatMode" placeholder="显示方式" style="width: 150px">
          <el-option
            v-for="item in modeTypes"
            :key="item.key"
            :label="item.label"
            :value="item.key">
          </el-option>
        </el-select>
        <el-select v-model="chatMsgShowMode" placeholder="消息筛选" style="width: 150px">
          <el-option
            v-for="item in chatMsgShowModes"
            :key="item.key"
            :label="item.label"
            :value="item.key">
          </el-option>
        </el-select>
        <el-button type="primary" :disabled="step !== SingleChatState.Chat" :loading="loading" @click="onEndGame">好感结算</el-button>
      </div>
      <ChatView :chatMsgs="chatMsgs" :allRoleList="allRoleList" :chatMsgShowMode="chatMsgShowMode" :chatMode="chatMode"/>
    </div>
  </div>
  </template>
  <script>
  // el-icon-circle-plus-outline
  import PlayerSetting from './components/playerSetting.vue'
  import SinglePlayer from './components/singlePlayer.vue';
  import MultiPlayer from './components/multiPlayer.vue';
  import ChatView from './components/chatView.vue';
  import RoomSetting from './components/roomSetting.vue';
  import { modeTypes, chatMsgShowModes, SingleChatState, chatMsgShowModeTypes, MessageTypes } from '@/config/aiInspect'
  import { endChat, endGame, playAction, playGame, startChat, startGame, userChat, getRoomState, getRoomUsers } from '@/api/gateTown';
  import { formatDate } from '@/utils/format';
  export default {
    name: 'ai-single-chat-page',
    components: { 
      PlayerSetting,
      SinglePlayer,
      MultiPlayer,
      ChatView,
      RoomSetting
    },
    data() {
      return {
        SingleChatState,
        step: SingleChatState.RoomSetting,
        modeTypes,
        chatMode: 0,
        chatMsgShowModes,
        chatMsgShowMode: chatMsgShowModeTypes.ALL,
        disabled: false,
        loading: false,
        isCreateGame: false,
        waitStartGame: false,
        roomInfo: {
          imei: '',
          uid: '',
          room_id: -1
        },
        roleInfo: [],
        allRoleList: [],
        chatMsgs: [],
        timer: null,
        updatePlayerTimer: null 
      }
    },
    mounted() {
      console.log(formatDate(new Date()))
    },
    methods: {
      updateRoomInfo(info, isCreateGame = false) {
        this.roomInfo = info;
        this.step = SingleChatState.RoleSetting;
        this.isCreateGame = isCreateGame;
        if (isCreateGame) {
          this.startUpdatePlayer();
        }
      },
      startUpdatePlayer() {
        if (this.updatePlayerTimer) {
          clearInterval(this.updatePlayerTimer);
        }
        this.updatePlayerTimer = setInterval(() => {
          this.getAllPlayer();
          if (this.step === SingleChatState.Chat) {
            clearInterval(this.updatePlayerTimer);
          }
        }, 3000)
      },
      async roleSetting(roleInfo) {
        this.roleInfo = roleInfo;
        this.step = SingleChatState.Wait
        // 如果是在web端创建，则直接开始游戏
        if (this.isCreateGame) {
          const startResult = await startGame({
            room_id: this.roomInfo.room_id,
            uid: this.roleInfo[0].uid
          })
          if (startResult.code === 0) {
            this.step = SingleChatState.Chat
            this.getAllPlayer();
          }
          return;
        }
        this.startWaitGameStart();
      },
      startWaitGameStart() {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.waitStartGame = true;
        this.timer = setInterval(async () => {
          const roomResult = await getRoomState({
            room_id: this.roomInfo.room_id,
            uid: this.roleInfo[0].uid
          });
          if (roomResult.status === 1) {
            this.waitStartGame = false;
            this.step = SingleChatState.Chat;
            this.getAllPlayer();
          }
          if (this.step !== SingleChatState.Wait) {
            clearInterval(this.timer);
          }
        }, 3000);
      },
      async singleChat(chatInfo) {
        // 开启聊天
        this.saveUserChatMsg(chatInfo.roleInfo,chatInfo.user_ask);
        const startResult = await startChat({
          uid: chatInfo.uid,
          room_id: this.roomInfo.room_id
        });
        if (startResult.code !== 0) {
          this.$message.error(startResult.message)
          return;
        }
        const chatResult = await userChat({
          uid: chatInfo.uid,
          room_id: this.roomInfo.room_id,
          dialogue_id: startResult.dialogue_id,
          user_ask: chatInfo.user_ask
        });
        if (chatResult.code !== 0) {
          this.$message.error(chatResult.message)
          return;
        }
        this.saveAiChatMsg(chatResult.feeds, chatInfo.roleInfo);
        const endResult = await endChat({
          uid: chatInfo.uid,
          room_id: this.roomInfo.room_id,
          dialogue_id: startResult.dialogue_id
        })

      },
      saveUserChatMsg(roleInfo, msg) {
        const chatData = {
          type: 'user',
          roleInfo: roleInfo,
          behavior: 'chat',
          msg,
          timer: formatDate(new Date())
        }
        this.chatMsgs.push(chatData);
        this.saveAiWaitMsg();
      },
      saveAiWaitMsg() {
        setTimeout(() => {
          const waitMsg = {
            type: 'AI',
            behavior: 'chat',
            messageType: MessageTypes.Wait,
            timer: formatDate(new Date()),
          }
          this.chatMsgs.push(waitMsg);
        }, 500)
      },
      removeAiWaitMsg() {
        if (this.chatMsgs[this.chatMsgs.length - 1].messageType  === MessageTypes.Wait) {
          this.chatMsgs.pop();
        }
      },
      saveAiChatMsg(feeds, targetUser, isMulti = false) {
        this.removeAiWaitMsg();
        const chatData = {
          type: 'AI',
          behavior: 'chat',
          messageType: MessageTypes.Chat,
          timer: formatDate(new Date()),
          feeds: feeds,
          isMulti,
          targetUser
        }
        this.chatMsgs.push(chatData);
      },
      async singleAction(actionInfo) {
        this.saveUserActionMsg(actionInfo)
        const result = await playAction({
          uid: actionInfo.uid,
          room_id: this.roomInfo.room_id,
          action_type: actionInfo.action_type,
          action_repeat: actionInfo.action_repeat,
          answer_no: actionInfo.answer_no,
          action_id: actionInfo.action_id
        });
        if (result.code === 0) {
          this.saveAiActionMsg(result, actionInfo.roleInfo);
        }
      },
      saveUserActionMsg(actionInfo) {
        const actionMsg = {
          type: 'user',
          roleInfo: actionInfo.roleInfo,
          behavior: 'behavior',
          isMulti: false,
          actionInfo,
          timer: formatDate(new Date())
        }
        this.chatMsgs.push(actionMsg);
        this.saveAiWaitMsg();
      },
      saveAiActionMsg(actionResult, targetUser, isMulti = false) {
        this.removeAiWaitMsg();
        const actionData = {
          type: 'AI',
          behavior: 'behavior',
          messageType: MessageTypes.Behavior,
          timer: formatDate(new Date()),
          feeds: actionResult.feeds || {},
          isMulti,
          targetUser
        }
        this.chatMsgs.push(actionData)
      },
      async singleGame(gameInfo) {
        this.saveUserGameMsg(gameInfo);
        const gameResult = await playGame({
          uid: gameInfo.uid,
          room_id: this.roomInfo.room_id,
          game_type: 1,
          game_id: gameInfo.action_id,
          game_score: gameInfo.game_score,
          game_start_time: Math.round(Date.now()/ 1000)
        });
        if (gameResult.code === 0) {
          this.saveAiGameMsg(gameResult, gameInfo.roleInfo);
        }
      },
      saveUserGameMsg(gameInfo) {
        const gameMsg = {
          type: 'user',
          roleInfo: gameInfo.roleInfo,
          behavior: 'game',
          isMulti: false,
          isGame: true,
          actionInfo: gameInfo,
          timer: formatDate(new Date())
        }
        this.chatMsgs.push(gameMsg);
        this.saveAiWaitMsg();
      },
      saveAiGameMsg(gameResult,targetUser) {
        this.removeAiWaitMsg();
        const actionData = {
          type: 'AI',
          behavior: 'behavior',
          isGame: true,
          messageType: MessageTypes.Behavior,
          timer: formatDate(new Date()),
          feeds: gameResult.feeds || {},
          targetUser
        }
        this.chatMsgs.push(actionData)
      },
      multiAction(actionInfo) {
        this.saveMultiUserActionMsg(actionInfo);
        for (let i = 0; i < this.roleInfo.length; i ++){
          this.multiActionRun({
            uid: this.roleInfo[i].uid,
            room_id: this.roomInfo.room_id,
            action_type: 1,
            action_id: actionInfo.action_id,
            answer_no: actionInfo.answer_no,
            action_repeat: 1,
            action_desc: actionInfo.action_desc
          }, this.roleInfo[i])
        }
      },
      async multiActionRun(params, targetUser) {
        const currentResult = await playAction(params);
        if (currentResult.code === 0) {
          this.saveAiActionMsg(currentResult, targetUser, true);
        }
      },
      saveMultiUserActionMsg(actionInfo) {
        const actionMsg = {
          type: 'user',
          roleList: this.roleInfo,
          behavior: 'behavior',
          isMulti: true,
          actionInfo,
          timer: formatDate(new Date())
        }
        this.chatMsgs.push(actionMsg);
        this.saveAiWaitMsg();
      },
      multiChat(chatInfo) {
        const newChatList = [];
        chatInfo.chatList.forEach(item => {
          const userArr = item.target.split('->');
          const msg = {};
          msg.content = item.content;
          msg.target = `${this.roleInfo[Number(userArr[0]) - 1].nick}->${this.roleInfo[Number(userArr[1]) - 1].nick}`
          newChatList.push(msg);
        })
        this.saveMultiUserChatMsg(newChatList);
        const chatDesc = this.getDescByChatList(newChatList);
        for (let i = 0; i < this.roleInfo.length; i ++){
          this.multiChatRun({
            uid: this.roleInfo[i].uid,
            room_id: this.roomInfo.room_id,
            action_type: 1,
            action_id: chatInfo.action_id,
            answer_no: chatInfo.answer_no,
            action_repeat: 1,
            action_desc: chatDesc
          }, this.roleInfo[i])
        }
      },
      getDescByChatList(chatList) {
        const chatArr = chatList.map(item => {
          return `${item.target}:${item.content}`;
        });
        return chatArr.join('\n');
      },
      saveMultiUserChatMsg(chatList) {
        const actionMsg = {
          type: 'user',
          roleList: this.roleInfo,
          behavior: 'chat',
          isMulti: true,
          chatList: chatList,
          timer: formatDate(new Date())
        }
        this.chatMsgs.push(actionMsg);
        this.saveAiWaitMsg();
      },
      async multiChatRun(params, targetUser) {
        const currentResult = await playAction(params);
        if (currentResult.code === 0) {
          this.saveAiChatMsg(currentResult.feeds, targetUser, true);
        }
      },
      onEndGameOld() {
        for (let i = 0; i < this.roleInfo.length; i ++){
          this.endGameFunc({
            uid: this.roleInfo[i].uid,
            room_id: this.roomInfo.room_id
          });
        }
      },
      async onEndGame() {
        this.loading = true;
        const endResult = await endGame({
            uid: this.roleInfo[0].uid,
            room_id: this.roomInfo.room_id
          });
        this.loading = false;
        if (endResult.code === 0) {
          const endGameMsg = {
            type: 'AI',
            behavior: 'behavior',
            isComment: true,
            messageType: MessageTypes.Comment,
            timer: formatDate(new Date()),
            results: endResult.results,
            roleList: this.roleInfo
          }
          this.chatMsgs.push(endGameMsg);
        }
      },
      async getAllPlayer() {
        const result = await getRoomUsers({
          room_id: this.roomInfo.room_id
        });
        if (result.code === 0) {
          this.allRoleList = result.users;
        }
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
  .ai-single-chat-page{
    width: 100%;
    height: 100%;
    background: #f8f9fa;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;    
    :deep(.el-button){
      border: 1px solid #dcdfe6;
    }
    .aside-wrap{
      width: 460px;
      min-width: 460px;
      padding: 10px 5px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      justify-self: flex-start;
      align-items: center;
      gap: 10px;
      border-right: 1px solid #cdcdcd;
    }
    .chat-wrap{
      width: calc(100% - 460px);
      .tool-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px;
        gap: 12px;
      }
    }
  }
</style>
  