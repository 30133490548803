<template>
  <div class="application-list">
    <div class="main-container">
      <div class="instruction-wrap">
        <div class="instruction-title">{{ $t('views.apiKeyManager.instruction') }}</div>
        <div class="instruction-content">
          <div class="content-text" v-for="(item, index) in $t('views.apiKeyManager.instructionList')" :key="index"> {{ item }}</div>
        </div>
      </div>
      <div class="api-wrap">
        <div class="tool-line">
          <div class="api-title">{{ $t('views.apiKeyManager.tableTitle') }}</div>
          <div class="btn-list">
            <div class="btn-color create-btn">{{ $t('views.apiKeyManager.createBtn') }}</div>
          </div>
        </div>
        <LitTable :columns="columns" :operates="operates" :tableData="list" @onOperate="onOperate"/>
      </div>
    </div>
    <CreateApiKeyDialog title="sadgdasg"/>
    <DeleteDialog
      :title="$t('views.apiKeyManager.dialog.deleteTitle')"
      :content="$t('views.apiKeyManager.dialog.deleteTips')"
      :confirmBtn="$t('views.apiKeyManager.dialog.sure')"
      :visible="deleteVisible"
    />
  </div>
</template>
  
<script>
import LitTable from '@/components/table/index.vue'
import CreateApiKeyDialog from './dialog/createApiKey.vue'
import DeleteDialog from '@/components/dialog/deleteDialog.vue'
export default {
  name: 'api-key-manager',
  components: { LitTable, CreateApiKeyDialog, DeleteDialog },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      loading: false,
      columns: [
        { label: 'views.apiKeyManager.tableTh.name', prop: 'name' },
        { label: 'views.apiKeyManager.tableTh.creationTime', prop: 'time' },
        { label: 'views.apiKeyManager.tableTh.key', prop: 'key' },
        { label: 'views.apiKeyManager.tableTh.binding', prop: 'demo' },
      ],
      operates: [
        { label: 'views.apiKeyManager.tableTh.editBtn', type: 1 },
        { label: 'views.apiKeyManager.tableTh.deleteBtn', type: 2 }
      ],
      list: [
        { name: 'LitGate', time: '2024 03-07 13:49:14', key: 'sk-eu...nmgq4', demo: 'Devil may cry' }
      ],
      deleteVisible: true
    }
  },
  mounted () {
  },
  methods: {
    onOperate(row, options) {
      console.log('operate', row, options)
    }
  },
  destroy() {
    window.removeEventListener('scroll', this.scrollEnd)
  }
}
</script>
<style lang="scss">
.application-list{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  padding: 0px 0px 40px 0;
  .main-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 1136px;
    background: #0f0f0f;
    padding: 32px;
    gap: 40px;
    .instruction-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      .instruction-title{
        color: #DDD;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
      .instruction-content{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        .content-text {
          color: #666;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
    .api-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 24px;
      .tool-line {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        .api-title{
          color:#DDD;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
        .btn-list{
          display: flex;
          justify-content: flex-end;
          flex-direction: row;
          align-items: center;
          .btn-color{
            background: #00FFF6;
          }
          .create-btn{
            display: flex;
            width: 104px;
            padding: 5px 16px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>