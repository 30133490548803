<template>
    <div id="layout-article" class="article-container page-account">
      <Nav currentTab=""/>
      <router-view />
    </div>
  </template>
  
  <script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import Nav from '@/layout/navMenu/gateNav.vue'
  @Component({
    name: 'layout-game-works',
    components: { Nav },
  })
  class layoutGameWorks extends Vue {
    private bgColor;
    mounted() {
      this.bgColor = document.body.style.backgroundColor;
      document.body.style.backgroundColor = '#000';
    }
    destroy() {
      document.body.style.backgroundColor = this.bgColor;
    }
  }
  export default layoutGameWorks
  </script>
  <style lang="scss" scoped>
  .page-account{
    background: #000;
    width: 100%;
    min-width: 1280px;
  }
  </style>