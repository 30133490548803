import { RouteConfig } from 'vue-router';
import * as communityRoutes from './community/route';
import * as commonRoutes from './commonRoute/route';
import * as gateTownRoutes from './gateTown/route';
import * as researchRoutes from './research/route';

/**
 * 定义默认路由
 * @description 当单独打包时，会设置默认路由
 */
export const defaultRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: '/',
    component: () => import('@/layout/gateHome.vue'),
    redirect: '/gate',
    meta: {
      isKeepAlive: true,
    },
    children: [],
  },
];

/**
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏、菜单搜索名称（国际化）
 *      isLink：        是否超链接菜单，开启外链条件，`1、isLink:true 2、链接地址不为空`
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      isIframe：      是否内嵌窗口，，开启条件，`1、isIframe:true 2、链接地址不为空`
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 *      fitH5:          是否以H5模式进行适配，PC设计尺寸：1920*1080，移动端设计尺寸：750*1624
 * }
 */

// 生产环境路由
export const staticRoutes: Array<RouteConfig> = [
  // ...developerRoutes.staticRoutes,
  // ...accountRoutes.staticRoutes,
  // ...gateTownRoutes.staticRoutes,
  ...commonRoutes.staticRoutes,
  ...communityRoutes.staticRoutes
];

// 测试环境路由
export const devStaticRoutes: Array<RouteConfig> = [
  // ...developerRoutes.staticRoutes,
  // ...accountRoutes.staticRoutes,
  ...researchRoutes.staticRoutes,
  ...gateTownRoutes.staticRoutes,
  ...commonRoutes.staticRoutes,
  ...communityRoutes.staticRoutes
];




