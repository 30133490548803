<template>
  <div class="room-setting-wrap">
    <div class="room-info-show">
      <div class="title">模式介绍</div>
      <div class="room-info">
        <div>用户可选择不同的角色身份，与多个玩家以及多个AI进行多人群聊。不同的AI有不同聊天风格，同时AI与用户扮演的角色有不同的人物关系，聊天风格也会存在差异。本模式主要是模拟一个多人与多AI的群聊环境，意在真实还原人与AI的多人群聊。</div>
        <div></div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { getUid, joinRoom } from '@/api/gateTown';
  export default {
    name: 'mode-introduction',
    props: {

    },
    components: { 
      
    },
    data() {
      return {
        step: 0, // 0: 1:加入房间 2:已经
        loading: false,
        joinLoading: false,
        disabled: false,
        roomInfo: {},
        inputRoomId: ''
      }
    },
    mounted() {
      
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
.room-setting-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  .title{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
  .room-setting-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }
  .room-join-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }
  .room-info-show{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: flex-start;
    .room-info{
      color: #999;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

}
</style>
  