<template>
  <div :class="{'guide-introduction': true, 'introduction-normal': !isReserve, 'introduction-reverse': isReserve}" :style="{ backgroundImage: 'url(' + iconPath + ') ' }">
    <div class="info">
      <div class="title">{{ title[0] }} <span>{{ titleGreen }}</span> {{ title[1] }}</div>
      <div class="greenStroke"></div>
      <div class="content">{{ content }}</div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'guide-introduction',
  props: {
    title: {
      type: Array,
      default: []
    },
    titleGreen: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: false
    },
    isReserve: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  mounted() {
     ;
  },
  methods: {
    clickMore() {
      this.$emit('onMore');
    }
  }
}

</script>

<style lang="scss" scoped>
.guide-introduction{
  width: 1200px;
  height: 512px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: row;
  .icon{
    width: 480px;
    height: 480px;
  }
  .info{
    width: 668px;
    height: 512px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .title{
      color: #F3F4F6;
      
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      span{
        color: #84E1BC;
        padding: 0 8px;
        font-weight: 700;
      }
    }
    .greenStroke{
      width: 100px;
      height: 0px;
      margin: 20px 0;
      border: 2px solid transparent;
      border-image: linear-gradient(to right, #31C48D, #16BDCA) 1;
    }
    .content{
      color: #F3FAF7;
      
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      opacity: 1;
    }
  }
}
.introduction-normal{
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  justify-content: flex-end;
}
.introduction-reverse{
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
  justify-content: flex-start;
}
</style>
