<template>
  <div class="card-wrap" @click="onClick">
    <div class="card">
      <div class="thumb" v-lazy-container="{ selector: 'img', error: require('@/assets/knowledge/card-normal-default.png'), loading: require('@/assets/knowledge/card-normal-default.png') }">
        <div v-if="showRank" :class="{ rank: true, one: rank == '1', two: rank == '2', three: rank == '3'}">{{rank || '0'}}</div>
        <img :data-src="value.other_data.medias.cover" alt="">
      </div>
      <div class="mixed">
        <div class="content">{{value.title}}</div>
        <div class="count">
          <div class="count-item">
            <div class="icon">
              <img src="@/assets/statIcon/icon_views.png" alt="">
            </div>
            <div class="num">{{formatCount(value.stat_data && value.stat_data.browse_num)}}</div>
          </div>
          <div class="count-item">
            <div class="icon">
                <img src="@/assets/knowledge/icon-likes.png" alt="">
            </div>
            <div class="num">{{value.stat_data && value.stat_data.like_num}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { abbreviateNumber } from '@/utils/statFormat'

@Component({
  name: 'CardHot',
  components: {},
})
class CardHot extends Vue {
  @Prop({
    required: true,
    default: () => ({}),
  })
  public value

  @Prop({
    required: true,
    default: 0,
  })
  public rank

  @Prop({
    required: false,
    default: true,
  })
  public showRank


  public onClick () {
    this.$emit('click', this.value)
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }
}

export default CardHot
</script>
  
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

.card-wrap {
  width: 100%;
  @media screen and (min-width: 1920px) {
    .card{
      flex-direction: row;
      height: 76px;
      align-items: center;
      .thumb{
        width: 136px;
        height: 100%;
      }
      .mixed{
        margin-left: 16px;
      }
    }
  }
  @media screen and (max-width: 1919px) {
    .card{
      flex-direction: row;
      height: 76px;
      align-items: center;
      .thumb{
        width: 136px;
        height: 100%;
      }
      .mixed{
        margin-left: 16px;
      }
    }
  }
  @media screen and (max-width: 1679px) {
    .card{
      flex-direction: column;
      height: 222px;
      align-items: flex-start;
      .thumb{
        width: 240px;
        height: 134px;
      }
      .mixed{
        margin-top: 12px;
        margin-left: 0;
      }
    }
  }
  .card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover{
      .thumb{
        img{
          transform: scale(1.1);
        }
      }
    }
    .thumb {
      flex-shrink: 0;
      position: relative;
      overflow: hidden;

      .rank {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
        color: #fff;
        width: 24px;
        height: 30px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: url('@/assets/knowledge/icon-rank.png') no-repeat center;
        background-size: 100% 100%;
        padding-bottom: 4px;
        z-index: 100;

        &.one {
          background-image: url('@/assets/knowledge/icon-rank-1.png');
        }

        &.two {
          background-image: url('@/assets/knowledge/icon-rank-2.png');
        }

        &.three {
          background-image: url('@/assets/knowledge/icon-rank-3.png');
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

      }
    }

    .mixed {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;


      .content {
        width: 100%;
        height: 44px;
        color: $gray_9;
        font-size: 14px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
      }

      .count {
        display: flex;

        .count-item+.count-item {
          margin-left: 40px;
        }

        &-item {
          display: flex;
          align-items: center;

          .icon {
            width: 24px;
            height: 24px;
            img{
              width: 100%;
              height: 100%;
            }
          }

          .num {
            color: $gray_6;
            font-size: 12px;
            margin-left: 2px;
          }
        }
      }
    }
  }
}
</style>