<template>
  <div class="knowledge-more">
    <div class="container">
      <div class="head">
        <div class="focus">
          <div class="thumb">
            <div class="logo">
              <img :src="currentPageInfo.logo" alt="" />
            </div>
            <div class="info">
              <div class="title">{{$t(currentPageInfo.name)}}</div>
              <div class="desc">{{currentPageInfo.desc ? $t(currentPageInfo.desc) : ''}}</div>
              <div class="count">
                <div class="icon">
                  <img src="@/assets/knowledge/icon-views-black.png" alt="" />
                </div>
                <div class="num">{{formatCount(globalPageInfo?.browse_num || 0)}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="category"></div>
      </div>
      <div class="main">
        <div class="content">
          <div class="list" v-if="!empty">
            <div class="list-content">
              <CardNormal class="list-item" v-for="(item, index) in value" :key="index" :value="item"
                @click="onClickCard" @clickLike="onClickCardLike"></CardNormal>
              <div class="loading" v-if="loading">loading...</div>
            </div>
          </div>
          <pageEmpty v-else></pageEmpty>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { getAllGameWorks } from '@/api/gameWorks';
import { getGlobalStatData } from '@/api/gateUser'
import { GlobalStateType } from '@/config/stat'
import CardNormal from '@/components/card/cardNormal.vue'
import CardHot from '@/components/card/cardHot.vue'
import CardAuthor from '@/components/card/cardAuthor.vue'
import { exportPV } from '@/utils/auth'
import { ArticleTypes } from '@/config/article'
import { abbreviateNumber } from '@/utils/statFormat'
import { GameWorksTypes } from '@/config/gameWorks';
import pageEmpty from '@/components/exception/pageEmpty.vue'
import logoMore1 from '@/assets/gameWorks/more/logo-more-1.png'

@Component({
  name: 'gameWorksMore',
  components: {
    CardNormal,
    CardHot,
    CardAuthor,
    pageEmpty
  },
})
class Knowledge extends Vue {
  public nav = [
    {
      name: 'All',
    },
    {
      name: 'Articles',
    },
    {
      name: 'Videos',
    },
  ]

  public navIndex: Number = 0
  public userInfo: any = {}
  public pageInfo = [
    {
      logo: logoMore1,
      name: 'views.gameWorks.category1',
      desc: 'views.gameWorksMore.category1Desc',
      value: ArticleTypes.Type_2D,
      dataKey: 'd2_data'
    },
  ]
  public currentPageInfo: any = {}
  public globalPageInfo: any = {}
  public value: any[] = []
  public id: any = ''
  public pageSize: number = 50
  public page: number = 0
  public loading: boolean = false
  public final: boolean = false
  public empty: boolean = false

  mounted () {
    const id = this.$route.query.id
    const userInfo = this.$store.state.user?.userInfo || {}
    this.userInfo = userInfo

    this.id = id
    this.currentPageInfo = this.pageInfo.find((v: any) => v.value == id) || {}

    window.addEventListener("scroll", this.handleScroll);

    this.fetchData()
    this.fetchGlobalData()

    exportPV()
  }

  beforeDestroy () {
    window.removeEventListener("scroll", this.handleScroll);
  }

  @Watch('$i18n.locale')
  onI18nLocaleChange(val: string) {
    console.log('on locale change')
    this.page = 0;
    this.final = false;
    this.value = [];
    this.fetchData()
  }


  /* 获取列表数据 */
  public async fetchData () {
    if (!this.id) return

    if (this.final) return

    if (this.loading) return

    this.loading = true

    const res: any = await getAllGameWorks({
      works_limit: this.pageSize,
      works_offset: this.page,
      works_type: GameWorksTypes.Works_Type_Game_Demo,
    })

    this.loading = false

    const { code, data = {}, message = '' } = res

    if (String(code) !== '0') {
      this.$message.error('获取内容失败:' + message)
      return
    }

    const { base_data, works_total } = data
    const _value = [ ...this.value, ...base_data ]

    if (Number(works_total) === 0) {
      this.empty = true
      return
    }

    this.value = _value
    this.final = _value.length >= Number(works_total)
  }

  /* 滚动监听 */
  handleScroll () {
    const scrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

    if (scrollTop + windowHeight >= scrollHeight - 10 && !this.loading) {
      this.page = this.page + 1
      this.fetchData();
    }
  }

  /* 获取全局统计类数据 */
  public async fetchGlobalData () {
    if (!this.id) return

    const res: any = await getGlobalStatData({
      stat_type: GlobalStateType.Global_Yes
    })

    console.log('getGlobalStatData res', res)

    const { code, message = '', data = {} } = res

    if (String(code) !== '0') {
      this.$message.error('获取内容失败:' + message)
      return
    }

    this.globalPageInfo = data?.works_data['game_dome_data']
  }

  public onChangeNav (index: Number) {
    this.navIndex = index
  }

  public onClickFollow (e: any) {
    console.log('携带的参数', e)
  }

  /* 点击卡片 */
  public onClickCard (e: any) {
    const { works_id } = e

    window.open(`/gameworks/detail?id=${ works_id }`)
  }

  /* 点击卡片里的点赞 */
  public onClickCardLike (e: any) {
    console.log('携带的参数', e)
  }

  /* 点击Post Knowledge */
  public onSkipEdit (e: any) {
    const { userInfo } = this

    if (!userInfo.userId) {
      this.$router.push({
        path: `/account/login`,
      })
      return
    }

    this.$router.push({
      path: `/editor/article`,
    })
  }

  public formatCount (val: any) {
    return abbreviateNumber(Number(val))
  }
}
export default Knowledge
</script>

<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;

/* 修改滚动条的颜色 */
::-webkit-scrollbar {
  width: 8px;
  /* 设置滚动条的宽度 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #000;
  /* 设置滚动条轨道的背景色 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: $black_light;
  /* 设置滚动条滑块的背景色 */
}

/* 鼠标悬停在滚动条上时的滑块样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: $black_light;
  /* 设置鼠标悬停时滚动条滑块的背景色 */
}

@media screen and (max-width: 1919px) {
  .knowledge-more {
    >.container {
      width: 1472px !important;
    }
  }
}

@media screen and (max-width: 1679px) {
  .knowledge-more {
    >.container {
      width: 1072px !important;
      
      .head {
        .focus {
          padding: 40px 0!important;
        }
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .knowledge-more {
    >.container {
      width: 1712px !important;
    }
  }
}


.knowledge-more {
  min-height: calc(100vh - 86px);
  width: 100%;
  background: #000 url(@/assets/knowledge/knowledge-more-head.png) no-repeat top center;
  background-size: 100% auto;
  display: flex;
  justify-content: center;

  >.container {
    min-height: 100%;
    padding-top: 110px;
    display: block;

    .head {
      .focus {
        width: 100%;
        padding: 65px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .thumb {
          display: flex;
          align-items: center;

          .logo {
            width: 120px;
            height: 120px;
            border: 1px solid rgba(255, 255, 255, 0.17);
            background: radial-gradient(81.97% 81.97% at 44.27% 18.23%, rgba(65, 237, 225, 0.11) 0%, rgba(58, 195, 185, 0.11) 25.64%, rgba(58, 195, 185, 0.08) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 72px;
              height: 72px;
            }
          }

          .info {
            margin-left: 24px;

            .title {
              font-size: 34px;
              color: $gray_d;
            }

            .desc {
              color: $gray_6;
              font-size: 14px;
              margin-top: 8px;
            }

            .count {
              display: flex;
              align-items: center;
              margin-top: 23px;

              .icon {
                width: 24px;
                height: 24px;
              }

              .num {
                color: $gray_6;
                font-size: 12px;
                margin-left: 2px;
              }
            }
          }
        }

        .btn {
          width: 83px;
          height: 40px;
          background-color: #00fff6;
          font-size: 16px;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .main {
      .nav-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .nav {
          display: flex;
          align-items: center;

          .nav-item+.nav-item {
            margin-left: 50px;
          }

          .nav-item {
            &.active {
              color: #00fff6;
            }

            color: $gray_9;
            font-size: 20px;
            cursor: pointer;
          }
        }

        .dropdown {}
      }

      >.content {
        width: 100%;
        padding: 30px 0;

        >.list {
          width: 100%;
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .list-content {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            overflow: visible;

            .list-item {
              width: calc(25% - 12px);
            }

            .loading {
              width: 100%;
              text-align: center;
              font-size: 14px;
              color: $gray_6;
              padding: 50px 0;
            }

            .empty {
              width: 100%;
              text-align: center;
              font-size: 14px;
              color: $gray_6;
              padding: 50px 0;
            }
          }
        }
      }
    }
  }
}
</style>