<template>
  <div :class="{'no-activity-card': true, 'activity-card-hover': isHover , 'activity-card-normal': !isHover}" :style="computedStyle">
    <div class="activity-logo"></div>
    <div :class="{'float-layer': true, 'hover-layer': isHover, 'normal-layer': !isHover }">
      <div class="content-box">
        {{ $t('views.guide.events.waitContent') }}
      </div>
      <div class="introduction" v-show="isHover">{{ introduction }}</div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'no-activity-card',
  props: {
    bg: {
      type: String,
      default: 'https://cdn.litgate.ai/gate/guide/eventIcon/eventBg.png'
    },
    timeRange: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    },
    moreLink: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isHover: false
    }
  },
  mounted() {
    console.log('mounted1', this.info);
  },
  methods: {
    mouseOverFunc(){
      if (!this.isHover) {
        this.isHover = true
      }
    },
    mouseoutFunc() {
      if (this.isHover) {
        this.isHover = false;
      }
    },
    clickMore() {
      if (this.moreLink) {
        window.open(this.moreLink);
      }
    }
  },
  computed: {
    computedStyle() {
      if (!this.isHover) {
        return { background: 'url(' + this.bg + ') no-repeat', backgroundSize: '100% 100%' };
      }
      return {};
    }
  }
}

</script>

<style lang="scss" scoped>

.activity-card-normal{
  justify-content: flex-end;
  background-color: #000;
  align-items: flex-end;
  transition: all ease-in-out 0.3s;
}
.no-activity-card {
  height: 400px;
  width: 590px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  position: relative;
  align-items: flex-start;
  .normal-layer{
    padding: 76px 24px 16px 24px;
    justify-content: flex-end;
    align-items: flex-start;
    height: 300px;
    // background: linear-gradient(0deg, rgba(5, 122, 85, 0.00) 0%, rgba(5, 122, 85, 0.00) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0.85) 100%);
  }
  .hover-layer{
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
  }
  .activity-logo{
    background: url('https://cdn.litgate.ai/gate/guide/eventIcon/LitGate.png') no-repeat;
    background-size: 100% 100%;
    width: 112px;
    height: 25px;
    margin-left: 40px;
    margin-bottom: 20px;
    opacity: 0.3;
  }
  .float-layer{
    display: flex;
    width: 100%;  
    flex-direction: column;
    gap: 4px;
    transition: all ease-in-out 0.3s;
    .time{
      padding: 2px 6px;
      border-radius: 4px;
      gap: 8px;
      text-align: center;
      
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }
    .time-normal{
      background: #fff;
      color: #000;
    }
    .time-hover{
      color: #fff;
      border: 1px solid var(--white, #FFF);
    }
    .content-box{
      width: 240px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: rgba(255, 255, 255, 0.3);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      margin-bottom: 20px;
      opacity: 0.3;
    }
    .introduction{
      overflow: hidden;
      color: rgba($color: #fff, $alpha: 0.6);
      text-overflow: ellipsis;
      /* text-base/font-normal */
      
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; 
      transition: all ease-in-out 1s;
    }
  }
  .corner{
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background: url('https://cdn.litgate.ai/gate/guide/Corner.png') no-repeat;
    background-size: 100% 100%;
  }
}
.activity-card:last-child{
  margin-right: 0
}
</style>
