<template>
  <div class="inspect-chat-view" ref="chatView">
    <div class="msg-wrap" >
      <div class="msg-line" v-for="(item, index) in filterMsg" :key="index">
        <PlayerMsg v-if="item.type !== 'AI'" :msgInfo="item" :chatMode="chatMode"/>
        <AIMsg v-if="item.type === 'AI'" :msgInfo="item" :allRoleList="allRoleList" :chatMode="chatMode"/>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import PlayerMsg from './playerMsg.vue'
  import AIMsg from './AiMsg.vue'
  import { chatMsgShowModes, chatMsgShowModeTypes } from '@/config/aiInspect'
  export default {
    name: 'inspect-chat-view',
    props: {
      chatMsgs: {
        type: Array,
        default: () => [
          { type: '玩家A', msg: { content: 'this is a test', score: 90 } },
          { type: 'AI', msg: { content: 'this is a test', score: 90 } },
        ]
      },
      allRoleList: {
        type: Array,
        default: () => []
      },
      chatMsgShowMode: {
        type: Number,
        default: chatMsgShowModeTypes.ALL
      },
      chatMode: {
        type: Number,
        default: 0
      },
    },
    components: {
      PlayerMsg,
      AIMsg
    },
    data() {
      return {
        chatMsgShowModes,
        chatMsgShowModeTypes
      }
    },
    mounted() {
      
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      
    },
    watch: {
      chatMsgs() {
        setTimeout(() => {
          this.$refs.chatView.scrollTop = 1000000;
        }, 200);
        // window.scrollTo({
        //   top: 10000,
        //   behavior: 'smooth'
        // });
      }
    },
    computed: {
      filterMsg() {
        console.log('filter:', this.chatMsgs, this.allRoleList)
        if (this.chatMsgShowMode === chatMsgShowModeTypes.ALL) {
          return this.chatMsgs;
        }
        if (this.chatMsgShowMode === chatMsgShowModeTypes.Player1 || this.chatMsgShowMode === chatMsgShowModeTypes.Player2) {
          const targetPlayer = this.chatMsgShowMode === chatMsgShowModeTypes.Player2 ? this.allRoleList[1] : this.allRoleList[0];
          return this.chatMsgs.filter((item, index) =>{
            if (item.isMulti) {
              return false;
            }
            if (item.type === 'AI' && item?.targetUser?.uid === targetPlayer.uid) {
              return true;
            }
            if (item.type !== 'AI' && item?.roleInfo?.uid === targetPlayer.uid) {
              return true;
            }
            return false;
          });
        }
        if (this.chatMsgShowMode === chatMsgShowModeTypes.Players){
          return this.chatMsgs.filter((item, index) =>{
            if (item.type === 'AI' && item.isMulti) {
              return true;
            }
            if (item.type !== 'AI' && item.isMulti) {
              return true;
            }
            return false;
          });
        }
        return this.chatMsgs
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  .inspect-chat-view{
    width: 100%;
    border-radius: 8px;
    padding: 8px 12px;
    height: calc(100% - 100px);
    overflow-y: auto;
    .msg-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      .msg-line{
        width: 100%;
      }
    }
  }
</style>
  