<template>
  <div class="test-font-set">
    <cherryMarkdown ref="cherryMarkdownEditor" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import cherryMarkdown from '@/components/gateEditor/cherryMarkdown.vue';
@Component({
  name: 'app',
  components: { cherryMarkdown },
})
class EditorTest extends Vue {

  public content = '';
  mounted() {
    this.$refs?.cherryMarkdownEditor.setContent(this.content);
    
  }
}
export default EditorTest
</script>
<style lang="scss" scoped>
.test-font-set {
  background: #000;
}
/* 中文语言环境下的字体 */
</style>
