<template>
	<div class="lscos-upload">
    <el-upload
      :list-type="listType"
      :auto-upload="false"
      action=""
      :before-upload="beforeUpload"
      :on-remove="handleRemove"
      :multiple="false"
      :on-change="uploadCover"
      :on-exceed="handlerExceed"
      :limit="maxLimit"
      :file-list="fileList"
      :accept="accept"
      ref="uploadDom"
      :show-file-list="showFileList"
      >
      <i class="el-icon-plus" style="" v-if="!isCustom"><br /><a style="fontSize: 12px;color: #666;">{{ $t('views.editorArticle.addCover') }}</a></i>
      <div class="custom-upload-tips" slot="tip" v-if="!isCustom">{{ $t(langUrl) }}</div>
      <slot v-if="isCustom" />
    </el-upload>
	</div>
  </template>
  
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { LiteGateCosUpload } from '@/utils/lsCosUpload';
import { checkFileProportion, isImagePath } from '@/utils/statFormat';

  
@Component({
	name: 'LscosUpload',
	components: {},
})
class LscosUpload extends Vue {
	// 通知文本内容
	@Prop({ default: '.jpg,.png,.jpeg' }) public accept!: string
	@Prop({ default: () => [] }) public fileList!: any[]
	@Prop({ default: 1 }) public maxLimit!: number
  // 文件大小限制 0则不限制
	@Prop({ default: 0 }) public imageSizeLimit!: number
	@Prop({ default: 0 }) public fileSizeLimit!: number
  // 文件比例限制 proportionLimit:比例  deviation:偏差范围(10=>即可以在proportionLimit的基础上偏差百分之10)
	@Prop({ default: 0 }) proportionLimit !: number
	@Prop({ default: 0 }) public deviation!: number
  // 上传按钮设置
	@Prop({ default: 'picture-card' }) public listType!: string
	@Prop({ default: 'views.editorArticle.coverFormat' }) public langUrl!: string
  // 自定义UI，当为true时，隐藏本组件UI，使用传入的slot
	@Prop({ default: false }) public isCustom!: boolean
	@Prop({ default: true }) public showFileList!: boolean



	public gUploadFile = null;
  public sha1: string = '';
  public fileSize = 0;
  public platform: string = '';
  public disabled = false;


  public beforeUpload(file) {
    console.log('before upload', file)
    return false
  }
  public async uploadCover(uploadFile, uploadFiles) {
    if(!uploadFile.raw) {
      this.gUploadFile = null;
      return;
    }
    if (isImagePath(uploadFile.name)) {
      if (this.imageSizeLimit > 0 && uploadFile.size >= this.imageSizeLimit) {
        this.$message.warning(this.$t('error.fileSizeLimit') as string);
        return;
      }
    } else if (this.fileSizeLimit > 0 && uploadFile.size >= this.fileSizeLimit) {
      this.$message.warning(this.$t('error.fileSizeLimit') as string);
      return;
    }
    if (this.proportionLimit > 0) {
      const status = await checkFileProportion(uploadFile.raw, this.proportionLimit, this.deviation);
      if (!status) {
        return;
      }
    }
    this.gUploadFile = uploadFile;
    LiteGateCosUpload.getInstance().upload(uploadFile.raw, this.successListener, this.errorListener, 'file', this.updateListener, this.uploadCallback)
    this.$emit('onChange', uploadFile, uploadFiles)
  }

  
  public uploadCallback(e) {
    this.sha1 = e.sha1;
    this.fileSize = e.file_size;
    this.platform = e.platform;
  }
  public handleRemove(item) {
    this.$emit('handleRemove', item)
  }

  public errorListener(e) {
    this.$emit('onError', new Error(e as string), this.gUploadFile, [this.gUploadFile])
    this.gUploadFile = null;
  }
  public async successListener(r) {
    // this.$refs.uploadDom && this.$refs.uploadDom.clearFiles();
    this.$emit('onSuccess', { ...(this.gUploadFile || {}), url: r, sha1: this.sha1, filesize: this.fileSize, platform: this.platform }, [this.gUploadFile])
    this.gUploadFile = null;
  }

  public updateListener(r) {
    this.$emit('onProgress', r, this.gUploadFile);
  }
  destroy() {}
  
  public handlerExceed() {
    console.log('this.listType', this.listType)
    // this.$refs.uploadDom && (this.$refs.uploadDom.$children[1].$el.style.display = 'none');
    // console.log(this.$refs.uploadDom , this.$refs.uploadDom.$children[1])
  }

  @Watch('fileList')
  onFileListChange(newList) {
    console.log('fileList:', this.fileList);
    if (this.listType !== 'picture-card') {
      return
    }
    if (newList.length >= this.maxLimit) {
      this.$refs.uploadDom && (this.$refs.uploadDom.$children[1].$el.style.display = 'none');
    } else {
      this.$refs.uploadDom && (this.$refs.uploadDom.$children[1].$el.style.display = '');
    }
  }
}
export default LscosUpload
</script>
  
<style scoped lang="scss">
.lscos-upload {
  :deep(.el-upload--picture-card) {
    width: 160px !important;
    height: 90px !important;
  }
  :deep(.el-upload-list--picture-card .el-upload-list__item){
    width: 160px;
    height: 90px;
  }
  .custom-upload-tips{
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #666;
    margin-top: 7px;
  }
}
</style>
