
//知识文章类型
export enum ArticleTypes {
  Type_0 = 0,       //其他 | 热门广告位
  Type_2D = 1,       //2D美术领域
  Type_3D = 2,         //3D建模领域
  Type_Video = 3,   //视频动画领域
  Type_Mp3 = 4,   //音频生成领域
  Type_Test = 5,   //文本生成领域
  Type_Code = 6,   //代码辅助领域
  Type_7 = 7,   //AI游戏生产实践
  Type_8 = 8,   //AI游戏创作实例
  Type_9 = 9,   //AI工具与测评
  Type_10 = 10,   //学术交流讨论
  Type_11 = 11,   //资讯时事
}

export enum ArticleAuditStatus {  //知识文章审核状态
  Status_Draft = 0,              //草稿
  Status_Release_Ing = 1,      //发布审核中
  Status_Audit_Fail = 2,      //发布审核失败
  Status_Audit_OK = 3,       //审核通过
}

export enum DeleteStatus {
  Works_Del_Status_Normal = 0,    //正常
  Works_Del_Status_Delete = 1  // 删除
}

export enum AdvertisePosition {
  Advertise_Type_Pos_INVALID = 0,    //正常
  Advertise_Type_Pos_Works_Home = 1, //作品广告位 -- 作品精选推荐
  Advertise_Type_Pos_Lore_Essay_Home = 2  //知识库文章广告位 -- 作品精选推荐
}

export const ArticleCategoryOptions = [
  // { type: ArticleTypes.Type_0, label: 'other' },
  // { type: ArticleTypes.Type_2D, label: '2D Art' },
  // { type: ArticleTypes.Type_3D, label: '3D Modeling ' },
  // { type: ArticleTypes.Type_Video, label: 'Video Animation' },
  // { type: ArticleTypes.Type_Mp3, label: 'Audio Generation' },
  // { type: ArticleTypes.Type_Test, label: 'Text Generation' },
  // { type: ArticleTypes.Type_Code, label: 'Coding Assistance' },
  { type: ArticleTypes.Type_7, label: 'AI Application in Game Producing', i18n: 'options.articleCategory.category7' },
  { type: ArticleTypes.Type_8, label: 'AI-empowered Game Creations', i18n: 'options.articleCategory.category8' },
  { type: ArticleTypes.Type_9, label: 'AI Tech Resource and Reviews', i18n: 'options.articleCategory.category9' },
  { type: ArticleTypes.Type_10, label: 'AI Academic and Theory Hub', i18n: 'options.articleCategory.category10'},
  { type: ArticleTypes.Type_11, label: 'Top Hits and Trends', i18n: 'options.articleCategory.category11' }
]

export const ArticleTagOptions = [ '3D', '2D' ]

