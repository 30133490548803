<template>
  <div :class="{'layout-navbar-knowledge': true, 'normal-line': hasLine, 'layout-navbar-scroll': isScroll}">
    <div class="container">
      <div class="menuTab">
        <div class="logo" @click="gotoHome">
          <Logo />
        </div>
        <div :class="{'menu': true, 'active': item.key === currentTab, 'hide': item.hide }"
          v-for="(item, index) in menuList" :key="index" @click="menuClick(item)">{{ $t(item.label) }}</div>
      </div>

      <div class="feature">
        <ApiDocBtn v-if="showApiDoc" />
        <LangDropdown />
        <PublishEntrance />
        <UserCard  :size="40" v-if="isLogin"/>
        <div class="login-warp" v-if="!isLogin" @click="loginFn">
          <div class="login-btn">{{ $t(loginText) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Logo from '@/components/logo/index.vue';
import { UserModule } from '@/store/modules/user'
import UserCard from '@/components/card/userWarp.vue'
import PublishEntrance from '@/layout/components/publishEntrance/index.vue'
import LangDropdown from '@/layout/components/langDropdown/index.vue'
import ApiDocBtn from '@/layout/components/apiDocBtn'

@Component({
  name: 'layoutNavBar',
  components: { Logo, UserCard, PublishEntrance, LangDropdown, ApiDocBtn },
})
class layoutNavBar extends Vue {
  @Prop({ default: 'knowledge' }) public currentTab!: String
  @Prop({ default: 0 }) public minWidth!: number
  @Prop({ default: false }) public hasLine!: boolean
  @Prop({ default: true }) public showApiDoc!: boolean

  public menuList = [
    { label: 'menus.home', key: 'home', path: '/gate' },
    { label: 'menus.knowledge', key: 'knowledge', path: '/knowledge/home' },
    { label: 'menus.gameProduct', key: 'product', path: '/gameworks/home' },
    { label: 'menus.creator', key: 'creator', path: '/activity/token' },
    { label: 'menus.aiToolkit', key: 'ai', path: '/home', hide: true },
    { label: 'menus.questions', key: 'question', path: '/home', hide: true },
  ];
  public personNav = [
    { label: 'personHome.center', key: 'center'},
    { label: 'personHome.logout', key: 'logout'},
  ]
  public isScroll = false;
  public loginText = 'route.account.login'
  
  public isShowLang = false;
  public isLogin = false;

  public menuClick (item) {
    if (this.$route.path === item.path) {
      return;
    }
    this.$router.push({ path: item.path })
  }

  public gotoHome() {
    if (this.$route.path === '/gate') {
      return;
    }
    this.$router.push({ path: '/gate' })
  }

  public onClickPersonalCenter () {
    this.$router.push({ path: '/personal/home' })
  }

  public handleScroll () {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 3) {
      this.isScroll = true;
    } else {
      this.isScroll = false;
    }
  }

  //跳转路径
  public toPath(path:string) {
    this.$router.push({ path })
  }

  //退出登录
  public logout() {
    UserModule.logout()
    window.location.href = `${location.origin}/account/login`;
  }

  //登录
  public loginFn() {
    window.location.href = `${location.origin}/account/login`;
  }

  public async fetchUserData (_local: any) {
    if (!_local.user_id) return

    this.isLogin = true;
    
  }

  //切换语言
  public changeLang() {
    this.isShowLang = !this.isShowLang;
    console.log('this.isShowLang', this.isShowLang)
  }

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    console.log('this.currentTab', this.currentTab)
  
    const local = localStorage.getItem('litGate_verify_data')
    const _local = local ? JSON.parse(local) : {}
    this.fetchUserData(_local)

  }

  
  destroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
export default layoutNavBar
</script>

<style scoped lang="scss">
@media screen and (min-width: 1920px) {
  .layout-navbar-knowledge{
    min-width: 1920px;
    .container{
      width: 1712px;
    }
  }
}
@media screen and (max-width: 1919px) {
  .layout-navbar-knowledge{
    min-width: 1680px;
    .container{
      width: 1472px;
    }
  }
}
@media screen and (max-width: 1679px) {
  .layout-navbar-knowledge{
    min-width: 1280px;
    .container{
      width: 1072px;
    }
  }
}

.layout-navbar-knowledge {
  width: 100%;
  position: absolute;
  padding: 0 104px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  
  .container {
    height: 100%;
    padding: 24px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .menuTab {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .logo {
        cursor: pointer;
      }

      .menu {
        color: rgba(255, 255, 255, 0.60);
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 42px;
        cursor: pointer;
      }

      .active {
        color: #fff;
        font-weight: 600;
      }

      .hide {
        display: none;
      }
    }

    .feature {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      flex-direction: row;
      gap: 24px;
      .user {
        width: 32px;
        height: 32px;
        background: url('../../assets/default_user_avatar.png') no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
      .login-warp{
          width: 104px;
          height: 36px;
          background: var(--Brand, #00FFF6);
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .login-btn{
            font-size: 16px;
            font-weight: 500;
            white-space: nowrap;
          }
      }
      .nav-collect{
        cursor: pointer;
        position: relative;
        &>img{
          width: 32px;
          height: 32px;
          margin-right: 24px;
        }
      }
    }
  }
  .user-avatar-warp{
    position: relative;
    // margin-right: 56px;
    .user-avatar{
      cursor: pointer;
      width: 40px;
      height: 40px;
      &>img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      &:hover{
        .avatar-menu-warp{
          display: block;
        }
      }
    }
    .avatar-menu-warp{
      display: none;
      position: absolute;
      width: 160px;
      height: 105px;
      left: -60px;
      top: 30px;
      .user-menu-box{
        position: relative;
        width: 160px;
        height: 86px;
        background: #141414;
        box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.05);
        padding: 8px;
        margin-top: 20px;
        .triangle{
          width: 10px;
          height: 6px;
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
        .menu-item{
          color: #666;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          padding: 6px 8px;
          cursor: pointer;
          .menu-icon{
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }
        }
        .personal-warp{
          .menu-icon{
            background: url('../../assets/user-profile-icon.png') no-repeat;
            background-size: 100% 100%;
          }
          &:hover{
            background: rgba(255, 255, 255, 0.10);
            color: #DDD;
            .menu-icon{
              background: url('../../assets/profile-icon-hover.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
        .logout-warp{
          .menu-icon{
            background: url('../../assets/logout-icon.png') no-repeat;
            background-size: 100% 100%;
          }
          &:hover{
            background: rgba(255, 255, 255, 0.10);
            color: #DDD;
            .menu-icon{
              background: url('../../assets/logout-icon-hover.png') no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
.normal-line{
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
}
.layout-navbar-scroll {
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.60) 100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  backdrop-filter: blur(5px);
}
</style>
