<template>
  <div class="gate-guide-contact">
    <div class="gate-guide-contain">
      <guideTitle :title="$t('views.guide.contact.title')" :hasMore="false"/>
      <div class="tips">
        {{ $t('views.guide.contact.tips') }}
        <span class="email">{{ $t('views.guide.contact.email') }}</span>
      </div>
      <!-- <div class="listTitle">
        {{ $t('views.guide.contact.listTitle') }}
      </div> -->
      <div class="guide-contact-container">
        <contactCard v-for="(item, index) in contactList" :title="$t(item.title)" :iconPath="item.icon" :key="index" :hoverIcon="item.hoverIcon" :openLink="item.openLink"/>
      </div>
    </div>
  </div>
</template>

<script>
import guideTitle from './components/guideTitle.vue';
import contactCard from './components/contactCard.vue';
export default {
  name: 'guide-contact',
  components: { guideTitle, contactCard },
  data() {
    return {
      contactList: [
        { title: 'views.guide.contact.Wechat', icon: 'https://cdn.litgate.ai/gate/guide/contact/wechat.png', hoverIcon: 'https://cdn.litgate.ai/gate/guide/contact/wechat_hover.png' },
        { title: 'views.guide.contact.wechatOpen', icon: 'https://cdn.litgate.ai/gate/guide/contact/wechat_open.png', hoverIcon: 'https://cdn.litgate.ai/gate/guide/contact/wechat_open_hover.jpeg' },
        { title: 'views.guide.contact.Discord', icon: 'https://cdn.litgate.ai/gate/guide/contact/discord.png', openLink: 'https://discord.gg/XpAUWcQwuM' },
        { title: 'views.guide.contact.Twitter', icon: 'https://cdn.litgate.ai/gate/guide/contact/twitter.png', openLink: 'https://twitter.com/LitGateOfficial' },
      ]
    }
  },
  mounted() {
     ;
  },
}

</script>

<style lang="scss" scoped>
.gate-guide-contact {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .gate-guide-contain{
    width: 1200px;
    .tips{
      margin-top: 20px;
      color:  #FFF;
      text-align: left;
      
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding: 0 360px 0 0;
      span{
        color: #84E1BC;
        font-family:'Helvetica Neue';
      }
    }
    .listTitle{
      margin-top: 20px;
      color:  #FFF;
      text-align: center;
      
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      opacity: 0.6;
    }
    .guide-contact-container{
      margin-top: 20px;
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    .guide-contact-container>div:nth-child(4n+1){
      margin-left: 0px;
    }
    .guide-contact-container>div{
      margin-left: 10px;
    }
  }
}
</style>
