<template>
  <div class="knowledge-detail">
    <div class="container" v-if="!isDelete">
      <div class="knowledge-spacer" ref="spacerEle"></div>
      <div class="knowledge-container">
        <div class="directory-container" :style="{height: contentHeight + 'px'}">
          <tinyDirectory ref="tinyDirectory" :content="content" @onActive="directoryActiveChange" />
        </div>
        <div class="float-box">
          <AnchorActionBar
            :isLiked="isLike"
            :isFavorite="isCollect"
            :likeCount="statData.like_num"
            :commentCount="statData.comment_num"
            :showStatTool="showStatTool"
            :showAnchorComment="showAnchorComment"
            :showTopBtn="showTopBtn"
            @gotoComment="gotoComment"
            @onSendComment="onSendComment"
            @like="likeFunc"
            @collect="collectFunc" @share="shareFunc" />
        </div>
        <div class="detail-container">
          <div class="title">{{ title }}</div>
          <Statistics :seeCount="statData.browse_num" :likeCount="statData.like_num"  :isLiked="isLike" />
          <div class="style-spacer" v-if="profiles"></div>
          <div class="lead" v-if="profiles">
            <span class="lead-before">{{ $t('views.knowledgeDetail.lead') }}</span>
            <span>{{ profiles }}</span>
          </div>
          <div class="content">
            <tinyView ref="tinyView" :content="content" @onContent="onContentMounted" @directoryActive="directoryActive"/>
          </div>
          <div class="style-spacer"></div>
          <StatInfoTool :isLiked="isLike" :isFavorite="isCollect" :likeCount="statData.like_num"
            :commentCount="statData.comment_num" :postTime="releaseTime" :articleId="articleId" @like="likeFunc"
            @collect="collectFunc" @share="shareFunc" v-if="Number(releaseTime) !== 0" @onShowAnchorBar="onShowAnchorBar" @onReport="onReport"/>
          <div class="tag">
            <StatTag :tags="baseData.label" />
          </div>
          <div class="style-spacer" ref="commentAnchor"></div>
          <CommentView :gId="articleId" ref="commentView" @onShowAnchorComment="onShowAnchorComment"/>
          <div class="style-spacer" :style="{height: '36px'}"></div>
        </div>
        
        <div class="info-container">
          <ArticleAuthorCard :userId="authorId" />
          <div class="style-spacer" :style="{height: '46px'}"></div>
          <articleRelevant :list="otherData.lore_essay_id" :title="$t('views.knowledgeDetail.relevant')" v-if="otherData.lore_essay_id.length > 0"/>
        </div>
      </div>
    </div>
    <div class="knowledge-detail-not-found" v-if="isDelete">
      <pageNotFound />
    </div>
    
  </div>
</template>
  
<script>
import Statistics from '@/components/statStatus/statistics.vue'
import StatTag from '@/components/statStatus/statTag.vue'
import StatInfoTool from '@/components/statStatus/statInfoTool.vue'
import AnchorActionBar from '@/components/statStatus/anchorActionBar.vue'
import tinyView from '@/components/gateEditor/tinyView.vue'
import tinyDirectory from '@/components/gateEditor/tinyDirectory.vue';
import ArticleAuthorCard from '@/components/card/authorCard.vue';
import pageNotFound from '@/components/exception/pageNotFound.vue'
import CommentView from '@/components/comment/index.vue'
import articleRelevant from '@/components/relatedList/relevantArticle.vue'
import { getArticle, queryArticleDraft } from '@/api/article'
import { CollectTypes, LikeTypes, GateReportTypes, PageModes } from '@/config/stat'
import { gateReport } from '@/api/gateReport'
import {
  deleteUserCollect,
  updateUserCollect,
  userLike,
} from '@/api/userLikeCollect'
import { exportPV } from '@/utils/auth'
import { ReportModule } from '@/store/modules/report'
import { GateReportEnvironments } from '@/config/report'
export default {
  name: 'knowledge-detail',
  components: {
    Statistics,
    tinyView,
    tinyDirectory,
    StatInfoTool,
    StatTag,
    ArticleAuthorCard,
    pageNotFound,
    articleRelevant,
    AnchorActionBar,
    CommentView
  },
  props: {},
  data () {
    return {
      articleId: '', // 文章id
      authorId: '',
      type: PageModes.Normal, // 页面浏览类型 preview | normal
      title: '', // 文章标题
      profiles: '', // 简介
      content: '', //内容
      like: 1000, //
      see: 100,
      isLike: false, // 是否点赞
      isCollect: false, // 是否收藏
      isDelete: false, // 是否删除
      lastUpdateTime: Date.now(), // 最后更新时间
      releaseTime: '',
      statData: {
        browse_num: 0, // 浏览次数
        collect_num: 0, // 点赞次数
        like_num: 0, // 收藏次数
        share_num: 0, // 分享次数
        comment_num: 0,
      },
      otherData: {
        game_demo: '',
        tools_id: [],
        lore_essay_id: [], // 相关内容
      },
      medias: {
        cover: '',
        movie: [],
        pictures: [],
      }, // 宣传相关
      baseData: {
        platform: [],
        label: [ 'AI', 'ChatGPT' ],
      },
      contentHeight: 200,
      showStatTool: false,
      showTopBtn: false,
      showAnchorComment: false,
      commentCount: 0
    }
  },
  mounted () {
    const type = this.$route.query.type || PageModes.Normal
    const id = this.$route.query.id
    if (id) {
      this.articleId = id
      this.type = type
      this.getArticleData()
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // this.getArticleDetail();
    this.gateReportEvent()
    this.stickAnchorElement();
  },
  methods: {
    onReport() {
      ReportModule.open({
        gId: this.articleId,
        env: GateReportEnvironments.Complaint_Lore_Essay
      });
    },
    onSendComment(content) {
      this.$refs.commentView.onSendComment(content)
    },
    gotoComment() {
      window.scrollTo({
        top: (this.$refs.commentAnchor.offsetTop  || 0) - 60,
        behavior: 'smooth'
      });
    },
    onShowAnchorBar(boo) {
      this.showStatTool = boo;
    },
    onShowAnchorComment(boo) {
      this.showAnchorComment = boo;
    },
    onContentMounted(info) {
      this.contentHeight = info.height;
    },
    directoryActive(target) {
      this.$refs.tinyDirectory.setActive(target);
    },
    directoryActiveChange(targetInfo) {
      this.$refs.tinyView.setActive(targetInfo);
    },
    async gateReportEvent() {
      gateReport({
        event_type: GateReportTypes.Report_Event_Type_LoreEssayBrowse,
        param: [ Math.floor(Date.now() / 1000) ],
        param_str: [
          this.articleId,
          this.$route.path,
          this.$route.fullPath,
        ],
      }).catch(e => {});
      exportPV([
        this.articleId,
        this.$route.path,
        this.$route.fullPath
      ]);
    },
    async getArticleData () {
      if (this.type == PageModes.Preview) {
        this.getArticleDraft()
        return
      }
      this.getArticleDetail()
    },
    async getArticleDraft () {
      if (!this.articleId) {
        return
      }
      queryArticleDraft({
        lore_essay_id: this.articleId,
      }).then(result => {
        if (result.code === 0) {
          const {
            data: { base_data: draftData },
          } = result
          if (draftData) {
            this.parseArticleData(draftData)
          }
        }
      }).catch(e => {
        this.isDelete = true;
      })
    },
    parseArticleDraftData (data) {
      const { content, profiles, title } = data
      this.title = title
      this.content = content
      this.profiles = profiles
    },
    async getArticleDetail () {
      if (!this.articleId) {
        return
      }
      const result = await getArticle({
        lore_essay_id: this.articleId,
      })
      if (result.code === 0) {
        const {
          data: { base_data },
        } = result
        if (base_data) {
          this.parseArticleData(base_data)
        } else {
          this.isDelete = true
        }
      }
    },
    parseArticleData (data, isDraft = false) {
      const {
        content,
        profiles,
        title,
        stat_data = {},
        is_collect,
        is_del_status,
        is_like,
        up_time,
        release_time,
        label,
        user_id,
        other_data,
        base_data,
      } = data
      this.title = title
      this.content = content
      this.profiles = profiles
      // 统计数据
      this.statData = stat_data || {}
      this.isLike = is_like
      this.isCollect = is_collect
      this.isDelete = is_del_status
      this.lastUpdateTime = Number(up_time) * 1000
      this.releaseTime = Number(release_time) * 1000
      this.label = label

      this.authorId = user_id
      // 基础信息
      this.otherData = other_data
      this.baseData = base_data
      this.medias = other_data?.medias || {
        cover: '',
        movie: [],
        pictures: [],
      }
    },
    // 点赞
    async likeFunc (isLike) {
      if (this.type === PageModes.Preview) return
      const params = {
        like_type: LikeTypes.Like_Type_Lore_Essay,
        gid: this.articleId,
        is_like: isLike,
      }
      const result = await userLike(params)
      if (result.code === 0) {
        this.getArticleDetail()
      }
    },
    async collectFunc (isCollect) {
      if (this.type === PageModes.Preview) return
      const params = {
        collect_type: CollectTypes.Collect_Type_Lore_Essay,
        gid: this.articleId,
      }
      let result
      if (isCollect) {
        result = await updateUserCollect(params)
      } else {
        result = await deleteUserCollect(params)
      }
      if (result.code === 0) {
        this.getArticleDetail()
      }
    },
    shareFunc () {
      if (this.type === PageModes.Preview) return
      this.gateReportShare();
    },
    gateReportShare () {
      gateReport({
        event_type: GateReportTypes.Report_Event_Type_LoreEssayShare,
        param: [ Math.floor(Date.now() / 1000) ],
        param_str: [
          this.worksId,
          this.$route.path,
          this.$route.fullPath,
          window.location.href
        ],
      }).catch(e=> {});
    },
    stickAnchorElement() {
      const self = this;
      var stickyElement = this.$refs.spacerEle;
      this.observer = new IntersectionObserver(function(entries) {
        console.log('entries:', entries);
        entries.forEach(function(entry) {
          if (entry.intersectionRatio <= 0) {
            self.showTopBtn = true;
          } else {
            self.showTopBtn = false;
          }
        });
      });
      this.observer.observe(stickyElement);
    }
  },
}
</script>
<style lang="scss" scoped>
$gray_6: #666;
$gray_9: #999;
$gray_d: #ddd;
$black_light: #141414;
@media screen and (min-width: 1920px) {
  .knowledge-detail{
    min-width: 1920px;
  }
}
@media screen and (max-width: 1919px) {
  .knowledge-detail{
    min-width: 1680px;
  }
}
@media screen and (max-width: 1679px) {
  .knowledge-detail{
    min-width: 1280px;
  }
}
.knowledge-detail {
  width: 100%;
  min-height: calc(100vh - 86px);
  word-break: break-word;
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .knowledge-spacer {
      width: 100%;
      height: 78px;
    }

    .style-spacer {
      width: 100%;
      height: 56px;
    }

    .knowledge-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 32px 104px 0 104px;
      flex: 1;
      @media screen and (min-width: 1920px) {
        .directory-container{
          width: 232px;
          margin-right: 56px;
        }
        .detail-container{
          width: 992px;
        }
        .info-container{
          width: 376px;
          margin-left: 56px;
        }
      }
      @media screen and (max-width: 1919px) {
        .directory-container{
          width: 199px;
          margin-right: 48px;
        }
        .detail-container{
          width: 853px;
        }
        .info-container{
          width: 323px;
          margin-left: 48px;
        }
      }
      @media screen and (max-width: 1679px) {
        .directory-container{
          width: 148px;
          margin-right: 35px;
        }
        .detail-container{
          width: 613px;
        }
        .info-container{
          width: 240px;
          margin-left: 35px;
        }
      }
      .directory-container{
        height: 100%;
        position: relative;
      }
      .detail-container {
        min-height: 300px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;

        .title {
          color: #ddd;
          font-size: 34px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          width: 100%;
          overflow-wrap: break-word;
        }

        .lead {
          color: #adadad;
          /* Body3 */
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          padding: 16px;
          background: #141414;
          width: 100%;
          // white-space: nowrap;
          overflow-wrap: break-word;
        }

        .lead-before {
          color: #00FFF6;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          border-left: 2px solid #00FFF6;
          padding: 0 8px 0 8px;
          min-width: 60px;
        }

        .content {
          margin-top: 40px;
          width: 100%;
          overflow: hidden;
        }

        .tag {
          margin-top: 16px;
          width: 100%;
        }
      }

      .info-container {
        min-height: 400px;
      }
    }
  }
}

.knowledge-detail-not-found {
  width: 100vw;
  height: 100vh;
}
</style>