import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { Local, clearUserSession } from '@/utils/storage';
import { litGateSessionKey, ResponseCode, SessionKey } from '@/config';
import { UserState } from '@/store/interface/index';

import store from '@/store';
import { getLitUserData } from '@/api/gateUser';
import { Language_Types } from '@/config/stat';
import { setLanguage } from '@/lang';

export type IUserState = UserState;

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule {
  private _userInfo = {};

  @Action
  public async updateUserInfo() {
    this.MUpdateUserInfo();
  }
  
  @Action
  public async logout() {
    clearUserSession();
    this.MRemoveUserInfo();
  }
  
  @Mutation
  private async MUpdateUserInfo() {
    const gateSession = Local.get(litGateSessionKey);
    if (!gateSession) {
      return;
    }
    const result = await getLitUserData({
      user_id: gateSession.user_id
    })
    if (result.code === 0 && result.data) {
      const { data = {} } = result.data
      const { language = Language_Types.Language_EN } = data;
      console.log('get user info')
      this._userInfo = data
      setLanguage(language === Language_Types.Language_CN ? 'zh-cn' : 'en');
    }
  }

  @Mutation
  private MRemoveUserInfo() {
   this._userInfo = {};
  }

  get userInfo() {
    return this._userInfo
  }
}

export const UserModule = getModule(User);
