<template>
  <div class="upload-step" >
    <div class="upload-img-box" :style="{ backgroundImage: 'url(' + imageUrl + ')' }" v-if="isImage"></div>
    <div class="upload-img-box" v-if="!isImage">
      <video class="video-box" :src="imageUrl" ref="videoEle"></video>
      <div class="play-btn" @click="playVideo" v-if="uploadModule.state === UploadStateTypes.Ready"></div>
    </div>
    <div class="mask"></div>
    <div class="info-wrap" v-if="!uploadModule.isCover && uploadModule.state === UploadStateTypes.Wait">
      <div class="state-tips">
        <div class="wait-icon"></div>
        <div class="info-text">{{ $t('views.postProductImage.waitUpload') }}</div>
      </div>
      <div class="progress">
        <el-progress :percentage="0" color="#00FFF6" :format="onFormat" :text-inside="true" :style="{height: '2px'}"></el-progress>
      </div>
    </div>
    <div class="info-wrap" v-if="!uploadModule.isCover && uploadModule.state === UploadStateTypes.Uploading">
      <div class="state-tips">
        <div class="uploading-icon"></div>
        <div class="info-text">{{ `${$t('views.postProductImage.uploading')}${uploadModule.progress || 0 }%` }}</div>
      </div>
      <div class="progress">
        <el-progress :percentage="uploadModule.progress" color="#00FFF6" :format="onFormat" :text-inside="true" :style="{height: '2px'}"></el-progress>
      </div>
    </div>
    <div class="info-wrap" v-if="!uploadModule.isCover && uploadModule.state === UploadStateTypes.Success">
      <div class="state-tips">
        <div class="success-icon"></div>
        <div class="success-text">{{ $t('views.postProductImage.uploadCompleted') }}</div>
      </div>
      <div class="progress">
        <el-progress :percentage="100" color="#00FFF6" :format="onFormat" :text-inside="true" :style="{height: '2px'}"></el-progress>
      </div>
    </div>
    <div class="info-wrap" v-if="!uploadModule.isCover && uploadModule.state === UploadStateTypes.Fail">
      <div class="state-tips">
        <div class="fail-icon"></div>
        <div class="fail-text">{{ $t('views.postProductImage.uploadFail') }}</div>
      </div>
    </div>
    <div class="info-wrap" v-if="!uploadModule.isCover && uploadModule.state === UploadStateTypes.Ready">
      <div class="delete-icon" @click="onDelete"></div>
      <!-- <div class="set-cover-btn" @click="setCover" v-if="!uploadModule.isCover">{{ $t('views.postProductImage.setAsCoverBtn') }}</div> -->
    </div>
    <div class="info-wrap" v-if="uploadModule.isCover">
      <div class="cover-text">{{ $t('views.postProductImage.coverTips') }}</div>
      <div class="delete-icon" @click="onDelete" v-if="uploadModule.state === UploadStateTypes.Ready"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { UploadStateTypes } from '@/config/stat';
export default {
  name: 'post-product-upload-view',
  components: { },
  props: {
    uploadModule: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      UploadStateTypes,
      imageUrl: '',
      isImage: true
    }
  },
  mounted() {
    this.getBgUrl();
    console.log('uploadModule:', this.uploadModule)
  },
  methods: {
    getBgUrl(){
      if (this.uploadModule.state === UploadStateTypes.Success || this.uploadModule.state === UploadStateTypes.Ready || this.uploadModule.state === UploadStateTypes.Cover) {
        this.imageUrl =  this.uploadModule.uploadUrl
        this.isImagePath();
        return;
      }
      if (!this.uploadModule.raw) {
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
        this.isImagePath();
      };
      reader.readAsDataURL(this.uploadModule.raw);
    },
    onFormat(num) {
      return ''
    },
    setCover() {
      this.$emit('setCover', this.uploadModule)
    },
    onDelete() {
      this.$emit('onDelete', this.uploadModule)
    },
    isImagePath() {
      const imageExtensions = /\.(jpeg|jpg|gif|png|bmp|webp)$/i;
      this.isImage =  imageExtensions.test(this.imageUrl);
    },
    playVideo() {
      // window.open(this.imageUrl);
      // this.$refs.videoEle && this.$refs.videoEle.play();
    }
  },
  watch: {
    uploadModule() {
      this.getBgUrl();
    },
    'uploadModule.state': function(newLocale) {
      this.getBgUrl();
    },
    'uploadModule.isCover': function(newLocale) {
      this.getBgUrl();
    }
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped>
  .upload-step{
    min-width: 166px;
    width: 166px;
    height: 94px;
    position: relative;
    background: #000;
    border: 1px solid rgba(255, 255, 255, 0.15);
    .upload-img-box{
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      .video-box{
        width: 100%;
        height: 100%;
      }
    }
    .play-btn{
      position: absolute;
      left: 66px;
      top: 32px;
      width: 32px;
      height: 32px;
      background: url('@/assets/postApplication/icon_video.png');
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 10000;
    }
    .info-wrap{
      padding: 8px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      width: 100%;
      height: 86px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-start;
      .uploading-tips{
        color: #ddd;
        font-size: 12px;
        font-weight: 400;
      }
      .progress{
        width: 100%;
        height: 4px;
        :deep(.el-progress-bar__outer){
          height: 2px;
        }
      }
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      .state-tips{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        .wait-icon{
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background-image: url('@/assets/postProduct/icon_wait.png');
          background-size: cover;
        }
        .uploading-icon{
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background-image: url('@/assets/postProduct/icon_uploading.png');
          background-size: cover;
          animation: rotate 2s linear infinite;
        }
        .success-icon{
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background-image: url('@/assets/postProduct/icon_uploaded.png');
          background-size: cover;
        }
        .fail-icon{
          width: 16px;
          height: 16px;
          border-radius: 8px;
          background-image: url('@/assets/postProduct/icon_fail.png');
          background-size: cover;
        }
        .success-text{
          color: #00FFF6;
          font-size: 12px;
          font-weight: 400;
        }
        .info-text{
          color: #ddd;
          font-size: 12px;
          font-weight: 400;
        }
        .fail-text{
          color: #FF5C51;
          font-size: 12px;
          font-weight: 400;
        }
      }
      .delete-icon{
        position: absolute;
        right: 6px;
        top: 6px;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-image: url('@/assets/postProduct/icon_delete.png');
        background-size: cover;
        z-index: 1000;
        cursor: pointer;
      }
      .set-cover-btn{
        position: absolute;
        right: 6px;
        bottom: 0px;
        padding: 4px 8px;
        background: rgba(0, 0, 0, 0.30);
        color: #ddd;
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
      }
      .cover-text{
        position: absolute;
        left: 0;
        top: 0;
        padding: 4px 8px;
        background: rgba(0, 0, 0, 0.30);
        color: #ddd;
        font-size: 12px;
        font-weight: 400;
      }

    }
    .mask{
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.60) 100%);
    }
  }
</style>
