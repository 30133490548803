<template>
  <div class="gate-not-found-page">
    <div class="not-found-container">
      <div class="not-found-icon"></div>
      <div class="not-found-text">{{ $t('views.page404.title') }}</div>
      <div class="go-back-btn" @click="gotoHome">{{ $t('views.page404.btn') }}</div>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  name: 'cherry-view',
  props: {
    content: {
      type: String,
      default: ''
    }
  },
 methods: {
    gotoHome() {
      this.$router.push({ path: '/gate' })
    }
 }
}
</script>
<style lang="scss">
.gate-not-found-page{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .not-found-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .not-found-icon{
      background: url('../../assets/exception/404icon.png') no-repeat;
      background-size: 100% 100%;
      width: 160px;
      height: 160px;
    }
    .not-found-text{
      margin-top: 12px;
      color:  #666;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
    }

    .go-back-btn{
      margin-top: 24px;
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      padding: 5px 16px;
      border: 1px solid #666;
      cursor: pointer;
    }
  }
}
</style>
