  <template>
    <div>
      <el-dialog
        :visible.sync="visible"
        width="424px"
        :show-close="false"
        custom-class="lit-join-creator-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        >
        <div class="pop-top" slot="title">
          <div class="delete-title">{{ $t('views.createApplication.joinCreator.title') }}</div>
          <div class="delete-clone" @click="onClose"></div>
        </div>
        <div class="dialog-content-wrap">
          <div class="audit-result" v-if="auditInfo && auditInfo.audit_opinion">{{ auditInfo.audit_opinion }}</div>
          <div class="tips">{{ useScene === Join_Scenes.Reply ?  $t('views.createApplication.joinCreator.replyTips') : $t('views.createApplication.joinCreator.tips') }}</div>
          <div class="input-wrap" v-if="useScene === Join_Scenes.Act && this.demoList.length > 0">
            <div class="input-label require-input">{{ $t('views.createApplication.joinCreator.selectDemo') }}</div>
            <el-select v-model="currentDemoId" :placeholder="$t('views.createApplication.joinCreator.selectDemoPH')" @change="selectDemo">
              <el-option
              v-for="item in demoList"
              :key="item.works_id"
              :label="item.works_title"
              :value="item.works_id">
            </el-option>
            <el-option
              key="createDemoKey"
              :label="$t('views.createApplication.joinCreator.createOption')"
              value="createDemoKey">
            </el-option>
            </el-select>
          </div>
          <div class="input-wrap" v-if="initState">
            <div class="input-label require-input">{{ $t('views.createApplication.joinCreator.demoName') }}</div>
            <el-input
              :placeholder="$t('views.createApplication.joinCreator.demoNamePH')"
              v-model="demoName"
              class="lit-custom-dialog-input"
              clearable>
            </el-input>
          </div>
          <div class="input-wrap" v-if="initState">
            <div class="input-label require-input">{{ $t('views.createApplication.joinCreator.intro') }}</div>
            <el-input
              class="input-title"
              type="textarea"
              :placeholder="$t('views.createApplication.joinCreator.introPH')"
              v-model="intro"
              :autosize="{ minRows: 6, maxRows: 6}"
              maxlength="1000"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <div slot="footer" style="padding: 0 10px;">
          <el-button type="primary" :class="{'submit-btn': true, 'disable': !canSubmit}" :loading="loading" @click="onSubmit" :disabled="!canSubmit">{{ useScene === Join_Scenes.Reply ? $t('views.createApplication.joinCreator.reSubmit') : $t('views.createApplication.joinCreator.submit') }}</el-button>
        </div>
      </el-dialog>
      <ConfirmDialog
        :title="$t('views.createApplication.joinCreator.updateDraftTitle')"
        :content="$t('views.createApplication.joinCreator.updateDraftContent')"
        :confirmBtn="$t('views.createApplication.joinCreator.updateDraftSubmit')"
        :cancelBtn="$t('views.createApplication.joinCreator.updateDraftCancel')"
        @onCancel="cancelSaveDraft"
        @onConfirm="confirmSaveDraft"
        :visible="confirmVisible"
        />
    </div>
  </template>
  
  <script>
  import ConfirmDialog from '@/components/dialog/deleteDialog.vue';
  import { requestJoinCreator } from '@/api/token';
import { getUserWorks, getUserWorksDraft, setGameWorksAudit } from '@/api/gameWorks';
import { GameWorksTypes } from '@/config/gameWorks';
import { Join_Scenes, Token_Types } from '@/config/token';
import { ArticleAuditStatus } from '@/config/article';
  export default {
    name: 'join-creator-dialog',
    components: { ConfirmDialog },
    props: {
    },
    data() {
      return {
        useScene: 0,// 0: 编辑页 1:活动页
        demoName: '',
        intro: '',
        auditInfo: null,
        isDraft: false, // 是否草稿
        visible: false,
        loading: false,
        confirmVisible: false,
        demoList: [],
        currentDemoId: '',
        currentDemo: null,
        initState: false, // 初始化状态
        Join_Scenes
      }
    },
    mounted() {
    },
    computed: {
      canSubmit() {
        if (this.useScene === Join_Scenes.Edit) {
          return this.demoName !== '' && this.intro !== ''
        }
        if (this.demoList.length > 0) {
          return this.currentDemoId !== '' && this.demoName !== '' && this.intro !== ''
        }
        return this.demoName !== '' && this.intro !== ''
      }
    },
    methods: {
      open(useScene, currentDemo, isDraft = false, auditInfo) {
        this.visible = true;
        this.useScene = useScene;
        this.isDraft = isDraft;
        this.currentDemoId = '';
        this.currentDemo = null;
        console.log('open =====>', this.useScene)
        if (useScene === Join_Scenes.Edit) {
          this.currentDemo = currentDemo;
          this.demoName = currentDemo.works_title;
          this.intro = currentDemo.profiles;
          this.initState = true;
          return;
        }
        if (useScene === Join_Scenes.Reply){
          this.currentDemo = currentDemo;
          this.demoName = currentDemo.works_title;
          this.intro = currentDemo.profiles;
          this.useScene = Join_Scenes.Reply;
          this.auditInfo = auditInfo;
          this.initState = true;
          return;
        }
        this.demoName = '';
        this.intro = '';
        this.initDemoList();
        
      },
      async initDemoList() {
        this.demoList = [];
        this.initState = false;
        await this.fetchWorksDraft();
        
        if (this.useScene !== Join_Scenes.Act) {
          this.initState = true;
        }
      },
      async fetchWorksList() {
        const res = await getUserWorks({
          common_limit: 100,
          common_offset: 0,
          works_type: GameWorksTypes.Works_Type_Game_Demo,
        })
        if (res.code === 0 && res.data) {
          const { audit_base_data, common_total } = res.data;
          const targetList = audit_base_data.filter((item) => !item.is_token_apply)
          this.demoList = this.demoList.concat(targetList);
        }
        if (this.demoList.length === 0) {
          this.selectDemo('createDemoKey')
        }
      },
      async fetchWorksDraft() {
        //获取作品草稿
        const res = await getUserWorksDraft({
          works_type: GameWorksTypes.Works_Type_Game_Demo,
          common_limit: 300,
          common_offset: 0
        })
        if (res.code === 0 && res.data) {
          const { audit_base_data, common_total } = res.data;
          const targetList = audit_base_data.filter((item) => !item.is_token_apply)
          this.demoList = this.demoList.concat(targetList);
          console.log('fetchWorksDraft', this.demoList);
        }
        this.fetchWorksList();
      },
      selectDemo(value) {
        this.initState = true;
        if (value === 'createDemoKey') {
          this.currentDemo = {
            works_id: 'createDemoKey'
          }
          this.demoName = '';
          this.intro = '';
          return;
        }
        this.currentDemo = this.demoList.find(item => item.works_id === value);
        if (this.currentDemo) {
          this.demoName = this.currentDemo.works_title;
          this.intro = this.currentDemo.profiles;
          this.isDraft = this.currentDemo.status === ArticleAuditStatus.Status_Draft
        }
        console.log('value:',value, this.currentDemo, this.isDraft)
      },
      onClose() {
        this.visible = false;
      },
      onConfirm() {
        this.$emit('onConfirm');
      },
      onSubmit() {
        if (this.isDraft) {
          if (this.currentDemo.works_title !== this.demoName || this.currentDemo.profiles !== this.intro) {
            this.confirmVisible = true;
            return;
          }
        }
        // 新建,触发条件=> 1、活动页 & 没有草稿和作品 2、选择了创建新作品
        if (this.useScene === Join_Scenes.Act) {
          if (this.demoList.length === 0 || this.currentDemo.works_id === 'createDemoKey') {
            this.saveDraft(true);
            return;
          }
        }
        this.sendJoinCreator();
      },
      cancelSaveDraft() {
        this.confirmVisible = false;
        this.sendJoinCreator(false);
      },
      confirmSaveDraft() {
        this.confirmVisible = false;
        this.sendJoinCreator(true);
      },
      async sendJoinCreator(cbStatus = false) {
        console.log('save::::::', cbStatus)
        this.loading = true;
        const params = {
          project_id: this.currentDemo.works_id,
          apply_type: Token_Types.Apply_Type_Project,
          demo_name: this.demoName,
          introduction: this.intro
        };
        // 申请创作者计划
        const result = await requestJoinCreator(params);
        // console.log('result:', result);
        this.visible = false;
        this.loading = false;
        if (result.code === 0) {
          this.$message.success(this.$t('views.createApplication.joinCreator.successMsg'))
          if (this.isDraft && cbStatus) {
            console.log('on save')
            await this.saveDraft();
          }
          
          this.$emit('onCallback', {
            saveStatus: cbStatus,
            works_title: this.demoName,
            profiles: this.intro
          });
          return;
        }
        this.$message.success(result.message)
      },
      async saveDraft(isCreate = false) {
        let params;
        if (isCreate){
          params = {
            works_type: 1,
            works_title: this.demoName,
            profiles: this.intro,
            works_version: "0.0.1",
            editor_type: 1,
            base_data: {
              platform: [],
              label:[]
            },
            other_data: {
              tools_id: [],
              medias:{
                movie:[],
                pictures:[]
              },
              download: 1,
              game_web_url: ""
            },
            photo_data: [],
            works_id: "",
            user_id:"",
            status: 0
          }
        } else {
          const { photo_data, base_data, editor_type, other_data, status, works_id, works_type, works_version } = this.currentDemo;
          params = {
            photo_data,
            base_data,
            editor_type,
            other_data: {
              ...other_data,
              demo_file_size: Number(other_data.demo_file_size || '0')
            },
            status,
            works_id,
            works_type,
            works_version,
            works_title: this.demoName,
            profiles: this.intro
          }
        }
        const result = await setGameWorksAudit(params);
        if (isCreate) {
          if (result.code === 0 && result.data) {
            const { audit_base_data } = result.data;
            this.currentDemo =  audit_base_data;
            this.sendJoinCreator();
          }
        }
        console.log('result:', result)
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .lit-join-creator-dialog{
    .pop-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        .delete-title {
          color: #DDD;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }

        .delete-clone {
          width: 16px;
          height: 16px;
          background: url(@/assets/knowledge/close_info.png) no-repeat center;
          background-size: 100% 100%;
          cursor: pointer;
        }
      }
    .dialog-content-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0 10px;
      gap: 24px;
      .audit-result{
        color: #FF5C51;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
      .tips{
        color: #999;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        // align-self: stretch;
      }
      .key-window{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 8px;
        padding: 5px 12px;
        align-items: center;
        border: 1px solid #333;
        .key-value{
          flex: 1 0 0;
          overflow: hidden;
          color: #333;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
        .copy-btn{
          width: 14px;
          height: 16px;
          cursor: pointer;
          background: url('@/assets/postApplication/apiKey/icon_copy.png');
          background-size: 100% 100%;
        }
      }
      .require-input{
        margin-left: -8px;
      }
      .require-input::before{
        content: "*";
        color: #f56c6c;
        margin-right: 4px;
      }
      .input-wrap{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 16px;
        .input-label{
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; 
        }
        :deep(.el-textarea__inner) {
          color: #999 !important;
        }
        :deep(textarea) {
          resize: none;
          border: 1px solid #333;
        }
        :deep(.el-input__count){
          background: transparent;
          color: #666;
        }
        :deep(.el-input__count-inner){
          background: transparent;
          color: #666;
        }
      }
    }
    .submit-btn{
      display: flex;
      width: 100%;
      height: 32px;
      padding: 5px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      background: #00FFF6;
      color: #000;
      cursor: pointer;
      // pointer-events: none;
    }
    .submit-btn:hover{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #00FFF6;
      color: #000;
    }
    .disable{
      opacity: 0.3;
      background: #00FFF6 !important;
    }
  }
  
  </style>
  