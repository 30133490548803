import { LiteGateCosUpload } from '@/utils/lsCosUpload';

export const tinyApiKey = 'ub8oh8oe6s7gfeda06lspuse75v5qdhk1i39zmxziaw30x3y';
export const cherryId = 'gateCherryIdsdg';

let editorDialog = null as any;


export const gateEditorConfig = {
    plugins: 'image link lists advlist autosave codesample table textcolor autoresize wordcount media paste',
    toolbar: 'undo redo | removeformat | fontsizeselect | formatselect | forecolor backcolor | bold italic underline strikethrough | link image media table mergetags | codesample | align lineheight | checklist numlist bullist | blockquote highlight | indent outdent | emoticons charmap',
    menubar: false,
    fontsize_formats: '12px 14px 16px 20px 24px',
    block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2',
    resize: false,
    content_css: [ '/tinyCss/tiny.codepen.min.css', '/tinyCss/tinymce.gate.css', '/tinyCss/prism.css' ],
    statusbar: false,
    codesample_prismjs_url: '/tinyCss/prism.js',
    images_upload_handler: LiteGateCosUpload.getInstance().upload,
    paste_data_images: true,
    file_picker_types: 'media file',
    file_picker_callback: uploadVideoFile,
    icons_url: '/tinyCss/tinymce.icons.js',
    icons: 'litgate',
    formats: {
      blockquote: { block: 'blockquote', classes: 'blockquote' }
    },
    setup: function(editor) {
      // 高亮
      editor.ui.registry.addButton("highlight", {
        // text: "highlight",
        icon: 'highlight',
        onAction: function() {
          const selectedText = editor.selection.getContent({format: 'json'});
          editor.selection.setContent(`<div class="highlight">${selectedText}</div>`)
        }
      });
      // 打开弹窗
      editor.on('OpenWindow', function(eventDetails) {
        editorDialog = eventDetails.dialog;
      });
    }
  };

  export const markdownEditorConfig = {
    externals: {
    },
    engine: {
      global: {
        urlProcessor(url, srcType) {
          console.log(`url-processor`, url, srcType);
          return url;
        },
      },
      syntax: {
        table: {
          enableChart: false,
          // chartEngine: Engine Class
        },
        fontEmphasis: {
          allowWhitespace: true, // 是否允许首尾空格
        },
        mathBlock: {
          engine: 'MathJax', // katex或MathJax
          src: 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js', // 如果使用MathJax将js在此处引入，katex则需要将js提前引入
        },
        inlineMath: {
          engine: 'MathJax', // katex或MathJax
        },
        emoji: {
          useUnicode: false,
          customResourceURL: 'https://github.githubassets.com/images/icons/emoji/unicode/${code}.png?v8',
          upperCase: true,
        },
      },
      customSyntax: {},
    },
    toolbars: {
      toolbar: [
        'switchModel',
        '|',
        'bold',
        'italic',
        'strikethrough',
        '|',
        'color',
        'header',
        '|',
        'list',
        {
          insert: ['image', 'audio', 'video', 'link', 'hr', 'br', 'code', 'formula', 'toc', 'table', 'pdf', 'word'],
        },
        'graph',
        'settings',
      ],
      bubble: false
    },
    editor: {
      defaultModel: 'editOnly',
    },
    previewer: {
      // 自定义markdown预览区域class
      // className: 'markdown'
    },
    keydown: [],
    //extensions: [],
  };

  // 上传视频
  function uploadVideoFile(cb) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/*');
    input.setAttribute('multiple', 'false');
    input.onchange = async function(){
      if (!input.files) return;
      if (editorDialog){
        editorDialog.block('begin upload');
      }
      const file = input.files[0];
      const editorUploadSuccess = (r) => {
        if (editorDialog){
          editorDialog.unblock('end upload');
        }
        cb(r);
      };
      const editorUploadFail = () => {};
      LiteGateCosUpload.getInstance().upload(file, editorUploadSuccess, editorUploadFail, 'file');
    }
    input.click();
  }
