<template>
  <div class="banner-carousel" @mouseover="startHover" @mouseout="stopHover">
        <div class="cardBox">
          <div class="container" ref="bannerContainer" :style="{ width: carouselWidth + 'px', transform: 'translateX(' + translateXList[translateIndex] + 'px)' }">
            <slot />
          </div>
        </div>
        <div class="maskContainer">
          <div class="leftMask mask" ref="maskEle" @click="toLast">
            <div class="leftBtnBg btnBg"  v-show="this.translateIndex > 0">
              <div class="leftBtn Btn"></div>
            </div>
          </div>
          <div class="rightMask mask" @click="toNext">
            <div class="rightBtnBg btnBg"  v-show="this.translateIndex < this.translateXList.length - 1">
              <div class="rightBtn Btn"></div>
            </div>
          </div>
        </div>
      </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
@Component({
    name: 'bannerCarousel'
})
class BannerCarousel extends Vue {
  @Prop({ default: () => [] }) public listData!: any[]

  public translateIndex = 0;
  public translateXList = [0, -610, -1220];
  public carouselWidth = 10000;
  public timer;
  public hover = true;
  public lastTime = Date.now();

  mounted() {
    this.initializedPro();
    this.startTimer();
    this.hover = false;
    window.onresize = () => {
      this.initializedPro();
    }
  }

  public startTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(this.autoAnimation, 3000);
  }

  public autoAnimation() {
    const nowTime = Date.now();
    if (nowTime - this.lastTime < 3000 || this.hover) {
      return;
    }
    if (this.translateIndex < this.translateXList.length - 1) {
      this.toNext();
      return;
    }
    this.translateIndex = 0;
    this.onChange();
    this.lastTime = nowTime;
  }

  public startHover() {
    this.hover = true;
  }

  public stopHover() {
    this.hover = false;
  }



  public carouselItems() {
    const slots = this.$slots.default || [];
    if (slots.length > 1) {
      return [slots[slots.length - 1], ...slots, slots[0]]
    }
    return slots;
  }

  public toLast() {
    if (this.translateIndex > 0) {
      this.translateIndex --;
      this.onChange();
      this.lastTime = Date.now();
    }
  }
  public toNext() {
    if (this.translateIndex < this.translateXList.length - 1) {
      this.translateIndex ++;
      this.onChange();
      this.lastTime = Date.now();
    }
  }

  public onChange() {
    this.$emit('change', this.translateIndex);
  }

  public countSlotWidth() {
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (windowWidth > 1919) {
      return { maskWidth: 273, slotWidth: 1136 };
    }
    if (windowWidth > 1679) {
      return { maskWidth: 234, slotWidth: 976 }
    }
    return { maskWidth: 171, slotWidth: 711 }
  }


  public initializedPro() {
    const widthResult = this.countSlotWidth();
    const slotList = this.$slots.default || [];
    const len = slotList?.length || 0;
    const slotWidth = widthResult.slotWidth;
    this.carouselWidth = len * slotWidth + (len - 1) * 16;
    const translateXList = [] as any[];
    for(let i = 0; i < len; i ++) {
      translateXList.push(-i * (slotWidth + 16) + widthResult.maskWidth + 15);
    }
    this.translateXList = translateXList;
  }

  destroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

}

export default BannerCarousel
</script>
  
<style lang="scss" scoped>

.banner-carousel{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .cardBox{
    width: 100%;
    height: 100%;
    .container{
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: row;
      transition: all ease-in-out 0.5s;
    }
  } 
  @media screen and (min-width: 1920px) {
    .mask {
      width: 273px;
    }
  }
  @media screen and (max-width: 1919px) {
    .mask{
      width: 234px;
    }
  }
  @media screen and (max-width: 1679px) {
    .mask{
      width: 171px;
    }
  }
  .mask{
    position: absolute;
    top: 0;
    height: 100%;
    cursor: pointer;
    // background: rgba($color: #000, $alpha: 0.5);
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
    .btnBg{
      position: absolute;
      top: calc(50% - 60px);
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 1;
      background-size: 100% 100%;
      .Btn{
        width: 64px;
        height: 64px;
      }
    }
  }
  .leftMask{
    left: 0;
    .leftBtnBg{
      right: 20px;
      .leftBtn{
        background: url('../../../assets/gameWorks/home/icon_arrow_back.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .rightMask{
    right: 0;
    .rightBtnBg{
      left: 20px;
      .rightBtn{
        background: url('../../../assets/gameWorks/home/icon_arrow_forward.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  
}
</style>