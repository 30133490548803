import { ContinueQueryKey } from '@/config';
import { Local } from './storage';
import { RegEmail } from '@/utils/regs';

/**
 * 图片懒加载
 * @param el dom 目标元素
 * @param arr 列表数据
 * @description data-xxx 属性用于存储页面或应用程序的私有自定义数据
 */
export const lazyImg = (el: any, arr: any) => {
  const io = new IntersectionObserver((res) => {
    res.forEach((v: any) => {
      if (v.isIntersecting) {
        const { img, key } = v.target.dataset;
        v.target.src = img;
        v.target.onload = () => {
          io.unobserve(v.target);
          arr[key].loading = false;
        };
      }
    });
  });
  setTimeout(() => {
    document.querySelectorAll(el).forEach(img => io.observe(img));
  });
};

/**
 * 对象深克隆
 * @param obj 源对象
 * @returns 克隆后的对象
 */
export function deepClone(obj: any) {
  let newObj: any;
  try {
    newObj = obj.push ? [] : {};
  } catch (error) {
    newObj = {};
  }
  for (const attr in obj) {
    if (typeof obj[attr] === 'object') {
      newObj[attr] = deepClone(obj[attr]);
    } else {
      newObj[attr] = obj[attr];
    }
  }
  return newObj;
}

/**
 * 判断是否是移动端
 */
export function isMobile() {
  if (
    navigator.userAgent.match(/('phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone')/i)
  ) {
    return true;
  }
  return false;
}

/**
 * 判断数组对象中所有属性是否为空，为空则删除当前行对象
 * @description @感谢大黄
 * @param list 数组对象
 * @returns 删除空值后的数组对象
 */
export function handleEmpty(list: any) {
  const arr = [];
  for (const i in list) {
    const d = [];
    for (const j in list[i]) {
      d.push(list[i][j]);
    }
    const leng = d.filter(item => item === '').length;
    if (leng !== d.length) {
      arr.push(list[i]);
    }
  }
  return arr;
}

export function downloadFile(url, name) {
  const a = document.createElement('a');
  const filename = name || (`file_${Date.now()}`);
  a.href = url;
  a.download = filename;
  a.click();
  // window.URL.revokeObjectURL(url)
}

/**
 * 统一批量导出
 * @method elSvg 导出全局注册 element plus svg 图标
 * @method useTitle 设置浏览器标题国际化
 * @method lazyImg 图片懒加载
 * @method deepClone 对象深克隆
 * @method isMobile 判断是否是移动端
 * @method handleEmpty 判断数组对象中所有属性是否为空，为空则删除当前行对象
 */
const other = {
  useTitle: () => {
    useTitle();
  },
  lazyImg: (el: any, arr: any) => {
    lazyImg(el, arr);
  },
  deepClone: (obj: any) => {
    deepClone(obj);
  },
  isMobile: () => isMobile(),
  handleEmpty: (list: any) => handleEmpty(list),
};
// 统一批量导出
export default other;

export function getParam(name: string, url = location.href) {
  const reg = new RegExp(`(\\?|&)${name}=([^&]*)(&|$)`, 'i');
  const r = url.match(reg);
  return r != null ? r[2] : '';
}

// 调整时区，添加8小时
export function UTCTime2BJTime(time: Date | string) {
  if (!time) return 0;
  if (typeof time === 'string') time = new Date(time);
  time.setHours(time.getHours() + 8);
  return time;
}
// 特殊参数出storage
export function getContinueQuery() {
  return Local.get(ContinueQueryKey) || null;
}

function delUrlParam(param) {
  if (typeof window.URL !== 'function') return;
  let obj = new window.URL(window.location.href);
  obj.searchParams.delete(param);
  return obj.href;
}
function addUrlParam(key, value) {
  if (typeof window.URL !== 'function') return;
  let obj = new window.URL(window.location.href);
  obj.searchParams.set(key, value);
  return obj.href;
}

// 是否为中文
export function isCnChar(c) {
  return !((c >= 0x0001 && c <= 0x007e) || (0xff60 <= c && c <= 0xff9f));
}

// 计算字符串长度，英文1个字符，中文2个字符
export function strlen(str) {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    const c = str.charCodeAt(i);
    if (isCnChar(c)) len += 2;
    else len += 1;
  }
  return len;
}


// 转换app测试账号
export function transTestAcc(account, appId) {
  if (RegEmail.test(account)) return account;
  const suffix = 'TestOasis';
  return `${account}-${appId}@${suffix}.com`;
}

// 数组分为n组
export function getNewArray(array, subGroupLength) {
  let index = 0;
  let newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}