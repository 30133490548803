<template>
  <div class="gate-guide-footer">
    <div class="gate-guide-contain">
      <div class="gate-guide-logo"></div>
      <div class="gate-copyright">
        Copyright © 2023  litgate.ai  All rights reserved
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'guide-footer',
  data() {
    return {
      lang: 'en',
      langTypes: [
        { key: 'zh', label: '中' },
        { key: 'en', label: 'EN' },
      ]
    }
  },
  mounted() {
     ;
  },
  methods: {
    setLang(item) {
      this.lang = item.key;
    }
  }
}
</script>

<style lang="scss" scoped>
.gate-guide-footer {
  margin: 0;
  height: 100px;
  margin-top: 150px;
  padding: 12px 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  .gate-guide-contain{
    width: 1200px;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    .gate-guide-logo{
      width: 240px;
      height: 60px;
      background: url("https://cdn.litgate.ai/gate/guide/gate_logo.png") no-repeat;
      background-size: 100% 100%;
      align-items: center;
      opacity: 0.5;
    }
    .gate-copyright{
      color:  #FFF;
      text-align: right;
      
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      text-transform: uppercase;
      opacity: 0.5;
    }
  }
}
</style>
