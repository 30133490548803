<template>
  <el-dialog :title="title" :visible.sync="visible" width="1044px" custom-class="dialog-custom article-select">
    <div class="relevant-dialog">
      <div class="article-content-dialog">
        <div class="left-box">
          <div class="select-box">
            <el-input v-model="searchName" :placeholder="$t('views.editorArticle.keywords')"></el-input>
            <el-select v-model="selectedType" clearable :placeholder="$t('views.editorArticle.selectCategory')">
              <el-option
                v-for="item in ArticleCategoryOptions"
                :key="item.type"
                :label="$t(item.i18n)"
                :value="item.type">
              </el-option>
            </el-select>
            <div class="search-btn btn" @click="onSearch">{{ $t('views.editorArticle.search') }}</div>
          </div>
          <div class="list-title">{{ $t('views.editorArticle.searchList') }}</div>
          <div class="table-box">
            <div class="list-item" v-for="(articleItem, index) in listData" :key="index">
              <el-checkbox :value="isCheck(articleItem)" @change="(e) => checkChange(e, articleItem)"></el-checkbox>
              <div class="title">
                {{ articleItem.title }}
              </div>
            </div>
          </div>
          <div class="table-pagination" v-if="listData.length > 0">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="dataPagination.total"
              :current-page="dataPagination.current + 1"
              @current-change="changeSearch"
            >
            </el-pagination>
          </div>
         
        </div>
        <div class="right-box">
          <div class="list-title">{{ $t('views.editorArticle.selected') }}({{ selection.length }})</div>
          <div class="table-box">
            <div class="list-item" v-for="(articleItem, index) in selection" :key="index">
              <div class="title">
                {{ articleItem.title }}
              </div>
              <div class="close-btn" @click="removeCheck(articleItem)">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tool-box">
        <div class="btn cancelBtn" @click="closeFunc">{{ $t('views.editorArticle.cancel') }}</div>
        <div class="btn subBtn" @click="onSubmit">{{ $t('views.editorArticle.save') }}</div>
      </div>
    </div>
  </el-dialog>
</template>
  
<script>
import { ArticleCategoryOptions } from '@/config/article'
import { getAllArticle } from '@/api/article'
export default {
  name: 'article-select-dialog',
  props: {
    title: {
      type: String,
      default: 'Related Posts'
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      searchName: '',
      selectedType: '',
      ArticleCategoryOptions,
      listData: [],
      selection: [],
      dataPagination: {
        size: 10,
        total: 100,
        current: 0,
      },
      checkState: true
    }
  },
  mounted() {
    console.log('dialog mounted')
  },
  methods: {
    openDialog(arr) {
      this.selection = arr.slice(0);
      this.visible = true;
    },
    closeFunc() {
      this.visible = false;
    },
    onSubmit() {
      this.$emit('submit', this.selection);
      this.visible = false;
    },
    isCheck(item){
      const result = this.selection.find(ele => ele.lore_essay_id === item.lore_essay_id);
      if (result) {
        return true;
      }
      return false;
    },
    changeSearch(current) {
      this.dataPagination.current = current - 1;
      this.onSearch();
    },
    checkChange(current, item) {
      if (current) {
        this.addCheck(item);
        return;
      }
      this.removeCheck(item);
    },
    removeCheck(item) {
      let selectedArr = this.selection;
      const result = selectedArr.findIndex(ele => ele.lore_essay_id === item.lore_essay_id);
      if (result >= 0) {
        selectedArr.splice(result, 1);
        this.selection = [].concat(selectedArr);
        this.listData = [].concat(this.listData);
      }
    },
    addCheck(item) {
      const result = this.selection.find(ele => ele.lore_essay_id === item.lore_essay_id);
      if (result) {
        return;
      }
      this.selection.push(item);
    },
    async onSearch() {
      let params = {
        lore_essay_limit: this.dataPagination.size,
        lore_essay_offset: this.dataPagination.current
      };
      if (this.selectedType) {
        params.lore_essay_type = this.selectedType;
      }
      if (this.searchName) {
        params.search_name = this.searchName;
      }
      const result = await getAllArticle(params);
      if (result.code === 0){
        const { data } = result;
        const { base_data = [] } = data;
        this.dataPagination.total = Number(data.lore_essay_total);
        this.listData = base_data;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.dialog-custom){
  padding: 32px;
}
.relevant-dialog{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    cursor: pointer;
  }
  .article-content-dialog{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    
    .left-box{
      width: 474px;
      height: 522px;
      border: 1px solid #333;
      padding: 24px;
      gap: 24px;
      flex-direction: column;
      align-items: flex-start;
      display: flex;
      .select-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        :deep(.el-input){
          width: 160px;
          height: 32px;
        }
        :deep(.el-select){
          width: 160px;
        }
        :deep(.el-input__inner){
          width: 160px;
          height: 32px;
        }
        .search-btn{
          width: 80px;
          min-width: 82px;
          height: 32px;
          background: #00FFF6;
          color: #000;
        }
      }
      .table-pagination{
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
    .list-title{
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
    }
    .table-box{
      width: 100%;
      height: 378px;
      overflow-y: scroll;
      .list-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        .title{
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          width: 400px;
          word-wrap: break-word;
          margin-left: 8px;
        }
        .close-btn{
          width: 16px;
          height: 16px;
          background-image: url('@/assets/article/icon/close_icon.png');
          background-size: contain;
          cursor: pointer;
        }
      }
    }
    .right-box{
      width: 474px;
      height: 522px;
      border: 1px solid #333;
      padding: 24px;
      gap: 24px;
      flex-direction: column;
      align-items: flex-start;
      display: flex;
    }
  
  }
  .tool-box{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .subBtn{
      width: 80px;
      min-width: 82px;
      height: 32px;
      background: #00FFF6;
      color: #000;
      margin-left: 16px;
    }
    .cancelBtn{
      width: 80px;
      min-width: 82px;
      height: 32px;
      color: #666;
      margin-left: 16px;
      border: 1px solid #666;
    }
  }
}
</style>