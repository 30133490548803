<template>
  <div>
    <cherryMarkdown :height="500"/>
    <!-- <cherryView :content="markdownContent" /> -->
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import cherryView from '@/components/gateEditor/cherryView.vue';
import cherryMarkdown from '@/components/gateEditor/cherryMarkdown.vue';
export default {
  name: 'markdown-page',
  components: { cherryView, cherryMarkdown },
  props: {
    
  },
  data() {
    return {
      fileList: [],
      markdownContent: ''
    }
  },
  mounted() {
    this.fetchMarkdownContent();
  },
  methods: {
    changeFile(files) {
      console.log('file:', files)
    },
    handlePreview(file) {
      console.log(file);
    },
    fetchMarkdownContent() {
      axios.get('http://localhost:8080/api.md')
        .then(response => {
          this.markdownContent = response.data;
          console.log('this.markdownContent', this.markdownContent)
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
