<template>
  <div class="room-setting-wrap">
    <div class="room-setting-card" v-show="groupId < 0">
      <div>
        <el-button type="primary" :disabled="disabled" :loading="loading" @click="createGroup" style="width: 320px">创建群聊</el-button>
      </div>
      <el-button :disabled="disabled" :loading="joinLoading" @click="choiceJoinRoom" plain style="width: 320px">加入群聊</el-button>
    </div>
    <div class="room-join-card" v-if="step === 1 && groupId < 0">
      <div class="title">加入群聊</div>
      <el-input
        placeholder="请输入房间号"
        type="number"
        :min="0"
        v-model="inputRoomId">
      </el-input>
      <el-button type="primary" :loading="loading" @click="onJoinGroup">确定加入</el-button>
    </div>
    <div class="room-info-show" v-if="groupId >= 0">
      <div class="title-line">
        <div class="title">群聊信息</div>
        <div class="update-btn" @click="updateGroupInfo">
          <a title="更新群信息"><i class="el-icon-refresh" /></a>
        </div>
      </div>
      <div class="room-info">
        <div>群聊号:{{ groupId === 0 ? '创建中' : groupId}}</div>
        <div>群里共{{ allRoleList.length }}人</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { getUid, joinRoom } from '@/api/gateTown';
import { throttle } from '@/utils/throttle';
  export default {
    name: 'room-setting-wrap',
    props: {
      groupId: {
        type: Number,
        default: -1
      },
      allRoleList: {
        type: Array,
        default: () => []
      },
      canJoinRoom: {
        type: Boolean,
        default: true
      }
    },
    components: { 
      
    },
    data() {
      return {
        step: 0, // 0: 1:加入房间 2:已经
        loading: false,
        joinLoading: false,
        disabled: false,
        roomInfo: {},
        inputRoomId: ''
      }
    },
    mounted() {
      
    },
    methods: {
      updateGroupInfo: throttle(function() {
        this.$emit('updateRoleInGroup')
      }, 2000),
      handleChange(val) {
        console.log(val);
      },
      async choiceJoinRoom() {
        this.joinLoading = true;
        this.step = 1;
      },
      async onJoinGroup() {
        if(this.inputRoomId !== '') {
          const roomId = Number(this.inputRoomId);
          this.$emit('onJoinGroup', roomId);
        }
      },
      async createGroup() {
        const result = await getUid();
        if (result.code === 0) {
          this.$emit('updateRoomInfo', result, true);
          this.$message.success('创建群聊模式!')
          this.step = 2;
          return;
        }
        this.$message.error(result.message)
      },
      joinGroupCb() {
        this.joinLoading = false;
        this.$message.success('加入群聊模式!')
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
.room-setting-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  .title{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
  .room-setting-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }
  .room-join-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }
  .room-info-show{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: flex-start;
    .title-line{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .update-btn{
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
    .room-info{
      color: #999;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

}
</style>
  