<template>
  <div class="works-publish-setting">
    <el-form ref="optionForm" :model="form" :rules="rules" label-width="180px" @submit.native.prevent class="general-form">
      <el-form-item :label="$t('views.createApplication.platform')" prop="platform" >
        <el-checkbox-group v-model="form.platform">
          <el-checkbox name="pcWeb" :label="GameWorksPlatform.pcWeb">Web</el-checkbox>
          <el-checkbox name="macos" :label="GameWorksPlatform.macos">Mac</el-checkbox>
          <el-checkbox name="windows" :label="GameWorksPlatform.windows">Windows</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item :label="$t('views.createApplication.gameDemo')" prop="demoList" v-if="form.platform.indexOf(GameWorksPlatform.macos) >= 0 || form.platform.indexOf(GameWorksPlatform.windows) >= 0">
        <LscosUpload v-show="demoList.length < demoLimit" @onSuccess="demoUploadSuccess" @onChange="demoUploadBegin" @onProgress="demoUploadProgress" :fileList="demoList" :showFileList="false" :fileSizeLimit="1024 * 1024 * 1024" @handleRemove="handleRemoveDemo" accept='.rar,.zip,.gzip,.tar,.7z' listType="text" :isCustom="true" :maxLimit="demoLimit">
          <div class="upload-btn" >
            <div class="upload-icon"></div>
            <div class="upload-txt">{{ $t('views.createApplication.uploadBtn') }}</div>
          </div>
        </LscosUpload>
        <div class="demo-list-wrap">
          <DemoResourceItem @onDelete="handleRemoveDemo" :resource="demo" v-for="(demo, index) in demoList" :key="index"/>
        </div>
        <div class="custom-upload-tips">{{ $t('views.createApplication.uploadFormatTips') }}</div>
      </el-form-item>
      <el-form-item :label="$t('views.createApplication.demoUrl')" prop="demoUrl"  v-if="form.platform.indexOf(GameWorksPlatform.pcWeb) >= 0">
        <el-input
          class="input-title"
          v-model.trim="form.demoUrl"
          :placeholder="$t('views.createApplication.demoUrlPlaceHolder')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('views.createApplication.promotionMaterials')" prop="movieList">
        <div style="width: 656px;">
          <uploadListView @onChange="onChange" :uploadList="uploadList" :uploadLimit="20" @onDelete="onDelete"/>
        </div>
        <div class="tips-wrap">
          <div class="tips">{{ $t('views.createApplication.materialsTips.supported') }}</div>
          <div class="tips">{{ $t('views.createApplication.materialsTips.size') }}</div>
          <div class="tips">{{ $t('views.createApplication.materialsTips.resolution') }}</div>
        </div>
      </el-form-item>
      <el-form-item :label="$t('views.createApplication.cover')" prop="cover">
        <LscosUpload @onSuccess="uploadCoverSuccess" @onChange="uploadCover" :fileList="form.cover" @handleRemove="handleRemoveCover"/>
      </el-form-item>
      <el-form-item :label="$t('views.postProductImage.imgTag')" prop="label">
        <el-tag
          :key="tag"
          v-for="tag in form.label"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)">
          {{tag}}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          maxlength="30"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-if="showAddTagBtn" class="button-new-tag" size="small" @click="showInput" >+ {{ $t('views.editorArticle.tagAdd') }}</el-button>
      </el-form-item>
      <el-form-item :label="$t('views.editorArticle.relevantContent')" prop="lore_essay_id">
        <el-button icon="el-icon-plus" plain @click="openRelevantDialog">{{ $t('views.editorArticle.relevantAdd') }}</el-button>
      </el-form-item>
    </el-form>
    <div class="relevant-content" v-if="relevantList.length > 0">
      <div class="relevant-item" v-for="(item, index) in relevantList" :key="index">
        <div class="content" @click="gotoSeeRelevant(item)">{{ item.title }}</div>
        <div class="del-btn" @click="removeRelevant(item)"></div>
      </div>
    </div>
    <articleSelectDialog :title="$t('views.editorArticle.relatedPost')" ref="relevantDialog" @submit="setRelevant"/>
  </div>
  </template>
  
  <script>
  import { ArticleCategoryOptions } from '@/config/article';
  import { batchGetArticle } from '@/api/article';
  import LscosUpload from '@/components/lscosUpload/index.vue';
  import articleSelectDialog from '@/components/articleSelectDialog/index.vue'
  import uploadListView from './uploadListView.vue';
  import DemoResourceItem from './demoResourceItem.vue';
  import { GameWorksPlatform } from '@/config/gameWorks';
  import { LiteGateCosUpload } from '@/utils/lsCosUpload';
  import { UploadStateTypes } from '@/config/stat';
  import { generateUUID } from '@/utils/file';
  import { isImagePath } from '@/utils/statFormat';
import { RegUrl } from '@/utils/regs';
  export default {
    name: 'image-setting-form',
    components: { LscosUpload, articleSelectDialog, uploadListView, DemoResourceItem },
    data() {
      return {
        ArticleCategoryOptions,
        GameWorksPlatform,
        relevantList: [],
        uploadList: [],
        demoLimit: 1,
        demoList: [],
        form: {
          label: [],
          cover: [],
          platform: [],
          movieList: [1],
          demoList: [1],
          demoUrl: '',
          works_version: '0.0.1'
        },
        rules: {
          platform: [
            { type: 'array', required: true, message: '', trigger: 'submit' },
          ],
          movieList: [
            { type: 'array', required: true, message: '', trigger: 'submit' },
            { validator: this.validatePromotionMaterials, trigger: 'submit' },
          ],
          cover: [
            { type: 'array', required: true, message: '', trigger: 'submit' },
          ],
          demoList: [
            { type: 'array', required: true, message: '', trigger: 'submit' },
            { validator: this.validateDemo, trigger: 'submit' },
          ],
          demoUrl: [
            { validator: this.validateDemoUrl, trigger: 'submit' },
            { required: true, message: '', trigger: 'submit' },
          ],
        },
        inputVisible: false,
        maxTagLimit: 5,
        inputValue: ''
      }
    },
    mounted() {},
    methods: {
      removeRelevant(item) {
        let selectedArr = this.relevantList;
        const result = selectedArr.findIndex(ele => ele.lore_essay_id === item.lore_essay_id);
        if (result >= 0) {
          selectedArr.splice(result, 1);
          this.relevantList = [].concat(selectedArr);
        }
      },
      validatePromotionMaterials(rule, value, callback) {
        console.log('value:',value)
        if (this.uploadList.length === 0) {
          callback(new Error(this.$t('views.createApplication.promotionMaterialsError')))
          return
        }
        callback();
      },
      validateDemo(rule, value, callback) {
        if (this.demoList.length === 0) {
          callback(new Error(this.$t('views.createApplication.demoError')))
          return
        }
        callback();
      },
      validateDemoUrl(rule, value, callback) {
        if (!RegUrl.test(value)) {
          callback(new Error(this.$t('views.createApplication.demoUrlError')))
          return
        }
        callback();
      },
      onChange(file) {
        this.onUpload(file);
      },
      findUploadModulesByUid(uid) {
        return this.uploadList.find(item => item.uid === uid);
      },
      onUpload(file) {
        const uid = file.uid;
        console.log('file:', file);
        const uploadModules = {
          ...file,
          progress: 0,
          state: UploadStateTypes.Wait,
          uploadUrl: '',
          isCover:  false
        }
        LiteGateCosUpload.getInstance().upload(file.raw, (url) => {
          this.onSuccess(uid, url);
        }, () => {
          this.onFail(uid);
        }, 'file', (progress) => {
          this.onProgress(uid, progress)
        });
        this.uploadList.unshift(uploadModules);
      },
      onSuccess(uid, url) {
        const target = this.findUploadModulesByUid(uid);
        if (!target) return;
        target.uploadUrl = url;
        target.state = UploadStateTypes.Success
        setTimeout(() => {
          target.state = UploadStateTypes.Ready
        }, 3000);
      },
      onFail(uid) {
        const target = this.findUploadModulesByUid(uid);
        if (!target) return;
        target.state = UploadStateTypes.Fail
      },
      onDelete(target) {
        const index = this.uploadList.findIndex(item => item.uid === target.uid)
        this.uploadList.splice(index, 1);
        if (target.isCover) {
          if (this.uploadList.length > 0) {
            this.uploadList[0].isCover = true;
          }
        }
      },
      onProgress(uid, progress) {
        const target = this.findUploadModulesByUid(uid);
        if (!target) return;
        target.state = UploadStateTypes.Uploading
        target.progress = progress;
      },
      platformChange(check) {
        console.log('check:', check)
      },
      submitForm(isValidate = true) {
        return new Promise(async (resolve) => {
          if (!isValidate) {
            const option = this.getSettingOption();
            console.log('option:', option)
            if (option) {
              return resolve(option);
            }
            return false;
          }
          this.$refs.optionForm.validate((valid, errs = {}) => {
            console.log('valid:', valid, this.form)
            if (valid) { 
              const option = this.getSettingOption();
              if (option) {
                return resolve(option);
              }
              return false;
            } else {
              return false;
            }
          });
        });
      },
      restoreForm(formData) {
        const { works_version = '0.0.1', label, platform, other_data = {} } = formData;
        const { lore_essay_id, game_demo, game_web_url = '', medias = {}, demo_file_name='', demo_file_size = 0 } = other_data;
        const { cover, movie = [], pictures = [] } = medias;
        // 回显相关文章
        this.form.lore_essay_id = lore_essay_id;
        this.fetchRelevant();

        this.form = {
          label,
          platform,
          cover: [],
          works_version,
          movieList: [1],
          demoUrl: game_web_url,
          demoList: [1]
        }
        if (game_demo) {
          this.demoList = [
            { name: demo_file_name || game_demo, uploadUrl: game_demo, size: Number(demo_file_size), state: UploadStateTypes.Ready }
          ];
        }

        if (cover) {
          this.form.cover = [
            { name: 'unknown', url: cover, state: UploadStateTypes.Ready }
          ]
        }

        const totalArr = movie.concat(pictures);
        // const movieList = totalArr.map(item => {
        //   return { name: 'unknown', url: item }
        // });
        this.uploadList = totalArr.map(ele => {
          return {
            uid: generateUUID(),
            progress: 100,
            state: UploadStateTypes.Ready,
            uploadUrl: ele,
            isCover: false
          };
        });

        // this.form.movieList = movieList;
      },
      resetForm() {
        this.$refs.optionForm.resetFields();
      },
      getSettingOption() {
        const { label, cover, platform, demoUrl = '' } = this.form;
        const movies = [];
        const pictures = [];

        let materialsReady = true;
        this.uploadList.forEach(item => {
          if (item.state !== UploadStateTypes.Ready) {
            materialsReady = false;
          }
          if (isImagePath(item.name)) {
            pictures.push(item.uploadUrl);
          } else {
            movies.push(item.uploadUrl);
          }
        })
        const notReadyList = this.demoList.filter(item => item.state !== UploadStateTypes.Ready);
        const notReadyCoverList = this.form.cover.filter(item => item.state !== UploadStateTypes.Ready);

        if (!materialsReady || notReadyList.length > 0 || notReadyCoverList.length > 0) {
          this.$message.error(this.$t('views.postProductImage.imageNotReady'))
          return false;
        }

        const option = {
          works_version: this.works_version || '0.0.1',
          label,
          platform,
          cover: cover.map(item => item.url),
          movie: movies,
          pictures,
          demoUrl
        };
        if (this.relevantList.length > 0) {
          option.lore_essay_id = this.relevantList.map(item => item.lore_essay_id);
        }
        if (this.demoList.length > 0) {
          option.game_demo = this.demoList[0]
        }
        return option;

      },
      handleClose(tag) {
        this.form.label.splice(this.form.label.indexOf(tag), 1);
      },

      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },

      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          if (this.form.label.indexOf(inputValue) < 0) {
            this.form.label.push(inputValue);
          }
        }
        this.inputVisible = false;
        this.inputValue = '';
      },
      openRelevantDialog() {
        this.$refs.relevantDialog.openDialog(this.relevantList);
      },
      async fetchRelevant() {
        if (this.form.lore_essay_id.length === 0) {
          return;
        }
        const result = await batchGetArticle({
          lore_essay_id: this.form.lore_essay_id
        });
        if (result.code === 0) {
          const { base_data = [] } = result.data;
          this.relevantList = base_data;
        }
      },
      setRelevant(arr) {
        this.relevantList = arr;
      },
      gotoSeeRelevant(item) {
        window.open(`/knowledge/detail?id=${ item.lore_essay_id }`)
      },
      handleRemoveCover(item) {
        const target = this.form.cover.findIndex(ele => ele.uid == item.uid);
        if (target >= 0) {
          this.form.cover.splice(target, 1);
        }
      },
      handleRemoveDemo(item) {
        const target = this.demoList.findIndex(ele => ele.uid == item.uid);
        if (target >= 0) {
          this.demoList.splice(target, 1)
        }
      },
      demoUploadBegin(file) {
        this.demoList.push({
          ...file,
          progress: 0,
          state: UploadStateTypes.Uploading
        });
      },
      demoUploadProgress(r, file) {
        const target = this.demoList.find(item => item.uid === file.uid);
        if (target && target.state != UploadStateTypes.Success && target.state != UploadStateTypes.Ready) {
          target.state = UploadStateTypes.Uploading;
          target.progress = r;
        }
      },
      demoUploadSuccess(file) {
        const target = this.demoList.find(item => item.uid === file.uid);
        if (target) {
          target.state = UploadStateTypes.Success;
          target.progress = 100;
          target.uploadUrl = file.url;
          setTimeout(() => {
            target.state = UploadStateTypes.Ready
          }, 3000);
        }
        
        console.log('this.demoList:', this.demoList)
      },
      uploadCover(file) {
        this.form.cover.push({
          ...file,
          state: UploadStateTypes.Uploading
        });
      },
      uploadCoverSuccess(file) {
        console.log('file:', file, this.form.cover)
        const target = this.form.cover.findIndex(item => item.uid === file.uid);
        const fileObj = {
          ...file,
          state: UploadStateTypes.Ready
        }
        if (target >= 0) {
          this.form.cover[target] = fileObj;
          return;
        }
        this.form.cover.push(fileObj);
        // this.form.cover.push(file);
      },
    },
    computed: {
      showAddTagBtn() {
        return !this.inputVisible && this.form.label.length < this.maxTagLimit
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  $border_color: #666;
  .works-publish-setting{
    padding: 32px 0;
    background: #0f0f0f;
    :deep(.el-select){
      width: 320px;
    }

    :deep(.el-button){
      width: 241px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      font-size: 14px;
    }
    :deep(.el-form-item__label){
      text-align: left;
    }
    :deep(.el-input__inner){
      border: 1px solid #333;
      color: #999;
    }
    :deep(.el-upload--picture-card){
      width: 241px;
      height: 136px;
    }
    :deep(.el-textarea__inner) {
      color: #999 !important;
    }
    :deep(textarea) {
      resize: none;
      border: 1px solid #333;
    }
    :deep(.el-input__count){
      background: transparent;
      color: #666;
    }
    :deep(.el-input__count-inner){
      background: transparent;
      color: #666;
    }
    :deep(.el-checkbox__inner){
      background: transparent;
      color: #666;
      border-color: #666;
    }
    :deep(.el-checkbox__input.is-checked+.el-checkbox__label){
      color: #666;
    }
    :deep(.el-checkbox__input.is-checked .el-checkbox__inner){
      background-color: #00FFF6;
      color: #0f0f0f;
    }
    :deep(.el-checkbox__inner::after) {
      border-color: #0f0f0f;
    }

    .general-form{
      padding-right: 256px;
    }

    .upload-btn{
      border: 1px solid #666;
      padding: 5px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .upload-icon{
        width: 16px;
        height: 16px;
        background-image: url('@/assets/postApplication/icon_upload.png');
        background-size: 100% 100%;
      }
      .upload-txt{
        font-size: 14px;
        color: #666;
        line-height: 22px;
      }
    }
    .relevant-content{
      margin-left: 160px;
      display: flex;
      padding: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      background: rgba(255, 255, 255, 0.10);
      margin-top: 16px;
      .relevant-item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .content{
          color: #999;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px; /* 157.143% */
          border-bottom: 1px solid transparent;
          cursor: pointer;
        }
        .content:hover{
          border-bottom: 1px solid #999;
        }
        .del-btn{
          margin-left: 5px;
          width: 16px;
          height: 16px;
          background-image: url('@/assets/article/icon/close_icon.png');
          background-size: contain;
          cursor: pointer;
        }
      }
    }
    .tips-wrap{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1px;
      padding-top: 8px;
      .tips {
        font-size: 12px;
        color: #666;
        line-height: 18px;
      }
    }
    .demo-list-wrap{
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    .custom-upload-tips{
      line-height: 18px;
      font-size: 12px;
      font-weight: 400;
      color: #666;
    }
  }
  
  </style>
  