<template>
  <div :class="{'directory-box': true }" :style="{top: top + 'px'}">
    <div class="directory-title" v-show="directoryList.length > 0">
      {{ $t('views.knowledgeDetail.directory') }}
    </div>
    <div class="directory-list">
      <div :class="{'headline-wrap': true, 'active': active === index, 'active-hide': item.title === '' }" v-for="(item, index) in directoryList" :key="index" @click="onActive(item, index)">
        <!-- <div class="active-wrap">
          <div class="active-icon" v-show="active === index"></div>
        </div> -->
        <div :class="{'headline-h1': item.lowerTagName === currentMainTitle, 'headline-h2': item.lowerTagName === currentSubLevelTitle,'active-title': active === index }">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  name: 'tiny-directory',
  props: {
    content: {
      type: String,
      default: ''
    },
    mainTitle: {
      type: String,
      default: 'h1'
    },
    subLevelTitle: {
      type: String,
      default: 'h2'
    },
    top: {
      type: Number,
      default: 118
    },
  },
  data() {
    return {
      directoryList: [] as any[],
      active: -1,
      isScrollTop: false
    }
  },
  mounted() {
    if (this.content) {
      this.countDirectory();
    }
  },
  computed: {
    currentMainTitle() {
      return this.mainTitle.toLocaleLowerCase();
    },
    currentSubLevelTitle() {
      return this.subLevelTitle.toLocaleLowerCase();
    }
  },
  methods: {
    countDirectory() {
      const domEle = document.createElement('div');
      domEle.innerHTML = this.content;
      const title = domEle.querySelectorAll(`${this.currentMainTitle}, ${this.currentSubLevelTitle}`);
      const list = [] as any[];
      title.forEach((item) => {
        const text = item.textContent ? item.textContent.replace(/&nbsp;/g, " ").replace(/ /g, ' ') : item.textContent;
        list.push({
          title: text,
          tagName: item.tagName,
          lowerTagName: item.tagName.toLocaleLowerCase(),
          node: item,
        });
      });
      
      this.directoryList = list;
    },
    setActive(target) {
      this.active = target;
    },
    onActive(item, index) {
      this.active = index;
      this.$emit('onActive', { tagName: item.tagName, index: index });
    },
  },
  watch: {
    content() {
      setTimeout(() => {
        this.countDirectory();
      }, 200);
    }
  }
}
</script>
<style lang="scss" scoped>
.directory-box{
  width: 100%;
  position: sticky;
  .directory-line{
    width: 100%;
    height: 1px;
  }
  .directory-title{
    width: 100%;
    height: 46px;
    color: #DDD;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .directory-list{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .headline-wrap{
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      padding: 8px 8px 8px 0;
      cursor: pointer;
      .active-wrap{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
        width: 12px;
        min-width: 12px;
        .active-icon {
          width: 8px;
          height: 6px;
          background-image: url('@/assets/knowledge/detail/icon_directory_active.png');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      .headline-h1{
        color: #999;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        white-space: pre-wrap;
      }
      .headline-h1:hover{
        background: #141414;
        color: #ddd;
      }
      .headline-h2{
        padding-left: 16px;
        color: #999;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        white-space: pre-wrap;
      }
      .headline-h2:hover{
        // background: #141414;
        color: #ddd;
      }
      .active-title{
        color: #ddd;
      }
    }
    
    .active{
      background: #141414;
    }
    .active-hide{
      display: none;
    }
  }
}
</style>
