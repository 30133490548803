<template>
  <div class="room-setting-wrap">
    <div class="room-setting-card" v-show="roomId <= 0">
      <div>
        <el-button type="primary" :disabled="disabled" :loading="loading" @click="createRoom" style="width: 320px">创建房间</el-button>
      </div>
      <el-button :disabled="disabled" :loading="joinLoading" @click="choiceJoinRoom" plain style="width: 320px" v-show="canJoinRoom">加入房间</el-button>
    </div>
    <div class="room-join-card" v-if="step === 1 && roomId < 0">
      <div class="title">加入房间</div>
      <el-input
        placeholder="请输入房间号"
        type="number"
        :min="0"
        v-model="inputRoomId">
      </el-input>
      <el-button type="primary" :loading="loading" @click="onJoinRoom">确定加入</el-button>
    </div>
    <div class="room-info-show" v-if="roomId >= 0">
      <div class="title">房间信息</div>
      <div class="room-info">
        <div>房间号:{{ roomId }}</div>
        <div>共{{ allRoleList.length }}名玩家</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import { getUid, joinRoom } from '@/api/gateTown';
  export default {
    name: 'room-setting-wrap',
    props: {
      roomId: {
        type: Number,
        default: -1
      },
      allRoleList: {
        type: Array,
        default: () => []
      },
      canJoinRoom: {
        type: Boolean,
        default: true
      }
    },
    components: { 
      
    },
    data() {
      return {
        step: 0, // 0: 1:加入房间 2:已经
        loading: false,
        joinLoading: false,
        disabled: false,
        roomInfo: {},
        inputRoomId: ''
      }
    },
    mounted() {
      
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },
      async choiceJoinRoom() {
        this.joinLoading = true;
        this.step = 1;
      },
      async onJoinRoom() {
        if(this.inputRoomId !== '') {
          const roomId = Number(this.inputRoomId);
          const result = await joinRoom({
            room_id: roomId
          });
          this.joinLoading = false;
          if (result.code === 0) {
            this.$emit('updateRoomInfo', {
              uid: result.uid,
              room_id: roomId
            }, false);
            this.$message.success('加入房间成功!')
            return;
          }
          this.$message.error(result.message)
        }
      },
      async createRoom() {
        const result = await getUid();
        if (result.code === 0) {
          this.$emit('updateRoomInfo', result, true);
          this.$message.success('创建房间成功!')
          this.step = 2;
          return;
        }
        this.$message.error(result.message)
      },
    }
  }
  </script>
  
<style lang="scss" scoped>
.room-setting-wrap{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  .title{
    width: 100%;
    font-size: 18px;
    font-weight: bold;
  }
  .room-setting-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }
  .room-join-card{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }
  .room-info-show{
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: flex-start;
    .room-info{
      color: #999;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

}
</style>
  