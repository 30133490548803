<template>
  <div id="layout-article" class="article-container page-article">
    <!-- <Nav currentTab="product"/> -->
    <router-view :key="key"></router-view>
  </div>
</template>
  
  <script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import { LangList } from '@/config'
  import { getLanguage } from '@/lang'
  
  @Component({
    name: 'layoutAccount',
    components: { },
  })
  class layoutAccount extends Vue {
    private active = {
      lang: 'zh-cn'
    }
    private langList = LangList

    public mounted() {
      const cacheLang = getLanguage();
      if (cacheLang) {
        this.onChangeLang(cacheLang);
      }
    }
  
    get key() {
      return this.$route.path;
    }
  
    public onLogoClick() {
    }
    private onChangeLang(lang: any) {
      this.active.lang = lang;
      this.$i18n.locale = lang;
    }
  }
  export default layoutAccount
  </script>
  <style lang="scss">
  .page-article{
    background: #000;
    width: 100%;
    min-height: 100%;
  }
  </style>
  