<template>
  <div class="statistics-info">
    <div class="icon see"></div>
    <div class="info">{{ seeNum }} {{ $t('views.gameWorksDetail.views') }}</div>
    <div :class="{'icon': true, 'like': !isLiked, 'like-active': isLiked}" @click="likeFunc"></div>
    <div class="info">{{ likeNum}}</div>
  </div>
</template>
  
<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { abbreviateNumber } from '@/utils/statFormat'
@Component({
    name: 'statistics'
})
class Statistics extends Vue {
  @Prop({ default: 0 }) public seeCount!: number
  @Prop({ default: 0 }) public likeCount!: number
  @Prop({ default: false }) public isLiked!: boolean

  public formatCount(num){
    return abbreviateNumber(num);
  }

  public likeFunc() {
    this.$emit('like', !this.isLiked ? 1 : 0)
  }

  get likeNum() {
    return this.formatCount(this.likeCount);
  }

  get seeNum() {
    return this.formatCount(this.seeCount);
  }
}

export default Statistics
</script>
  
<style lang="scss" scoped>
.statistics-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-end;
  .icon{
    width: 16px;
    height: 16px;
    margin-left: 16px;
    margin-right: 3px;
  }
  .icon:first-of-type{
    margin-left: 0;
  }
  .see{
    background: url('../../assets/knowledge/detail/icon_statistics_see.png') no-repeat;
    background-size: 100%;
  }
  .like{
    background: url('../../assets/knowledge/detail/icon_statistics_like.png') no-repeat;
    background-size: 100%;
  }
  
  .like-active{
    background: url('../../assets/knowledge/icon_like_active.png') no-repeat;
    background-size: 100%;
  }
  .info {
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
  }
  
}
</style>