<template>
  <div class="application-create">
    <div class="main-container">
      <div class="option-card">
        <div class="option-title">{{ $t('views.createApplication.generalSetting') }}</div>
        <GeneralForm ref="generalForm"/>
      </div>
      <div class="option-card">
        <div class="option-title">{{ $t('views.createApplication.publishSetting') }}</div>
        <PublishSetting ref="publishSetting" />
      </div>
      <CreateFooter @onCancel="onCancel" @onSave="onSave"  @onPost="onPost" @onJoin="openJoinCreator" :isDraft="isDraft" :creatorAuditStatus="creatorAuditStatus"/>
    </div>
    <JoinCreatorDialog ref="joinCreatorDialog" @onCallback="joinCreatorCallback"/>
  </div>
</template>
  
<script>
import { EditorTypes } from '@/config/stat';
import CreateFooter from './components/createFooter.vue';
import GeneralForm from './components/generalForm.vue';
import PublishSetting from './components/publishSetting.vue';
import JoinCreatorDialog from './dialog/joinCreator.vue';
import { DownloadState, GameWorksTypes } from '@/config/gameWorks';
import { throttle } from '@/utils/throttle';
import { ArticleAuditStatus } from '@/config/article';
import { getGameWorksAudit, setGameWorksAudit } from '@/api/gameWorks';
import { requestJoinCreatorStatus } from '@/api/token';
import { Audit_State, Token_Types } from '@/config/token';


export default {
  name: 'post-demo-list',
  components: { GeneralForm, CreateFooter, PublishSetting, JoinCreatorDialog },
  props: {
    size: {
      type: Number,
      default: 32
    }
  },
  data () {
    return {
      workId: '',
      editor_type: EditorTypes.Editor_Type_1,
      works_type: GameWorksTypes.Works_Type_Game_Demo,
      isDraft: true,
      creatorAuditStatus: Audit_State.Status_Default,
      auditInfo: null
    }
  },
  mounted () {
    const workId = this.$route.query.id;
    if (workId){
      this.workId = workId;
      this.getDraft();
    }
  },
  methods: {
    async getJoinCreatorStatus() {
      const result = await requestJoinCreatorStatus({
        project_id: this.workId,
        apply_type: Token_Types.Apply_Type_Project,
        seq: '0'
      })
      console.log('result:', result)
      if (result.code === 0) {
        if (result.data) {
          const { info } = result.data;
          const { audit_status } = info;
          this.creatorAuditStatus = audit_status;
          this.auditInfo = info;
          return;
        }
        this.creatorAuditStatus = Audit_State.Status_Default
      }
    },
    async openJoinCreator(sceneType) {
      if (!this.workId) {
        await this.onSave(true, sceneType);
        return;
      }
      const params  = await this.getSettingOption(false);
      this.$refs.joinCreatorDialog.open(sceneType, params, this.isDraft, this.auditInfo);
      // this.$refs.joinCreatorDialog.open(1);
    },
    joinCreatorCallback(params) {
      const { works_title, profiles, saveStatus = false } = params;
      this.getJoinCreatorStatus();
      if (!saveStatus) return;
      this.$refs.generalForm.restoreForm({
        title: works_title,
        profiles
      });
    },
    gotoCreateDemo() {
      this.$router.push({ path: '/application/create' })
    },
    onCancel() {
      this.$router.go(-1)
    },
    async getDraft() {
      const result = await getGameWorksAudit({
        works_id: this.workId
      });
      console.log('draft:', result);
      if (result.code === 0 && result.data) {
        const { base_data } = result.data;
        this.restoreDraft(base_data);
        this.getJoinCreatorStatus();
        if (base_data.status !== ArticleAuditStatus.Status_Draft) {
          this.isDraft = false;
        }
      }
    },
    restoreDraft(baseData) {
      const { base_data, profiles, works_title, photo_data, other_data, works_version } = baseData;
      const { label, platform } = base_data;
      const { game_demo= '', lore_essay_id, medias, demo_file_name='', demo_file_size = 0 } = other_data;
      this.$refs.generalForm.restoreForm({
        title: works_title,
        profiles
      });

      this.$refs.publishSetting.restoreForm({
        works_version,
        label,
        platform,
        other_data,
      })
    },
    onSave: throttle(async function(next = false, arg) {
      const params  = await this.getSettingOption(false);
      if (params) {
        params.status = ArticleAuditStatus.Status_Draft;
        const result = await setGameWorksAudit(params);
        if (result.code === 0 && result.data) {
          const { audit_base_data } = result.data;
          const { works_id } = audit_base_data;
          this.$message.success(this.$t('views.postProductImage.saveSuccess'))
          if (works_id && this.workId !== works_id) {
            this.workId = works_id;
            this.$router.replace({
              path: '/application/editworks',
              query: {
                id: works_id
              }
            })
          }
          if (next) {
            setTimeout(() => {
              this.openJoinCreator(arg);
            }, 200)
          }
          return;
        }
        this.$message.error(this.$t('views.postProductImage.saveError'))
      }
    }),
    onPost: throttle(async function() {
      const params  = await this.getSettingOption();
      console.log('params:', params)
      if (params) {
        params.status = ArticleAuditStatus.Status_Release_Ing;
        params.version_content = '无';
        const result = await setGameWorksAudit(params);
        if(result.code === 0 && result.data) {
          this.$message.success(this.$t('views.postProductImage.postSuccess'))
          this.$router.push({path: `/application/main`})
          return
        }
        this.$message.error(this.$t('views.postProductImage.postError')+ ":" + result.message)
      }
    }),
    async getSettingOption(isValidate = true) {
      const generalData = await this.$refs.generalForm.submitForm(isValidate);
      const publishData = await this.$refs.publishSetting.submitForm(isValidate);
      console.log('generalData:', generalData)
      console.log('publishData:', publishData)
      const { title, profiles } = generalData;
      const { works_version, label, platform, cover, movie, pictures, lore_essay_id, game_demo, demoUrl, download = DownloadState.Download_Yes } = publishData;

      const medias = {
        cover: cover[0],
        movie,
        pictures
      }
      const base_data = {
        platform,
        label
      };
      const other_data = {
        tools_id: [],
        lore_essay_id,
        medias,
        download,
        game_web_url: demoUrl
      };
      console.log('game demo:', game_demo)
      if (game_demo) {
        other_data.game_demo = game_demo.uploadUrl
        other_data.demo_file_name = game_demo.name
        other_data.demo_file_size = game_demo.size
      }


      const params = {
        works_type: this.works_type,
        works_title: title,
        profiles,
        works_version,
        editor_type: this.editor_type,
        base_data,
        other_data,
        photo_data: []
      };
      // if (this.workId) {
      params.works_id = this.workId;
      params.user_id = '';
      // }
      return params;
    }

  },
}
</script>
<style lang="scss">
.application-create{
  min-height: calc(100vh - 200px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  .main-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 40px;
    width: 1136px;
    .option-card{
      margin-top: 40px;
      background-color: #0f0f0f;
      padding: 32px;
      .option-title{
        color: #DDD;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 30px */
      }
    }
  }
}
</style>