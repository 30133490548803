export enum PersonalNavTypes {
    Draft = 0,
    Favorite = 1,
    Article = 2,
    Image = 3,
    Demo = 4,
    Following = 5,
}

export enum Follo {
    Following = 0, //我关注的
    Followers = 1, //关注我的
}

export enum FolloStatus {
    follow = 1,  //关注
    unfollow = 2, //取消关注
}