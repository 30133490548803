<template>
  <div class="incentives-step-container" :style="{ left: left + 'px', top: top + 'px' }">
    <div class="icon-container" :style="{ backgroundImage: `url(${iconImg})` }">
      <div class="title">
        <div class="title-index">{{ titleIndex }}</div>
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="content-list">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="list-content">
            {{ $t(item.content) }}
          </div>
          <div class="list-tips">
            {{ $t(item.tips) }}
          </div>
        </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'incentives-step',
  props: {
    top: {
      type: Number,
      default: 0
    },
    left: {
      type: Number,
      default: 0
    },
    titleIndex:{
      type: String,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    iconImg: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },

}
</script>
<style lang="scss" scoped>
  .incentives-step-container{
    width: 523px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    gap: 15px;
    .icon-container{
      width: 523px;
      height: 500px;
      background-size: 100% 100%;
      position: relative;  
      .title{
        background: #00FFEE;
        padding: 8px 10px;
        color: #140D35;
        font-size: 40px;
        line-height: 42px;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        position: absolute;
        left: 26px;
        bottom: 45px;
        .title-index {
          background: #140D35;
          color: #00FFEE;
          border-radius: 25px;
          width: 45px;
          height: 45px;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
        }
        span{
          color: #140D35;
          font-size: 40px;
          line-height: 42px;
          font-weight: bold;
        }
      }
    }
    .content-list{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 20px;
      gap: 12px;
      .list-item:before {
        content: "";
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 15px;
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 50%;
        margin-right: 8px;
      }
      .list-item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        padding-left: 10px;
        gap: 4px;
        .list-content{
          font-size: 30px;
          color: #C6D3D2;
          line-height: 32px;
        }
        .list-tips{
          font-size: 30px;
          font-weight: bold;
          line-height: 32px;
          color: #00FFEE;
        }
      }
    }
  }
</style>