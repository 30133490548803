<template>
  <div :class="{'activity-card': true, 'activity-card-hover': isHover , 'activity-card-normal': !isHover}" :style="computedStyle"  @mouseenter="mouseOverFunc" @mouseleave="mouseoutFunc">
    <div :class="{'float-layer': true, 'hover-layer': isHover, 'normal-layer': !isHover }" :style="{ height: isHover ? '400px' : '120px' }">
      <div :class="{'time': true, 'time-hover': isHover, 'time-normal': !isHover}" >{{ timeRange }}</div>
      <div class="content-box">
        <div class="info">
          <div class="title">{{ title }}</div>
          <div class="address">{{ address }}</div>
        </div>
        <div :class="{'more': true, 'more-hover': isHover, 'more-normal': !isHover}" @click="clickMore">
          <div class="label">{{ moreLink ? $t('views.guide.events.eventMore') : $t('views.guide.events.wait') }}</div>
          <div :class="{'icon': true, 'icon-hover': isHover}"></div>
        </div>
      </div>
      <div class="introduction" v-show="isHover">{{ introduction }}</div>
    </div>
    <div class="corner" v-show="!isHover"></div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'activity-card',
  props: {
    bg: {
      type: String,
      default: ''
    },
    timeRange: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    introduction: {
      type: String,
      default: ''
    },
    moreLink: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isHover: false
    }
  },
  mounted() {
  },
  methods: {
    mouseOverFunc(){
      if (!this.isHover) {
        this.isHover = true
      }
    },
    mouseoutFunc() {
      if (this.isHover) {
        this.isHover = false;
      }
    },
    clickMore() {
      if (this.moreLink) {
        window.open(this.moreLink);
      }
    }
  },
  computed: {
    computedStyle() {
      return {backgroundImage: 'linear-gradient(0deg, rgba(5, 122, 85, 0.00) 0%, rgba(5, 122, 85, 0.00) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0.85) 100%), url(' + this.bg + ')', backgroundSize: '100% 100%'};
    }
  }
}

</script>

<style lang="scss" scoped>
.activity-card {
  height: 400px;
  width: 590px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  position: relative;
  transition: all ease-in-out 0.3s;
  .hover-layer{
    background: linear-gradient(90deg, rgba(11, 152, 99, 0.80) 0%, rgba(0, 120, 130, 0.80) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 50%, rgba(0, 0, 0, 0.00) 100%);
    backdrop-filter: blur(12px);
  }
  .normal-layer{
    background: rgba(0, 0, 0, 0);
  }
  .float-layer{
    padding: 24px 24px 16px 24px;
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;  
    flex-direction: column;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all ease-in-out 0.3s;
    .time{
      padding: 2px 6px;
      border-radius: 4px;
      gap: 8px;
      text-align: center;
      
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      text-transform: uppercase;
    }
    .time-normal{
      background: #fff;
      color: #000;
    }
    .time-hover{
      color: #fff;
      border: 1px solid var(--white, #FFF);
    }
    .content-box{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .info {
        display: flex;
        flex-direction: column;
        .title{
          
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          color: #fff;
        }
        .address{
          
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 18px */
          text-transform: uppercase;
          color: #fff;
          opacity: 0.6;
        }
      }
      .more-normal{
        flex-direction: row;
        border: 1px solid rgba(255, 255, 255, 0.30);
        color: #fff;
      }
      .more-hover{
        flex-direction: row-reverse;
        background: #fff;
        color: #0E9F6E;
      }
      .more{
        display: flex;
        padding: 8px 20px;
        justify-content: center;
        align-items: center;    
        line-height: 1;
        height: 40px;
        width: 120px;
        cursor: pointer;
        .label{
          text-align: center;
          
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          // text-transform: uppercase;
        }
        .icon{
          margin-left: 6px;
          width: 24px;
          height: 24px;
          background: url('https://cdn.litgate.ai/gate/guide/icon_arrow_right.png') no-repeat;
          background-size: 100% 100%;
        }
        .icon-hover{
          background-image: url('https://cdn.litgate.ai/gate/guide/icon_arrow-right-alt.png') !important;
        }
      }
    }
    .introduction{
      overflow: hidden;
      color: rgba($color: #fff, $alpha: 0.6);
      text-overflow: ellipsis;
      /* text-base/font-normal */
      
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; 
      transition: all ease-in-out 1s;
    }
  }
  .corner{
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background: url('https://cdn.litgate.ai/gate/guide/Corner.png') no-repeat;
    background-size: 100% 100%;
  }
}
.activity-card:last-child{
  margin-right: 0
}
</style>
