<template>
  <div>
    <div class="guide-contact-card" @click="openTarget" v-if="!hoverIcon">
      <div class="icon" :style="{ background: 'url(' + iconPath + ') no-repeat', backgroundSize: 'contain' }"></div>
      <div class="title">{{ title }}</div>
    </div>
    <div v-if="hoverIcon != ''">
      <!-- <el-popover
        placement="top-start"
        width="200"
        height="200"
        trigger="hover">
        <div :style="{ width: '200px', height: '200px', background: 'url(' + hoverIcon + ') no-repeat', backgroundSize: 'contain' }"></div>
        <div class="guide-contact-card" slot="reference" @click="openTarget">
          <div class="icon" :style="{ background: 'url(' + iconPath + ') no-repeat', backgroundSize: 'contain' }"></div>
          <div class="title">{{ title }}</div>
        </div>
      </el-popover> -->
      <div class="guide-contact-warp">
        <div class="guide-contact-card" slot="reference" @click="openTarget">
          <div class="icon" :style="{ background: 'url(' + iconPath + ') no-repeat', backgroundSize: 'contain' }"></div>
          <div class="title">{{ title }}</div>
        </div>
        <div class="guide-qrcode-warp">
          <div class="guide-qrcode-box">
            <img :src="hoverIcon" alt="">
          </div>
          <div class="arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'guide-contact-card',
  props: {
    title: {
      type: String,
      default: ''
    },
    iconPath: {
      type: String,
      default: ''
    },
    openLink: {
      type: String,
      default: ''
    },
    hoverIcon: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  mounted() {
     ;
  },
  methods: {
    openTarget() {
      if (this.openLink) window.open(this.openLink);
    }
  }
}

</script>

<style lang="scss" scoped>
.guide-contact-card{
  width: 224px;
  height: 123px;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.30);
  &:hover{
    background: #0E9F6E;
    border-color: #0E9F6E;
    box-shadow: 0px 10px 32px 4px rgba(58, 242, 154, 0.25);
  }
  cursor: pointer;
  .icon{
    min-width: 40px;
    min-height: 40px;
  }
  .title{
    margin-top: 5px;
    color:  #FFF;
    text-align: center;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .guide-hover-icon{
    width: 220px;
    height: 220px;
  }
}
.guide-contact-warp{
  position: relative;
  &:hover{
    // background: #0E9F6E;
    // border-color: #0E9F6E;
    // box-shadow: 0px 10px 32px 4px rgba(58, 242, 154, 0.25);
    .guide-qrcode-warp{
      display: block;
    }
  }
  .guide-qrcode-warp{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 135px;
    display: none;
    .guide-qrcode-box{
      &>img{
        width: 202.3px;
        height: 200px;
        border-radius: 2px;
      }
    }
    .arrow{
      position: absolute;
      left: 50%;
      bottom: 0px;
      transform: translateX(-50%);
      width: 13px;
      height: 6px;
      background: url('https://cdn.litgate.ai/gate/guide/contact/arrow.png') no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
