import request from '@/utils/request';

// 开放平台
const BaseUrl = `/api/basesys`;

// 邮箱收集
export async function sendEmailCollect(email) {
  return request({
    url: `${BaseUrl}/emailcollect`,
    method: 'POST',
    data: {
      email
    }
  });
}
