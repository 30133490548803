import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=f4e8954e&scoped=true"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"
import style0 from "./notice.vue?vue&type=style&index=0&id=f4e8954e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4e8954e",
  null
  
)

export default component.exports